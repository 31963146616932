import { Button, Grid, Grid2, Typography } from "@mui/material";
import FlashCardsIcon from "../../assets/FlashCardfolder.png";
import React, { useState } from "react";
import CreateFlashCard from "./CreateFlashCard";
import InstructionDialog from "../Instrucation";
import { useSelector } from "react-redux";
import { flashCardReducer } from "../../redux/flashCards";

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function FlashCards() {
  const [isFlashCardCreate, setIsFlashCardCreate] = useState(false);
  const [instructionOpen, setInstructionOpen] = useState(false);
  const [orgInfo, setOrgInfo] = useState(() =>
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [tempInfo, setTempInfo] = useState(() =>
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );

  const { getCWCloading } = useSelector(flashCardReducer);

  const handleCreateFlashCard = () => {
    setIsFlashCardCreate(true);
  };
  const handleNewFlashcardsClose = () => {
    setIsFlashCardCreate(false);
  };

  const handleInstruction = () => {
    setInstructionOpen(true);
  };

  const closeInstruction = () => {
    setInstructionOpen(false);
  };

  const TabTitle = "FlashCards";

  const getInstructionKey = (TabTitle) => {
    switch (TabTitle) {
      case "myPicnotes":
        return "picnotes";
      case "myFolder":
        return "folders";
      case "FlashCards":
        return "flashcards";
      default:
        return null;
    }
  };

  return (
    <>
      {getCWCloading ? (
        "Loading data..."
      ) : (
        <Grid container item xs={12}>
          {instructionOpen && (
            <InstructionDialog
              TabName={getInstructionKey(TabTitle)}
              open={instructionOpen}
              onClose={closeInstruction}
            />
          )}

          {isFlashCardCreate ? (
            <Grid item xs={12} width={"100%"}>
              <CreateFlashCard
                handleNewFlashcardsClose={handleNewFlashcardsClose}
              />
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
                marginTop: "20px",
              }}
            >
              <Grid item xs={12} style={{ ...center }}>
                <img
                  src={FlashCardsIcon}
                  alt="Icon"
                  style={{
                    width: "120px",
                    height: "120px",
                  }}
                />
              </Grid>
              <Grid item xs={12} style={center} marginTop={"10px"}>
                <Typography
                  style={{
                    fontFamily: "Aileron",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "21.6px",
                    textAlign: "center",
                    textUnderlinePosition: "from-font",
                    textDecorationSkipInk: "none",
                    color: "#707070",
                  }}
                >
                  {orgInfo?.userId !== tempInfo?.userId
                    ? `${tempInfo?.firstName} has not created any flashcard.`
                    : "You have not created any flashcard."}
                </Typography>
              </Grid>
              <Grid item xs={12} style={center} marginTop={"10px"}>
                {orgInfo?.userId === tempInfo?.userId ? (
                  <Typography
                    style={{
                      fontFamily: "Aileron",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "21.6px",
                      textAlign: "center",
                      textUnderlinePosition: "from-font",
                      textDecorationSkipInk: "none",
                      color: "#707070",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleInstruction}
                  >
                    How do i create Flashcards?
                  </Typography>
                ) : null}
              </Grid>
              {orgInfo?.userId === tempInfo?.userId ? (
                <Grid item xs={12} style={center} marginTop={"15px"}>
                  <Button
                    onClick={handleCreateFlashCard}
                    style={{
                      color: "rgba(188, 23, 29, 1)",
                      minWidth: "130px",
                      height: "38px",
                      border: "1px solid rgba(188, 23, 29, 1)",
                      borderRadius: "25px",
                      fontFamily: "Aileron",
                      fontSize: "13px",
                      fontWeight: 400,
                      margin: "0px 10px",
                      cursor: "pointer",
                      textTransform: "none",
                    }}
                  >
                    Create New Flashcard
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export default FlashCards;
