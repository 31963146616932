import { Grid2, Typography } from "@mui/material";
import React from "react";
import first from "./../../assets/1st.png";
import second from "./../../assets/2nd.png";
import third from "./../../assets/3rd.png";
import line from "./../../assets/line.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeadingStyle = {
  fontFamily: "Cako regular",
  fontSize: "35px",
  fontWeight: 400,
  lineHeight: "55.21px",
  textAlign: "center",
  color: "rgba(63, 63, 63, 1)",
};

const supportingStyle = {
  fontFamily: "Aileron",
  fontSize: "16px",
  fontWeight: 400,
  textAlign: "center",
  color: "rgba(112, 112, 112, 1)",
};

const styleHeading = {
  color: "rgba(63, 63, 63, 1)",
  fontFamily: "Aileron",
  fontSize: "18px",
  fontWeight: "600",
  lineHeight: "26.4px",
  textAlign: "center",
  marginBottom: "10px",
};

const styletext = {
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "21px",
  textAlign: "center",
  maxWidth: "270px",
};

function Guideline() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid2 container mt={"35px"}>
      <Grid2 item size={12}>
        <Typography style={HeadingStyle}>Create Stunning Picnotes</Typography>
      </Grid2>
      <Grid2 item size={12}>
        <Typography style={{ ...supportingStyle, fontSize: "20px" }}>
          {/* Share your knowledge 650 characters at a time */}
          Unlock your learning potential 
        </Typography>
      </Grid2>
      <Grid2
        item
        size={12}
        sx={{
          marginTop: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: { xs: "20px", sm: "57px" },
          flexWrap: "wrap",
        }}
      >
        <img src={first} alt="First" style={{ width: "290px" }} />
        {isMobile ? (
          <Typography variant="h6" sx={styleHeading}>
            Choose Your Image
          </Typography>
        ) : null}
        <img src={second} alt="Second" style={{ width: "290px" }} />
        {isMobile ? (
          <Typography variant="h6" sx={styleHeading}>
          Write Your Notes
          </Typography>
        ) : null}
        <img src={third} alt="Third" style={{ width: "290px" }} />
        {isMobile ? (
          <Typography variant="h6" sx={styleHeading}>
            Preview and Publish
          </Typography>
        ) : null}
      </Grid2>
     {!isMobile ? (
      <>
       <Grid2
        item
        size={12}
        sx={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: { xs: "20px", sm: "50px" },
          flexWrap: "wrap",
        }}
      >
        <img
          src={line}
          alt="Line"
          style={{
            width: "50%",
            maxWidth: { xs: "300px", sm: "400px", md: "600px" },
            height: "auto",
          }}
        />
      </Grid2> 
      <Grid2
        container
        item
        size={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid2
          container
          item
          size={10}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingTop: { xs: "20px", sm: "20px" },
          }}
        >
          <Grid2
            item
            size={3.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "80px",
            }}
          >
            <Typography variant="h6" sx={styleHeading}>
              Choose Your Image
            </Typography>
          </Grid2>

          <Grid2
            item
            size={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "-40px",
            }}
          >
            <Typography variant="h6" sx={styleHeading}>
              Write Your Notes
            </Typography>
          </Grid2>

          <Grid2
            item
            size={3.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "60px",
            }}
          >
            <Typography variant="h6" sx={styleHeading}>
              Preview and Publish
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
      </>
    ) : null}
    </Grid2>
  );
}

export default Guideline;
