import React, { useEffect, useState } from "react";
import { Typography, Button, Dialog, IconButton, Grid2 } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Select } from "antd";
import { useFormik } from "formik";
import "./style.css";
import folder from "../assets/folder-add1.png";
import CreateNewFolder from "./CreateNewFolder";
import { useDispatch, useSelector } from "react-redux";
import { getFolder } from "../redux/folders";
import { addPicnoteToFolder, othersPicnoteToFolder } from "../redux/picnote";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";

const HeadingStyle = {
  fontFamily: "Cako regular",
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "31.21px",
  textAlign: "center",
  color: "rgba(63, 63, 63, 1)",
};

function FolderList({ onClose, picnoteDetail, picnoteResponseWhileAddingNote, picnoteedfOwnerId }) {
  const [createNewFolder, setCreateNewFolder] = useState(false);
  const [originalUser, setOriginalUser] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );

  // Dispatch instance
  const dispatch = useDispatch();

  // console.log("picnoteedfOwnerId", picnoteedfOwnerId);
  // console.log("originalUser", originalUser);
  // console.log("picnoteResponseWhileAddingNote,,,", picnoteResponseWhileAddingNote);

  useEffect(() => {
    dispatch(getFolder());
  }, [dispatch]);

  // Fetch data from the Redux store
  const { allFolderList, getFolderloading } = useSelector(
    (state) => state.folders
  );
  const { addPictoFolderloading } = useSelector((state) => state.picnote);

  // Initialize formik
  const formik = useFormik({
    initialValues: {
      folderId: "",
      noteId: picnoteDetail || picnoteResponseWhileAddingNote,
    },

    onSubmit: async (values) => {
      // console.log("subitted values: ", values);
      let resp = await dispatch(picnoteedfOwnerId !== originalUser?.userId ? othersPicnoteToFolder(values) : addPicnoteToFolder(values));
      if (resp.status === 200) {
        toast.success(resp.data.message);
        onClose();
      } else {
        toast.error(resp.data.message);
      }
    },
  });

  const handleCreateNewFolder = () => {
    setCreateNewFolder(true);
  };

  const onCloseNewFolder = () => {
    setCreateNewFolder(false);
  };

  return (
    <>
      {createNewFolder && <CreateNewFolder onClose={onCloseNewFolder} />}

      <Dialog
        open={true}
        maxWidth="xs"
        sx={{
          zIndex: 1201,
          "& .MuiDialog-paper": { borderRadius: "12px", padding: "20px 30px" },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid2 container alignItems="center">
            <Grid2 item size={12} mb={"10px"}>
              <IconButton
                aria-label="close"
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={onClose}
              >
                <CloseIcon style={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Grid2>

            <Grid2 item size={12} textAlign="center">
              <Typography style={{ ...HeadingStyle, marginBottom: "15px" }}>
                Add Picnotes to Folder
              </Typography>
            </Grid2>

            <Grid2 item size={12} className="inputContainerStyle">
              <Grid2
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  style={{
                    color: "gray",
                    fontSize: "12px",
                    paddingBottom: "5px",
                  }}
                >
                  Name
                </Typography>
                <Typography
                  onClick={handleCreateNewFolder}
                  style={{
                    fontSize: "12px",
                    paddingBottom: "5px",
                    color: "rgba(188, 23, 29, 1)",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={folder}
                    alt="folder logo"
                    style={{ marginRight: "5px", height: "14px" }}
                  />
                  Create New Folder
                </Typography>
              </Grid2>

              <Select
                loading={getFolderloading}
                style={{ width: "100%", height: "38px", fontFamily: "Aileron" }}
                showSearch
                placeholder="Select a folder"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={allFolderList?.folderList?.map((folder) => ({
                  label: folder.title,
                  value: folder.folderId,
                }))}
                onChange={(value) => formik.setFieldValue("folderId", value)} // Set folderId on change
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ zIndex: 1300 }}
              />
            </Grid2>

            <Grid2
              item
              size={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0px",
              }}
            >
              <LoadingButton
                loading={addPictoFolderloading}
                loadingPosition="start"
                type="submit"
                variant="contained"
                style={{
                  borderRadius: "20px",
                  color: "white",
                  width: "50%",
                  backgroundColor: "rgba(188, 23, 29, 1)",
                  textTransform: "none",
                }}
              >
                Add to Folder
              </LoadingButton>
            </Grid2>
          </Grid2>
        </form>
      </Dialog>
    </>
  );
}

export default FolderList;
