import axios from "axios";
import config from "../services/config";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  loadingAllTags: false,
  allTagsList: null,
  loadingAllFollowers: false,
  FollowersList: null,
  loadingFollowingList: false,
  followingList: null,
  selectedFollower: null, 
  tempProfileInfo: null,
  tmpProfileToken: null,
};

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    addingTag(state) {
      state.loading = true;
      state.error = null;
    },
    addingTagSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    addingTagFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    gettingAllTags(state) {
      state.loadingAllTags = true;
      state.error = null;
    },
    gettingAllTagsSuccess(state, action) {
      state.loadingAllTags = false;
      state.error = null;
      state.allTagsList = action.payload;
    },
    gettingAllTagsFailure(state, action) {
      state.loadingAllTags = false;
      state.error = action.payload;
    },

    gettingAllFollowers(state) {
      state.loadingAllFollowers = true;
      state.error = null;
    },
    gettingAllFollowersSuccess(state, action) {
      state.loadingAllFollowers = false;
      state.error = null;
      state.FollowersList = action.payload;
    },
    gettingAllFollowersFailure(state, action) {
      state.loadingAllFollowers = false;
      state.error = action.payload;
    },

    gettingFollowingList(state) {
      state.loadingFollowingList = true;
      state.error = null;
    },
    gettingFollowingListSuccess(state, action) {
      state.loadingFollowingList = false;
      state.error = null;
      state.followingList = action.payload;
    },
    gettingFollowingListFailure(state, action) {
      state.loadingFollowingList = false;
      state.error = action.payload;
    },

    // New reducer to set the selected follower
    setSelectedFollower(state, action) {
      state.selectedFollower = action.payload;
    },

    setTempProfile(state, action) {
      state.tempProfileInfo = action.payload;
    },
  },
});

export const {
  addingTag,
  addingTagSuccess,
  addingTagFailure,
  gettingAllTags,
  gettingAllTagsSuccess,
  gettingAllTagsFailure,
  gettingAllFollowers,
  gettingAllFollowersSuccess,
  gettingAllFollowersFailure,
  gettingFollowingList,
  gettingFollowingListSuccess,
  gettingFollowingListFailure,
  setSelectedFollower, // Export new action
  setTempProfile,
} = slice.actions;

export const selectAllTagsList = (state) => state.tags.allTagsList;
export const selectSelectedFollower = (state) => state.tags.selectedFollower; // Selector for selected follower

// Thunk to create a new tag
export const createTag = (data) => async (dispatch) => {
  dispatch(addingTag());
  try {
    const response = await axios.post(`${config.baseUrl}/picnote/addtag`, data);
    if (response.status === 200) {
      dispatch(addingTagSuccess());
      dispatch(allTags()); // Optionally fetch updated tags list if needed
    } else {
      dispatch(addingTagFailure(response.status));
    }
    return response;
  } catch (e) {
    dispatch(addingTagFailure("Something went wrong while adding tag!"));
  }
};

// Thunk to get all tags
export const allTags = () => async (dispatch) => {
  dispatch(gettingAllTags());
  try {
    const response = await axios.get(`${config.baseUrl}/picnote/listactivetags`);
    if (response.status === 200) {
      dispatch(gettingAllTagsSuccess(response.data));
    } else {
      dispatch(gettingAllTagsFailure(response.data));
    }
    return response;
  } catch (e) {
    dispatch(gettingAllTagsFailure("Something went wrong while getting tags!"));
  }
};

// Thunk to get followers list
export const getFollowerList = () => async (dispatch) => {
  dispatch(gettingAllFollowers());
  try {
    const response = await axios.get(`${config.baseUrl}/edf/fetchfollowers`);
    if (response.status === 200) {
      dispatch(gettingAllFollowersSuccess(response.data));
    } else {
      dispatch(gettingAllFollowersFailure(response.data));
    }
    return response;
  } catch (e) {
    dispatch(gettingAllFollowersFailure("Something went wrong while getting followers!"));
  }
};

// Thunk to get following list
export const getFollowingList = () => async (dispatch) => {
  dispatch(gettingFollowingList());
  try {
    const response = await axios.get(`${config.baseUrl}/edf/fetchfollowings`);
    if (response.status === 200) {
      dispatch(gettingFollowingListSuccess(response.data));
    } else {
      dispatch(gettingFollowingListFailure(response.data));
    }
    return response;
  } catch (e) {
    dispatch(gettingFollowingListFailure("Something went wrong while getting following list!"));
  }
};

export default slice.reducer;
