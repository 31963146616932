import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Chip, Dialog, Grid2, IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextArea from "antd/es/input/TextArea";
import { Input } from "antd";
import { useFormik } from "formik";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import * as yup from "yup";
import { createTool, editTool, getAllTools } from "../redux/tool";
import { allTags, createTag } from "../redux/tags";
import arrow from "../assets/arrow-down-01-round.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const headerStyle = {
  fontFamily: "Cako regular",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "31.21px",
  textAlign: "center",
  color: "rgba(63, 63, 63, 1)",
};

const labelStyle = {
  marginBottom: "5px",
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "31.21px",
};

const inputContainerStyle = {
  marginBottom: "15px",
};

const inputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "38px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
};

const centerlizing = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
function AddToTools({ onClose, title, clickedCard1 }) {
  const [imageUrl, setImageUrl] = useState("");
  const [imageError, setImageError] = useState(false);
  const [contentType, setContentType] = useState(""); // Add this line
  const [videoUrl, setVideoUrl] = useState(""); // Add this line


  //! INSTANCES
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  // Fetch data from the Redux store
  const { createTrackerL, allTools } = useSelector((state) => state.tracker);
  const { allTagsList } = useSelector((state) => state.tags);

  // console.log("clickedCard1", clickedCard1)
  // console.log("allTagsList", allTagsList)

  useEffect(()=>{
    dispatch(getAllTools())
    dispatch(allTags());
  },[])
  
  const validationSchema = yup.object({
    toolTitle: yup
      .string()
      .required("title is required")
      .min(3, "title must be at least 3 characters"),
      toolNotes: yup
      .string()
      .required("Note is required")
      .min(3, "Note must be at least 3 characters"),
      toolLink: yup
      .string()
      .required("Tool Link is required")
      .min(3, "Tool Link must be at least 3 characters"),
      toolTags: yup
      .array()
      .min(1, "At least one tag is required")
      .of(yup.object().shape({ title: yup.string().required() })),
 
  });

  const formik = useFormik({
    initialValues: {
      // toolTitle: title?.toolTitle ||  "",
      toolTitle: title?.toolTitle || "",
      toolNotes:  title?.toolNotes || "",
      toolLink:  title?.toolLink || "",
      toolTags:   [],
      toolType: title?.toolType?.toLowerCase() || title?.name,
      toolId: title?.toolId || "",
      ownerId: title?.ownerId || ""

    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        toolType: values.toolType.toLowerCase(),
        toolTags: values.toolTags.map(tag => tag.tagId),
        
      };
      // let resp = await dispatch( createTool(payload));
      let resp = await dispatch(clickedCard1 ? editTool(payload) : createTool(payload));
      if (resp.status === 200) {
        dispatch(getAllTools());
        toast.success(resp.data.message);
        onClose();
        formik.resetForm();
      } else {
        toast.error(resp.data.message);
      }
    },
  });

  const handleInputChange = (e) => {
    const url = e.target.value;
    setImageError(false);

    try {
      const domain = new URL(url).hostname;

      // Image handling
      if (/\.(jpeg|jpg|gif|png)$/.test(url)) {
        setContentType("image");
        setImageUrl(url);
      }
      // YouTube video handling
      else if (domain.includes("youtube.com") || domain.includes("youtu.be")) {
        const videoId = url.split("v=")[1] || url.split("/").pop();
        setContentType("video");
        setVideoUrl(`https://www.youtube.com/embed/${videoId}`);
      }
      // Website logo for articles or general websites
      else {
        setContentType("logo");
        setImageUrl(`https://logo.clearbit.com/${domain}`);
      }
    } catch {
      setImageUrl("");
      setImageError(true);
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      sx={{
        zIndex: 1200,
        "& .MuiDialog-paper": {
          borderRadius: "10px",
          padding: "20px 30px",
          minWidth: isMobile ? "350px" : "400px",
          maxWidth: isMobile ? "500px" : "600px",
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid2 container alignItems="center">
          <Grid2 item size={12} mb={"10px"}>
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={onClose}
            >
              <CloseIcon style={{ width: "20px", height: "20px" }} />
            </IconButton>
          </Grid2>

          <Grid2 item size={12} textAlign="center">
            <Typography
              variant="h5"
              component="div"
              style={{ ...headerStyle, marginBottom: "20px" }}
            >
              {title.name}
            </Typography>
          </Grid2>
        </Grid2>

        <Grid2 item size={12} style={inputContainerStyle}>
          <label htmlFor="title" style={labelStyle}>
            Title (Required)
          </label>
          <Input
            className="inputTypeTextStyle"
            id="toolTitle"
            style={{ ...inputStyle, width: "100%" }}
            placeholder="Topic (Required)"
            name="toolTitle"
            value={formik.values.toolTitle}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.toolTitle && Boolean(formik.errors.toolTitle)}
          />
          {formik.touched.toolTitle && formik.errors.toolTitle ? (
            <div style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.toolTitle}
            </div>
          ) : null}
        </Grid2>

        <Grid2 item size={12} style={inputContainerStyle}>
          <label htmlFor="author" style={labelStyle}>
          Notes (Required)
          </label>
          <Input
            className="inputTypeTextStyle"
            id="toolNotes"
            style={{ ...inputStyle, width: "100%", 
              height: "75px",
              borderRadius: "10px",


             }}
            placeholder="Notes"
            name="toolNotes" // Bind to formik
            value={formik.values.toolNotes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.toolNotes && Boolean(formik.errors.toolNotes)}
          />
            {formik.touched.toolNotes && formik.errors.toolNotes ? (
            <div style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.toolNotes}
            </div>
          ) : null}
        </Grid2>

        {/* <Grid2 item size={12} mb={"10px"}>
          <Typography
            variant="body6"
            style={{ ...labelStyle, marginBottom: "8px", textAlign: "left" }}
          >
            Notes
          </Typography>
          <TextArea
            className="inputTypeTextStyle"
            placeholder="Add description here"
            rows={6}
            maxRows={6}
            name="description" // Add this line to bind it with formik
            value={formik.values.description} // Bind the value to formik state
            onChange={formik.handleChange} // Bind the onChange to formik
            onBlur={formik.handleBlur} // Bind the onBlur to formik
            style={{
              borderRadius: "10px",
              width: "100%",
              border: "1px solid rgba(236, 228, 228, 1)",
              paddingLeft: "10px",
              paddingRight: "40px",
            }}
          />
        </Grid2> */}

        <Grid2 item size={12} mb={"10px"}>
          <Typography
            variant="body6"
            style={{ ...labelStyle, marginBottom: "8px", textAlign: "left" }}
          >
            Link
          </Typography>
          <div className="inputContainer">
            <Input
              className="inputTypeTextStyle"
              placeholder="add link"
              name="toolLink" // Bind to Formik
              value={formik.values.toolLink} // Bind the value to Formik state
              onChange={(e) => {
                formik.handleChange(e); // Update Formik state
                handleInputChange(e); // Call your existing handler
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.toolLink && Boolean(formik.errors.toolLink)}
              style={{ ...inputStyle,
                width: "100%",
                height: "77px",
                borderRadius: '10px'
              }}
            />

            {/* Conditional Rendering */}
            {contentType === "image" && !imageError && (
              <img src={imageUrl} alt="Image" className="inputImage" />
            )}

            {contentType === "video" && (
              <iframe
                width="120px"
                height="75px"
                src={videoUrl}
                title="Video Preview"
                allow="fullscreen"
              />
            )}

            {contentType === "logo" && !imageError && (
              <img src={imageUrl} alt="Website Logo" className="inputImage" />
            )}

            {/* Error Message */}
            {imageError && (
              <Typography
                color="red"
                fontSize={"11px"}
                style={{ marginTop: "5px" }}
              >
                Image could not be loaded.
              </Typography>
            )}
          </div>
          {formik.touched.toolLink && formik.errors.toolLink ? (
            <div style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.toolLink}
            </div>
          ) : null}
        </Grid2>

        <Grid2 item size={12} style={inputContainerStyle}>
          <Typography
            variant="body6"
            style={{ ...labelStyle, marginBottom: "8px", textAlign: "left" }}
          >
            Hashtags (Required)
          </Typography>
          <Autocomplete
          freeSolo
            popupIcon={
              <img
                src={arrow}
                alt="drop"
                style={{
                  color: "#7A53EC",
                  margin: "3px 5px",
                  width: "22px",
                  height: "22px",
                }}
              />
            }
            value={formik.values.toolTags}
            id="toolTags"
            multiple
            limitTags={1}
            options={allTagsList || []}
            getOptionLabel={(option) => option?.title || ""}
            onChange={(e, value) => {
              if (!value || value.length === 0) return; // Check if value is not empty

              const newTagTitle =
                value[value.length - 1]?.title || value[value.length - 1];

              // Check if it's a new tag and not already in allTagsList
              if (
                newTagTitle &&
                !allTagsList.some((tag) => tag.title === newTagTitle)
              ) {
                // Dispatch action to add the new tag to Redux
                dispatch(createTag({ title: newTagTitle }));
              }

              formik.setFieldValue("toolTags", value);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  label={option?.title}
                  size="small"
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                variant="standard"
                id="toolTags"
                name="toolTags"
                size="small"
                placeholder="Tags"
                error={
                  formik.touched.toolTags &&
                  Boolean(formik.errors.toolTags)
                }
                style={{
                  borderRadius: "25px",
                  padding: "5px",
                  border: "1px solid #d1d1d1",
                  paddingLeft: "15px",
                  fontSize: '12px'
                }}
              />
            )}
          />

          {/* Display error message below the Autocomplete */}
          {formik.touched.toolTags && formik.errors.toolTags ? (
            <Typography
              color="red"
              fontSize="12px"
              style={{ marginTop: "5px" }}
            >
              {formik.errors.toolTags}
            </Typography>
          ) : null}
        </Grid2>


        <Grid2
          item
          size={12}
          style={{ ...centerlizing, paddingBottom: "10px" }}
        >
          <LoadingButton
            loading={createTrackerL}
            loadingPosition="start"
            type="submit"
            style={{
              borderRadius: "20px",
              color: "white",
              width: "50%",
              backgroundColor: "rgba(188, 23, 29, 1)",
              textTransform: "none",
            }}
          >
            Submit
          </LoadingButton>
        </Grid2>
      </form>
    </Dialog>
  );
}

export default AddToTools;
