import auth from "./auth";
import folders from "./folders";
import media from "./media";
import picnote  from "./picnote";
import tool  from "./tool";
import tracker from "./tracker";
import user from "./user";
import tags from "./tags";
import  flashcards from "./flashCards";

const rootReducer = {
  auth,
  media,
  user,
  picnote,
  folders,
  tracker,
  tool,
  tags,
  flashcards,
};
export default rootReducer;
