import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { Link } from "react-router-dom";

const style = {
  marginRight: "10px",
  borderRadius: "50px",
  border: "1px solid",
  padding: "5px",
  width: "30px",
  height: "30px",
  color: "#fff",
  cursor: "pointer",
};


function BlogDetail({ handleClose, details }) {
  const interviews = [
    {
      name: "Dexter Chu",
      bio: "With a professional background in computer science, Dexter Chu's lifelong learning journey has greatly expanded his competence and knowledge in various areas. Dexter Chu greatly values spontaneous and informal learning.",
      x: "",
      website:
        "https://www.meetup.com/login/?returnUri=https://www.meetup.com/Vancouver-Knowledge-Sharing-Club/members/180221552/",
      firstQuestion:
        "Can you please share the main educational topics that you love and why?",
      firstAnswer:
        "I am interested in almost every part of science.  I like it because it is our best attempt at explaining the physical world.  All the experiments and thinking that so many people have done to figure out the world is there for us to read.  It feels almost like cheating to get that knowledge and not have done the work myself. I also find economics and current affairs interesting.  I'm interested in it because it is trying to explain some of our social worlds.  Experiments can't be done for most social issues, and conclusions are usually tentative, but that's the nature of the subject.",
      secondQuestion:
        "How do you measure your learning progress in a specific field?",
      secondAnswer:
        "When I read an article or even a whole book, I quickly forget a lot of the information. However, I measure my learning progress and my understanding of specific topics on my capacity to explain them to other people. For example, if I'm able to explain a geological event to my meetup group members, that means that I have a great understanding of that topic.",
      thirdQuestion:
        "Between structure and spontaneity, how would you describe your schedule for learning activities?",
      thirdAnswer:
        "It's spontaneity. I don't have a plan, or anything planned out in terms of learning about a particular area. It's very ad hoc. I dedicate time to whatever I happen to hear that sounds particularly interesting, which sparks my curiosity.",
    },
    {
      name: "Naomi Harm",
      bio: "Naomi Harm is a celebrated educational leader who's passionate about women leadership, brain-based teaching and learning, and instigating STEM innovation into all learning offerings. As the Founder and CEO of Innovative Educator Consulting, Naomi speaks with knowledge and passion on education and the use of technology to create innovative solutions for educational organizations.",
      x: "https://x.com/naomiharm",
      website: "https://www.innovativeeducator.org/",
      firstQuestion:
        "How would you define an innovative educational experience for K to 12 schools?",
      firstAnswer:
        "An innovative educational experience for K to 12 schools is an experience that is more immersed in multi-sensory learning. Meaning, there could be different ways that a child can experience a problem provided for them. They can experience it with hands-on learning and manipulatives. They can also experience it by having an opportunity to reflect on their learning whether they make a simple sketch note, they do a markup, or they do a quick write as a reflection to understand their learning. But then also, it can be a tech-enhanced experience, where the student wants to show what they know by producing a dynamic learning activity to showcase that experience. An innovative educational experience meets the children where they are at based on how they learn how they process information, and yet choosing the best tool basically, to get the job done. Children’s learning experience is almost like an immersion. Meaning that it's not just one idea, one strategy, and we're done. It's that overarching ability that children are now responsible, and creators of their learning. ",
      secondQuestion:
        "How can schools combine their educational vision to their strategic learning goals?",
      secondAnswer:
        "Yes, that's a very big question. I've worked exclusively in a partnership with ISTE, the International Society for technology and education. And ISTE provides technology literacy and leadership skills for all of our schools, our students, our administrative staff, our technology coaches, and our computer science teachers. When we create a vision, we do a backward design by using a UDL model. That particular model helps us take into accountability every kind of learner. It also gives us the accessibility factor in making sure that every child, parent, teacher, the administrator are successful with that plan. With the creation of a plan and then doing a backward design, we strategically align the school's goals. The goals are then broken out into chunks, but the goals are aligned to the standards of ISTE. The learning scenarios that are built within the ISTE standards give what the learning experiences can look like. It doesn't mean it just has to be technology. It means the type of equity and access of the learning experience to the child, meaning, how to be an effective communicator, how to model effective digital citizenship, and how to put on the hat of a designer because students will be designers of this new future. ",
      thirdQuestion:
        "What are the core educational needs of today's Generation Z and Alpha students? ",
      thirdAnswer:
        "The core values and beliefs of how and why our children learn best up today are that they really, genuinely note that they love to work in actually smaller groups instead of larger groups. They want to have experiences where they can learn something new in small snackable bite-sized chunks, but then they want to interpret it at their rate and pace; they want more time to process. Instead of just having somebody lecture at them. They want to find out something new, but they want to work with it through those different learning sensories. Children want to connect with a teammate or a friend because our kids are so social. You could set them up as a dynamic duo or a transformative trio of kids. Being in small groups, kids can come up with new ideas; they can share their learning experiences and understand another child's understandings or their perspective. The other thing that we truly know is that our children love to tinker, make, and create. They want to be immersed in that learning, they want to see how things work, they want to fix it, and they want to build it. Our children also look for a social cause, and they want to impact our world for good. ",
    },
    {
      name: "Ahmed Hussein",
      bio: "Ahmed Hussein is a renowned professor emeritus of physics at the University of Northern British Columbia and an affiliate scientist at TRIUMF. He is considered a polymath in the world of science. His knowledge span and expertise include nuclear physics, astronomy, astrophysics, radioactivity, and so much more.",
      x: "",
      website: "https://www.unbc.ca/people/hussein-dr-ahmed",
      linkedIn: "https://www.linkedin.com/in/ahmed-hussein-73a8975/",
      firstQuestion:
        "Throughout your teaching career, what is your biggest accomplishment? ",
      firstAnswer:
        "When I joined UNBC, the university was at its early stage. They hired me as the chair of the physics department, and I was in charge of building this department from scratch. That was a great challenge. The undergraduate and graduate curricula had to be set. That involved defining the title, description, prerequisites, and credit hours of over 50 undergraduate and 20 graduate courses. Finally, we had to decide on the textbooks and order enough copies through the university bookstore. In addition, all lab courses had to be set up as well, and that involved selecting the experiments that had to be done in each course, buying the equipment, and writing up lab manuals that describe the theoretical basis of each experiment, how experiments can be carried out, determining the data to be collected and how the data be analysed. The crucial part of the job was to make sure we were following the national and international standards so that our physics degrees would be recognized by Canadian and international universities. At the same time, we were hiring faculty members, and making sure they are highly qualified not only as teaching professors but also as excellent researchers. Overall, we built the department reasonably well. And eventually, after five years, UBC, along with three other major Canadian universities, reviewed our department. They talked to me, to the other professors, and the students. They reviewed the qualifications of all the professors and their research activities, and the curriculum and concluded that the Physics department at UNBC was of excellent standards. I was proud of the entire team, and it was an outstanding achievement.",
      secondQuestion:
        "Physics is sometimes viewed as a difficult field of study, what advice would you give a lifelong learner who wants to learn the basics of physics on their own?",
      secondAnswer:
        "There are lots of popular science books about physics. They are available everywhere, and many are written in a language that the general public can understand. There's a book by Stephen Hawkins called A Brief History of time, and it's an excellent book for people interested in science and physics in particular. So, my advice is to go to a bookstore or the internet and look for popular physics books. Try them. They explain complicated theories in a simple language; most people understand. That can provide a general view of what physics is all about. If, however, you want to get more detailed knowledge, you can start with a professional physics book like high school physics book or a first-year university physics book. And then you will have, at that time, enough background to let you go step by step through the higher levels and understand physics a bit more. By the way, I hear this all the time from people I meet in various situations, like fellow passengers in airplane for example.",
      thirdQuestion:
        "Do you think that technology has made physics easier to understand?",
      thirdAnswer:
        "Computers made teaching and research much easier. I started working with computers a few years after my graduation. That computer was an early modern computer. It was bought by the Faculty of Engineering at Alexandria University. Once I knew that it was there, I went and took a course on how to program, and I have been using computers since then. Right now, my iPhone is much more powerful than my first computer. Before the availability of that computer, I used to do my calculations using a mechanical device.You can now demonstrate and show things on computers that are difficult to show otherwise. Other technologies like how we make measurements are much easier and much more accurate than it used to be. Now it is possible to do much more complex experiments and control the equipment remotely. Due to the communication revolution, hundreds of scientists from different countries can collaborate in experimental research without leaving their home institutions. So, technological developments improved how we do experiments, and how data gets accumulated. Now, we accumulate massive amount of data in a relatively short period of time. We also understand a lot more from those experiments than we used to. Improvements in visualization techniques using computers or even 3D printers allow deeper understanding of experimental data. The progress in many branches of science has accelerated tremendously, particularly in medicine, pharmaceuticals, astronomy, and cosmology, due to technological development in those fields. However, of course, this is the nature of research. Easy experiments are always done first. Now new experiments have their challenges, and they have their difficulties to overcome, and hopefully, new technologies will make them possible.",
    },
    {
      name: "David Crowe",
      bio: "David Crowe is a passionate lifelong learner who views knowledge-seeking as the ultimate sign of vitality. As a retired computer scientist, ongoing education is at the centre of his life.",
      x: "",
      website: "",
      linkedIn: "https://www.linkedin.com/in/david-crowe-2070551/",
      firstQuestion: "Why is learning so necessary to your day-to-day life?",
      firstAnswer:
        "I guess I'm a cerebral person. My primary purpose in life almost is information gathering. Learning new things sort of makes my day and reminds me of my vitality. I tend to remember things so that when I learned something new, I can often relate it to something I've already learned. ",
      secondQuestion:
        "What motivates you to actively seek knowledge that is not related to your professional field?",
      secondAnswer:
        "I've always been interested in a broad range of topics. What I do to gather information is to try to have all sorts of information sources across my desk.  I am drawn to issues using a very low threshold of relevance to my field. If it's interesting, even if it's not necessarily very relevant, then I'll still look at it. And if I start gaining knowledge in the area, well, then I'm certainly interested in learning more. Now it has to be said that events outside my field can often affect my field, too. You can't be learning things just narrowly in your field and necessarily function properly. My professional field is computer science, but I have always had an interest in history and human achievement.",
      thirdQuestion:
        "What are the three key characteristics of a lifelong learner?",
      thirdAnswer:
        "A lifelong learner, I think, is fairly cerebral. Learning feeds the brain, and the lifelong learner has to view the brain as the main organ. Although for me, I must say my stomach sometimes provides some competition because I do like eating. A lifelong learner had to be curious. Your curiosity drives a lot of learning and research. It motivates learners to go down new routes to gain new knowledge. And a lifelong learner, I think, is often quite observant. A lot of us learn simply by observing our surroundings. ",
    },
  ];

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        style={{
          padding: "10px 0px 40px 0px",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Left Side: Dummy Text */}

          <Grid item xs={12} sm={4}>
            <img
              src={details?.image}
              alt="Blog Detail"
              style={{
                width: "100%",
                maxHeight: "350px",
                minHeight: "350px",
                objectFit: "fill",
                borderRadius: "10px",
              }}
            />
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: "10px 0" }}
            >
              <Typography variant="h5" fontFamily={"Cako regular"}>
                {details?.title}
              </Typography>
              {/* <div>
                <LanguageIcon
                  style={{ ...style, backgroundColor: "#bc171d" }}
                />
                <FacebookOutlinedIcon
                  style={{ ...style, backgroundColor: "#5890FF" }}
                />
                <XIcon style={{ ...style, backgroundColor: "#000" }} />
                <LinkedInIcon
                  style={{ ...style, backgroundColor: "#0077B5" }}
                />
              </div> */}
              <Box display="flex" alignItems="center" mb={2}>
                {details.website && (
                  <Link
                    to={details.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Box>
                      <LanguageIcon
                        style={{ ...style, backgroundColor: "#bc171d" }}
                      />
                    </Box>
                  </Link>
                )}
                {details.x && (
                  <Link
                    to={details.x}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Box>
                      <XIcon style={{ ...style, backgroundColor: "#000" }} />
                    </Box>
                  </Link>
                )}
                {details.linkedIn && (
                  <Link
                    to={details.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Box>
                      <LinkedInIcon
                        style={{ ...style, backgroundColor: "#0077B5" }}
                      />
                    </Box>
                  </Link>
                )}
              </Box>
            </Grid>
          </Grid>

          {/* Right Side: Image */}

          <Grid container item xs={12} sm={8}>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="h5"
                fontWeight={600}
                gutterBottom
                style={{
                  fontFamily: "Aileron",
                }}
              >
                {details?.description}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              maxHeight={"350px"}
              overflow={"auto"}
              borderRadius={"10px"}
              border={"1px solid"}
            >
              <Typography variant="body1">
                {interviews
                  .filter((item) => item.name === details.title)
                  .map((item, index) => (
                    <div key={index} style={{ marginTop: "10px" }}>
                      <Typography
                        style={{ padding: "10px", textAlign: "justify" }}
                      >
                        {item.bio}
                      </Typography>

                      <h4 style={{ padding: "10px" }}>
                        <span style={{ fontWeight: "bold", color: "#bc171d" }}>
                          Q:
                        </span>{" "}
                        {item.firstQuestion}
                      </h4>
                      <p
                        style={{
                          padding: "10px",
                          textAlign: "justify",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "green",
                            marginRight: "8px",
                          }}
                        >
                          A:
                        </span>
                        <Typography color="text.secondary">
                          {item.firstAnswer}
                        </Typography>
                      </p>

                      <h4 style={{ padding: "10px", textAlign: "justify" }}>
                        <span style={{ fontWeight: "bold", color: "#bc171d" }}>
                          Q:
                        </span>{" "}
                        {item.secondQuestion}
                      </h4>
                      <p
                        style={{
                          padding: "10px",
                          textAlign: "justify",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "green",
                            marginRight: "8px",
                          }}
                        >
                          A:
                        </span>{" "}
                        <Typography color="text.secondary">
                          {item.secondAnswer}
                        </Typography>
                      </p>

                      <h4 style={{ padding: "10px", textAlign: "justify" }}>
                        <span style={{ fontWeight: "bold", color: "#bc171d" }}>
                          Q:
                        </span>{" "}
                        {item.thirdQuestion}
                      </h4>
                      <p
                        style={{
                          padding: "10px",
                          textAlign: "justify",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "green",
                            marginRight: "8px",
                          }}
                        >
                          A:
                        </span>{" "}
                        <Typography color="text.secondary">
                          {item.thirdAnswer}
                        </Typography>
                      </p>
                    </div>
                  ))}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default BlogDetail;
