import React from "react";
import picture from "./../../assets/about1.jpg";
import { Button, Grid2, Typography } from "@mui/material";
import Grid from "antd/es/card/Grid";
import Schools from "./Schools";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const buttonStyle = {
  padding: "15px",
  width: "197px",
  height: "48px",
  gap: "0px",
  borderRadius: "90px",
  backgroundColor: "rgba(188, 23, 29, 1)",
  color: "rgba(255, 255, 255, 1)",
  fontFamily: "coko regular",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  textAlign: "center",
  marginTop: "20px",
  textTransform: "none",
  "@media (max-width: 600px)": {
    width: "140px",
    height: "40px",
  },
};

const textStyle = {
  fontFamily: "Cako regular",
  fontSize: "65px",
  fontWeight: 400,
  lineHeight: "76.02px",
  textAlign: "left",
  "@media (max-width: 600px)": {
    fontSize: "40px",
    lineHeight: "48px",
  },
};

function MainPageForSchool() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const HeadingStyle = {
    fontFamily: "Cako regular",
    fontSize: isMobile ? "30px" : "35px",
    fontWeight: 400,
    lineHeight: "55.21px",
    textAlign: "center",
    color: "rgba(63, 63, 63, 1)",
    padding: "0px 20px",
  };

  return (
    <Grid2 container>
      <Grid2 container item size={isMobile ? 12 : 6} marginTop={"30px"}>
        <Grid2 item size={12} marginLeft={"50px"}>
          <Typography
            style={{
              ...textStyle,
              lineHeight: isMobile ? "48px" : "76.02px",
              fontSize: isMobile ? "35px" : "65px",
              color: "rgba(63, 63, 63, 1)",
            }}
          >
            Unlock Potential: <br />
            Sign up to Transform Your School and <br />
            Support Your{" "}
            <span
              style={{
                ...textStyle,
                color: "rgba(188, 23, 29, 1)",
                fontWeight: "bold",
                fontSize: isMobile ? "35px" : "65px",
              }}
            >
              Teachers!
            </span>
          </Typography>
          <Grid2
            item
            size={12}
            display={"flex"}
            justifyContent={"start"}
          >
            <Link to="../schoolsignup">
              <Button
                style={{ ...buttonStyle, width: isMobile ? "220px" : "197px" }}
              >
                Sign up as School
              </Button>
            </Link>
          </Grid2>
        </Grid2>
      </Grid2>

      <Grid2 item size={isMobile ? 12 : 6} mt={isMobile ? "30px" : "0px"}>
        <img
          src={picture}
          alt="Logo"
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "759px",
          }}
        />
      </Grid2>

      <Grid2
        item
        size={isMobile ? 12 : 12}
        style={{
          backgroundColor: "rgba(188, 23, 29, 0.1)",
          height: { xs: "auto", md: "350px" },
          marginTop: "20px",
        }}
      >
        <Grid2 item size={12}>
          <Typography style={{ ...HeadingStyle, marginBottom: "20px" }}>
            Our registered Schools
          </Typography>
          <Grid2
            item
            size={isMobile ? 11 : 12}
            style={{
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-start", 
              alignItems: "center", 
              margin: "0 auto", 
            }}
          >
            <Schools />
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

export default MainPageForSchool;
