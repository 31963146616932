import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  IconButton,
  Grid2,
  Avatar,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import search from "./../assets/search.png";
import { Input } from "antd";
import { eduList, followUser, profileDetial } from "../redux/user";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const headerStyle = {
  fontFamily: "Cako regular",
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "31.21px",
  textAlign: "center",
  color: "rgba(63, 63, 63, 1)",
};

const listName = {
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "31.21px",
  color: "rgba(63, 63, 63, 1)",
  letterSpacing: "0.5px",
};

function LifeLongFollowers({ onClose }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );

  // Dispatch instance
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(eduList({ edfId: userDetail?.userId }));
  }, [dispatch]);

  // Fetch data from the Redux store
  const { eduListDta, folloingLoader } = useSelector((state) => state.user); // Access the state correctly

  // console.log("folloingLoader:", folloingLoader)

  const filteredUsers = Array.isArray(eduListDta?.userFollowList)
    ? eduListDta.userFollowList.filter(
        (user) =>
          user?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user?.lastName?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const handleFollow = async (followId) => {
    // Dispatch the followUser action and wait for the result
    let resp = await dispatch(followUser({ followId }));

    if (resp.status === 200) {
      toast.success(resp.data.message);
      dispatch(eduList());
      dispatch(profileDetial());
    } else {
      toast.error(resp.data.message);
    }
  };

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="md"
      sx={{
        zIndex: 1200,
        "& .MuiDialog-paper": {
          borderRadius: "15px",
          minWidth: isMobile ? "350px" : "400px",
          maxWidth: isMobile ? "20%" : "25%",
        },
      }}
    >
      <Grid2 container alignItems="center" paddingTop={"20px"}>
        <Grid2 item size={12} alignItems="center" mb={"10px"}>
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={onClose}
          >
            <CloseIcon style={{ width: "20px", height: "20px" }} />
          </IconButton>
        </Grid2>

        <Grid2 item size={12} textAlign="center">
          <Typography
            variant="h5"
            component="div"
            style={{ ...headerStyle, marginBottom: "20px" }}
          >
            Follow Lifelong Learners
          </Typography>
        </Grid2>

        <Grid2 item size={12} textAlign="center" p={"0px 30px"}>
          {/* Search bar */}
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "100%",
            }}
          >
            <Input
              style={{
                borderRadius: "20px",
                width: "100%",
                height: "38px",
                backgroundColor: "rgba(245, 245, 245, 1)",
                border: "1px solid rgba(236, 228, 228, 1)",
                paddingLeft: "10px",
                paddingRight: "40px",
              }}
              type="text"
              placeholder="Enter name"
              value={searchQuery} // Bind input value to searchQuery
              onChange={(e) => setSearchQuery(e.target.value)} // Update searchQuery on input change
            />
            <span
              style={{
                position: "absolute",
                right: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
                backgroundColor: "rgba(188, 23, 29, 1)",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={search}
                alt="Search"
                style={{ height: "16px", width: "16px" }}
              />
            </span>
          </div>
        </Grid2>

        {/* List of users */}
        <Grid2 item size={12} padding={"0px 20px"}>
          <div
            style={{
              minHeight: "300px",
              maxHeight: "500px",
              overflowY: "auto",
            }}
          >
            <List>
              {folloingLoader
                ? "loading..."
                : filteredUsers.map((user) => (
                    <ListItem
                      key={user.edfluencerId}
                      style={{
                        borderBottom: "1px solid #eee",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* Avatar component */}
                      {/* <Avatar
                        alt={user.firstName}
                        src={user.userPicture}
                        style={{ marginRight: "10px" }}
                      /> */}

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        {user.userPicture &&
                        (user.userPicture.endsWith(".jpg") ||
                          user.userPicture.endsWith(".jpeg") ||
                          user.userPicture.endsWith(".png")) ? (
                          <img
                            src={user?.userPicture}
                            alt={user?.firstName}
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <Box
                            width="30px"
                            height="30px"
                            borderRadius="50%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor="#b10303"
                            color="white"
                            fontSize="15px"
                            fontWeight="bold"
                            textTransform="uppercase"
                            // marginBottom="15px"
                          >
                            {user?.firstName?.[0]}
                            {user?.lastName?.[0]}
                          </Box>
                        )}
                      </Box>

                      <ListItemText
                        primary={user.firstName + " " + user.lastName}
                        primaryTypographyProps={{ style: listName }} // Apply styles correctly
                      />

                      <LoadingButton
                        loading={folloingLoader}
                        loadingPosition="start"
                        style={{
                          color: "rgba(188, 23, 29, 1)",
                          fontSize: "12px",
                          fontFamily: "Aileron",
                          fontWeight: 600,
                          textTransform: "none",
                        }}
                        onClick={() => handleFollow(user.edfluencerId)} // Pass the user's ID to handleFollow
                      >
                        Follow
                      </LoadingButton>
                    </ListItem>
                  ))}
            </List>
          </div>
        </Grid2>
      </Grid2>
    </Dialog>
  );
}

export default LifeLongFollowers;
