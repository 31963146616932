import axios from "axios";
import config from "../services/config";
import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  createTrackerL: false,
  getAllTrackL: false,
  allTrackers: null,
  commentData: null,
  getCommentL: false,
  deleteTrackerL: false,
  editLoader: false,
  updateStageL: false

}


const slice = createSlice({
  name: 'tracker',
  initialState,
  reducers: {
    creatingTracker(state, action) {
      state.createTrackerL = true;
      state.error = null;
    },
    creatingTrackerSuccess(state, action) {
      state.createTrackerL = false;
      state.error = null;

    },
    creatingTrackerFailure(state, action) {
      state.createTrackerL = false;
      state.error = action.payload;
    },
    gettingAllTrackers(state, action) {
      state.getAllTrackL = true;
      state.error = null;
    },
    gettingAllTrackersSuccess(state, action) {
      state.getAllTrackL = false;
      state.error = null;
      state.allTrackers = action.payload;


    },
    gettingAllTrackersFailure(state, action) {
      state.getAllTrackL = false;
      state.error = action.payload;
    },
    
    gettingsingleTracker(state, action) {
      state.getAllTrackL = true;
      state.error = null;
    },
    gettingsingleTrackerSuccess(state, action) {
      state.getAllTrackL = false;
      state.error = null;
      state.singleTraker = action.payload;
    },
    gettingsingleTrackerFailure(state, action) {
      state.getAllTrackL = false;
      state.error = action.payload;
    },
    
    likingTracker(state, action) {
      state.getAllTrackL = true;
      state.error = null;
    },
    likingTrackerSuccess(state, action) {
      state.getAllTrackL = false;
      state.error = null;
    },
    likingTrackerFailure(state, action) {
      state.getAllTrackL = false;
      state.error = action.payload;
    },
    gettingtrackerCommets(state, action) {
      state.getCommentL = true;
      state.error = null;
    },
    gettingtrackerCommetsSuccess(state, action) {
      state.getCommentL = false;
      state.error = null;
      state.commentData = action.payload;
    },
    gettingtrackerCommetsFailure(state, action) {
      state.getCommentL = false;
      state.error = action.payload;
    },
    addingComment(state, action) {
      state.createTrackerL = true;
      state.error = null;
    },
    addingCommentSuccess(state, action) {
      state.createTrackerL = false;
      state.error = null;

    },
    addingCommentFailure(state, action) {
      state.createTrackerL = false;
      state.error = action.payload;
    },
    deletingTracker(state, action) {
      state.deleteTrackerL = true;
      state.error = null;
    },
    deletingTrackerSuccess(state, action) {
      state.deleteTrackerL = false;
      state.error = null;

    },
    deletingTrackerFailure(state, action) {
      state.deleteTrackerL = false;
      state.error = action.payload;
    },
    deletingComTracker(state, action) {
      state.deleteTrackerL = true;
      state.error = null;
    },
    deletingComTrackerSuccess(state, action) {
      state.deleteTrackerL = false;
      state.error = null;

    },
    deletingComTrackerFailure(state, action) {
      state.deleteTrackerL = false;
      state.error = action.payload;
    },
    editingTracker(state, action) {
      state.editLoader = true;
      state.error = null;
    },
    editingTrackerSuccess(state, action) {
      state.editLoader = false;
      state.error = null;

    },
    editingTrackerFailure(state, action) {
      state.editLoader = false;
      state.error = action.payload;
    },
    updatingTrackerStage(state, action) {
      state.updateStageL = true;
      state.error = null;
    },
    updatingTrackerStageSuccess(state, action) {
      state.updateStageL = false;
      state.error = null;

    },
    updatingTrackerStageFailure(state, action) {
      state.updateStageL = false;
      state.error = action.payload;
    },
  },
  });

  const {
    creatingTracker,
    creatingTrackerSuccess,
    creatingTrackerFailure,
    gettingAllTrackers,
    gettingAllTrackersSuccess,
    gettingAllTrackersFailure,
    gettingsingleTracker,
    gettingsingleTrackerSuccess,
    gettingsingleTrackerFailure,
    likingTracker,
    likingTrackerSuccess,
    likingTrackerFailure,
    gettingtrackerCommets,
    gettingtrackerCommetsSuccess,
    gettingtrackerCommetsFailure,
    addingComment,
    addingCommentSuccess,
    addingCommentFailure,
    deletingTracker,
    deletingTrackerSuccess,
    deletingTrackerFailure,
    deletingComTracker,
    deletingComTrackerSuccess,
    deletingComTrackerFailure,
    editingTracker,
    editingTrackerSuccess,
    editingTrackerFailure,
    updatingTrackerStage,
    updatingTrackerStageSuccess,
    updatingTrackerStageFailure
   
  } = slice.actions;

  export const trackerReducer = (state) => state.tracker


  export const createTracker = (data) => async (dispatch) => {
    dispatch(creatingTracker());
    try {
      const response = await axios.post(
        `${config.baseUrl}/tracker/create`, data    );
        
      if (response.status === 200) {
        dispatch(creatingTrackerSuccess(response.status)); 
      } else {
        dispatch(creatingTrackerFailure(response.status)); 
      }
  
      return response;
    } catch (e) {
      dispatch(
        creatingTrackerFailure("Something went wrong while creating tracker!")
      );
    }
  };

  export const getAllTrackers = (trackerId) => async (dispatch) => {
    dispatch(gettingAllTrackers());
    try {
      const response = await axios.get(`${config.baseUrl}/tracker/alltrackerlist`);
      
      if (response.status === 200) {
        // Assuming the tracker list is in response.data
        dispatch(gettingAllTrackersSuccess(response.data));
      } else {
        dispatch(gettingAllTrackersFailure("Failed to fetch trackers"));
      }
  
      return response;
    } catch (e) {
      dispatch(gettingAllTrackersFailure("Something went wrong while getting all trackers!"));
    }
  };
  
  export const deleteTracker = (trackerId) => async (dispatch) => {
    dispatch(deletingTracker());  // Dispatching action to show it's in progress
    try {
      const response = await axios.get(
        `${config.baseUrl}/tracker/delete/${trackerId}`
      );
      
      if (response.status === 200) {
        dispatch(deletingTrackerSuccess(response.data)); // Success action with response data
      } else {
        dispatch(deletingTrackerFailure(response.status)); // Failure action with status code
      }
  
      return response; // Return the response to be handled in the component
    } catch (e) {
      dispatch(
        deletingTrackerFailure("Something went wrong while deleting tracker!")
      );
      return { status: 500, message: "Request failed" }; // Returning an error response
    }
  };

  export const singleTrackers = (trackerId) => async (dispatch) => {
    dispatch(gettingsingleTracker());
    try {
      const response = await axios.get(
        `${config.baseUrl}/tracker/gettracker/${trackerId}`   );
        
      if (response.status === 200) {
        dispatch(gettingsingleTrackerSuccess(response.status)); 
      } else {
        dispatch(gettingsingleTrackerFailure(response.status)); 
      }
  
      return response;
    } catch (e) {
      dispatch(
        gettingsingleTrackerFailure("Something went wrong while getting single tracker!")
      );
    }
  };

export const likeTracker = (data) => async (dispatch) => {
  dispatch(likingTracker());  // Dispatching action to show it's in progress
  try {
    const response = await axios.patch(
      `${config.baseUrl}/tracker/liketracker`, data);
    
    if (response.status === 200) {
      dispatch(likingTrackerSuccess(response.data)); // Success action with response data
    } else {
      dispatch(likingTrackerFailure(response.status)); // Failure action with status code
    }

    return response; // Return the response to be handled in the component
  } catch (e) {
    dispatch(
      likingTrackerFailure("Something went wrong while liking tracker!")
    );
    return { status: 500, message: "Request failed" }; // Returning an error response
  }
};

export const getTrackerComment = (trackerId) => async (dispatch) => {
  dispatch(gettingtrackerCommets());  // Dispatching action to show it's in progress
  try {
    const response = await axios.get(
      `${config.baseUrl}/tracker/gettrackercomments/${trackerId}`
    );
    
    if (response.status === 200) {
      dispatch(gettingtrackerCommetsSuccess(response.data)); // Success action with response data
    } else {
      dispatch(gettingtrackerCommetsFailure(response.status)); // Failure action with status code
    }

    return response; // Return the response to be handled in the component
  } catch (e) {
    dispatch(
      gettingtrackerCommetsFailure("Something went wrong while getting comments!")
    );
    return { status: 500, message: "Request failed" }; // Returning an error response
  }
};


export const deleteCommentTracker = (commentId) => async (dispatch) => {
  dispatch(deletingComTracker());  // Dispatching action to show it's in progress
  try {
    const response = await axios.get(
      `${config.baseUrl}/tracker/deletecomment/${commentId}`
    );
    
    if (response.status === 200) {
      dispatch(deletingComTrackerSuccess(response.data)); // Success action with response data
    } else {
      dispatch(deletingComTrackerFailure(response.status)); // Failure action with status code
    }

    return response; // Return the response to be handled in the component
  } catch (e) {
    dispatch(
      deletingComTrackerFailure("Something went wrong while deleting comment!")
    );
    return { status: 500, message: "Request failed" }; // Returning an error response
  }
};


export const createComment = (data) => async (dispatch) => {
  dispatch(addingComment());
  try {
    const response = await axios.post(
      `${config.baseUrl}/tracker/createcomment`, data    );
      
    if (response.status === 200) {
      dispatch(addingCommentSuccess(response.status)); 
    } else {
      dispatch(addingCommentFailure(response.status)); 
    }

    return response;
  } catch (e) {
    dispatch(
      addingCommentFailure("Something went wrong while adding commenting!")
    );
  }
};

export const editTracker = (data) => async (dispatch) => {
  dispatch(editingTracker());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/tracker/edit`, data    );
      
    if (response.status === 200) {
      dispatch(editingTrackerSuccess(response.status)); 
    } else {
      dispatch(editingTrackerFailure(response.status)); 
    }

    return response;
  } catch (e) {
    dispatch(
      editingTrackerFailure("Something went wrong while creating tracker!")
    );
  }
};

export const updateTrackerStage = (data) => async (dispatch) => {
  dispatch(updatingTrackerStage());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/tracker/stage`, data    );
      
    if (response.status === 200) {
      dispatch(updatingTrackerStageSuccess(response.status)); 
    } else {
      dispatch(updatingTrackerStageFailure(response.status)); 
    }

    return response;
  } catch (e) {
    dispatch(
      updatingTrackerStageFailure("Something went wrong while updating stage of tracker!")
    );
  }
};


export default slice.reducer;
