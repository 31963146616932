import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { Box, Button, Grid, Input, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  creatingFlashCardCategory,
  getCategoryWithCards,
} from "../../redux/flashCards";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const inputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "38px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
  position: "relative",
  paddingTop: "5px",
};

const cancelButtonStyle = {
  color: "rgba(188, 23, 29, 1)",
  minWidth: "100px",
  height: "38px",
  border: "1px solid rgba(188, 23, 29, 1)",
  borderRadius: "25px",
  fontFamily: "Aileron",
  fontSize: "13px",
  fontWeight: 400,
  margin: "0px 10px",
  cursor: "pointer", // Try removing this temporarily to see if it affects the issue
  textTransform: "none",
  display: "inline-block", // Ensure it's displayed as a button
};

const labelStyle = {
  marginBottom: "5px",
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "31.21px",
};

const saveButtonStyle = {
  borderRadius: "20px",
  backgroundColor: "rgba(188, 23, 29, 1)",
  color: "#fff",
  fontSize: "12px",
  fontWeight: 300,
  minWidth: "100px",
  height: "38px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid",
  padding: "15px",
  textTransform: "none",
};

function CreateFlashCard({ handleNewFlashcardsClose }) {
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const validationSchema = yup.object({
    title: yup
      .string()
      .required("title is required")
      .min(3, "title must be at least 3 characters"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      cards: [], // Array to store front and back values for all cards
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      // console.log("Submitted values:", values);
      let resp = await dispatch(creatingFlashCardCategory(values));
      if (resp.status === 200) {
        toast.success(resp.data.message);
        dispatch(getCategoryWithCards());
        handleCloseTab();
      } else {
        toast.error(resp.data.message);
      }
    },
  });

  const addCard = () => {
    const newCard = {
      question: "",
      answer: "",
      // isEditing: false
    };
    setCards((prevCards) => [...prevCards, newCard]);
    formik.setFieldValue("cards", [...formik.values.cards, newCard]);
  };

  const handleCloseTab = () => {
    handleNewFlashcardsClose();
  };

  const toggleEdit = (index) => {
    setCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index ? { ...card, isEditing: !card.isEditing } : card
      )
    );
  };

  const handleCardChange = (index, field, value) => {
    // Update local state
    const updatedCards = cards.map((card, i) =>
      i === index ? { ...card, [field]: value } : card
    );
    setCards(updatedCards);

    // Update Formik values
    const updatedFormikCards = formik.values.cards.map((card, i) =>
      i === index ? { ...card, [field]: value } : card
    );
    formik.setFieldValue("cards", updatedFormikCards);
  };

  const saveCard = (index) => {
    toggleEdit(index); // Exit edit mode
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container >
        {/* Title Input */}
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={isMobile ? 12 : 5}>
            <label htmlFor="title" style={labelStyle}>
              Topic/Category
            </label>
            <Input
              id="title"
              style={{ ...inputStyle, width: "100%" }}
              placeholder="e.g. Science"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disableUnderline={true}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title} // Add this line to render the error message
            />
            
          </Grid>
          <Grid
            container
            item
            xs={isMobile ? 12 : 4}
            style={{
              justifyContent: "start",
              alignItems: "end",
              display: "flex",
            }}
          >
            <Button
              style={cancelButtonStyle}
              sx={{ mr: 2 }}
              onClick={handleCloseTab}
            >
              Cancel
            </Button>
            <Button type="submit" style={saveButtonStyle}>
              Save
            </Button>
          </Grid>
        </Grid>

        {/* Cards List */}
        <Grid item xs={isMobile ? 12 : 8}>
          <Paper sx={{ padding: 2, mt: 2, backgroundColor: "#F3F3F3" }}>
            <Grid container spacing={2}>
              <Grid item xs={isMobile ? 6 : 6}>
                <Typography
                  style={{
                    color: "#707070",
                    fontFamily: "Aileron",
                    margin: "-8px",
                  }}
                  align="center"
                >
                  Front
                </Typography>
              </Grid>

              <Grid item xs={isMobile ? 6 : 6}>
                <Typography
                  style={{
                    color: "#707070",
                    fontFamily: "Aileron",
                    margin: "-8px",
                  }}
                  align="center"
                >
                  Back
                </Typography>
              </Grid>

              {cards.map((card, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        height: "180px",
                        maxWidth: "320px",
                      }}
                      onDoubleClick={() => toggleEdit(index)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "20px",
                          marginRight: "-18px",
                          marginTop: "25%",
                        }}
                      >
                        {/* <DragIndicatorIcon
                          style={{ color: "#3F3F3F", width: "18px" }}
                        /> */}
                      </Box>

                      <Box
                        sx={{
                          bgcolor: "white",
                          borderRadius: "4px 0 0 4px",
                          width: isMobile ? 0 : 24,
                          height: 26,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // color: "#000",
                          fontFamily: "#707070",
                          // fontWeight: "bold",
                          zIndex: 1,
                          overflow: "hidden",
                          fontSize: "12px",
                          color: "#707070",
                        }}
                      >
                        {index + 1}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          p: 2,
                          bgcolor: index % 2 === 0 ? "#1da8c7" : "mediumpurple",
                          borderRadius: 2,
                          color: "#fff",
                          position: "relative",
                          height: "100%",
                          flex: 1,
                          zIndex: 5,
                        }}
                      >
                        {card.isEditing ?  null : ( 
                        <Box
                          sx={{
                            position: "absolute",
                            top: "60%",
                            left: "20%",
                            transform: "translate(-50%, -50%)", 
                            fontSize: "230px", 
                            color: "rgba(255, 255, 255, 0.1)",
                            zIndex: 1,
                            userSelect: "none",
                          }}
                        >
                          Q
                        </Box> )}

                        {card.isEditing ? (
                          <Input
                            value={card.question}
                            placeholder="Type your question here..."
                            onChange={(e) =>
                              handleCardChange(
                                index,
                                "question",
                                e.target.value
                              )
                            }
                            onBlur={() => saveCard(index)}
                            onKeyDown={(e) =>
                              e.key === "Enter" && saveCard(index)
                            }
                            sx={{
                              width: "100%",
                              height: "100%",
                              color: "#fff",
                              bgcolor: "transparent",
                              border: "none",
                            }}
                          />
                        ) : (
                          <Box sx={{ flex: 1, textAlign: "center" }}>
                           {card.question || "Double click to add Question."}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box
                      sx={{
                        p: 2,
                        bgcolor: "mediumseagreen",
                        borderRadius: 2,
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "180px",
                        position: "relative", 
                      }}
                      onDoubleClick={() => toggleEdit(index)}
                    >
                      {/* Background "A" */}
                      {card.isEditing ?  null : (  <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "30%",
                          transform: "translate(-50%, -50%)", 
                          fontSize: "215px", 
                          color: "rgba(255, 255, 255, 0.1)", 
                          zIndex: 1,  
                          userSelect: "none",
                        }}
                      >
                        A
                      </Box>)}

                      {card.isEditing ? (
                        <Input
                          value={card.answer}
                          placeholder="Type your answer here..."
                          onChange={(e) =>
                            handleCardChange(index, "answer", e.target.value)
                          }
                          onBlur={() => saveCard(index)}
                          onKeyDown={(e) =>
                            e.key === "Enter" && saveCard(index)
                          }
                          sx={{
                            width: "100%",
                            height: "100%",
                            color: "#fff",
                            bgcolor: "transparent",
                            border: "none",
                          }}
                        />
                      ) : (
                        <Typography> {card.answer || "Double click to add Answer."}</Typography>
                      )}
                    </Box>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            {/* Add New Card Button */}
            <Grid item xs={12} mt={"10px"}>
              <Button
                onClick={addCard}
                startIcon={<AddIcon />}
                color="error"
                sx={{
                  display: "flex",
                  margin: "auto",
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                Add New Card
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}

export default CreateFlashCard;
