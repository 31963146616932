import { Card, Col, Row } from 'antd';
import Meta from 'antd/es/card/Meta';
import React from 'react';
import school from "../../assets/school1.png";
import school2 from "../../assets/school2.png";
import school3 from "../../assets/school3.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Schools() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const cardData = [
    {
      title: "Abraham Lincoln school",
      description: "www.abrahamlincoln.com",
      imgSrc: school,
    },
    {
      title: "Martin Luther King Jr. school",
      description: "www.Martinlutherkingjr.com",
      imgSrc: school3,
    },
    {
      title: "William Albertson",
      description: "www.WilliamAlbertson.com",
      imgSrc: school2,
    },
    {
      title: "William Albertson",
      description: "www.WilliamAlbertson.com",
      imgSrc: school,
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      {cardData.map((card, index) => (
        <Col
          key={index}
          xs={24} // Full-width for extra-small screens
          sm={12} // Two cards per row for small screens
          md={8} // Three cards per row for medium screens
          lg={6} // Four cards per row for large screens
        >
          <Card
            hoverable
            style={{
              width: isMobile ? '100%' : '100%',
              marginBottom: '20px',
              padding: "10px"
            }}
            cover={<img alt="example" src={card.imgSrc} />}
          >
            <Meta title={card.title} description={card.description} />
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default Schools;
