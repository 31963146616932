import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import FlashCardfolder from "../assets/FlashCardfolder.png";
import FeedImage from "../assets/test.jpg";
import favTest from "../assets/favTest.jpg";
import noteTest from "../assets/noteTest.jpg";
import FolderImage from "../assets/folder 1@2x.png";
import CreateNewPicnote from "./create-picnote/CreateNewPicnote";
import CreateNewFolder from "./CreateNewFolder";
// import StarsIcon from "@mui/icons-material/Stars";
// import NoteIcon from "@mui/icons-material/Note";
// import FeedIcon from "@mui/icons-material/Feed";
import LifeLongFollowers from "./LifeLongFollowers";
import InstructionDialog from "./Instrucation";
import CreateFlashCard from "./flash-cards/CreateFlashCard";

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function NoData({ TabTitle }) {
  const [isFlashCardCreate, setIsFlashCardCreate] = useState(false);
  const [picnotesOpen, setPicnotesOpen] = useState(false);
  const [createFolder, setCreateFolder] = useState(false);
  const [userList, setUserList] = useState(false);
  const [instructionOpen, setInstructionOpen] = useState(false);
  const [orgInfo, setOrgInfo] = useState(() =>
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [tempInfo, setTempInfo] = useState(() =>
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );

  const buttonStyles = {
    color: "rgba(188, 23, 29, 1)",
    minWidth: "130px",
    height: "38px",
    border: "1px solid rgba(188, 23, 29, 1)",
    borderRadius: "25px",
    fontFamily: "Aileron",
    fontSize: "13px",
    fontWeight: 400,
    margin: "0px 10px",
    cursor: "pointer",
    textTransform: "none",
  };

  const handleCreateFlashCard = () => {
    setIsFlashCardCreate(true);
  };

  const handlePicnotes = () => {
    setPicnotesOpen(true);
  };

  const onClose = () => {
    setPicnotesOpen(false);
  };

  const handleNewFolder = () => {
    setCreateFolder(true);
  };
  const handleUserList = () => {
    setUserList(true);
  };
  const onCloseUserList = () => {
    setUserList(false);
  };
  const onCloseFolder = () => {
    setCreateFolder(false);
  };

  const handleInstruction = () => {
    setInstructionOpen(true);
  };

  const closeInstruction = () => {
    setInstructionOpen(false);
  };

  const getInstructionKey = (TabTitle) => {
    switch (TabTitle) {
      case "myPicnotes":
        return "picnotes";
      case "myFolder":
        return "folders";
      case "FlashCards":
        return "flashcards";
      case "myedTracker":
        return "myedTracker";
      case "myedTools":
        return "myedTools";
      default:
        return null;
    }
  };

  return (
    <>
      {picnotesOpen && <CreateNewPicnote onClose={onClose} />}
      {createFolder && <CreateNewFolder onClose={onCloseFolder} />}
      {userList && <LifeLongFollowers onClose={onCloseUserList} />}
      {instructionOpen && (
        <InstructionDialog
          TabName={getInstructionKey(TabTitle)}
          open={instructionOpen}
          onClose={closeInstruction}
        />
      )}

      <Grid
        container
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          marginTop: "20px",
        }}
      >
        <Grid item xs={12} style={{ ...center }}>
          <img
            src={
              TabTitle === "myFolder"
                ? FolderImage
                : TabTitle === "myFeeds"
                ? FeedImage
                : TabTitle === "myFavourite"
                ? favTest
                : TabTitle === "myPicnotes"
                ? noteTest
                : FlashCardfolder
            }
            alt="Icon"
            style={{
              width: "120px",
              height: "120px",
            }}
          />
          {/* )} */}
        </Grid>
        <Grid item xs={12} style={center} marginTop={"10px"}>
          <Typography
            style={{
              fontFamily: "Aileron",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "21.6px",
              textAlign: "center",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
              color: "#707070",
            }}
          >
            {TabTitle === "myPicnotes" ? (
              orgInfo?.userId === tempInfo?.userId ? (
                "You have not created any Picnotes."
              ) : (
                `${tempInfo?.firstName} has not created any Picnotes.`
              )
            ) : TabTitle === "FlashCards" ? (
              orgInfo?.userId === tempInfo?.userId ? (
                "You have not created any flashcard."
              ) : (
                `${tempInfo?.firstName} has not created any Flashcard.`
              )
            ) : TabTitle === "myedTracker" ? (
              orgInfo?.userId === tempInfo?.userId ? (
                "You have not used your EdTracker."
              ) : (
                `${tempInfo?.firstName} has not used EdTracker.`
              )
            ) : TabTitle === "myFavourite" ? (
              orgInfo?.userId === tempInfo?.userId ? (
                "You have not liked any Picnotes."
              ) : (
                `${tempInfo?.firstName} has not liked any Picnotes.`
              )
            ) : TabTitle === "myFolder" ? (
              orgInfo?.userId === tempInfo?.userId ? (
                "You have not created any folders."
              ) : (
                `${tempInfo?.firstName} has not created any folders.`
              )
            ) : TabTitle === "myedTools" ? (
              orgInfo?.userId === tempInfo?.userId ? (
                "You have not used your EdTools."
              ) : (
                `${tempInfo?.firstName} has not used EdTools.`
              )
            ) : TabTitle === "myFeeds" ? (
              <span>
                {orgInfo?.userId === tempInfo?.userId
                  ? "To see content in your Feed, start following other users—updates"
                  : `${tempInfo?.firstName} is not following anyone. Once ${tempInfo?.firstName} starts following others, feeds will appear here.`}
                <br />
                and posts from those {orgInfo?.userId === tempInfo?.userId ? 'you' : `${tempInfo?.firstName}`} follows will appear here!

              </span>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={12} style={center} marginTop={"10px"}>
          {orgInfo?.userId === tempInfo?.userId ? (
            <Typography
              style={{
                fontFamily: "Aileron",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "21.6px",
                textAlign: "center",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "#707070",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleInstruction}
            >
              {TabTitle === "myPicnotes"
                ? "How do I create Picnotes?"
                : TabTitle === "FlashCards"
                ? "How do I create Flashcards?"
                : TabTitle === "myFolder"
                ? "How do I create Folders?"
                : TabTitle === "myedTracker"
                ? "How do I use my Edtracker?"
                : TabTitle === "myedTools"
                ? "How do I use my EdTools?"
                : null}
            </Typography>
          ) : null}
        </Grid>

        <Grid item xs={12} style={center} marginTop={"15px"}>
          {/* { TabTitle === "myPicnotes" ? (
            <Button
              onClick={handlePicnotes}
              style={{
                color: "rgba(188, 23, 29, 1)",
                minWidth: "130px",
                height: "38px",
                border: "1px solid rgba(188, 23, 29, 1)",
                borderRadius: "25px",
                fontFamily: "Aileron",
                fontSize: "13px",
                fontWeight: 400,
                margin: "0px 10px",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              + Create Picnote
            </Button>
          ) : TabTitle === "myFolder" ? (
            <Button
              onClick={handleNewFolder}
              style={{
                color: "rgba(188, 23, 29, 1)",
                minWidth: "130px",
                height: "38px",
                border: "1px solid rgba(188, 23, 29, 1)",
                borderRadius: "25px",
                fontFamily: "Aileron",
                fontSize: "13px",
                fontWeight: 400,
                margin: "0px 10px",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              Create New Folder
            </Button>
          ) : TabTitle === "FlashCards" ? (
            <Button
              onClick={handleCreateFlashCard}
              style={{
                color: "rgba(188, 23, 29, 1)",
                minWidth: "130px",
                height: "38px",
                border: "1px solid rgba(188, 23, 29, 1)",
                borderRadius: "25px",
                fontFamily: "Aileron",
                fontSize: "13px",
                fontWeight: 400,
                margin: "0px 10px",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              Create New Flashcard
            </Button>
          ) : TabTitle === "myFeeds" ? (
            <Button
              onClick={handleUserList}
              style={{
                color: "rgba(188, 23, 29, 1)",
                minWidth: "130px",
                height: "38px",
                border: "1px solid rgba(188, 23, 29, 1)",
                borderRadius: "25px",
                fontFamily: "Aileron",
                fontSize: "13px",
                fontWeight: 400,
                margin: "0px 10px",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              Start Following
            </Button>
          ) : null} */}
          {orgInfo?.userId === tempInfo?.userId &&
            (TabTitle === "myPicnotes" ? (
              <Button onClick={handlePicnotes} style={buttonStyles}>
                + Create Picnote
              </Button>
            ) : TabTitle === "myFolder" ? (
              <Button onClick={handleNewFolder} style={buttonStyles}>
                Create New Folder
              </Button>
            ) : TabTitle === "FlashCards" ? (
              <Button onClick={handleCreateFlashCard} style={buttonStyles}>
                Create New Flashcard
              </Button>
            ) : TabTitle === "myFeeds" ? (
              <Button onClick={handleUserList} style={buttonStyles}>
                Start Following
              </Button>
            ) : null)}
        </Grid>
      </Grid>
    </>
  );
}

export default NoData;
