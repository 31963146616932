import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ViewPicnote from "./ViewPicnote";
import MasonryCom from "./MasonryCom";
import NoData from "./NoData";
import { myFeed } from "../redux/picnote";

function PicnotesCards() {
  const [addResource, setAddResource] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { myFeedsData } = useSelector((state) => state.picnote) || {
    myFeedsData: [],
  };
  

  useEffect(() => {
    setLoading(true);
    dispatch(myFeed())
      .finally(() => setLoading(false));
  }, [dispatch]);

  const closeAddSource = () => {
    setAddResource(false);
    setSelectedNote(null);
  };

  return (
    <>
      {addResource && selectedNote ? (
        <ViewPicnote
          closeAddSource={closeAddSource}
          picnoteCardsdata={selectedNote}
          AllNotesForRelated={myFeedsData?.myFeeds}

        />
      ) : null}

      {loading ? (
        "Loading Data..."
      ) : myFeedsData?.myFeeds?.length > 0 ? (
        <MasonryCom
          picnotes={myFeedsData?.myFeeds}
          column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
          cardsWidth={"1200px"}
          TabName={"myFeed"}
        />
      ) : (
        <NoData TabTitle={"myFeeds"} />
      )}
    </>
  );
}

export default PicnotesCards;
