import {
  Box,
  Button,
  Dialog,
  Grid,
  Grid2,
  IconButton,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "./style.css";
import CloseIcon from "@mui/icons-material/Close";
import favourite from "../assets/favouriteCircled.png";
import redfavourite from "../assets/redfavouriteCircled.png";
import flag from "../assets/Flag.png";
import thumbUp from "../assets/thumbs-up.png";
import thumbDown from "../assets/thumbs-down.png";
import AddPicnoteFolder from "./AddPicnoteFolder";
import * as yup from "yup";
import FolderList from "./FolderList";
import { useDispatch, useSelector } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import folderAdd from "./../assets/folder-add.png";
import VideocamIcon from "@mui/icons-material/Videocam";
import noImage from "../assets/no image.jpg";


import {
  addResource,
  resetVSingleNoteData,
  singleVisiterNote,
  vSimilarNotes,
} from "../redux/picnote";
import toast from "react-hot-toast";
import MasonryCom from "./MasonryCom";
import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import PhotoRoundedIcon from "@mui/icons-material/PhotoRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import Comments from "./Comments";
import { useMediaQuery, useTheme } from "@mui/material";
import { setSelectedFollower } from "../redux/tags";
import { tempProfile } from "../redux/user";
import { useNavigate } from "react-router-dom";

const addPicnoteButtonStyle = {
  borderRadius: "20px",
  backgroundColor: "rgba(188, 23, 29, 1)",
  color: "#fff",
  fontSize: "12px",
  fontWeight: 300,
  height: "35px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid",
  padding: "15px",
  textTransform: "none",
  cursor: "context-menu",
};

const styleForIcons = {
  fontWeight: 400,
  fontFamily: "Aileron",
  paddingRight: "5px",
  fontSize: "16px",
  color: "#3F3F3F",
};

const thumbStyle = {
  backgroundColor: "#17BC6D33",
  width: "150px",
  height: "42px",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px",
  marginBottom: "10px",
  // cursor: "pointer",
};

function ViewPicnote({
  closeAddSource,
  picnoteResponse,
  selectedNoteFromMyNote,
  isDialogOpen,
  AllNotesForRelated,
}) {
  const [createNew, setCreateNew] = useState(false);
  // const [selectedTag, setSelectedTag] = useState("Add");
  const [selectedTag, setSelectedTag] = useState("Book");
  const [addOption, setAddOption] = useState("Book");
  const [handleComments, setHandleComments] = useState(false);

  const tags = ["Book", "Article", "Video", "Image"];

  const handleTagClick = (tag) => {
    setSelectedTag(tag); // Set the clicked tag as the selected tag
    if (tag === "Add") {
      setAddOption(""); // Reset dropdown selection when "Add" is clicked
    }
  };

  // Dispatch instance
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { allFolderList, vSingleNoteData, vSingleNoteL } = useSelector(
    (state) => state.picnote
  );

  const selectedNoteIndex = AllNotesForRelated?.findIndex(
    (note) => note.noteId === vSingleNoteData?.note?.noteId
  );

  const [pictureUrl, setPictureUrl] = useState("");

  if (isDialogOpen === true) {
    dispatch(resetVSingleNoteData());
    closeAddSource();
  }

  const handleCloseRes = () => {
    dispatch(resetVSingleNoteData());
    closeAddSource();
  };

  useEffect(() => {
    if (vSingleNoteData?.note?.notePicture) {
      setPictureUrl(vSingleNoteData.note.notePicture);
    }
  }, [vSingleNoteData]);

  const relatedNotes =
    selectedNoteIndex >= 0
      ? AllNotesForRelated.slice(selectedNoteIndex + 1)
      : AllNotesForRelated;

  useEffect(() => {
    dispatch(singleVisiterNote(selectedNoteFromMyNote?.noteId));
    dispatch(vSimilarNotes(vSingleNoteData?.note?.tags?.map((i) => i.tagId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const validationSchema = yup.object({
    resTitle: yup.string().required("Title is required"),
    resAuthor: yup.string().required("Author is required"),
    resLink: yup
      .string()
      .url("Must be a valid URL")
      .required("Link is required"),
  });

  const formik = useFormik({
    initialValues: {
      noteId: picnoteResponse?.note?.noteId || vSingleNoteData?.note?.noteId,
      resType: addOption,
      resTitle: "",
      resAuthor: "",
      resLink: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log("values", values);
      const payload = {
        noteId: values.noteId,
        resources: {
          resType: values.resType,
          resTitle: values.resTitle,
          resAuthor: values.resAuthor,
          resLink: values.resLink,
        },
      };
      let resp = await dispatch(addResource(payload));

      if (resp.status === 200) {
        toast.success(resp.data.message);
        formik.resetForm();
      } else {
        const errorMessages = resp.data.message
          .map((err) => err.msg)
          .join(", "); // Join messages into a single string
        toast.error(errorMessages);
      }
    },
  });

  const onClose = () => {
    setCreateNew(false);
  };

  const handleProfileClick = async (follower) => {
    dispatch(setSelectedFollower(follower));
    const tempResp = await dispatch(tempProfile(follower?.edfId));
    localStorage.setItem("Temptoken", tempResp.data?.tempToken);
    localStorage.setItem(
      "TempuserInfo",
      JSON.stringify(tempResp.data.tempInfo)
    );
    navigate("/auth/pages");
  };

  // const goToPreviousPicnote = () => {
  //   if (vSimilerNoteData?.similarNotes?.length > 0) {
  //     const firstNoteId = vSimilerNoteData.similarNotes[0].noteId;
  //     dispatch(singleVisiterNote(firstNoteId));
  //     dispatch(vSimilarNotes(vSingleNoteData?.note?.tags?.map((i) => i.tagId)));
  //   }
  // };

  // const goToNextPicnote = () => {
  //   if (vSimilerNoteData?.similarNotes?.length > 0) {
  //     const lastNoteId =
  //       vSimilerNoteData.similarNotes[vSimilerNoteData.similarNotes.length - 1]
  //         .noteId;
  //     dispatch(singleVisiterNote(lastNoteId));
  //     dispatch(vSimilarNotes(vSingleNoteData?.note?.tags?.map((i) => i.tagId)));
  //   }
  // };

  const goToPreviousPicnote = () => {
    if (selectedNoteIndex > 0) {
      const previousNoteId = AllNotesForRelated[selectedNoteIndex - 1].noteId;
      dispatch(singleVisiterNote(previousNoteId));
      dispatch(vSimilarNotes(vSingleNoteData?.note?.tags?.map((i) => i.tagId)));
    }
  };

  const goToNextPicnote = () => {
    if (selectedNoteIndex < AllNotesForRelated.length - 1) {
      const nextNoteId = AllNotesForRelated[selectedNoteIndex + 1].noteId;
      dispatch(singleVisiterNote(nextNoteId));
      dispatch(vSimilarNotes(vSingleNoteData?.note?.tags?.map((i) => i.tagId)));
    }
  };

  const onCloseComment = () => {
    setHandleComments(false);
  };

  return (
    <>
      {handleComments ? (
        <Comments
          onClose={onCloseComment}
          title={vSingleNoteData?.note}
          comp={"addResource"}
        />
      ) : null}
      {isMobile ? null : (
        <IconButton
          onClick={goToPreviousPicnote}
          sx={{
            position: "fixed",
            left: 20,
            top: "50%",
            transform: "translateY(-50%)",
            color: "#fff",
            backgroundColor: isMobile ? "rgba(255, 255, 255, 0.82)" : "#989898",
            "&:hover": { backgroundColor: "#dcdcdc" },
            zIndex: 1300,
          }}
        >
          <ChevronLeftIcon sx={{ color: isMobile ? "red" : "white" }} />
        </IconButton>
      )}

      <Dialog
        open={true}
        fullWidth
        maxWidth={useMediaQuery(useTheme().breakpoints.up("lg")) ? "lg" : "lg"}
        sx={{
          zIndex: 1200,
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            padding: isMobile ? "20px 10px 20px 10px" : "20px",
            minWidth: isMobile ? "90%" : "400px",
          },
        }}
      >
        {createNew ? (
          Array.isArray(allFolderList?.folderList) &&
          allFolderList.folderList.length > 0 ? (
            <FolderList onClose={onClose} />
          ) : (
            <AddPicnoteFolder onClose={onClose} />
          )
        ) : null}

        <Grid2 item size={12} mb={"10px"}>
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleCloseRes}
          >
            <CloseIcon style={{ width: "20px", height: "20px" }} />
          </IconButton>
        </Grid2>

        <Grid2
          item
          size={12}
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
            paddingTop: "10px",
            gap: isMobile ? "15px" : "0px",
          }}
        >
          <Grid2
            item
            size={isMobile ? 6 : 6}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() =>
              handleProfileClick(vSingleNoteData?.note?.edFluencer)
            }
          >
            {/* {vSingleNoteData?.note?.edFluencer?.edfPicture?.startsWith(
              "http" || "https"
            ) ? ( */}
            {/* he aj 11 tareekh ty change kayo aa ko issue ayo ta change kry wathndus wapsi mathyon */}
            {vSingleNoteData?.note?.edFluencer?.edfPicture?.endsWith(".jpg") ||
            vSingleNoteData?.note?.edFluencer?.edfPicture?.endsWith(".jpeg") ||
            vSingleNoteData?.note?.edFluencer?.edfPicture?.endsWith(".png") ? (
              <img
                src={
                  picnoteResponse?.edFluencer?.edfPicture ||
                  vSingleNoteData?.note?.edFluencer?.edfPicture ||
                  picnoteResponse?.note?.notePicture
                }
                alt="profile"
                style={{
                  width: "44px",
                  height: "44px",
                  borderRadius: "50px",
                  marginRight: "10px",
                  cursor: "pointer",
                  border: "1px solid slategray",
                }}
              />
            ) : (
              <div
                style={{
                  width: "44px",
                  height: "44px",
                  border: "1px solid slategray",
                  borderRadius: "50%",
                  backgroundColor: "#b10303",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                id="basic-button"
              >
                {`${
                  vSingleNoteData?.note?.edFluencer?.firstName?.[0]?.toUpperCase() ||
                  ""
                }${
                  vSingleNoteData?.note?.edFluencer?.lastName?.[0]?.toUpperCase() ||
                  ""
                }`}
              </div>
            )}
            &nbsp;
            <span style={{ ...styleForIcons, cursor: "pointer" }}>
              {/* {selectedNoteFromMyNote?.ownerName} */}
              {`${
                picnoteResponse?.edFluencer?.firstName ||
                vSingleNoteData?.note?.edFluencer?.firstName ||
                ""
              } ${
                picnoteResponse?.edFluencer?.lastName ||
                vSingleNoteData?.note?.edFluencer?.lastName ||
                ""
              }`}
            </span>
          </Grid2>

          <Grid2
            item
            size={isMobile ? 12 : 6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile ? "space-between" : "flex-end", // Adjust alignment based on isMobile
              gap: isMobile ? 0 : "10px", // Add gap when not mobile
            }}
          >
            {/* Render button separately when isMobile */}
            {isMobile && (
              <Button
                style={{
                  ...addPicnoteButtonStyle,
                  fontSize: "12px",
                }}
                // onClick={handleNewPicnote}
              >
                <img
                  src={folderAdd}
                  alt="folder"
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                />
                &nbsp; Add to folder
              </Button>
            )}

            {/* Right-side group for all elements */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* Button included when not isMobile */}
              {!isMobile && (
                <Button
                  style={{
                    ...addPicnoteButtonStyle,
                    fontSize: "14px",
                  }}
                  // onClick={handleNewPicnote}
                >
                  <img
                    src={folderAdd}
                    alt="folder"
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                  &nbsp; Add to folder
                </Button>
              )}

              {/* Other elements */}
              <ChatBubbleOutlineRoundedIcon
                sx={{
                  width: "30px",
                  height: "30px",
                  color: "rgba(188, 23, 29, 1)",
                  border: "1px solid lightgray",
                  borderRadius: "50%",
                  padding: "5px",
                }}
              />
              {vSingleNoteData?.note?.heart === true ? (
                <img
                  src={redfavourite}
                  alt="liked"
                  style={{ width: "30px", height: "30px" }}
                />
              ) : (
                <img
                  src={favourite}
                  alt="not liked"
                  style={{ width: "30px", height: "30px" }}
                />
              )}
              <img
                src={flag}
                alt="important"
                style={{ width: "30px", height: "30px" }}
              />
            </div>
          </Grid2>
        </Grid2>
        {/* {vSingleNoteL ? (
          <Grid2 container minHeight={"90%"} spacing={2}>
            <Grid2 item size={6}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={530}
                height={450}
                sx={{ borderRadius: "10px" }}
              />
            </Grid2>
            <Grid2 item size={6}>
              <Skeleton width={200} height={50} sx={{ borderRadius: "10px" }} />
              <Skeleton
                animation="wave"
                width={390}
                height={250}
                sx={{ borderRadius: "10px" }}
              />
              <Skeleton
                animation={false}
                width={470}
                height={150}
                sx={{ borderRadius: "10px" }}
              />
            </Grid2>
          </Grid2>
        ) : ( */}
        <Grid2 container spacing={2}>
          {/* <Grid2 item size={isMobile ? 12 : 6}>
            <img
              src={pictureUrl}
              alt="Loading.."
              style={{
                width: "100%",
                minHeight: "470px",
                maxHeight: "470px",
                borderRadius: "10px",
              }}
            />
          </Grid2> */}
          <Grid2 item size={isMobile ? 12 : 6}>
          {vSingleNoteL ? (
  <p>Loading...</p>
) : (
  pictureUrl ? (
    <img
      src={pictureUrl}
      alt="Loading.."
      style={{
        width: "100%",
        minHeight: "470px",
        maxHeight: "470px",
        borderRadius: "10px",
      }}
    />
  ) : (
    <div 
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        flexDirection: 'column',  // Ensures the image centers properly
        border: '1px solid lightgray',
        borderRadius: '10px',
        minHeight: '480px',
        maxHeight: '480px',
        width: "100%", // Ensures it spans the full container
      }}
    >
      <img
        src={noImage}
        alt="No Image"
        style={{
          width: "50%",
          height: "50%",
          objectFit: "cover",
          borderRadius: "10px",
        }}
      />
    </div>
  )
)}

          </Grid2>

          <Grid2
            container
            item
            size={isMobile ? 12 : 6}
            md={6}
            lg={6}
            style={{ alignItems: "start" }}
          >
            {vSingleNoteL ? (
              <Grid2 container minHeight={"90%"} spacing={2}>
                <Grid2 item size={6}>
                  <Skeleton
                    width={200}
                    height={50}
                    sx={{ borderRadius: "10px" }}
                  />
                  <Skeleton
                    animation="wave"
                    width={390}
                    height={250}
                    sx={{ borderRadius: "10px" }}
                  />
                  <Skeleton
                    animation={false}
                    width={470}
                    height={150}
                    sx={{ borderRadius: "10px" }}
                  />
                </Grid2>
              </Grid2>
            ) : (
              <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <Grid2
                  container
                  item
                  size={12}
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Grid2 item xs={6} style={{ margin: 0 }}>
                    <Typography
                      style={{
                        fontFamily: "Cako regular",
                        fontSize: "18px",
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {`${(
                        vSingleNoteData?.note?.title || picnoteResponse?.title
                      )?.substring(0, 100)}${
                        (vSingleNoteData?.note?.title || picnoteResponse?.title)
                          ?.length > 100
                          ? "..."
                          : ""
                      }`}
                    </Typography>
                  </Grid2>
                </Grid2>

                <Grid2 style={{ alignItems: "start", gap: 0 }}>
                  <Typography
                    style={{
                      margin: 0,
                      color: "#707070",
                      fontSize: "12px",
                      fontWeight: 400,
                      height: isMobile ? "100%" : "135px",
                      textAlign: "justify",
                    }}
                  >
                    {(
                      (vSingleNoteData?.note?.description ||
                        picnoteResponse?.description) ??
                      ""
                    ).length > (isMobile ? 650 : 650)
                      ? `${(
                          vSingleNoteData?.note?.description ||
                          picnoteResponse?.description
                        )?.slice(0, isMobile ? 650 : 650)}...`
                      : vSingleNoteData?.note?.description ||
                        picnoteResponse?.description}
                  </Typography>
                  <Grid2
                    item
                    size={12}
                    paddingTop={"5px"}
                    overflowX="auto"
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap", // Ensure all tags stay in a single line
                      overflowX: "auto",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      {" "}
                      {/* Wrapper to ensure flex behavior */}
                      {vSingleNoteData?.note?.tags?.map((item, index) => (
                        <Typography
                          key={index}
                          style={{
                            display: "inline-block",
                            margin: "5px",
                            padding: "5px 10px",
                            borderRadius: "25px",
                            border: "1px solid #BC171D",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                            color: "#BC171D",
                          }}
                        >
                          {item?.tagTitle}
                        </Typography>
                      ))}
                    </div>
                  </Grid2>

                  <Grid2 marginTop="5px">
                    <span
                      style={{
                        ...thumbStyle,
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                        color: "#17BC6D",
                      }}
                    >
                      <img src={thumbUp} alt="thumb Up" /> &nbsp;{" "}
                      {picnoteResponse?.rights || vSingleNoteData?.note?.rights}
                    </span>

                    <span
                      style={{
                        ...thumbStyle,
                        backgroundColor: "#BC171D33",
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                        color: "#BC171D",
                      }}
                    >
                      <img src={thumbDown} alt="thumb Down" /> &nbsp;{" "}
                      {picnoteResponse?.wrongs || vSingleNoteData?.note?.wrongs}
                    </span>
                  </Grid2>
                </Grid2>

                <Grid2
                  container
                  item
                  xs={12}
                  style={{
                    border: "1px solid #70707080",
                    borderRadius: "5px",
                    padding: "10px",
                    minWidth: isMobile ? "320px" : "100%",
                    maxWidth: isMobile ? "320px" : "100%",
                    overflowX: isMobile ? "scroll" : "hidden",
                    minHeight: "200px",
                    maxHeight: "200px",
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <Grid2
                    container
                    item
                    size={12}
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                      overflow: "hidden",
                      minWidth: "520px",
                      maxWidth: "520px",
                      minheight: "200px",
                      maxheight: "200px",
                      overflowY: "hidden",
                    }}
                  >
                    <Grid2 item size={isMobile ? 12 : 4} paddingBottom={"10px"}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontFamily: "Cako",
                          fontSize: "24px",
                          fontWeight: "400",
                          lineHeight: "28.8px",
                          textAlign: "left",
                        }}
                      >
                        Resources
                      </Typography>
                    </Grid2>

                    <Grid2
                      item
                      size={isMobile ? 12 : 8}
                      alignItems={"end"}
                      justifyContent={isMobile ? "start" : "end"}
                      display="flex"
                      gap={1}
                      paddingBottom={isMobile ? "30px" : "0px"}
                    >
                      {tags.map((tag, index) => {
                        const matchingResources =
                          vSingleNoteData?.note?.resources.filter(
                            (resource) => {
                              if (
                                tag === "Book" &&
                                resource?.resType === "Book"
                              )
                                return true;
                              if (
                                tag === "Article" &&
                                resource?.resType === "Article"
                              )
                                return true;
                              if (
                                tag === "Image" &&
                                resource?.resType === "Image"
                              )
                                return true;
                              if (
                                tag === "Video" &&
                                resource?.resType === "Video"
                              )
                                return true;
                              return false;
                            }
                          );

                        return (
                          <Box
                            key={index}
                            onClick={() => handleTagClick(tag)}
                            sx={{
                              border:
                                selectedTag === tag
                                  ? "1px solid #BC171D"
                                  : "1px solid #70707080",
                              borderRadius: "25px",
                              padding: "5px 8px",
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "12px",
                              fontFamily: "Aileron",
                              fontWeight: 400,
                              color:
                                selectedTag === tag ? "#BC171D" : "inherit",
                              maxWidth: "80px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {tag}{" "}
                            {tag === "Add"
                              ? null
                              : ": " + matchingResources?.length}
                          </Box>
                        );
                      })}
                    </Grid2>

                    {/* {vSingleNoteData?.note?.resources?.length > 0 ? (
                    vSingleNoteData.note.resources.length > 0 &&
                    vSingleNoteData.note.resources.map((resource, index) => {
                      const isResourceMatching =
                        (selectedTag === "Book" &&
                          resource?.resType === "Book") ||
                        (selectedTag === "Article" &&
                          resource?.resType === "Article") ||
                        (selectedTag === "Image" &&
                          resource?.resType === "Image") ||
                        (selectedTag === "Video" &&
                          resource?.resType === "Video");

                      return (
                        isResourceMatching && (
                          <Grid2
                            container
                            item
                            size={12}
                            spacing={2}
                            display={"flex"}
                            alignItems="center"
                            key={index}
                            sx={{ marginBottom: "16px" }}
                          >
                            <Grid2 item size={2}>
                              <Box
                                sx={{
                                  backgroundColor: "#F3F3F3",
                                  padding: "20px 10px",
                                  borderRadius: "8px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {resource?.resType === "Book" ? (
                                  <MenuBookRoundedIcon />
                                ) : resource?.resType === "Article" ? (
                                  <ArticleRoundedIcon />
                                ) : resource?.resType === "Image" ? (
                                  <PhotoRoundedIcon />
                                ) : resource?.resType === "Video" ? (
                                  <OndemandVideoRoundedIcon />
                                ) : null}
                              </Box>
                            </Grid2>

                            <Grid2 item size={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontFamily: "Aileron",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  color: "#454545",
                                }}
                              >
                                {resource?.resTitle}
                              </Typography>
                              <Box
                                sx={{
                                  fontFamily: "Aileron",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  color: "#707070",
                                  textTransform: "lowercase",
                                }}
                              >
                                {resource?.resAuthor?.length > 0 && (
                                  <>
                                    <Typography
                                      component="span"
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        color: "#707070",
                                      }}
                                    >
                                      {resource?.resType === "Book" ||
                                      resource?.resType === "Article"
                                        ? "author"
                                        : null}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      component="span"
                                      sx={{
                                        fontFamily: "Aileron",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        ml: 0.5,
                                        color: "#454545",
                                      }}
                                    >
                                      {resource?.resAuthor}
                                    </Typography>
                                  </>
                                )}
                              </Box>
                              <Link
                                href={resource?.resLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                  fontFamily: "Aileron",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  textDecoration: "none",
                                  color: "#454545",
                                  mt: resource?.resAuthor?.length > 0 ? 0 : 1, // Optional spacing adjustment
                                }}
                              >
                                {resource?.resLink?.slice(0, 30)}...
                              </Link>
                            </Grid2>
                          </Grid2>
                        )
                      );
                    })
                  ) : (
                    <Grid
                      container
                      style={{
                        display: "flex",
                        justifyContent: isMobile ? "start" : "center",
                        alignItems: "center",
                        paddingTop: isMobile ? "20px" : "40px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "20px",
                          color: "#6c6c6c",
                          // padding: "50px",
                        }}
                      >
                        No resources added.
                      </Typography>
                    </Grid>
                  )} */}
                    {vSingleNoteData?.note?.resources?.length > 0 ? (
                      selectedTag ? (
                        vSingleNoteData.note.resources.filter((resource) => {
                          return (
                            (selectedTag === "Book" &&
                              resource?.resType === "Book") ||
                            (selectedTag === "Article" &&
                              resource?.resType === "Article") ||
                            (selectedTag === "Image" &&
                              resource?.resType === "Image") ||
                            (selectedTag === "Video" &&
                              resource?.resType === "Video")
                          );
                        }).length > 0 ? (
                          vSingleNoteData.note.resources.map(
                            (resource, index) => {
                              const isResourceMatching =
                                (selectedTag === "Book" &&
                                  resource?.resType === "Book") ||
                                (selectedTag === "Article" &&
                                  resource?.resType === "Article") ||
                                (selectedTag === "Image" &&
                                  resource?.resType === "Image") ||
                                (selectedTag === "Video" &&
                                  resource?.resType === "Video");

                              return (
                                isResourceMatching && (
                                  <Grid2
                                    container
                                    item
                                    size={12}
                                    spacing={2}
                                    display={"flex"}
                                    alignItems="start"
                                    justifyContent={"start"}
                                    key={index}
                                    sx={{ marginBottom: "10px" }}
                                  >
                                    <Grid2 item size={2}>
                                      <Box
                                        sx={{
                                          backgroundColor: "#F3F3F3",
                                          padding: "20px 10px",
                                          borderRadius: "8px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {resource?.resType === "Book" ? (
                                          <MenuBookRoundedIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : resource?.resType === "Article" ? (
                                          <ArticleRoundedIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : resource?.resType === "Image" ? (
                                          <PhotoRoundedIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : resource?.resType === "Video" ? (
                                          <VideocamIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : null}
                                      </Box>
                                    </Grid2>

                                    <Grid2 item size={10}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontFamily: "Aileron",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          color: "#454545",
                                        }}
                                      >
                                        {resource?.resTitle}
                                      </Typography>
                                      <Box
                                        sx={{
                                          fontFamily: "Aileron",
                                          fontSize: "12px",
                                          fontWeight: 400,
                                          color: "#707070",
                                          textTransform: "lowercase",
                                        }}
                                      >
                                        {resource?.resAuthor?.length > 0 && (
                                          <>
                                            <Typography
                                              component="span"
                                              sx={{
                                                fontSize: "12px",
                                                fontWeight: 400,
                                                color: "#707070",
                                              }}
                                            >
                                              {resource?.resType === "Book" ||
                                              resource?.resType === "Article"
                                                ? "author"
                                                : null}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              component="span"
                                              sx={{
                                                fontFamily: "Aileron",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                ml: 0.5,
                                                color: "#454545",
                                              }}
                                            >
                                              {resource?.resAuthor}
                                            </Typography>
                                          </>
                                        )}
                                      </Box>
                                      <Link
                                        href={resource?.resLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                          fontFamily: "Aileron",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          textDecoration: "none",
                                          color: "#454545",
                                          mt:
                                            resource?.resAuthor?.length > 0
                                              ? 0
                                              : 1, // Optional spacing adjustment
                                        }}
                                      >
                                        {resource?.resLink?.length > 30
                                          ? `${resource.resLink.slice(
                                              0,
                                              30
                                            )}...`
                                          : resource?.resLink}
                                      </Link>
                                    </Grid2>
                                  </Grid2>
                                )
                              );
                            }
                          )
                        ) : (
                          <Grid
                            container
                            style={{
                              display: "flex",
                              justifyContent: isMobile ? "start" : "center",
                              alignItems: "center",
                              paddingTop: isMobile ? "10px" : "40px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "20px",
                                color: "#6c6c6c",
                              }}
                            >
                              No resources added.
                            </Typography>
                          </Grid>
                        )
                      ) : null
                    ) : (
                      <Grid
                        container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "40px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "20px",
                            color: "#6c6c6c",
                          }}
                        >
                          No resources added.
                        </Typography>
                      </Grid>
                    )}
                  </Grid2>
                </Grid2>
              </form>
            )}
          </Grid2>

          <Grid2
            container
            style={{
              fontFamily: "Cako regular",
              fontSize: "26px",
              fontWeight: 400,
              lineHeight: "36.01px",
              textAlign: "center",
              padding: "10px 0px",
            }}
          >
            Related Picnotes
          </Grid2>
          <MasonryCom
            // picnotes={vSimilerNoteData?.similarNotes}
            picnotes={relatedNotes}
            column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
            isDialogOpen={true}
          />
        </Grid2>
        {/* )} */}
      </Dialog>
      {/* Button for Next Picnote */}
      {isMobile ? null : (
        <IconButton
          onClick={goToNextPicnote}
          sx={{
            position: "Fixed",
            right: 20,
            top: "50%",
            transform: "translateY(-50%)",
            color: "#fff",
            backgroundColor: isMobile ? "rgba(255, 255, 255, 0.82)" : "#989898",

            "&:hover": { backgroundColor: "#dcdcdc" },
            zIndex: 1300,
          }}
        >
          <ChevronRightIcon sx={{ color: isMobile ? "red" : "white" }} />
        </IconButton>
      )}
    </>
  );
}

export default ViewPicnote;
