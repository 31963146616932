import React from "react";
import about2 from "../../assets/about/group study.jpg";
import studying from "../../assets/about/studying.jpg";
import profile from "../../assets/about/New folder/5.png";
import picnotesImage from "../../assets/about/added.png";
import group from "../../assets/about/group study.jpg";
import penNote from "../../assets/about/added2.png";
import { Button, Divider, Grid2, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import board from "../../assets/about/board.jpg";
import oldPicnotes from "../../assets/about/U design.png";
import friends from "../../assets/about/friends.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const textStyle = {
  fontFamily: "Ailreon",
  fontSize: "45px",
  fontWeight: 400,
  lineHeight: "46.02px",
  textAlign: "left",
  "@media (max-width: 600px)": {
    fontSize: "40px",
    lineHeight: "48px",
  },
};
const ailreonText = {
  fontFamily: "Ailreon",
};

const supportDoc = {
  fontFamily: "Aileron",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "rgba(112, 112, 112, 1)",
  marginTop: "20px",
  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
};

const buttonStyle = {
  cursor: "pointer",
  padding: "15px",
  width: "127px",
  height: "48px",
  marginRight: "10px",
  borderRadius: "90px",
  backgroundColor: "rgba(188, 23, 29, 1)",
  color: "rgba(255, 255, 255, 1)",
  fontFamily: "coko regular",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  textAlign: "center",
  marginTop: "20px",
  textTransform: "none",
  "@media (max-width: 600px)": {
    width: "140px",
    height: "40px",
  },
};

function AboutUs() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid2 container>
      <Grid2 container item size={12}>
        <Grid2
          item
          size={isMobile ? 12 : 6}
          padding={"50px"}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Grid2 item xs={12}>
            <Typography
              // variant="h2"
              style={{
                fontFamily: "Cako regular",
                fontSize: isMobile ? "40px" : "65px",
                fontWeight: 400,
                lineHeight: isMobile ? "48px" : "76.02px",
                textAlign: "left",
              }}
            >
              Conscious lifelong learners have found a place to call home
            </Typography>
          </Grid2>
          <Divider
            style={{
              border: "2px dashed #f6d5da",
              marginTop: "40px",
            }}
          />
          <Grid2 item size={12} mt={"50px"}>
            <Typography style={supportDoc}>
              A community for those of you whose curiosity has no boundaries.
            </Typography>
          </Grid2>
          <Grid2
            item
            size={12}
            mt={"40px"}
            style={{
              width: "100%",
              display: "flex",
              alignItems: isMobile ? "center" : null,
              justifyContent: isMobile ? "space-between" : "start",
            }}
            spacing={2}
          >
            <Link to="../signup" style={{ textDecoration: "none" }}>
              {/* <Button style={buttonStyle}>Sign up</Button> */}
              <Button style={buttonStyle}>Join Us</Button>
            </Link>

            <Link to="./../landingpage">
              <Button
                style={{
                  cursor: "pointer",
                  width: "auto",
                  height: "48px",
                  gap: "0px",
                  padding: "10px",
                  borderRadius: "90px",
                  border: "1px solid rgba(188, 23, 29, 1)",
                  color: "rgba(188, 23, 29, 1)",
                  fontFamily: "coko regular",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  marginTop: "20px",
                  textTransform: "none",
                  "@media (max-width: 600px)": {
                    width: "140px",
                    height: "40px",
                  },
                }}
              >
                Start browsing
              </Button>
            </Link>
          </Grid2>
        </Grid2>
        <Grid2
          item
          size={isMobile ? 12 : 6}
          display={"flex"}
          justifyContent={"center"}
          paddingTop={isMobile ? "10px" : "50px"}
        >
          <img
            src={board}
            alt="pictures"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderTopLeftRadius: isMobile ? "0px" : "20px",
              borderBottomLeftRadius: isMobile ? "0px" : "20px",
            }}
          />
        </Grid2>
      </Grid2>

      <Grid2
        container
        item
        size={12}
        mt={"20px"}
        padding={"30px 0px 0px 0px"}
        spacing={2}
        // backgroundColor="#fbe8eb"
      >
        <Grid2
          container
          item
          size={isMobile ? 12 : 6}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Typography
            style={{
              ...ailreonText,
              fontSize: isMobile ? "24px" : "30px",
            }}
          >
            We live and breathe education
          </Typography>
          <img
            src={about2}
            alt="About pic"
            style={{
              width: isMobile ? "100%" : "90%",
              height: "90%",
              objectFit: "cover",
            }}
          />

          {isMobile ? (
            <Grid2 item size={12} padding={"0px 30px 40px 40px"}>
              <Typography
                style={{
                  fontSize: "26px",
                  fontFamily: "Aileron",
                  textAlign: isMobile ? "justify" : null,
                  ...supportDoc,
                }}
              >
                Picnotes combines the appeal of a picture sharing platform to an
                educational website.
              </Typography>
            </Grid2>
          ) : null}
        </Grid2>

        <Grid2
          container
          item
          size={isMobile ? 12 : 6}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingTop: isMobile ? "120px" : "0px",
          }}
        >
          <Typography
            style={{
              ...ailreonText,
              fontSize: isMobile ? "24px" : "30px",
              paddingLeft: "40px",
            }}
          >
            Personal learning sparks profound pleasure
          </Typography>
          <img
            src={studying}
            alt="About pic"
            style={{
              width: isMobile ? "100%" : "90%",
              height: "90%",
              objectFit: "cover",
              borderTopRightRadius: isMobile ? "0px" : "20px",
              borderBottomRightRadius: isMobile ? "0px" : "20px",
            }}
          />
        </Grid2>

        <Grid2
          container
          item
          size={12}
          justifyContent={"space-between"}
          paddingTop={isMobile ? "30px" : "0px"}
        >
          {!isMobile ? (
            <Grid2 item size={isMobile ? 12 : 6} padding={"0px 30px 0px 40px"}>
              <Typography
                style={{
                  fontSize: "26px",
                  fontFamily: "Aileron",
                  textAlign: isMobile ? "justify" : null,
                  ...supportDoc,
                }}
              >
                Picnotes combines the appeal of a picture sharing platform to an
                educational website.
              </Typography>
            </Grid2>
          ) : null}

          <Grid2
            container
            item
            size={isMobile ? 12 : 6}
            padding={"0px 30px 0px 40px"}
          >
            <Typography
              style={{
                fontSize: "26px",
                fontFamily: "Aileron",
                textAlign: isMobile ? "justify" : null,
                ...supportDoc,
              }}
            >
              The greatest sense of personal fulfillment comes from ongoing
              learning.
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>

      <Grid2
        container
        item
        size={12}
        padding={isMobile ? "30px 0px 0px 30px" : "30px 0px 0px 0px"}
        spacing={2}
        alignItems={"center"}
        justifyContent={isMobile ? "start" : "center"}
      >
        <Grid2 container>
          <Typography
            style={{
              ...textStyle,
              fontSize: "30px",
              // fontWeight: 600,
            }}
          >
            Make your knowledge useful
          </Typography>
        </Grid2>
      </Grid2>

      <Grid2
        container
        item
        size={12}
        padding={isMobile ? "20px 0px 0px 0px" : "40px 0px 0px 0px"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid2
          container
          item
          size={isMobile ? 12 : 6}
          display={"flex"}
          justifyContent={isMobile ? "start" : "center"}
          paddingLeft={isMobile ? "30px" : "0px"}
          alignItems={"center"}
        >
          <Grid2 item size={isMobile ? 12 : 7}>
            <Typography
              style={{
                ...textStyle,
                fontSize: "30px",
              }}
            >
              Sharing is caring
            </Typography>
          </Grid2>
          <Grid2 item size={isMobile ? 11 : 7}>
            <Divider
              style={{
                width: "100%",
                border: "2px solid #e5343a",
              }}
            />
          </Grid2>
          <Grid2 item size={isMobile ? 11 : 7}>
            <Typography
              style={{
                fontSize: "26px",
                fontFamily: "Aileron",
                textAlign: "justify",
                ...supportDoc,
              }}
            >
              Somewhere, out there, there's someone who doesn’t know what you
              know. We believe that education is the ultimate way to connect
              with people.
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2
          item
          size={isMobile ? 12 : 6}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={picnotesImage}
            alt="About pic"
            style={{
              width: "100%",
              height: "90%",
              objectFit: "cover",
              border: "1px solid #f2f2f2",
              borderRadius: "20px",
              padding: "15px",
            }}
          />
        </Grid2>
      </Grid2>

      <Grid2
        container
        item
        size={12}
        padding={"60px 0px 0px 0px"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid2
          item
          size={isMobile ? 12 : 6}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={profile}
            alt="About pic"
            style={{
              width: "100%",
              height: "90%",
              objectFit: "cover",
              borderRadius: "20px",
              padding: "15px",
              border: "1px solid #f2f2f2",
            }}
          />
        </Grid2>
        <Grid2
          container
          item
          size={isMobile ? 12 : 6}
          display={"flex"}
          justifyContent={isMobile ? "start" : "center"}
          alignItems={"center"}
          paddingLeft={isMobile ? "30px" : "0px"}
        >
          <Grid2 item size={isMobile ? 12 : 7}>
            <Typography
              style={{
                ...textStyle,
                fontSize: "30px",
              }}
            >
              Picnotes are easy and fun to create
            </Typography>
          </Grid2>
          <Grid2 item size={isMobile ? 11 : 7}>
            <Divider
              style={{
                width: "100%",
                border: "2px solid #e5343a",
              }}
            />
          </Grid2>
          <Grid2 item size={isMobile ? 11 : 7}>
            <Typography
              style={{
                fontSize: "26px",
                fontFamily: "Aileron",
                textAlign: "justify",
                ...supportDoc,
              }}
            >
              Share an educational topic by combining an image to short notes.
              Provide resources such as articles, videos, and book
              recommendations. Any resources that you provide, will further
              other users' understanding about a topic.
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>

      <Grid2
        container
        item
        size={12}
        padding={"100px 0px 0px 0px"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid2
          container
          item
          size={isMobile ? 12 : 6}
          display={"flex"}
          justifyContent={isMobile ? "start" : "center"}
          paddingLeft={isMobile ? "30px" : "0px"}
          alignItems={"center"}
        >
          <Grid2 item size={isMobile ? 12 : 7}>
            <Typography
              style={{
                ...textStyle,
                fontSize: "30px",
              }}
            >
              We strive to offer a fantastic educational activity
            </Typography>
          </Grid2>
          <Grid2 item size={isMobile ? 11 : 7}>
            <Divider
              style={{
                width: "100%",
                border: "2px solid #e5343a",
              }}
            />
          </Grid2>
          <Grid2 item size={isMobile ? 11 : 7}>
            <Typography
              style={{
                fontSize: "26px",
                fontFamily: "Aileron",
                textAlign: "justify",
                ...supportDoc,
              }}
            >
              Ongoing learning allows you to foster a positive attitude,
              confidence, and personal growth. However, more than anything, it
              simply provides tremendous pleasure.
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2
          item
          size={isMobile ? 12 : 6}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={penNote}
            alt="About pic"
            style={{
              width: "100%",
              height: "90%",
              objectFit: "cover",
              border: "1px solid #f2f2f2",
              borderRadius: "20px",
              padding: "15px",
            }}
          />
        </Grid2>
      </Grid2>
      <Grid2
        container
        item
        size={12}
        marginTop={"50px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid2 item size={isMobile ? 10 : 9.5}>
          <Typography
            style={{
              ...textStyle,
              fontSize: "30px",
            }}
          >
            Cruising from one topic to another and learning things that you
            never knew before
          </Typography>
        </Grid2>
        {/* <Grid2 item size={9.5}> */}
        <Grid2 item size={isMobile ? 10 : 9.5}>

          <Divider
            style={{
              width: "100%",
              border: "2px solid #e5343a",
              margin: "10px 0px",
            }}
          />
        </Grid2>

        {/* <Grid2 item size={9.5}> */}
        <Grid2 item size={isMobile ? 10 : 9.5}>

          <Typography style={{ ...supportDoc, textAlign: "justify" }}>
            The fluidity of the platform enables you to read one topic after
            another. If you're looking for something specific narrow your search
            with #tags.
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2
        item
        size={12}
        mt={"50px"}
        display={"flex"}
        justifyContent={"center"}
      >
        <img
          src={oldPicnotes}
          alt="logo"
          style={{
            width: "95%",
            height: "90%",
            objectFit: "cover",
          }}
        />
      </Grid2>

      <Grid2
        container
        item
        size={12}
        marginTop={"50px"}
        padding={isMobile ? "0px 0px 0px 30px" : "30px 0px 0px 0px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={isMobile ? "start" : "center"}
      >
        <Typography
          style={{
            ...textStyle,
            fontSize: "30px",
            // fontWeight: 600,
          }}
        >
          Be part of a community
        </Typography>
      </Grid2>

      <Grid2
        container
        item
        size={12}
        // padding={"60px 0px 0px 0px"}
        padding={isMobile ? "20px 0px 0px 0px" : "60px 0px 0px 0px"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid2
          container
          item
          size={isMobile ? 12 : 6}
          display={"flex"}
          justifyContent={isMobile ? "start" : "center"}
          paddingLeft={isMobile ? "30px" : "0px"}
          alignItems={"center"}
        >
          <Grid2 item size={isMobile ? 12 : 7}>
            <Typography
              style={{
                ...textStyle,
                fontSize: "30px",
                // fontWeight: 600,
                // fontFamily: "Aileron",
              }}
            >
              Learn from lifelong learners across the world
            </Typography>
          </Grid2>
          <Grid2 item size={isMobile ? 11 : 7}>
            <Divider
              style={{
                width: "100%",
                border: "2px solid #e5343a",
              }}
            />
          </Grid2>
          <Grid2 item size={isMobile ? 11 : 7}>
            <Typography
              style={{
                fontSize: "26px",
                fontFamily: "Aileron",
                textAlign: "justify",
                ...supportDoc,
              }}
            >
              Follow people and interact with them. You'll discover who they are
              and what they're passionate about simply by reading their
              Picnotes.
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2
          item
          size={isMobile ? 12 : 6}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={group}
            alt="About pic"
            style={{
              width: "90%",
              height: "90%",
              objectFit: "cover",
              borderRadius: isMobile ? "10px" : "20px",
            }}
          />
        </Grid2>
      </Grid2>

      <Grid2
        container
        item
        size={12}
        padding={"60px 0px 0px 0px"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid2
          item
          size={isMobile ? 12 : 6}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={friends}
            alt="About pic"
            style={{
              width: "90%",
              height: "90%",
              objectFit: "cover",
              borderRadius: isMobile ? "10px" : "20px",
            }}
          />
        </Grid2>
        <Grid2
          container
          item
          size={isMobile ? 12 : 6}
          display={"flex"}
          justifyContent={isMobile ? "start" : "center"}
          paddingLeft={isMobile ? "30px" : "0px"}
          alignItems={"center"}
        >
          <Grid2 item size={isMobile ? 12 : 7}>
            <Typography
              style={{
                ...textStyle,
                fontSize: "30px",
              }}
            >
              Make friends
            </Typography>
          </Grid2>
          <Grid2 item size={isMobile ? 11 : 7}>
            <Divider
              style={{
                width: "100%",
                border: "2px solid #e5343a",
              }}
            />
          </Grid2>
          <Grid2 item size={isMobile ? 11 : 7}>
            <Typography
              style={{
                fontSize: "26px",
                fontFamily: "Aileron",
                textAlign: "justify",
                ...supportDoc,
              }}
            >
              Connect with people who view conscious learning as a lifestyle.
              You will encounter like-minded individuals who like you love to
              organize their interests and learning goals.
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2 container marginTop={"50px"}>
          <Link to="./../signup">
            <Button style={buttonStyle}>Join us</Button>
          </Link>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

export default AboutUs;
