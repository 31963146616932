import React, { useEffect, useState } from "react";
import { allMyPnotes } from "../../redux/picnote";
import { useDispatch, useSelector } from "react-redux";
import AddResource from "./AddResource";
import MasonryCom from "../MasonryCom";
import NoData from "../NoData";

function MyPicnotes() {
  const [addResource, setAddResource] = useState(false);
  const [selectedNoteFromMyNote, setSelectedNoteFromMyNote] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const dispatch = useDispatch();

  const { allMyNotes } = useSelector((state) => state.picnote) || {
    allMyNotes: [],
  };

  useEffect(() => {
    setLoading(true);
    dispatch(allMyPnotes()).finally(() => setLoading(false));
  }, []);

  const closeAddSource = () => {
    setAddResource(false);
    setSelectedNoteFromMyNote(null);
  };

  // console.log("allMyNotes", allMyNotes?.picnotesList )

  return (
    <>
      {addResource && selectedNoteFromMyNote ? (
        <AddResource
          closeAddSource={closeAddSource}
          selectedNoteFromMyNote={selectedNoteFromMyNote}
          AllNotesForRelated={allMyNotes?.picnotesList}

        />
      ) : null}

      {loading ? (
        "Loading Data..."
      ) : allMyNotes?.picnotesList?.length > 0 ? (
        <MasonryCom
          picnotes={allMyNotes?.picnotesList}
          column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
          cardsWidth={"1200px"}
          TabName={"myPicnotes"}
        />
      ) : (
        <NoData TabTitle={"myPicnotes"} />
      )}
    </>
  );
}

export default MyPicnotes;
