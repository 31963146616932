import {
  Autocomplete,
  Dialog,
  Grid2,
  IconButton,
  TextField,
} from "@mui/material";
import { Card, Divider, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import sent from "./../assets/sent.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  createComment,
  createTracker,
  deleteCommentTracker,
  deleteTracker,
  editTracker,
  getAllTrackers,
  getTrackerComment,
  trackerReducer,
} from "../redux/tracker";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import favourite from "./../assets/favourite.png";
import commentImage from "./../assets/message-01.png";
import shareIcon from "./../assets/link-square-02.png";
import deleteImage from "../assets/delete-02.png";
import editImage from "../assets/edit-02.png";
import { useFormik } from "formik";
import redfavourite from "./../assets/justFav.png";
import { allTags } from "../redux/tags";
import {
  createPicnoteComment,
  deletePicnoteComment,
  getPicnoteComments,
  singleFeed,
} from "../redux/picnote";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const inputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "38px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
  position: "relative",
  paddingTop: "5px",
};

function Comments({ onClose, title, comp }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [comments, setComments] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [userDetail, setUserDetail] = useState(JSON.parse(localStorage.getItem("userInfo")))
  const [tempuser, setTempUser] = useState(
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );

  useEffect(() => {
    dispatch(
      comp === "addResource"
        ? getPicnoteComments(title?.noteId)
        : getTrackerComment(title?.trackerId)
    );
    dispatch(allTags());
  }, [dispatch, comp, title]);

  const { commentData } = useSelector(trackerReducer);
  const { noteCommentData } = useSelector((state) => state.picnote);
  const { allTagsList } = useSelector((state) => state.tags);

  const handleComment = async () => {
    if (comments.trim()) {
      let resp = await dispatch(
        comp === "addResource"
          ? createPicnoteComment({ noteId: title?.noteId, comments })
          : createComment({
              trackerId: commentData?.trackerComments?.trackerId,
              comments,
            })
      );

      if (resp.status === 200) {
        setComments("");
        toast.success(resp?.data?.message);
        dispatch(
          comp === "addResource"
            ? getPicnoteComments(title?.noteId)
            : getTrackerComment(title?.trackerId)
        );
        dispatch(singleFeed(title?.noteId))
      }
    }
  };

  const handleDelete = async () => {
    let resp = await dispatch(deleteTracker(title?.noteId));
    if (resp.status === 200) {
      toast.success(resp.data.message);
      dispatch(getAllTrackers());
      onClose();
    } else {
      toast.error(resp.data.message);
    }
  };
  const handleDeleteComment = async (commentId) => {
    let resp = await dispatch(
      comp === "addResource"
        ? deletePicnoteComment(commentId)
        : deleteCommentTracker(commentId)
    );
    if (resp.status === 200) {
      toast.success(resp.data.message);
      dispatch(
        comp === "addResource"
          ? getPicnoteComments(title?.noteId)
          : getTrackerComment(title?.trackerId)
      );
      dispatch(singleFeed(title?.noteId))

    } else {
      toast.error(resp.data.message);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const formik = useFormik({
    initialValues: {
      trackerId: title?.noteId,
      title: title?.title,
      trackerTags: title?.trackerTags || [],
    },
    onSubmit: async (values) => {
      // console.log("edit.com", values);
      let resp = await dispatch(editTracker(values));
      if (resp.status === 200) {
        dispatch(getAllTrackers());
        toast.success(resp.data.message);
        setIsEditing(false);
      } else {
        toast.error(resp.data.message);
      }
    },
  });

  const handleSave = () => {
    formik.handleSubmit(); 
  };

  return (
    <Dialog
      open={true}
      maxWidth="xs"
      sx={{
        zIndex: 1500,
        "& .MuiDialog-paper": {
          borderRadius: "18px",
          minWidth: isMobile ? "350px" : "450px",
          maxWidth: isMobile ? "500px" : "600px",
        },
      }}
    >
      <Card
        size="xs"
        title={
          <Typography
            variant="h6"
            style={{
              fontFamily: "Cako regular",
              fontSize: "24px",
              fontWeight: 400,
              color: "rgba(63, 63, 63, 1)",
            }}
          >
            {comp === "addResource"
              ? title?.title
              : title?.trackerStage === "learning"
              ? "Currently Learning"
              : title?.trackerStage === "want"
              ? "Want to Learn"
              : "Learned"}
          </Typography>
        }
        bordered={false}
        style={{
          width: "100%",
        }}
        extra={
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={onClose}
          >
            <CloseIcon style={{ width: "20px", height: "20px" }} />
          </IconButton>
        }
      >
        {(comp === "addResource" && noteCommentData?.noteComments) ||
        commentData?.trackerComments?.ownerDetails ? (
          <Grid2
            container
            key={
              noteCommentData?.noteComments ||
              commentData?.trackerComments?.ownerDetails?.edfId
            }
            alignItems="flex-start"
            // spacing={2}
            style={{ marginBottom: "15px" }}
          >
            {/* Left side image */}
            <Grid2 item size={1.5}>
              <img
                src={
                  comp === "addResource"
                    ? noteCommentData?.noteComments?.edfPicture
                    : commentData?.trackerComments?.ownerDetails?.edfPicture
                }
                alt="Commenter"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </Grid2>

            {/* Right side content */}
            <Grid2 item size={10.5}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 0",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isEditing ? (
                    <Input
                      className="inputTypeTextStyle"
                      id="title"
                      style={{ ...inputStyle, width: "100%" }}
                      placeholder="Topic (Required)"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                    />
                  ) : (
                    <Typography
                      style={{
                        fontFamily: "Aileron",
                        fontSize: "18px",
                        fontWeight: 400,
                      }}
                    >
                      {comp === "addResource"
                        ? `${noteCommentData?.noteComments?.firstName} ${noteCommentData?.noteComments?.lastName}`
                        : title?.title?.length > 20
                        ? `${title?.title.slice(0, 20)}...`
                        : title?.title}
                    </Typography>
                  )}

                  {/* Share Icon with spacing */}
                  {/* <img
                    src={shareIcon}
                    alt="share"
                    style={{
                      width: "14px",
                      height: "14px",
                      marginLeft: "10px",
                    }}
                  /> */}
                </div>

                {/* Edit and Delete Icons */}
                {comp !== "addResource" && tempuser?.userId === userDetail?.userId && (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "10px",
    }}
  >
    {/* Edit */}
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <img src={editImage} alt="edit" style={{ width: 14, height: 14 }} />
      <Typography
        style={{
          marginLeft: "5px",
          fontFamily: "Aileron",
          fontSize: "12px",
          fontWeight: 400,
        }}
        onClick={() => (isEditing ? handleSave() : handleEdit(title.trackerId))}
      >
        {isEditing ? "Save" : "Edit"}
      </Typography>
    </div>

    {/* Delete */}
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => handleDelete(title.trackerId)}
    >
      <img src={deleteImage} alt="delete" style={{ width: 14, height: 14 }} />
      <Typography
        style={{
          marginLeft: "5px",
          fontFamily: "Aileron",
          fontSize: "12px",
          fontWeight: 400,
          color: "#3F3F3F",
        }}
      >
        Delete
      </Typography>
    </div>
  </div>
)}

              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={title?.liked ? redfavourite : favourite}
                  alt="likes"
                  style={{
                    width: "17px",
                    height: "17px",
                  }}
                />
                &nbsp;
                <Typography variant="body2" style={{ marginLeft: 1 }}>
                  {title.likes?.length}
                </Typography>
                <img
                  src={commentImage}
                  alt="comments"
                  style={{
                    width: "17px",
                    height: "17px",
                    marginLeft: "16px",
                  }}
                />
                &nbsp;
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {comp
                    ? noteCommentData?.noteComments?.allComments?.length
                    : commentData?.trackerComments?.allComments?.length}
                </Typography>
              </div>
            </Grid2>
          </Grid2>
        ) : (
          <Typography>No data available.</Typography>
        )}

        <Grid2 item size={6}>
          {isEditing ? (
            <Autocomplete
              multiple
              options={allTagsList || []}
              getOptionLabel={(option) => option.title}
              value={formik.values.trackerTags}
              onChange={(event, newValue) =>
                formik.setFieldValue("trackerTags", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Select Tags"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  style={{
                    borderRadius: "25px",
                    padding: "5px",
                    border: "1px solid #d1d1d1",
                    paddingLeft: "15px",
                    width: "100%",
                  }}
                />
              )}
            />
          ) : (
            <Typography style={{ ...inputStyle, width: "100%" }}>
              <span
                style={{
                  color: "rgba(112, 112, 112, 1)",
                  fontFamily: "Aileron",
                  fontSize: "12px",
                  lineHeight: "16.8px",
                }}
              >
                Tags:
              </span>
              <span
                style={{
                  color: "#fff",
                  fontFamily: "Aileron",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16.8px",
                  borderRadius: "25px",
                  display: "inline-block",
                }}
              >
                &nbsp;
                {comp === "addResource"
                  ? title?.tags?.map((tag, index) => (
                      <span
                        key={index}
                        style={{
                          backgroundColor: "#fcd9da",
                          color: "rgba(188, 23, 29, 1)",
                          padding: "4px 6px",
                          borderRadius: "12px",
                          marginRight: "4px",
                          display: "inline-block",
                        }}
                      >
                        {tag?.tagTitle}
                        {index < title.tags?.length - 1 && ", "}
                      </span>
                    ))
                  : title?.trackerTags?.map((tag, index) => (
                      <span
                        key={index}
                        style={{
                        
                          backgroundColor: "#fcd9da",
                          color: "rgba(188, 23, 29, 1)",
                          padding: "4px 6px",
                          borderRadius: "12px",
                          marginRight: "4px",
                          display: "inline-block",
                        }}
                      >
                        {tag?.tagTitle}
                        {index < title.trackerTags?.length - 1 && ", "}
                      </span>
                    ))}
              </span>
            </Typography>
          )}
        </Grid2>

        <Divider />
        {Array.isArray(
          comp === "addResource"
            ? noteCommentData?.noteComments?.allComments
            : commentData?.trackerComments?.allComments
        ) &&
        (comp === "addResource"
          ? noteCommentData?.noteComments?.allComments.length > 0
          : commentData?.trackerComments?.allComments.length > 0) ? (
          (comp === "addResource"
            ? noteCommentData?.noteComments?.allComments
            : commentData?.trackerComments?.allComments
          ).map((allComment) => (
            <Grid2
              container
              key={allComment.commentId}
              alignItems="flex-start"
              spacing={2}
              style={{ marginBottom: "15px" }}
            >
              <Grid2 item size={1}>
                <img
                  src={allComment?.commentByDetails?.edfPicture}
                  alt="Commenter"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </Grid2>

              {/* Right side text */}
              <Grid2 item size={9}>
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: "5px",
                    color: "#9b9b9b",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    fontSize: "12px",
                  }}
                >
                  {allComment?.commentByDetails?.firstName}{" "}
                  {allComment?.commentByDetails?.lastName}
                  <FiberManualRecordIcon
                    style={{
                      width: "10px",
                      height: "10px",
                      color: "#cecece",
                      marginLeft: "8px",
                      marginRight: "8px",
                    }}
                  />
                  {allComment?.commentDate?.dayDifference > 0
                    ? allComment.commentDate.dayDifference + " days ago"
                    : "Today"}
                </Typography>

                <Typography
                  variant="body2"
                  style={{
                    color: "#3F3F3F",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    fontSize: "12px",
                  }}
                >
                  {allComment.comments}{" "}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginBottom: "5px",
                    color: "#9b9b9b",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDeleteComment(allComment?.commentId)}
                >
                  Delete
                </Typography>
              </Grid2>
            </Grid2>
          ))
        ) : (
          <Typography padding="5px">No comments available.</Typography>
        )}

        <Grid2 container>
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "100%",
            }}
          >
            <span
              style={{
                position: "absolute",
                left: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {comp === "addResource" ? (
                <img
                  src={userDetail?.picture}
                  alt="Start"
                  style={{
                    height: "26px",
                    width: "26px",
                    borderRadius: "50px",
                  }}
                />
              ) : (
                <img
                  src={userDetail?.picture}
                  alt="Start"
                  style={{
                    height: "26px",
                    width: "26px",
                    borderRadius: "50px",
                  }}
                />
              )}
            </span>

            <input
              style={{
                borderRadius: "20px",
                width: "100%",
                height: "38px",
                border: "1px solid rgba(236, 228, 228, 1)",
                paddingLeft: "40px",
                paddingRight: "40px",
              }}
              type="text"
              placeholder="Type comments..."
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />

            <span
              style={{
                position: "absolute",
                right: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={sent}
                alt="Send"
                style={{ height: "16px", width: "16px", cursor: "pointer" }}
                onClick={handleComment}
              />
            </span>
          </div>
        </Grid2>
      </Card>
    </Dialog>
  );
}

export default Comments;
