import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import AddResource from "./create-picnote/AddResource";
import ViewPicnote from "./ViewPicnote";
import Comments from "./Comments";
import { allMyPnotes, favouritePicnote, myFeed } from "../redux/picnote";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import commentImage from "../assets/message-01.png";
import heart from "../assets/favourite.png";
import Likedheart from "../assets/redfavourite.png";
import noImage from "../assets/no image.jpg";

const heights = [390, 235, 430, 410, 320];

function MasonryCom({ picnotes, cardsWidth, TabName, isDialogOpen }) {
  const [addResource, setAddResource] = useState(false);
  const [selectedNoteFromMyNote, setSelectedNoteFromMyNote] = useState(null);
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [comments, setComments] = useState(false);
  const [clickedCardTitle, setClickedCardTitle] = useState("");
  const [displayCount, setDisplayCount] = useState(20);
  const bottomRef = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (TabName === "myPicnotes") {
      dispatch(allMyPnotes());
    } else if (TabName === "myFeed") {
      dispatch(myFeed());
    } else if (TabName === "favouritePicnote") {
      dispatch(favouritePicnote());
    }
  }, [dispatch, TabName]);


useEffect(() => {
  const target = bottomRef.current;
  if (!target) return;

  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        setDisplayCount((prevCount) => prevCount + 40);
      }
    },
    { threshold: 0.1, rootMargin: "100px" } // Adjust rootMargin as needed
  );

  observer.observe(target);

  return () => observer.unobserve(target);
}, [bottomRef.current]);

  const handlePicnote = (note) => {
    setSelectedNoteFromMyNote(note);
    setAddResource(true);
  };

  const closeAddSource = () => {
    setAddResource(false);
    setSelectedNoteFromMyNote(null);
  };

  return (
    <Grid
      container
      sx={{
        width:
          TabName === "AddResource" ||
          TabName === "myPicnotes" ||
          TabName === "myFeed" ||
          TabName === "favouritePicnote"
            ? "100%"
            : "92%",
      }}
      justifyContent="center"
      alignItems="center"
      marginLeft={"10px"}
      paddingLeft={isMobile ? "15px" : "0px"}
    >
      {addResource && selectedNoteFromMyNote ? (
        userDetail ? (
          <AddResource
            closeAddSource={closeAddSource}
            selectedNoteFromMyNote={selectedNoteFromMyNote}
            isDialogOpen={isDialogOpen}
            AllNotesForRelated={picnotes}
          />
        ) : (
          <ViewPicnote
            closeAddSource={closeAddSource}
            selectedNoteFromMyNote={selectedNoteFromMyNote}
            isDialogOpen={isDialogOpen}
            AllNotesForRelated={picnotes}
          />
        )
      ) : null}

      {comments ? (
        <Comments
          onClose={() => setComments(false)}
          title={clickedCardTitle}
          comp="addResource"
        />
      ) : null}

      {picnotes ? (
        <Box sx={{ width: "100%", maxWidth: cardsWidth, mx: "auto" }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "16px",
            }}
          >
            {picnotes.slice(0, displayCount).map((note, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  gridRowEnd: `span ${Math.ceil(
                    heights[index % heights.length] / 16
                  )}`,
                  overflow: "hidden",
                  cursor: "pointer",
                  position: "relative",
                  borderRadius: "10px",
                  border: note?.notePicture ? null :  '1px solid lightgray',
                  "&:hover .cardContent": {
                    opacity: 1, // Show on hover only
                  },
                }}
                onClick={() => handlePicnote(note)}
              >
                {note?.notePicture ? (
                <img
                  src={note.notePicture}
                  alt={note.title}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                /> ) :(
                  <div style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    paddingTop: '20%' 
                  }}>
                    <img
                      src={noImage}
                      alt={note.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  
                ) }

                {/* Card Content (Includes Icons) */}
                <Box
                  className="cardContent"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "start",
                    background:
                      "linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5))",
                    padding: "15px",
                    borderRadius: "10px",
                    opacity: 0, // Initially hidden
                    transition: "opacity 0.3s ease",
                  }}
                >
                  {/* Icons - Will only show on hover */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignSelf: "flex-end",
                    }}
                  >
                    {note?.heart ? (
                      <img
                        src={Likedheart}
                        alt="heart"
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        }}
                      />
                    ) : (
                      <img
                        src={heart}
                        alt="heart"
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                          padding: "7px",
                        }}
                      />
                    )}
                    <img
                      src={commentImage}
                      alt="comment"
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        padding: "7px",
                        cursor: "pointer",
                      }}
                    />
                  </Box>

                  {/* Title and Owner */}
                  <Box>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "1rem",
                        fontFamily: "Aileron",
                      }}
                    >
                      {note.title}
                    </Typography>
                    {TabName !== "myPicnotes" && TabName !== "myFolders" && (
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "0.7rem",
                          fontFamily: "Aileron",
                        }}
                      >
                        {note?.ownerName}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
          </Grid>
          <div ref={bottomRef} style={{ height: "20px" }}></div>
        </Box>
      ) : null}
    </Grid>
  );
}

export default MasonryCom;
