import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../services/config";


const initialState = {
  fileUploadLoading: false,
  serachData: null,
  activeTab: "MyFeed", // Added activeTab state

};

const slice = createSlice({
  name: "media",
  initialState,
  reducers: {
    uploadFile(state, action) {
      state.fileUploadLoading = true;
      state.error = null;
    },
    uploadFileSuccess(state, action) {
      state.fileUploadLoading = false;
      state.error = null;
    },
    uploadFileFailure(state, action) {
      state.fileUploadLoading = false;
      state.error = action.payload;
    },
    contactUs(state, action) {
      state.contactLoader = true;
      state.error = null;
    },
    contactUsSuccess(state, action) {
      state.contactLoader = false;
      state.error = null;
    },
    contactUsFailure(state, action) {
      state.contactLoader = false;
      state.error = action.payload;
    },
    searching(state, action) {
      state.searchLoader = true;
      state.error = null;
    },
    searchingSuccess(state, action) {
      state.searchLoader = false;
      state.error = null;
      state.serachData = action.payload
    },
    searchingFailure(state, action) {
      state.searchLoader = false;
      state.error = action.payload;
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
  },
});


const {
  uploadFile,
  uploadFileSuccess,
  uploadFileFailure,
  contactUs,
  contactUsSuccess,
  contactUsFailure,
  searching,
  searchingSuccess,
  searchingFailure,
  setActiveTab, // Exporting the new action
 
} = slice.actions;

export const mediaReducer = (state) => state.media;

export const uploadTheFile = (data) => async (dispatch) => {
  dispatch(uploadFile());
  try {
    const response = await axios.post(
      `${config.baseUrl}/extras/fileupload`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.status === 200) {
      dispatch(uploadFileSuccess(response.data));
    } else {
      dispatch(uploadFileFailure(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      uploadFileFailure("Something went wrong while uploading user profile!")
    );
  }
}

export const contact = (data) => async (dispatch) => {
  dispatch(contactUs());
  try {
    const response = await axios.post(
      `${config.baseUrl}/extras/contact`, data
      // {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // }
    );
    if (response.status === 200) {
      dispatch(contactUsSuccess(response.data));
    } else {
      dispatch(contactUsFailure(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      contactUsFailure("Something went wrong while sending message!")
    );
  }
};

export const searchInput = (search) => async (dispatch) => {
  dispatch(searching());
  try {
    const response = await axios.get(
      `${config.baseUrl}/extras/searching/${search}`
    );
    if (response.status === 200) {
      dispatch(searchingSuccess(response.data));
    } else {
      dispatch(searchingFailure(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      searchingFailure("Something went wrong while uploading user profile!")
    );
  }
};

export { setActiveTab };



export default slice.reducer;