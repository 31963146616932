import { Card, CardContent, Grid, Grid2, Typography } from "@mui/material";
import React from "react";
// import backgroundImage from "../../assests/BG.png";
import { Link } from "react-router-dom";
import backgroundImage from "../assets/bg.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styleCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const styleText = {
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Aileron",
};
function PrivacyPolicy() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      style={{
        padding: "1rem", // or use a responsive unit
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // Ensures the background covers the whole area
        backgroundPosition: "center", // Centers the background image
        height: "calc(100vh - 86px)", // Fits the remaining viewport height
      }}
      justifyContent="center"
      alignItems="center" // Center vertically
    >
     <Grid
              container
              alignItems="center"
              justifyContent="center"
              xs={isMobile ? 12 : 10} 
              style={{
                height: isMobile ? "auto" : "450px",
              }}
            >
              <Card
                style={{
                  boxShadow: "10px 10px 30px 0px #6271EB33",
                  borderRadius: "24px",
                  width: isMobile ? "100%" : "auto", 
                }}
              >
          {" "}
          <CardContent
            style={{
              margin: "20px 30px",
              height: "500px",
              overflow: "auto",
            }}
          >
            <Typography
              variant="h2"
              style={{
                color: "#213641",
                fontSize: "30px",
                fontWeight: 400,
                paddingBottom: "20px",
              }}
            >
              Terms & conditions
            </Typography>
            <Typography
              style={{
                color: "#666666",
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Lexend",
                paddingBottom: "10px",
              }}
            ></Typography>
            <Grid2 container spacing={2}>
              <Grid2 item size={12} style={{ ...styleCenter }}>
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "Aileron",
                  }}
                >
                  www.picnotes.org Privacy Policy
                </Typography>
              </Grid2>

              <Grid2
                item
                size={12}
                style={{
                  textAlign: "center",
                }}
              >
                <Typography>
                  Type of website: Social media and user-generated content
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "center",
                }}
              >
                <Typography>Effective date: 1st day of August, 2024</Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography>
                  www.picnotes.org (the "Site") is owned and operated by
                  Picnotes Learnings Ltd.. Picnotes Learnings Ltd. is the data
                  controller and can be contacted at:
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography>
                  info@picnotes.org
                  <br />
                  (604) 347-9629
                  <br />
                  1640 Alberni Street Vancouver BC V6G 1A7
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Purpose
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                  }}
                >
                  The purpose of this privacy policy (this "Privacy Policy") is
                  to inform users of our Site of the following:
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                  }}
                >
                  1. The personal data we will collect <br />
                  2. Use of collected data <br />
                  3. Who has access to the data collected <br />
                  4. The rights of Site users <br />
                  5. The Site's cookie policy
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                  }}
                >
                  This Privacy Policy applies in addition to the terms and
                  conditions of our Site.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  GDPR
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                  }}
                >
                  For users in the European Union, we adhere to the Regulation
                  (EU) 2016/679 of the European Parliament and of the Council of
                  27 April 2016, known as the General Data Protection Regulation
                  (the "GDPR"). For users in the United Kingdom, we adhere to
                  the GDPR as enshrined in the Data Protection Act 2018.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Consent
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                  }}
                >
                  By using our Site users agree that they consent to: <br />
                  1. The conditions set out in this Privacy Policy.
                  <br />
                  When the legal basis for us processing your personal data is
                  that you have provided your consent to that processing, you
                  may withdraw your consent at any time. If you withdraw your
                  consent, it will not make processing which we completed before
                  you withdrew your consent unlawful.
                  <br />
                  You can withdraw your consent by: Contacting the founders of
                  the company.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Legal Basis for Processing
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                  }}
                >
                  We collect and process personal data about users in the EU
                  only when we have a legal basis for doing so under Article 6
                  of the GDPR.
                  <br /> We rely on the following legal basis to collect and
                  process the personal data of users in the EU:
                  <br /> 1. Users have provided their consent to the processing
                  of their data for one or more specific purposes.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Personal Data We Collect
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                  }}
                >
                  We only collect data that helps us achieve the purpose set out
                  in this Privacy Policy. We will not collect any additional
                  data beyond the data listed below without notifying you first.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Data Collected Automatically
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                  }}
                >
                  When you visit and use our Site, we may automatically collect
                  and store the following information:
                  <br />
                  1. Location; <br />
                  2. Clicked links;
                  <br /> 3. Content viewed;
                  <br /> 4. Email address;
                  <br /> 5. First and last name.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Data Collected in a Non-Automatic Way
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  We may also collect the following data when you perform
                  certain functions on our Site:
                  <br />
                  1. First and last name; <br />
                  2. Email address;
                  <br /> 3. Auto fill data;
                  <br />
                  This data may be collected using the following methods:
                  <br />
                  1. Creating an account.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  How We Use Personal Data
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  Data collected on our Site will only be used for the purposes
                  specified in this Privacy Policy or indicated on the relevant
                  pages of our Site. We will not use your data beyond what we
                  disclose in this Privacy Policy.
                  <br />
                  The data we collect automatically is used for the following
                  purposes:
                  <br />
                  1. Statistics. <br />
                  The data we collect when the user performs certain functions
                  may be used for the following purposes:
                  <br />
                  1. Communication.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Who We Share Personal Data With
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <span style={{ textDecoration: "underline" }}>Employees</span>
                  <br />
                  We may disclose user data to any member of our organization
                  who reasonably needs access to user data to achieve the
                  purposes set out in this Privacy Policy.
                  <br /><br />
                  <span style={{ textDecoration: "underline" }}>
                    Other Disclosures
                  </span>
                  <br />
                  We will not sell or share your data with other third parties,
                  except in the following cases:
                  <br />
                  <br />
                  1. If the law requires it; <br />
                  2. If it is required for any legal proceeding; <br />
                  3. To prove or protect our legal rights;
                  <br />
                  4. To buyers or potential buyers of this company in the event
                  that
                        we seek to sell the company. <br />
                  <br />
                  If you follow hyperlinks from our Site to another site, please
                  note that we are not responsible for and have no control over
                  their privacy policies and practices.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  How Long We Store Personal Data
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  User data will be stored until the purpose the data was
                  collected for has been achieved. You will be notified if your
                  data is kept for longer than this period.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  How We Protect Your Personal Data
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  In order to protect your security, we use the strongest
                  available browser encryption and store all of our data on
                  cloud computing platforms and APIs.
                  <br />
                  <br />
                  While we take all reasonable precautions to ensure that user
                  data is secure and that users are protected, there always
                  remains the risk of harm. The Internet as a whole can be
                  insecure at times and therefore we are unable to guarantee the
                  security of user data beyond what is reasonably practical.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Your Rights as a User
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  Under the GDPR, you have the following rights:
                  <br />
                  <br />
                  1. Right to be informed; 2. Right of access;
                  <br />
                  3. Right to rectification;
                  <br />
                  4. Right to erasure;
                  <br />
                  5. Right to restrict processing;
                  <br />
                  6. Right to data portability; and
                  <br />
                  7. Right to object.
                  <br />
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Children
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  We collect the personal data of children under 16 years of
                  age. We only collect the personal data of children under 16
                  years of age with the express consent of their parents or
                  guardians. This consent is collected as follows:
                  <br />
                  <br />
                  1. Consent via teachers.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  How to Access, Modify, Delete, or Challenge the Data Collected
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  If you would like to know if we have collected your personal
                  data, how we have used your personal data, if we have
                  disclosed your personal data and to who we disclosed your
                  personal data, if you would like your data to be deleted or
                  modified in any way, or if you would like to exercise any of
                  your other rights under the GDPR, please contact our data
                  protection officer here:
                  <br />
                  <br />
                  Fakhruddin (Mithu) Valika
                  <br /> <br />
                  mvalika@picnotes.org
                  <br />
                  (416) 831-8995
                  <br />
                  2355 Briargrove Circle
                  <br />
                </Typography>
              </Grid2>

              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Do Not Track Notice
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  Do Not Track ("DNT") is a privacy preference that you can set
                  in certain web browsers. We do not track the users of our Site
                  over time and across third party websites and therefore do not
                  respond to browser-initiated DNT signals.
                  <br />
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  How to Opt-Out of Data Collection, Use or Disclosure
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  In addition to the method(s) described in the How to Access,
                  Modify, Delete, or Challenge the Data Collected section, we
                  provide the following specific opt-out methods for the forms
                  of collection, use, or disclosure of your personal data:
                  <br />
                  <br />
                  1. You can opt-out of the use of your personal data for
                  marketing emails. You can opt-out by clicking "unsubscribe" on
                  the bottom of any marketing email.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Cookie Policy
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  A cookie is a small file, stored on a user's hard drive by a
                  website. Its purpose is to collect data relating to the user's
                  browsing habits. You can choose to be notified each time a
                  cookie is transmitted. You can also choose to disable cookies
                  entirely in your internet browser, but this may decrease the
                  quality of your user experience.
                  <br />
                  <br />
                  We use the following types of cookies on our Site:
                  <br />
                  <br />
                  1. Functional cookies Functional cookies are used to remember
                  the selections you make on our Site so that your selections
                  are saved for your next visits; 2. Analytical cookies
                  <br />
                  Analytical cookies allow us to improve the design and
                  functionality of our Site by collecting data on how you access
                  our Site, for example data on the content you access, how long
                  you stay on our Site, etc; <br />
                  3. Targeting cookies Targeting cookies collect data on how you
                  use the Site and your preferences. This allows us to
                  personalize the information you see on our Site for you.
                  <br />
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Modifications
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  This Privacy Policy may be amended from time to time in order
                  to maintain compliance with the law and to reflect any changes
                  to our data collection process. When we amend this Privacy
                  Policy we will update the "Effective Date" at the top of this
                  Privacy Policy. We recommend that our users periodically
                  review our Privacy Policy to ensure that they are notified of
                  any updates. If necessary, we may notify users by email of
                  changes to this Privacy Policy.
                  <br />
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Complaints
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  If you have any complaints about how we process your personal
                  data, please contact us through the contact methods listed in
                  the Contact Information section so that we can, where
                  possible, resolve the issue. If you feel we have not addressed
                  your concern in a satisfactory manner you may contact a
                  supervisory authority. You also have the right to directly
                  make a complaint to a supervisory authority. You can lodge a
                  complaint with a supervisory authority by contacting the The
                  Office of the Privacy Commissioner of Canada.
                  <br />
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Contact Information
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  If you have any questions, concerns or complaints, you can
                  contact our data protection officer, Fakhruddin (Mithu)
                  Valika, at:
                  <br />
                  <br />
                  mvalika@picnotes.org <br />
                  (416) 831-8995 2355 <br />
                   Briargrove Circle<br />
                  <br />
                </Typography>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default PrivacyPolicy;









