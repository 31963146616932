import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid2,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/userContext";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import {
  authReducer,
  loginWithCredentials,
  loginWithEmail,
  loginWithSocial,
  resetPassword,
} from "../../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import picture from "./../../assets/login.png";
import { Input } from "antd";
import facebook from "./../../assets/facebook.png";
import google from "./../../assets/google.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import arrow from "./../../assets/arrow-down-01-round.png";
import { getFollowingList, setTempProfile } from "../../redux/tags";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import FacebookTwoToneIcon from '@mui/icons-material/FacebookTwoTone';

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "start",
  paddingBottom: "20px",
};

const inputContainerStyle = {
  fontFamily: "Aileron",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "19.2px",
  textAlign: "center",
  paddingBottom: "20px",
};

const inputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "38px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
  position: "relative",
};

const labelStyle = {
  marginBottom: "5px",
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "31.21px",
};

const signupStyle = {
  backgroundColor: "rgba(188, 23, 29, 0.1)",
  color: "rgba(188, 23, 29, 1)",
  borderRadius: "25px",
  height: "38px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  cursor: "pointer",
  textTransform: "none",
};

const CustomSelectIcon = () => (
  <img
    src={arrow} // Replace with the URL of your icon
    alt="Select icon"
    style={{ width: "20px", height: "20px", marginRight: "10px" }} // Adjust size as needed
  />
);

function SignIn() {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [validateUserInfo, setValidateUserInfo] = useState(null);
  const [userData, setUserData] = useContext(UserContext);
  const [showResetPassScreen, setShowResetPassScreen] = useState(false);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const { followingList } = useSelector((state) => state.tags);

  const facebookId = process.env.REACT_APP_FACEBOOK_ID;
  const googleId = process.env.REACT_APP_GOOGLE_ID;

  //! INSTANCES
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { loading } = useSelector(authReducer);

  const checkIsEmailValid = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  
  const fetchFacebookData = async (accessToken) => {
    try {
      const response = await fetch(
        `https://graph.facebook.com/me?fields=id,name,email,picture&access_token=${accessToken}`
      );
      const data = await response.json();
      setProfile(data);
    } catch (error) {
      console.error("Error fetching Facebook data:", error);
    }
  };
  
  useEffect(() => {
    // Check if both profile and provider are available
    if (profile && provider) {
      // Prepare data to send in the API request
      const userData = {
        firstName: profile?.givenName || profile?.name,
        lastName: profile?.familyName || profile?.name,
        email: profile?.email || profile?.email || "",
        smuSource: provider, // The provider (either 'google' or 'facebook')
        picture:
          provider === "facebook"
            ? profile?.picture?.data?.url || ""
            : profile?.profilePicture || "",
        pnSection: "Edfluencer",
        email_verified: true,
        sub:
          provider === "facebook"
            ? `facebook|${profile?.id || ""}`
            : profile?.googleUserId || "",
      };

      // Example of dispatching action with userData
      const login = async () => {
        try {
          // Dispatch your login action here with userData as the payload
          const resp = await dispatch(loginWithSocial(userData));

          if (resp?.status === 200) {
            // Save token & user info in local storage
            localStorage.setItem("token", resp.data?.token);
            localStorage.setItem(
              "userInfo",
              JSON.stringify(resp.data?.userData)
            );
            localStorage.setItem("Temptoken", resp.data?.token);
            localStorage.setItem(
              "TempuserInfo",
              JSON.stringify(resp.data?.userData)
            );
            localStorage.setItem(
              "baseFollowingList",
              JSON.stringify(followingList)
            );

            // Dispatch an action to get the following list (optional)
            dispatch(getFollowingList());

            // Show success toast
            toast.success("Login successful, Welcome!");

            // Redirect to dashboard or any page
            navigate("/app/pages");
            window.location.reload(); // If you need to reload the page after login
          }
        } catch (error) {
          // Handle any errors during the login process
          console.error("Login failed:", error);
          toast.error("Something went wrong. Please try again.");
        }
      };

      // Call the login function
      login();
    }
  }, [profile, provider, dispatch]);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.email = values.email.toLowerCase(); // Ensure email is always lowercase before submitting

      if (!checkIsEmailValid(values.email)) {
        return toast.error("Please write email in correct format!");
      }
      let resp = await dispatch(loginWithEmail({ email: values.email }));
      if (resp.status === 400) {
        return toast.error(resp.data.message);
      }
      if (resp.status === 200) {
        setIsEmailValid(true);

        setValidateUserInfo(resp.data);
        // return toast.success(resp.data.message);
      }
    },
  });

  const validationSchemaTendency = yup.object({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    email: yup.string().required("Email is required"),
    acctId: yup.string().required("Tenant selection is required"),
  });

  const formikTendency = useFormik({
    initialValues: {
      acctId: validateUserInfo?.loginList?.accounts?.[0]?.acctId || "",
      password: "",
      email: validateUserInfo?.loginList?.email?.toLowerCase() || "",
      counts: validateUserInfo?.loginList?.counts || 1,
    },
    enableReinitialize: true,
    validationSchema: validationSchemaTendency,
    onSubmit: async (values) => {
      values.email = values.email.toLowerCase(); // Ensure email is always lowercase before submitting

      let resp = await dispatch(loginWithCredentials(values));
      if (resp?.status === 200) {
        setUserData(resp.data?.token);
        toast.success("Login successful, Welcome!");
        localStorage.setItem("token", resp.data?.token);
        localStorage.setItem("userInfo", JSON.stringify(resp.data.userData));
        localStorage.setItem("Temptoken", resp.data?.token);
        localStorage.setItem(
          "TempuserInfo",
          JSON.stringify(resp.data.userData)
        );
        let followingResp = await dispatch(getFollowingList());
        // console.log("fello", followingResp)
        localStorage.setItem(
          "baseFollowingList",
          JSON.stringify(followingResp?.data?.listFollowing)
        );
        dispatch(setTempProfile(resp.data.userData));
        Navigate("/app/pages");
      } else {
        if (Array.isArray(resp.data.message)) {
          resp.data?.message.forEach((res) => toast.error(res.msg));
        } else {
          toast.error(resp.data?.message);
        }
      }
    },
  });

  const openForgetPassScreen = async () => {
    if (formikTendency.values.counts === "") {
      return toast.error("Please select tenancy before forgot password!");
    }

    let resp = await dispatch(
      resetPassword({
        email: formikTendency.values.email,
        acctId: validateUserInfo?.loginList?.accounts?.[0]?.acctId || "",
      })
    );
    if (resp?.status === 200) {
      toast.success(resp?.data?.message);
      setShowResetPassScreen(true);
    } else {
      return toast.error(resp?.data.message);
    }
  };

  return (
    <Grid2
      container
      style={{
        display: "flex",
        alignItems: isMobile ? "center" : "start",
        justifyContent: isMobile ? "center" : "center",
        margin: "60px 0px 80px 0px",
      }}
    >
      {!isMobile ? (
        <Grid2
          item
          size={7.1}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingLeft: "65px",
          }}
        >
          <img
            src={picture}
            alt="Logo"
            style={{ height: "556px", width: "462px" }}
          />
        </Grid2>
      ) : null}

      {showResetPassScreen ? (
        <Card
          style={{
            boxShadow: "10px 10px 30px 0px #6271EB33",
            borderRadius: "24px",
            height: "200px",
            marginTop: "3rem",
          }}
        >
          <CardContent>
            <Grid2
              container
              size={12}
              alignItems="center"
              justifyContent="center"
            >
              <Grid2 item size={10}>
                <Typography
                  variant="h6"
                  style={{
                    color: "#213641",
                    fontSize: "30px",
                    marginTop: "2rem",
                  }}
                >
                  Email Sent
                </Typography>
              </Grid2>
              <Grid2 item size={10}>
                <Typography variant="subtitle1" style={{ color: "#829096" }}>
                  We’ve sent you a password reset link to your <br /> email
                  address{" "}
                  <span
                    style={{
                      color: "rgba(188, 23, 29, 1)",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {formikTendency?.values.email}
                  </span>{" "}
                  <br />
                  Please check your inbox.
                </Typography>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      ) : (
        <Grid2
          item
          size={isMobile ? 10 : 3}
          style={{
            alignSelf: "flex-start",
            paddingTop: "65px",
            alignItems: isMobile ? "center" : "end",
            display: "flex",
            justifyContent: isMobile ? "center" : "start",
          }}
        >
          {isEmailValid && isEmailValid ? (
            <form onSubmit={formikTendency.handleSubmit}>
              <Grid2 container>
                <Grid2 item size={12} style={centerStyle}>
                  <Typography
                    style={{
                      color: "rgba(63, 63, 63, 1)",
                      fontFamily: "Cako regular",
                      fontWeight: 400,
                      fontSize: "24px",
                    }}
                  >
                    Log In
                  </Typography>
                </Grid2>

                <Grid2 container size={12}>
                  {validateUserInfo?.loginList?.counts === 1 &&
                  validateUserInfo?.loginList?.accounts?.length === 1 ? (
                    <Grid2 item size={12}>
                      <Typography
                        sx={{
                          mt: 2,
                          mb: 2,
                          pl: "10px",
                          color: "#cccc",
                          border: "1px solid",
                          borderRadius: "10px",
                          height: "38px",
                          paddingTop: "5px",
                        }}
                      >
                        <span style={{ color: "#000" }}>
                          {validateUserInfo?.loginList?.accounts[0]?.acctTitle}
                        </span>
                      </Typography>
                    </Grid2>
                  ) : (
                    <Grid2 item size={12}>
                      <label htmlFor="Tenancy" style={labelStyle}>
                        Tenancy
                      </label>
                      <Select
                        style={{ ...inputStyle, width: "100%" }}
                        value={formikTendency.values.acctId}
                        onChange={(e) =>
                          formikTendency.setFieldValue("acctId", e.target.value)
                        }
                        IconComponent={CustomSelectIcon}
                      >
                        {(validateUserInfo?.loginList?.accounts || []).map(
                          (account) => (
                            <MenuItem
                              key={account.acctId}
                              value={account.acctId}
                            >
                              {account.acctTitle}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </Grid2>
                  )}

                  <Grid2 item size={12}>
                    <label htmlFor="password" style={labelStyle}>
                      Password
                    </label>
                    <Input.Password
                      id="password"
                      style={{ ...inputStyle, width: "100%" }}
                      placeholder="Password"
                      name="password"
                      value={formikTendency.values.password}
                      onChange={formikTendency.handleChange}
                      onBlur={formikTendency.handleBlur}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />

                    {formik.touched.password && formik.errors.password ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </Grid2>

                  <Grid2 size={12} style={{ marginTop: "20px" }}>
                    <LoadingButton
                      fullWidth
                      loading={loading}
                      type="submit"
                      sx={{
                        backgroundColor: "rgba(188, 23, 29, 1)",
                        color: "#fff",
                        borderRadius: "25px",
                        mt: 2,
                        textTransform: "none",
                      }}
                    >
                      Login
                    </LoadingButton>
                  </Grid2>
                  <Grid2
                    container
                    item
                    size={12}
                    paddingTop={"20px"}
                    justifyContent={"end"}
                  >
                    <span
                      onClick={openForgetPassScreen}
                      style={{
                        color: "rgba(188, 23, 29, 1)",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      Forget Password
                    </span>
                  </Grid2>

                  <Grid2 item size={12} paddingBottom="20px" marginTop="10px">
                    <Divider>
                      <Chip label="Or" size="small" />
                    </Divider>
                  </Grid2>
                  {/* Below grid is for next step when we are in password screen */}
                  <Grid2 container item size={12} spacing={2} marginTop="1rem">
                    <Grid2 item size={6}>
                      <Button style={signupStyle}>
                        <LoginSocialGoogle
                          isOnlyGetToken
                          client_id={googleId}
                        scope="email"

                          onResolve={({ provider, data }) => {
                            // Get the access token
                            const { access_token } = data;

                            // Make a request to Google’s UserInfo API to get user details
                            fetch(
                              "https://www.googleapis.com/oauth2/v2/userinfo",
                              {
                                method: "GET",
                                headers: {
                                  Authorization: `Bearer ${access_token}`,
                                },
                              }
                            )
                              .then((response) => response.json())
                              .then((userInfo) => {
                                // Now we have access to user info, such as:
                                const userName = userInfo.name; // Full name
                                const familyName = userInfo.family_name; // Family name (last name)
                                const givenName = userInfo.given_name; // Given name (first name)
                                const email = userInfo.email; // Email
                                const profilePicture = userInfo.picture; // Profile picture

                                // Store the profile in the state
                                setProvider(provider);
                                setProfile({
                                  userName,
                                  familyName,
                                  givenName,
                                  email,
                                  profilePicture,
                                });
                              })
                              .catch((error) => {
                                console.error(
                                  "Error fetching user info:",
                                  error
                                );
                              });
                          }}
                          onReject={(err) => {
                            console.log(err);
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={google}
                              alt="Google"
                              style={{ width: "20px", height: "20px" }}
                            />
                            &nbsp; Google
                          </span>
                        </LoginSocialGoogle>
                      </Button>
                    </Grid2>
                    <Grid2 item size={6}>
                     
                      <Button
                       disabled
                       style={{...signupStyle,
                         backgroundColor: "rgba(146, 146, 146, 0.1)",
                         color: "gray"
                       }}>
                      <LoginSocialFacebook
                        isOnlyGetToken
                        appId={facebookId}
                        onResolve={({ provider, data }) => {
                          setProvider(provider);
                          setProfile(data);

                          // Fetch user data from Facebook Graph API
                          fetchFacebookData(data.accessToken); // Access token from login
                        }}
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <img
                            src={facebook}
                            alt="Facebook"
                            style={{ width: "22px", height: "22px" }}
                          /> */}
                          <FacebookTwoToneIcon />

                          &nbsp; Facebook
                        </span>
                      </LoginSocialFacebook>
                    </Button>
                    </Grid2>
                  </Grid2>

                  <Grid2
                    item
                    size={12}
                    justifyContent="center"
                    alignItems={"center"}
                    display={"flex"}
                    style={{
                      ...inputContainerStyle,
                      marginTop: "2rem",
                      paddingBottom: "20px",
                    }}
                  >
                    <span style={{ color: "#829096" }}>
                      Don't have an account?{" "}
                      <Link
                        to="../signup"
                        style={{
                          color: "rgba(188, 23, 29, 1)",
                          fontWeight: 600,
                          textDecoration: "none",
                        }}
                      >
                        Sign up
                      </Link>
                    </span>
                  </Grid2>
                </Grid2>
              </Grid2>
            </form>
          ) : (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <Grid2 container>
                <Grid2 item size={12} style={centerStyle}>
                  <Typography
                    style={{
                      color: "rgba(63, 63, 63, 1)",
                      fontFamily: "Cako regular",
                      fontWeight: 400,
                      fontSize: "24px",
                      // margin: '30px 0px',
                    }}
                  >
                    Log In
                  </Typography>
                </Grid2>
                <Grid2 item size={12}>
                  <label htmlFor="email" style={labelStyle}>
                    Email
                  </label>
                  <Input
                    id="email"
                    style={{ ...inputStyle, width: "100%" }}
                    placeholder="abc@gmail.com"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {formik.errors.email}
                    </div>
                  ) : null}
                </Grid2>
                <Grid2 item size={12} paddingBottom="10px" marginTop="1rem">
                  <LoadingButton
                    fullWidth
                    loading={loading}
                    type="submit"
                    sx={{
                      backgroundColor: "rgba(188, 23, 29, 1)",
                      color: "#fff",
                      borderRadius: "25px",
                      mt: 2,
                      textTransform: "none",
                    }}
                  >
                    <span> Next</span>
                  </LoadingButton>
                </Grid2>
                <Grid2 item size={12} paddingBottom="10px" marginTop="1rem">
                  <Divider>
                    <Chip label="Or" size="small" />
                  </Divider>
                </Grid2>
                <Grid2 container item size={12} spacing={2} marginTop="1rem">
                  <Grid2 item size={6}>
                    <Button style={signupStyle}>
                      <LoginSocialGoogle
                        isOnlyGetToken
                        client_id={googleId}
                        scope="email"
                        onResolve={({ provider, data }) => {
                          const { access_token } = data;

                          // Fetch user info from Google's userinfo endpoint
                          fetch(
                            "https://www.googleapis.com/oauth2/v2/userinfo",
                            {
                              method: "GET",
                              headers: {
                                Authorization: `Bearer ${access_token}`,
                              },
                            }
                          )
                            .then((response) => response.json())
                            .then((userInfo) => {
                              // Google user ID (you can format it as needed)
                              const googleUserId = `google-oauth2|${userInfo.id}`;
                              // Store the profile in state or send to your backend
                              setProvider(provider);
                              setProfile({
                                userName: userInfo.name,
                                familyName: userInfo.family_name,
                                givenName: userInfo.given_name,
                                email: userInfo.email,
                                profilePicture: userInfo.picture,
                                googleUserId, // This is the formatted ID
                              });
                            })
                            .catch((error) => {
                              console.error("Error fetching user info:", error);
                            });
                        }}
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={google}
                            alt="Google"
                            style={{ width: "20px", height: "20px" }}
                          />
                          &nbsp; Google
                        </span>
                      </LoginSocialGoogle>
                    </Button>
                  </Grid2>
                  <Grid2 item size={6}>
                    <Button
                      disabled
                      style={{...signupStyle,
                        backgroundColor: "rgba(146, 146, 146, 0.1)",
                        color: "gray"

                      }}
                    >
                      <LoginSocialFacebook

                        isOnlyGetToken
                        appId={facebookId}
                        onResolve={({ provider, data }) => {
                          setProvider(provider);
                          setProfile(data);

                          // Fetch user data from Facebook Graph API
                          fetchFacebookData(data.accessToken); // Access token from login
                        }}
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <img
                            src={facebook}
                            alt="Facebook"
                            style={{ width: "22px", height: "22px" }}
                          /> */}
                          <FacebookTwoToneIcon />
                          
                          &nbsp; Facebook
                        </span>
                      </LoginSocialFacebook>
                    </Button>
                  </Grid2>
                </Grid2>
                <Grid2
                  item
                  size={12}
                  justifyContent="center"
                  alignItems={"center"}
                  display={"flex"}
                  style={{
                    ...inputContainerStyle,
                    marginTop: "1rem",
                    paddingBottom: "20px",
                  }}
                >
                  <span style={{ color: "#829096" }}>
                    Don't have an account?{" "}
                    <Link
                      to="../signup"
                      style={{
                        color: "rgba(188, 23, 29, 1)",
                        fontWeight: 600,
                        textDecoration: "none",
                      }}
                    >
                      Sign up
                    </Link>
                  </span>
                </Grid2>
              </Grid2>
            </form>
          )}
        </Grid2>
      )}
    </Grid2>
  );
}

export default SignIn;
