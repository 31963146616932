import React, { useEffect, useRef, useState } from "react";
import Explore from "./Explore";
// import picture1 from "./../../assets/about/stright1.png";
import picture1 from "./../../assets/about/mainPage.png";
import { Grid, Typography } from "@mui/material";
import Guideline from "./Guideline";
import MasonryCom from "../../components/MasonryCom";
import { useDispatch, useSelector } from "react-redux";
import { visiterTrending } from "../../redux/picnote";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth0 } from "@auth0/auth0-react";

import { loginWithSocial } from "../../redux/auth";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";

const HeadingStyle = {
  fontFamily: "Cako regular",
  fontSize: "36px",
  fontWeight: 400,
  lineHeight: "55.21px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "rgba(63, 63, 63, 1)",
  marginTop: "80px",
};

const supportDoc = {
  fontFamily: "Aileron",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: "rgba(112, 112, 112, 1)",
  marginTop: "20px",
  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
};

const supportDocStyle = {
  fontFamily: "Aileron",
  fontSize: "30px",
  fontWeight: 400,
  lineHeight: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "rgba(112, 112, 112, 1)",
  marginTop: "8px",
  marginBottom: "10px",
};



function LandingPage() {
  const guidelineRef = useRef(null);
  const exploreRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const textStyle = {
    fontFamily: "Cako regular",
    fontSize: isMobile ? "40px" : "65px",
    fontWeight: 400,
    lineHeight: isMobile ? "58px" : "76.02px",
    textAlign: "left",
  };

  // const { user, isAuthenticated, isLoading } = useAuth0();
  // const [authChecked, setAuthChecked] = useState(false);



  // useEffect(() => {
  //   const login = async () => {
  //     if (!isLoading && isAuthenticated && user) {
  //       setAuthChecked(true);

  //       const data = {
  //         pnSection: "Edfluencer",
  //         email: user?.email || "",
  //         firstName: user?.given_name || user?.name || "Unknown",
  //         lastName: user?.family_name || "",
  //         picture: user?.picture || "",
  //         sub: user?.sub || "",
  //         email_verified: true,
  //         smuSource: user?.sub?.includes("google-oauth2")
  //           ? "google"
  //           : user?.sub?.includes("facebook")
  //           ? "facebook"
  //           : "unknown",
  //       };

  //       const resp = await dispatch(loginWithSocial(data));
  //       if (resp?.status === 200) {
  //         // console.log("Login successful");
  //         localStorage.setItem("token", resp.data?.token);
  //         localStorage.setItem("userInfo", JSON.stringify(resp.data?.userData));
  //         localStorage.setItem("Temptoken", resp.data?.token);
  //         localStorage.setItem(
  //           "TempuserInfo",
  //           JSON.stringify(resp.data?.userData)
  //         );
  //         toast.success("Login successful, Welcome!");
  //         // Navigate("/app/pages");
  //         window.location.reload();
  //       } else {
  //         // console.log("Login failed, showing error messages");
  //         const messages = Array.isArray(resp?.data?.message)
  //           ? resp.data?.message
  //           : [resp.data?.message];
  //         messages.forEach((msg) => toast.error(msg?.msg || msg));
  //       }
  //     }
  //   };

  //   login();
  // }, [isAuthenticated, user, dispatch, isLoading]);

  useEffect(() => {
    dispatch(visiterTrending());
  }, [dispatch]);

  const { vTrinList, vTrinListL } = useSelector((state) => state.picnote) || {
    allMyNotes: [],
  };

  const scrollToGuideline = () => {
    if (guidelineRef.current) {
      guidelineRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToExplore = () => {
    if (exploreRef.current) {
      exploreRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      {/* Explore and Image */}
      <Grid
        container
        item
        xs={12}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        {isMobile ? (
          <>
            <Grid item xs={11}>
              <Typography
                style={{ ...textStyle, color: "rgba(63, 63, 63, 1)" }}
              >
                Share your <br /> knowledge{" "}
                <span style={{ ...textStyle, color: "rgba(188, 23, 29, 1)" }}>
                  650
                </span>{" "}
                <br /> characters at a time{" "}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography style={{ ...supportDoc, marginTop: "10px" }}>
                Get inspired by lifelong learners and students
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <img
                src={picture1}
                alt="Logo"
                style={{
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                padding: { xs: "20px 0px 20px 20px", md: "40px 0px 40px 40px" },
              }}
            >
              <Explore
                scrollToGuideline={scrollToGuideline}
                scrollToExplore={scrollToExplore}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                padding: { xs: "20px 0px 20px 20px", md: "40px 0px 40px 40px" },
              }}
            >
              <Explore
                scrollToGuideline={scrollToGuideline}
                scrollToExplore={scrollToExplore}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
              paddingBottom={"20px"}
            >
              <img
                src={picture1}
                alt="Logo"
                style={{
                  // width: isMobile ? "100%" : isTab ? "100%" :  "90%",
                  width: "90%",
                  objectFit: "contain",
                }}
              />
            </Grid>
          </>
        )}
      </Grid>

      {/* Guidelines Section */}
      <Grid
        item
        xs={12}
        ref={guidelineRef}
        sx={{
          backgroundColor: "rgba(188, 23, 29, 0.1)",
          padding: { xs: "20px", md: "40px" },
        }}
      >
        <Guideline />
      </Grid>

      {/* Picnotes Heading */}
      <Grid
        container
        item
        xs={10}
        ref={exploreRef}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          {/* <Typography style={HeadingStyle}>Our Picnotes</Typography> */}
          <Typography style={{ ...HeadingStyle }}>
            {/* Powered by lifelong learners and students from around the world */}
            Glide from one topic to the next
          </Typography>
        </Grid>
        <Grid item xs={12} style={supportDocStyle}>
          <Typography>
            {/* Explore some of the picnotes by Expert Creators. */}
            Powered by knowledge seekers from around the world
          </Typography>
        </Grid>
      </Grid>

      {/* Feeds Section */}
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: { xs: "20px", md: "40px" },
          maxWidth: { xs: "100%", md: "90%" },
        }}
      >
        {vTrinListL ? "Loading picnotes..." : (
        <MasonryCom
          picnotes={vTrinList?.lookingData}
          column={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 5 }}
          cardsWidth={"100%"}
          TabName={"landingPage"}
        />
      )}
      </Grid>
    </Grid>
  );
}

export default LandingPage;
