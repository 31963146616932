import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  Grid2,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import "./style.css";
import Blog from "../assets/Blog.png";
import Book from "../assets/BookRed.png";
import Podcasts from "../assets/Podcast.png";
import Article from "../assets/articale.png";
import Documentaries from "../assets/Documantries.png";
import OnlineCourses from "../assets/Online.png";
import AddToTools from "./AddToTools";
import { useDispatch, useSelector } from "react-redux";
import { deleteTools, toolReducer } from "../redux/tool";
import favourite from "./../assets/favourite.png";
import redfavourite from "./../assets/justFav.png";
import comment from "./../assets/message-01.png";
import shareIcon from "./../assets/link-square-02.png";
import toast from "react-hot-toast";
import { getAllTools, likeTool } from "../redux/tool";
import ToolComments from "./ToolComments";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoData from "./NoData";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function MyEdTools() {
  const [clickedCard, setClickedCard] = useState(false);
  const [clickedCardTitle, setClickedCardTitle] = useState("");
  const [comments, setComments] = useState(false);
  const [clickedCard1, setClickedCard1] = useState(false);
  const [clickedCardDetail, setClickedCardDetail] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [originalUser, setOriginalUser] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );

  useEffect(() => {
    dispatch(getAllTools());
  }, []);
  const { allTools } = useSelector(toolReducer);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log("allTools", allTools);
  // console.log("clickedCardTitle", clickedCardTitle);

  const cardData = [
    { name: "Book", image: Book },
    { name: "Article", image: Article },
    { name: "Blog", image: Blog },
    { name: "Podcast", image: Podcasts },
    { name: "Online Course", image: OnlineCourses },
    { name: "Documentary", image: Documentaries },
  ];

  const HandleClose = () => {
    setClickedCardDetail(false);
    setClickedCard1(false);
  };

  const handleEdit = (cardDetail) => {
    setClickedCard1(true);
    setClickedCardDetail(cardDetail);
  };

  const handleDelete = async (toolId) => {
    let resp = await dispatch(deleteTools(toolId));
    if (resp.status === 200) {
      toast.success(resp.data.message);
      dispatch(getAllTools());
    } else {
      toast.error(resp.data.message);
    }
    // console.log(toolId);
  };

  const handleClick = (card) => {
    setClickedCardDetail(card);
    setClickedCardTitle(card);
    // console.log("card", card)
  };

  const handleComment = (cardTitle) => {
    setComments(true);
    setClickedCardTitle(cardTitle);
  };
  const HandleCloseComment = () => {
    setComments(false);
  };

  const handleLike = async (toolId, isLiked) => {
    const newLikedState = !isLiked;

    let resp = await dispatch(likeTool({ toolId, liked: newLikedState }));

    if (resp.status === 200) {
      toast.success(resp?.data?.message);
      dispatch(getAllTools());
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null); // Reset the selected item when menu closes
  };

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget); // Track the menu's anchor
    setSelectedItem(item); // Track the clicked item
  };

  return (
    <>
      <Grid2 container justifyContent="space-between" alignItems="flex-start">
        {clickedCardDetail && userDetail?.userId === originalUser?.userId && (
          <AddToTools title={clickedCardDetail}  onClose={HandleClose} />
        )}

        {clickedCard1 && userDetail?.userId === originalUser?.userId && (
          <AddToTools title={clickedCardDetail} clickedCard1={clickedCard1} onClose={HandleClose} />
        )}

        {comments && (
          <ToolComments title={clickedCardTitle} onClose={HandleCloseComment} />
        )}
        {cardData.map((card, index) => (
          <Grid2
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            border="8px solid #F3F3F3"
            style={{
              marginBottom: "16px",
              justifyContent: "center",
              borderRadius: "8px",
            }}
            backgroundColor="#F3F3F3"
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card
                sx={{
                  width: "310px",
                  height: "67px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  paddingBottom: "10px",

                  
                }}
                elevation={0}
                onClick={originalUser?.userId === userDetail?.userId ? () => handleClick(card) : undefined}
                // onClick={() => handleClick(card)}
              >
                <AddIcon style={{ color: "#BC171D" }} /> <br />
                {/* </Grid2> */}
                <img
                  src={card.image}
                  alt={card.name}
                  style={{ width: "20px", height: "20px", marginRight: "8px" }}
                />
                <Typography className="myEdTools">{card.name}</Typography>
              </Card>
            </span>
            <Grid2 
                 sx={{
                  overflow: "auto", // Show scrollbar only when needed
                  maxHeight: "298px", // Set a max height for the grid
                  '&::-webkit-scrollbar': {
                    display: 'none', // Hide the scrollbar initially
                  },
                  '&:hover::-webkit-scrollbar': {
                    display: 'block', // Show the scrollbar when the grid is hovered
                  },
                }}
                  >
            {allTools?.toolList
              // ?.filter((tool) => tool.toolType === card.name)
              .filter((tool) => tool.toolType.toLowerCase() === card.name.toLowerCase())

              .map((item, idx) => (
                <Grid2
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={idx}
                  paddingTop={"8px"}
                  borderRadius={"8px"}
                  backgroundColor="#F3F3F3"

                >
                  <Card
                    sx={{
                      width: "310px",
                      borderRadius: "8px",
                      paddingBottom: "10px",
                    }}
                    elevation={0}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "18px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          style={{
                            fontFamily: "Aileron",
                            fontSize: "15px",
                            fontWeight: 400,
                            color: "#3F3F3F",
                          }}
                        >{item?.toolTitle}
                          {/* {item?.toolTitle?.length > 18
                            ? `${item.toolTitle.slice(0, 18)}...`
                            : item.toolTitle} */}
                        </Typography>
                      </div>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        {/* Replace FavoriteIcon with image */}
                        <img
                          src={item?.liked ? redfavourite : favourite}
                          alt="likes"
                          style={{
                            width: "17px",
                            height: "17px",
                            cursor: originalUser ? "pointer" : "default",
                          }}
                          onClick={originalUser ? () => handleLike(item?.toolId, item?.liked) : null}
                        />
                        &nbsp;
                        <Typography variant="body2" style={{ marginLeft: 1 }}>
                          {item?.toolLikes?.length}
                        </Typography>
                        {/* Replace CommentIcon with image */}
                        <img
                          src={comment}
                          alt="comments"
                          style={{
                            width: "17px",
                            height: "17px",
                            marginLeft: "12px",
                            cursor: originalUser ? "pointer" : "default",

                          }}
                          onClick={originalUser ?  () => handleComment(item) : null}
                        />
                        &nbsp;
                        <Typography variant="body2">
                          {item?.commentCounts}
                        </Typography>
                       {originalUser?.userId === userDetail?.userId ?

                        <MoreVertIcon
                          style={{
                            fontSize: "18px",
                            color: "#6c6c6c",
                            marginRight: "-10px",
                            cursor: "pointer",
                          }}
                          onClick={(event) => handleMenuOpen(event, item)} // Pass the item when opening the menu
                        /> : null}
                        {/* Menu for Edit and Delete */}
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={() => {
                              handleEdit(selectedItem); // Use the tracked item
                              handleMenuClose();
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleDelete(selectedItem.toolId); // Use the tracked item
                              handleMenuClose();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>

                    {/* Card image */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        marginTop: "8px",
                        padding: "0px 18px",
                      }}
                    >
                      {/* Conditional rendering for link content */}
                      {item.toolLink ? (
                        <img
                          src={`https://www.google.com/s2/favicons?sz=64&domain=${(() => {
                            try {
                              return new URL(item.toolLink).hostname;
                            } catch (error) {
                              return "example.com"; // Fallback domain
                            }
                          })()}`}
                          alt="Favicon"
                          // alt="tool preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            height: "40px",
                            width: "40px",
                            borderRadius: "5px",
                            border: "1px solid #B8B8B84D",
                            display: "block",
                          }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/path/to/fallback-image.png"; // Optional fallback image
                          }}
                        />
                      ) : (
                        <BrokenImageIcon
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "5px",
                            border: "1px solid #B8B8B84D",
                            display: "block",
                          }}
                        />
                      )}

                      {/* Tool link displayed as clickable text */}
                      <Typography
                        variant="body2"
                        sx={{
                          marginLeft: 2,
                          width: "50%",
                          textAlign: "left",
                          fontFamily: "Aileron",
                          fontSize: "12px",
                        }}
                      >
                        <a
                          href={item.toolLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "black" }}
                        >
                          {item.toolLink?.length > 24
                            ? `${item.toolLink.slice(0, 24)}...`
                            : item.toolLink}
                        </a>
                      </Typography>
                    </Box>
                  </Card>
                </Grid2>
              ))}
              </Grid2>
          </Grid2>
        ))}

        {allTools?.toolList?.length < 1 ? (
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <NoData TabTitle={"myedTools"} />
          </Grid>
        ) : null}
      </Grid2>
    </>
  );
}

export default MyEdTools;
