import { Card, CardContent, Grid, Grid2, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { contact, mediaReducer } from "../../redux/media";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import backgroundImage from "../../assets/bg.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const labelStyle = {
  marginBottom: "5px",
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "31.21px",
};

const inputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "38px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
  position: "relative",
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "13px",
};

const inputContainerStyle = {
  marginBottom: "15px",
};

const useStyles = makeStyles({
  textFieldStyle: {
    backgroundColor: "#F1F5F8",
    borderRadius: "10px",
  },
  textFieldStyleError: {
    backgroundColor: "#F1F5F8",
    borderRadius: "10px",
  },
  color: {
    color: "#fff",
  },
});

function ContactUs() {
  //! INSTANCES
  const classes = useStyles();

  let dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  //! SELECTORS
  const { contactLoader } = useSelector(mediaReducer);

  const validationSchema = yup.object({
    firstName: yup.string().required("First name is required !"),
    lastName: yup.string().required("Last name is required !"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    message: yup.string().required("Message is required !"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let resp = await dispatch(contact(values));
      // console.log("resp", resp);
      if (resp.status === 200) {
        resetForm();
        toast.success(resp.data.message);
      } else {
        toast.error(resp.data.message);
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        style={{
          padding: "1rem", // or use a responsive unit
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover", // Ensures the background covers the whole area
          backgroundPosition: "center", // Centers the background image
          height: "calc(100vh - 86px)", // Fits the remaining viewport height
        }}
        justifyContent="center"
        alignItems="center" // Center vertically
      >
        
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          xs={isMobile ? 12 : 4} 
          style={{
            height: isMobile ? "auto" : "450px",

          }}
        >
          <Card
            style={{
              boxShadow: "10px 10px 30px 0px #6271EB33",
              borderRadius: "24px",
              padding: "1rem",
              width: isMobile ? "100%" : "auto", 
            }}
          >
            <CardContent>
              <Grid
                container
                xs={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs={10}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: "#3F3F3F",
                      fontSize: "30px",
                      fontFamily: "Cako regular",
                    }}
                  >
                    Contact Us
                  </Typography>
                </Grid>
              </Grid>

              {/* From Sign Up */}
              <Grid
                container
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                <Grid2
                  container
                  item
                  size={12}
                  spacing={2}
                  paddingBottom={"10px"}
                >
                  <Grid2 item size={6}>
                    <label htmlFor="firstName" style={labelStyle}>
                      First Name
                    </label>
                    <Input
                      id="firstName"
                      style={{ ...inputStyle, width: "100%" }}
                      className="inputTextStyling"
                      placeholder="First Name"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </Grid2>
                  <Grid2 item size={6}>
                    <label htmlFor="lastName" style={labelStyle}>
                      Last Name
                    </label>
                    <Input
                      id="lastName"
                      className="inputTextStyling"
                      style={{ ...inputStyle, width: "100%" }}
                      placeholder="Last Name"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.lastName}
                      </div>
                    ) : null}
                  </Grid2>
                  <Grid2 item size={12} paddingBottom={"10px"}>
                    <label htmlFor="email" style={labelStyle}>
                      Email
                    </label>
                    <Input
                      id="email"
                      style={{ ...inputStyle, width: "100%" }}
                      placeholder="abc@gmail.com"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </Grid2>
                  <Grid2 item size={12} style={inputContainerStyle}>
                    <label htmlFor="message" style={labelStyle}>
                      Message
                    </label>
                    <TextArea
                      id="message"
                      placeholder="drop your message here"
                      autoSize={{ minRows: 4, maxRows: 5 }}
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      style={{
                        ...inputStyle,
                        borderRadius: "10px",
                        width: "100%",
                        border: "1px solid rgba(236, 228, 228, 1)",
                        paddingLeft: "10px",
                        paddingRight: "40px",
                      }}
                    />
                  </Grid2>
                </Grid2>

                <Grid
                  item
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid xs={6}>
                    <LoadingButton
                      loading={contactLoader}
                      loadingPosition="start"
                      fullWidth
                      sx={{
                        backgroundColor: "rgba(188, 23, 29, 1)",
                        color: "#fff",
                        borderRadius: "25px",
                        mt: 2,
                        "&:hover": {
                          backgroundColor: "rgba(188, 23, 29, 0.8)",
                        },
                      }}
                      type="submit"
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>

              {/* End is Here */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

export default ContactUs;
