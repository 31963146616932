import axios from "axios";
import config from "../services/config";
import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  createToolsL: false,
  getAllToolsL: false,
  getSingleToolL: false,
  allTools: null,
  commentData: null,
  getCommentL: false,
  deleteTrackerL: false,
  editLoader: false,
  updateStageL: false,
  createToolCommentL: false,
  deleteToolsL: false,
  deleteToolComL: false,
  editToolLoader: false,

}


const slice = createSlice({
  name: 'tool',
  initialState,
  reducers: {
    creatingTools(state, action) {
      state.createToolsL = true;
      state.error = null;
    },
    creatingToolsSuccess(state, action) {
      state.createToolsL = false;
      state.error = null;

    },
    creatingToolsFailure(state, action) {
      state.createToolsL = false;
      state.error = action.payload;
    },
    gettingAllTools(state, action) {
      state.getAllToolsL = true;
      state.error = null;
    },
    gettingAllToolsSuccess(state, action) {
      state.getAllToolsL = false;
      state.error = null;
      state.allTools = action.payload;


    },
    gettingAllToolsFailure(state, action) {
      state.getAllToolsL = false;
      state.error = action.payload;
    },
    
    gettingSingleTools(state, action) {
      state.getSingleToolL = true;
      state.error = null;
    },
    gettingSingleToolsSuccess(state, action) {
      state.getSingleToolL = false;
      state.error = null;
      state.singleTraker = action.payload;
    },
    gettingSingleToolsFailure(state, action) {
      state.getSingleToolL = false;
      state.error = action.payload;
    },
    
    likingTools(state, action) {
      state.likeLoader = true;
      state.error = null;
    },
    likingToolsSuccess(state, action) {
      state.likeLoader = false;
      state.error = null;
    },
    likingToolsFailure(state, action) {
      state.likeLoader = false;
      state.error = action.payload;
    },
    gettingToolsComments(state, action) {
      state.getCommentL = true;
      state.error = null;
    },
    gettingToolsCommentsSuccess(state, action) {
      state.getCommentL = false;
      state.error = null;
      state.commentData = action.payload;
    },
    gettingToolsCommentsFailure(state, action) {
      state.getCommentL = false;
      state.error = action.payload;
    },
    addingToolsComment(state, action) {
      state.createToolCommentL = true;
      state.error = null;
    },
    addingToolsCommentSuccess(state, action) {
      state.createToolCommentL = false;
      state.error = null;

    },
    addingToolsCommentFailure(state, action) {
      state.createToolCommentL = false;
      state.error = action.payload;
    },
    deletingTools(state, action) {
      state.deleteToolsL = true;
      state.error = null;
    },
    deletingToolsSuccess(state, action) {
      state.deleteToolsL = false;
      state.error = null;

    },
    deletingToolsFailure(state, action) {
      state.deleteToolsL = false;
      state.error = action.payload;
    },
    deletingComTools(state, action) {
      state.deleteToolComL = true;
      state.error = null;
    },
    deletingComToolsSuccess(state, action) {
      state.deleteToolComL = false;
      state.error = null;

    },
    deletingComToolsFailure(state, action) {
      state.deleteToolComL = false;
      state.error = action.payload;
    },
    editingTool(state, action) {
      state.editToolLoader = true;
      state.error = null;
    },
    editingToolSuccess(state, action) {
      state.editToolLoader = false;
      state.error = null;

    },
    editingToolFailure(state, action) {
      state.editToolLoader = false;
      state.error = action.payload;
    },
  },
  });

  const {
    creatingTools,
    creatingToolsSuccess,
    creatingToolsFailure,
    gettingAllTools,
    gettingAllToolsSuccess,
    gettingAllToolsFailure,
    gettingSingleTools,
    gettingSingleToolsSuccess,
    gettingSingleToolsFailure,
    likingTools,
    likingToolsSuccess,
    likingToolsFailure,
    gettingToolsComments,
    gettingToolsCommentsSuccess,
    gettingToolsCommentsFailure,
    addingToolsComment,
    addingToolsCommentSuccess,
    addingToolsCommentFailure,
    deletingTools,
    deletingToolsSuccess,
    deletingToolsFailure,
    deletingComTools,
    deletingComToolsSuccess,
    deletingComToolsFailure,
    editingTool,
    editingToolSuccess,
    editingToolFailure,   
  } = slice.actions;

  export const toolReducer = (state) => state.tool


  export const createTool = (data) => async (dispatch) => {
    dispatch(creatingTools());
    try {
      const response = await axios.post(
        `${config.baseUrl}/tool/create`, data    );
        
      if (response.status === 200) {
        dispatch(creatingToolsSuccess(response.status)); 
      } else {
        dispatch(creatingToolsFailure(response.status)); 
      }
  
      return response;
    } catch (e) {
      dispatch(
        creatingToolsFailure("Something went wrong while creating tracker!")
      );
    }
  };

  export const getAllTools = () => async (dispatch) => {
    dispatch(gettingAllTools());
    try {
      const response = await axios.get(`${config.baseUrl}/tool/allactivelist`);
      
      if (response.status === 200) {
        // Assuming the tracker list is in response.data
        dispatch(gettingAllToolsSuccess(response.data));
      } else {
        dispatch(gettingAllToolsFailure("Failed to fetch tools"));
      }
  
      return response;
    } catch (e) {
      dispatch(gettingAllToolsFailure("Something went wrong while getting all tools!"));
    }
  };
  

  export const singleTool = (trackerId) => async (dispatch) => {
    dispatch(gettingSingleTools());
    try {
      const response = await axios.get(
        `${config.baseUrl}/tracker/gettracker/${trackerId}`   );
        
      if (response.status === 200) {
        dispatch(gettingSingleToolsSuccess(response.status)); 
      } else {
        dispatch(gettingSingleToolsFailure(response.status)); 
      }
  
      return response;
    } catch (e) {
      dispatch(
        gettingSingleToolsFailure("Something went wrong while getting single tracker!")
      );
    }
  };

export const likeTool = (trackerId) => async (dispatch) => {
  dispatch(likingTools());  // Dispatching action to show it's in progress
  try {
    const response = await axios.patch(
      `${config.baseUrl}/tool/liketool`, trackerId );
    
    if (response.status === 200) {
      dispatch(likingToolsSuccess(response.data)); // Success action with response data
    } else {
      dispatch(likingToolsFailure(response.status)); // Failure action with status code
    }

    return response; // Return the response to be handled in the component
  } catch (e) {
    dispatch(
      likingToolsFailure("Something went wrong while liking tool!")
    );
    return { status: 500, message: "Request failed" }; // Returning an error response
  }
};

export const getToolComment = (toolId) => async (dispatch) => {
  dispatch(gettingToolsComments());  // Dispatching action to show it's in progress
  try {
    const response = await axios.get(
      `${config.baseUrl}/tool/gettoolcomments/${toolId}`
    );
    
    if (response.status === 200) {
      dispatch(gettingToolsCommentsSuccess(response.data)); // Success action with response data
    } else {
      dispatch(gettingToolsCommentsFailure(response.status)); // Failure action with status code
    }

    return response; // Return the response to be handled in the component
  } catch (e) {
    dispatch(
      gettingToolsCommentsFailure("Something went wrong while getting comments!")
    );
    return { status: 500, message: "Request failed" }; // Returning an error response
  }
};


export const deleteTools = (toolId) => async (dispatch) => {
  dispatch(deletingTools());  // Dispatching action to show it's in progress
  try {
    const response = await axios.get(
      `${config.baseUrl}/tool/delete/${toolId}`
    );
    
    if (response.status === 200) {
      dispatch(deletingToolsSuccess(response.data)); // Success action with response data
    } else {
      dispatch(deletingToolsFailure(response.status)); // Failure action with status code
    }

    return response; // Return the response to be handled in the component
  } catch (e) {
    dispatch(
      deletingToolsFailure("Something went wrong while deleting tool!")
    );
    return { status: 500, message: "Request failed" }; // Returning an error response
  }
};

export const deleteCommentTool = (commentId) => async (dispatch) => {
  dispatch(deletingComTools());  // Dispatching action to show it's in progress
  try {
    const response = await axios.get(
      `${config.baseUrl}/tool/deletecomment/${commentId}`
    );
    
    if (response.status === 200) {
      dispatch(deletingComToolsSuccess(response.data)); // Success action with response data
    } else {
      dispatch(deletingComToolsFailure(response.status)); // Failure action with status code
    }

    return response; // Return the response to be handled in the component
  } catch (e) {
    dispatch(
      deletingComToolsFailure("Something went wrong while deleting comment!")
    );
    return { status: 500, message: "Request failed" }; // Returning an error response
  }
};


export const createComment = (data) => async (dispatch) => {
  dispatch(addingToolsComment());
  try {
    const response = await axios.post(
      `${config.baseUrl}/tool/createcomment`, data    );
      
    if (response.status === 200) {
      dispatch(addingToolsCommentSuccess(response.status)); 
    } else {
      dispatch(addingToolsCommentFailure(response.status)); 
    }

    return response;
  } catch (e) {
    dispatch(
      addingToolsCommentFailure("Something went wrong while adding commenting!")
    );
  }
};

export const editTool = (data) => async (dispatch) => {
  dispatch(editingTool());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/tool/edit`, data    );
      
    if (response.status === 200) {
      dispatch(editingToolSuccess(response.status)); 
    } else {
      dispatch(editingToolFailure(response.status)); 
    }

    return response;
  } catch (e) {
    dispatch(
      editingToolFailure("Something went wrong while creating tracker!")
    );
  }
};


export default slice.reducer;