import { Dialog, Grid2, IconButton } from "@mui/material";
import { Card, Divider, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import sent from "./../assets/sent.png";
import Avatar from "./../assets/avatar2.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SaveIcon from "@mui/icons-material/Save";

import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import favourite from "./../assets/favourite.png";
import commentImage from "./../assets/message-01.png";
import shareIcon from "./../assets/link-square-02.png";
import deleteImage from "../assets/delete-02.png";
import editImage from "../assets/edit-02.png";
import { useFormik } from "formik";
import redfavourite from "./../assets/justFav.png";
import {
  createComment,
  deleteCommentTool,
  deleteTools,
  editTool,
  getAllTools,
  getToolComment,
  toolReducer,
} from "../redux/tool";

const inputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "38px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
  position: "relative",
  paddingTop: "5px",
};

function ToolComments({ onClose, title }) {
  const dispatch = useDispatch();
  const [comments, setComments] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [originalUser, setOriginalUser] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );

  useEffect(() => {
    dispatch(getToolComment(title?.toolId));
  }, [dispatch]);

  const { commentData } = useSelector(toolReducer);

  // console.log("commentData", commentData);

  const handleComment = async () => {
    if (comments.trim()) {
      const toolId = commentData?.toolComments?.toolId;
      if (toolId) {
        let resp = await dispatch(createComment({ toolId, comments }));
        if (resp.status === 200) {
          setComments("");
          dispatch(getAllTools());
          toast.success(resp?.data?.message);
          dispatch(getToolComment(title?.toolId));
        }
      }
    }
  };

  const handleDelete = async () => {
    let resp = await dispatch(deleteTools(title?.toolId));
    if (resp.status === 200) {
      toast.success(resp.data.message);
      dispatch(getAllTools());
      onClose();
    } else {
      toast.error(resp.data.message);
    }
  };
  const handleDeleteComment = async (commentId) => {
    let resp = await dispatch(deleteCommentTool(commentId));
    if (resp.status === 200) {
      toast.success(resp.data.message);
      dispatch(getToolComment(title?.toolId));
      dispatch(getAllTools());
    } else {
      toast.error(resp.data.message);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleCancel = () => {
    setIsEditing(false);
  };

  const formik = useFormik({
    initialValues: {
      toolId: title?.toolId,
      toolTitle: title?.toolTitle,
      toolType: title?.toolType,
    },
    onSubmit: async (values) => {
      // console.log("edit.com", values);
      let resp = await dispatch(editTool(values));
      if (resp.status === 200) {
        dispatch(getAllTools());
        toast.success(resp.data.message);
        setIsEditing(false);
      } else {
        toast.error(resp.data.message);
      }
    },
  });

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog
      open={true}
      maxWidth="xs"
      sx={{
        zIndex: 1200,
        "& .MuiDialog-paper": {
          borderRadius: "18px",
          minWidth: "450px",
          maxWidth: "450px",
        },
      }}
    >
      <Card
        size="xs"
        title={
          <Typography
            variant="h6"
            style={{
              fontFamily: "Cako regular",
              fontSize: "24px",
              fontWeight: 400,
              color: "rgba(63, 63, 63, 1)",
            }}
          >
            {title?.toolType}
          </Typography>
        }
        bordered={false}
        style={{
          width: "100%",
        }}
        extra={
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={onClose}
          >
            <CloseIcon style={{ width: "20px", height: "20px" }} />
          </IconButton>
        }
      >
        {commentData?.toolComments?.ownerDetails ? (
          <Grid2
            container
            key={commentData?.toolComments?.ownerDetails?.edfId}
            alignItems="flex-start"
            // spacing={2}
            style={{ marginBottom: "15px" }}
          >
            {/* Left side image */}
            <Grid2
              item
              size={1.5}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "8px",
              }}
            >
              <img
                src={commentData?.toolComments?.ownerDetails?.edfPicture}
                alt="Commenter"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </Grid2>

            {/* Right side content */}
            <Grid2 item size={10.5}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // padding: "10px 0",
                }}
              >
                {/* Title and Share Icon */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isEditing ? (
                    <Input
                      className="inputTypeTextStyle"
                      id="toolTitle"
                      style={{ ...inputStyle, width: "100%" }}
                      placeholder="Topic (Required)"
                      name="toolTitle"
                      value={formik.values.toolTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.toolTitle &&
                        Boolean(formik.errors.toolTitle)
                      }
                    />
                  ) : (
                    <Typography
                      style={{
                        fontFamily: "Aileron",
                        fontSize: "18px",
                        fontWeight: 400,
                      }}
                    >
                      {title?.toolTitle?.length > 20
                        ? `${title?.toolTitle.slice(0, 20)}...`
                        : title?.toolTitle}
                    </Typography>
                  )}
                </div>

                {/* Edit and Delete Icons */}
                {originalUser?.userId === userDetail?.userId ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {/* Edit */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      {!isEditing ? (
                        <img
                          src={editImage}
                          alt="edit"
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      ) : (
                        <SaveIcon
                          style={{
                            color: "rgba(188, 23, 29, 1)",
                            width: "20px",
                          }}
                        />
                      )}
                      {isEditing ? (
                        <Typography
                          style={{
                            marginLeft: "5px",
                            fontFamily: "Aileron",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                          onClick={() => handleSave()}
                        >
                          Save
                        </Typography>
                      ) : (
                        <Typography
                          style={{
                            marginLeft: "5px",
                            fontFamily: "Aileron",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                          onClick={() => handleEdit(title.toolId)}
                        >
                          Edit
                        </Typography>
                      )}
                    </div>

                    {/* Delete */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {!isEditing ? (
                        <img
                          src={deleteImage}
                          alt="delete"
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      ) : (
                        <CloseIcon
                          style={{
                            color: "rgba(188, 23, 29, 1)",
                            width: "20px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                      {isEditing ? (
                        <Typography
                          style={{
                            marginLeft: "5px",
                            fontFamily: "Aileron",
                            fontSize: "12px",
                            fontWeight: 400,
                            cursor: "pointer",
                          }}
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </Typography>
                      ) : (
                        <Typography
                          style={{
                            marginLeft: "5px", // Space between icon and text
                            fontFamily: "Aileron",
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "#3F3F3F",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDelete(title.toolId)}
                        >
                          Delete
                        </Typography>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={title?.liked ? redfavourite : favourite}
                  alt="likes"
                  style={{
                    width: "17px",
                    height: "17px",
                  }}
                />
                &nbsp;
                {/* {title?.likeCounts} */}
                <Typography variant="body2" style={{ marginLeft: 1 }}>
                  {/* {title?.likes?.length} */}
                  {commentData?.toolComments?.toolLikes?.length}
                </Typography>
                <img
                  src={commentImage}
                  alt="comments"
                  style={{
                    width: "17px",
                    height: "17px",
                    marginLeft: "16px",
                  }}
                />
                &nbsp;
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {commentData?.toolComments?.allComments?.length}
                </Typography>
              </div>
            </Grid2>
          </Grid2>
        ) : (
          <span>
            <Typography> No data available.</Typography>
          </span>
        )}

        <Divider />

        {Array.isArray(commentData?.toolComments?.allComments) &&
        commentData?.toolComments?.allComments?.length > 0 ? (
          commentData?.toolComments?.allComments.map((allComment) => (
            <Grid2
              container
              key={allComment.commentId}
              alignItems="flex-start"
              spacing={2}
              style={{ marginBottom: "15px" }}
            >
              <Grid2 item size={1}>
                <img
                  src={allComment?.commentByDetails?.edfPicture}
                  alt="Commenter"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </Grid2>

              {/* Right side text */}
              <Grid2 item size={9}>
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: "5px",
                    color: "#9b9b9b",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    fontSize: "12px",
                  }}
                >
                  {allComment?.commentByDetails?.firstName}{" "}
                  {allComment?.commentByDetails?.lastName}
                  <FiberManualRecordIcon
                    style={{
                      width: "10px",
                      height: "10px",
                      color: "#cecece",
                      marginLeft: "8px",
                      marginRight: "8px",
                    }}
                  />
                  {allComment?.commentDate?.dayDifference > 0
                    ? allComment.commentDate.dayDifference + "days ago"
                    : "Today"}
                </Typography>

                <Typography
                  variant="body2"
                  style={{
                    color: "#3F3F3F",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    fontSize: "12px",
                  }}
                >
                  {allComment.comments}{" "}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginBottom: "5px",
                    color: "#9b9b9b",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  onClick={
                    originalUser?.userId === userDetail?.userId
                      ? () => handleDeleteComment(allComment?.commentId)
                      : null
                  }
                >
                  Delete
                </Typography>
              </Grid2>
            </Grid2>
          ))
        ) : (
          <Typography marginBottom={"20px"}>No comments available.</Typography>
        )}

        {/* ))} */}
        {/* </Grid2> */}

        <Grid2 container>
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "100%",
            }}
          >
            <span
              style={{
                position: "absolute",
                left: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                // src={commentData?.toolComments?.ownerDetails?.edfPicture}
                src={originalUser?.picture}
                alt="Start"
                style={{ height: "26px", width: "26px", borderRadius: "50px" }}
              />
            </span>
            <input
              style={{
                borderRadius: "20px",
                width: "100%",
                height: "38px",
                border: "1px solid rgba(236, 228, 228, 1)",
                paddingLeft: "40px",
                paddingRight: "40px",
              }}
              type="text"
              placeholder="Type comments..."
              value={comments}
              onChange={(e) => setComments(e.target.value)} // Handle input change
            />

            <span
              style={{
                position: "absolute",
                right: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={sent}
                alt="Send"
                style={{ height: "16px", width: "16px", cursor: originalUser?.userId === userDetail?.userId ? "pointer" : "not-allowed" }}
                onClick={
                  originalUser?.userId === userDetail?.userId
                    ? handleComment
                    : null
                }
                aria-label="Send comment"
              />
            </span>
          </div>
        </Grid2>
      </Card>
    </Dialog>
  );
}

export default ToolComments;
