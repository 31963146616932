import React, { useEffect, useState } from "react";
import { getTrendeing, getVTrendeing } from "../redux/picnote";
import { useDispatch, useSelector } from "react-redux";
import { Grid2, Typography } from "@mui/material";
import ViewPicnote from "./ViewPicnote";
import MasonryCom from "./MasonryCom";

function Explore() {
  const [addResource, setAddResource] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedTag, setSelectedTag] = useState("New to Old Picnotes");

  let userInfo = localStorage.getItem("userInfo");
  userInfo = JSON.parse(userInfo);

  const dispatch = useDispatch();
  const { TrendingNotesList, vTrendingNotesList, TrendingLoader } = useSelector(
    (state) => state.picnote
  );

  const tags = ["New to Old Picnotes", "Old to New Picnotes"];

  useEffect(() => {
  if (userInfo !== null) {
    dispatch(getTrendeing());
  } else {
    dispatch(getVTrendeing());
  }
}, []);


  useEffect(() => {
    dispatch(userInfo ? getTrendeing() : getVTrendeing());
  }, [dispatch]);

  const closeAddSource = () => {
    setAddResource(false);
    setSelectedNote(null);
  };

  const notesList = userInfo ? TrendingNotesList : vTrendingNotesList;

  const filteredNotes = notesList?.lookingData?.slice()?.sort((a, b) => {
    if (selectedTag === "New to Old Picnotes") {
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else if (selectedTag === "Old to New Picnotes") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    return 0;
  });


  return (
    <>
      {addResource && selectedNote ? (
        <ViewPicnote
          closeAddSource={closeAddSource}
          picnoteCardsdata={selectedNote}
        />
      ) : null}

      <Grid2
        container
        spacing={2}
        mb={"80px"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* Title Grid */}
        <Grid2 item xs={12} style={{ textAlign: "center" }}>
          <Typography
            style={{
              fontFamily: "cako regular",
              fontWeight: 400,
              fontSize: "36px",
            }}
          >
            Explore
          </Typography>
        </Grid2>

        {/* Tags Grid */}
        <Grid2 item xs={12} style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              paddingBottom: "30px",
            }}
          >
            {tags.map((item, index) => (
              <Typography
                key={index}
                onClick={() => setSelectedTag(item)}
                style={{
                  borderRadius: "25px",
                  border: "1px solid",
                  borderColor: selectedTag === item ? "#BC171D" : "gray",
                  padding: "5px 10px",
                  fontSize: "14px",
                  color: selectedTag === item ? "#BC171D" : "inherit",
                  cursor: "pointer",
                  fontWeight: 400,
                  transition: "all 0.3s ease",
                }}
              >
                {item}
              </Typography>
            ))}
          </div>
        </Grid2>
        {TrendingLoader ? (
          "Loading picnotes..."
        ) : (
        <MasonryCom
          picnotes={filteredNotes}
          column={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 5 }}
          cardsWidth={"90%"}
          TabName={"Explore"}
        />
      )}

      </Grid2>
    </>
  );
}

export default Explore;
