import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import scroll from "./../../assets/Scroll Down 1.png";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const supportDoc = {
  fontFamily: "Aileron",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: "rgba(112, 112, 112, 1)",
  marginTop: "20px",
  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
};

const mediaText = {
  fontFamily: "Aileron",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  textAlign: "left",
  color: "rgba(63, 63, 63, 1)",
  marginTop: "20px",
  cursor: "pointer",
};

const buttonStyle = {
  cursor: "pointer",
  width: "177px",
  height: "48px",
  gap: "0px",
  borderRadius: "90px",
  backgroundColor: "rgba(188, 23, 29, 1)",
  color: "rgba(255, 255, 255, 1)",
  fontFamily: "coko regular",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  textAlign: "center",
  marginTop: "20px",
  textTransform: "none",
  "@media (max-width: 600px)": {
    width: "140px",
    height: "40px",
  },
};

function Explore({ scrollToGuideline, scrollToExplore }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const textStyle = {
    fontFamily: "Cako regular",
    fontSize: isMobile ? "50px" : "65px",
    fontWeight: 400,
    lineHeight: isMobile ? "58px" : "76.02px",
    textAlign: "left",
  };

  return (
    <Grid
      container
      margin={{ xs: "30px 0px 0px 20px", md: "60px 0px 0px 70px" }}
    >
      {isMobile ? null : (
        <>
          <Grid item xs={12}>
            <Typography style={{ ...textStyle, color: "rgba(63, 63, 63, 1)" }}>
              Share your <br /> knowledge{" "}
              <span style={{ ...textStyle, color: "rgba(188, 23, 29, 1)" }}>
                650
              </span>{" "}
              <br /> characters at a time{" "}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography style={{ ...supportDoc, marginTop: "10px" }}>
              Get inspired by lifelong learners and students
            </Typography>
          </Grid>
        </>
      )}

      <Grid
        item
        xs={12}
        container
        justifyContent="start"
        paddingTop="20px"
        paddingLeft={"15px"}
        spacing={2}
        gap={1}
      >
        <Button style={buttonStyle} onClick={scrollToExplore}>
          Start browsing
        </Button>

        <Link to="../login" style={{ textDecoration: "none" }}>
          <Button
            style={{
              cursor: "pointer",
              width: "auto",
              height: "48px",
              gap: "0px",
              padding: "10px",
              borderRadius: "90px",
              border: "1px solid rgba(188, 23, 29, 1)",
              color: "rgba(188, 23, 29, 1)",
              fontFamily: "coko regular",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "24px",
              textAlign: "center",
              marginTop: "20px",
              textTransform: "none",
              "@media (max-width: 600px)": {
                width: "140px",
                height: "40px",
              },
            }}
            onClick={scrollToExplore}
          >
            Share your knowledge
          </Button>
        </Link>
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "start", md: "start" },
          flexDirection: "row",
        }}
      >
        <Typography
          style={{ ...mediaText, textDecoration: "underline" }}
          onClick={scrollToGuideline}
        >
          How does it work?
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "end" },
          alignItems: "center",
          flexDirection: "row",
          zIndex: 1000,
          paddingRight: isMobile ? "30px" : "0px"
        }}
      >
        <img
          src={scroll}
          alt="scroll"
          style={{
            cursor: "pointer",
            width: "70px",
            height: "70px",
          }}
          onClick={scrollToGuideline}
        />
      </Grid>
    </Grid>
  );
}

export default Explore;
