import {
  Card,
  Grid,
  Typography,
  CardContent,
  Button,
} from "@mui/material";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  renewPassword,
} from "../../redux/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import Success from "../../components/Success";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const inputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "38px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
  position: "relative",
};

const labelStyle = {
  marginBottom: "5px",
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "31.21px",
};

const ResetPassword = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [expiredLink, setExpiredLink] = useState(false);

  //! INSTANCES
  let dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("token");

  //! SELECTORS
  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      token: paramValue,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      if (values.password !== values.confirmPassword) {
        return toast.error("Please match password and confirm password");
      }

      let resp = await dispatch(renewPassword(values));
      if (resp.status === 402) {
        setExpiredLink(true);
        return toast.error(resp.data.message);
      }
      if(resp.status === 400){
        toast.error(resp.data.message)
      }
      if (resp.status === 200) {
        setShowSuccess(true);
        return toast.success(resp.data.message);
      }
    },
  });



  return (
    <>
      {showSuccess ? (
       <Grid container style={{ width: "100%" }}>

       <Success
       title="Password Changed!"
       fullWidth
       subTitle="Now you can login with your new password"
       logo={<CheckCircleIcon style={{
        color: '#19ce47',

       }}/>} // Correct placement for the icon
       login={
         <Grid
           item
           container
           xs={isMobile ? 12 : 10}
           justifyContent="center"
           style={{ marginTop: ".5rem" }}
         >
           <span style={{ color: "#829096" }}>
             <Link to="../login" style={{ color: "#FD71AF" }}>
               <Button
                 style={{
                   backgroundColor: "rgba(188, 23, 29, 1)",
                   color: "#fff",
                   borderRadius: "25px",
                   borderColor: "rgba(188, 23, 29, 1)",
                   boxShadow: "8px 12px 16px -2px #6271EB4D",
                   textTransform: "none",
                 }}
               >
                 Go to Login
               </Button>
             </Link>
           </span>
         </Grid>
       }
     />
     </Grid>
     
      ) : expiredLink ? (
        <Success
          title="Sorry, this link is expired!"
          subTitle="You can now login with your new password"
          logo={<WarningAmberOutlinedIcon style={{ color: "f4b700" }} />}
          login={
            <Grid
              item
              container
              xs={isMobile ? 12 : 10}
              justifyContent="center"
              style={{ marginTop: "2rem" }}
            >
              <span style={{ color: "#829096" }}>
                <Link to="../login" style={{ color: "#FD71AF" }}>
                  <Button
                    style={{
                      backgroundColor: "rgba(188, 23, 29, 1)",
                      color: "#fff",
                      borderRadius: "10px",
                      borderColor: "#6271EB",
                      boxShadow: "8px 12px 16px -2px #6271EB4D",
                      textTransform: "none",

                    }}
                  >
                    Go to Login
                  </Button>
                </Link>
              </span>
            </Grid>
          }
        />
      ) : (
          <Grid
            container
            style={{
              padding: "0px 1rem",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "calc( 70vh - 50px )",
            }}
            justifyContent="center"
          >
            <Grid
              container
              alignItem="center"
              justifyContent="center"
              xs={isMobile ? 12 : 4}
              style={{
                marginTop: "5rem",
              }}
            >
              <Card
                sx={{
                  boxShadow: "10px 10px 30px 0px #6271EB33",
                  borderRadius: "24px",
                }}
              >
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={10}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#213641",
                          fontSize: isMobile ? "22" : "30px",
                          fontFamily: "Coka",
                        }}
                      >
                        Reset Your Password
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ mb: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "#829096", fontFamily: "Aileron" }}
                      >
                        Enter your new and confirm password
                      </Typography>
                    </Grid>
                  </Grid>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={10}>
                        <label htmlFor="password" style={labelStyle}>
                          Password
                        </label>
                        <Input.Password
                          id="password"
                          style={{ ...inputStyle, width: "100%" }}
                          placeholder="Password"
                          {...formik.getFieldProps("password")}
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                        {formik.touched.password && formik.errors.password && (
                          <Typography color="error" fontSize="12px">
                            {formik.errors.password}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={10}>
                        <label htmlFor="confirmPassword" style={labelStyle}>
                          Confirm Password
                        </label>
                        <Input.Password
                          id="confirmPassword"
                          style={{ ...inputStyle, width: "100%" }}
                          placeholder="Confirm Password"
                          {...formik.getFieldProps("confirmPassword")}
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword && (
                            <Typography color="error" fontSize="12px">
                              {formik.errors.confirmPassword}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item xs={10}>
                        <LoadingButton
                          fullWidth
                          sx={{
                            backgroundColor: "rgba(188, 23, 29, 1)",
                            color: "#fff",
                            borderRadius: "25px",
                            mt: 2,
                            textTransform: "none",
                          }}
                          type="submit"
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
      )}
    </>
  );
};

export default ResetPassword;
