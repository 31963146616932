import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../services/config";

const initialState = {
  loading: false,
  error: null,
  userData: null,
  status: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loggingWithEmail(state, action) {
      state.loading = true;
      state.error = null;
    },
    loggingWithEmailSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.userData = action.payload;
    },
    loggingWithEmailFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.userData = null;
    },
    loggingWithCredentials(state, action) {
      state.loading = true;
      state.error = null;
    },
    loggingWithCredentialsSuccess(state, action) {
      state.loading = false;
      state.error = null;
      // state.userData = action.payload;
    },
    loggingWithCredentialsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      // state.userData = null;
    },
    switchingTenant(state, action) {
      state.loading = true;
      state.error = null;
    },
    switchingTenantSuccess(state, action) {
      state.loading = false;
      state.error = null;
      // state.userData = action.payload;
    },
    switchingTenantFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      // state.userData = null;
    },
    signUp(state, action) {
      state.loading = true;
      state.error = null;
    },
    signUpSuccess(state, action) {
      state.loading = false;
      state.error = null;
      // state.userData = action.payload;
    },
    signUpFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      // state.userData = null;
    },
    schoolSigningUp(state, action) {
      state.loading = true;
      state.error = null;
    },
    schoolSigningUpSuccess(state, action) {
      state.loading = false;
      state.error = null;
      // state.userData = action.payload;
    },
    schoolSigningUpFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      // state.userData = null;
    },
    contactUs(state, action) {
      state.loading = true;
      state.error = null;
    },
    contactUsSuccess(state, action) {
      state.loading = false;
      state.error = null;
      // state.userData = action.payload;
    },
    contactUsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      // state.userData = null;
    },
    forgettingPassword(state, action) {
      state.loading = true;
      state.error = null;
    },
    forgettingPasswordSuccess(state, action) {
      state.loading = false;
      state.error = null;
      // state.userData = action.payload;
    },
    forgettingPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      // state.userData = null;
    },
    verifyEmailLink(state, action) {
      state.loading = true;
      state.error = null;
    },
    verifyEmailLinkSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.status = action.payload;
    },
    verifyEmailLinkFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      // state.userData = null;
    },
    resendEmailLink(state, action) {
      state.loading = true;
      state.error = null;
    },
    resendEmailLinkSuccess(state, action) {
      state.loading = false;
      state.error = null;
      // state.userData = action.payload;
    },
    resendEmailLinkFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      // state.userData = null;
    },
    socialLogin(state, action) {
      state.loading = true;
      state.error = null;
    },
    socialLoginSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    socialLoginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
const {
  loggingWithEmail,
  loggingWithEmailSuccess,
  loggingWithEmailFailure,
  loggingWithCredentials,
  loggingWithCredentialsSuccess,
  loggingWithCredentialsFailure,
  switchingTenant,
  switchingTenantSuccess,
  switchingTenantFailure,
  signUp,
  signUpSuccess,
  signUpFailure,
  contactUs,
  contactUsSuccess,
  contactUsFailure,
  forgettingPassword,
  forgettingPasswordSuccess,
  forgettingPasswordFailure,
  verifyEmailLink,
  verifyEmailLinkSuccess,
  verifyEmailLinkFailure,
  resendEmailLink,
  resendEmailLinkSuccess,
  resendEmailLinkFailure,
  schoolSigningUp,
  schoolSigningUpSuccess,
  schoolSigningUpFailure,
  socialLogin,
  socialLoginSuccess,
  socialLoginFailure

} = slice.actions;
export const authReducer = (state) => state.auth;

export const loginWithEmail = (email) => async (dispatch) => {
  dispatch(loggingWithEmail());
  try {
    const response = await axios.post(`${config.baseUrl}/user/login`, email);
    if (response.status === 200) {
      dispatch(loggingWithEmailSuccess(response.data));
    } else {
      dispatch(loggingWithEmailFailure(response.data));
    }

    return response;
  } catch (e) {
    dispatch(loggingWithEmailFailure("Something went wrong while login!"));
  }
};

export const loginWithCredentials = (data) => async (dispatch) => {
  dispatch(loggingWithCredentials());
  try {
    const response = await axios.post(`${config.baseUrl}/user/signin`, data);
    if (response.status === 200) {
      dispatch(loggingWithCredentialsSuccess(response.data));
    } else {
      dispatch(loggingWithCredentialsFailure(response.data));
    }

    return response;
  } catch (e) {
    dispatch(
      loggingWithCredentialsFailure("Something went wrong while login!")
    );
  }
};

export const switchTenant = (data) => async (dispatch) => {
  dispatch(switchingTenant());
  try {
    const response = await axios.post(
      `${config.baseUrl}/user/switchtenant`,
      data
    );
    if (response.status === 200) {
      dispatch(switchingTenantSuccess(response.data));
    } else {
      dispatch(switchingTenantFailure(response.data));
    }

    return response;
  } catch (e) {
    dispatch(switchingTenantFailure("Something went wrong while Switching!"));
  }
};

export const signup = (data) => async (dispatch) => {
  dispatch(signUp());
  try {
    const response = await axios.post(`${config.baseUrl}/edf/add`, data);
    if (response.status === 200) {
      dispatch(signUpSuccess(response.data));
    } else {
      dispatch(signUpFailure(response.data));
    }

    return response;
  } catch (e) {
    dispatch(contactUsFailure("Something went wrong while Sign up!"));
  }
};
export const schoolSignup = (data) => async (dispatch) => {
  dispatch(schoolSigningUp());
  try {
    const response = await axios.post(`${config.baseUrl}/school/register`, data);
    if (response.status === 200) {
      dispatch(schoolSigningUpSuccess(response.data));
    } else {
      dispatch(schoolSigningUpFailure(response.data));
    }
 
    return response;
  } catch (e) {
    dispatch(contactUsFailure("Something went wrong while Sign up!"));
  }
};

export const sendContactForm = (data) => async (dispatch) => {
  dispatch(contactUs());
  try {
    const response = await axios.post(`${config.baseUrl}/misc/contact`, data);
    if (response.status === 200) {
      dispatch(contactUsSuccess(response.data));
    } else {
      dispatch(contactUsFailure(response.data));
    }

    return response;
  } catch (e) {
    dispatch(contactUsFailure("Something went wrong while contact us!"));
  }
};


export const resetPassword = (data) => async (dispatch) => {
  dispatch(forgettingPassword());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/user/resetrequest`,
      data
    );
    if (response.status === 200) {
      dispatch(forgettingPasswordSuccess(response.data));
    } else {
      dispatch(forgettingPasswordFailure(response.data));
    }

    return response;
  } catch (e) {
    dispatch(
      forgettingPasswordFailure("Something went wrong while contact us!")
    );
  }
};

export const fetchOtherDetails = async (token) => {
  const response = await axios.get(
    `${config.baseUrl}/user/checkrenew/${token}`
  );

  return response;
};

export const renewPassword = (data) => async (dispatch) => {
  dispatch(forgettingPassword());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/user/resetuserpass`,
      data
    );
    if (response.status === 200) {
      dispatch(forgettingPasswordSuccess(response.data));
    } else {
      dispatch(forgettingPasswordFailure(response.data));
    }

    return response;
  } catch (e) {
    dispatch(
      forgettingPasswordFailure("Something went wrong while contact us!")
    );
  }
};

export const verifyEmail = (paramValue) => async (dispatch) => {
  dispatch(verifyEmailLink());
  try {
    const response = await axios.get(
      `${config.baseUrl}/user/verifyuser/${paramValue}`
    );

    if (response.status === 200) {
      dispatch(verifyEmailLinkSuccess(response.status)); // Dispatch response.data for success
    } else {
      dispatch(verifyEmailLinkFailure(response.status)); // Dispatch response.data for failure
    }

    return response;
  } catch (e) {
    dispatch(
      verifyEmailLinkFailure("Something went wrong while Email verification!")
    );
  }
};

export const resendEmail = (data) => async (dispatch) => {
  dispatch(resendEmailLink());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/user/resendverify`,
      data
    );

    if (response.status === 200) {
      dispatch(resendEmailLinkSuccess(response.data));
    } else {
      dispatch(resendEmailLinkFailure(response.data));
    }

    return response;
  } catch (e) {
    dispatch(
      resendEmailLinkFailure("Something went wrong while Email verification!")
    );
  }
};


export const loginWithSocial = (data) => async (dispatch) => {
  dispatch(socialLogin());
  try {
    const response = await axios.post(`${config.baseUrl}/user/smulogin`, data);
    if (response.status === 200) {
      dispatch(socialLoginSuccess(response.data));
    } else {
      dispatch(socialLoginFailure(response.data));
    }

    return response;
  } catch (e) {
    dispatch(socialLoginFailure("Something went wrong while login!"));
  }
};

export default slice.reducer;
