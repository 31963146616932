import { Divider, Grid2, ThemeProvider } from '@mui/material'
import React from 'react'
import Header from '../../views/auth/Header'
import Footer from '../../views/auth/Footer'
import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { Outlet } from 'react-router-dom'

function AppLayout() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ 
        position: 'sticky', 
        top: 0, 
        zIndex: 1000, // Ensure it stays above other content
        width: '100%',
        background: '#fff' // Optional, but helps to maintain a consistent background
      }} />
      <Content
        style={{
          padding: "10px",
          background: "#fff",
        }}
      >
        <Outlet />
      </Content>
      <Divider />
      <Footer />
    </Layout>
  )
}

export default AppLayout;
