import React from 'react';
import { Typography, Button, Dialog, IconButton, Grid2 } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { Input} from 'antd';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { createFolder, getFolder, getPicnotesWithFolder } from '../redux/folders';
import { LoadingButton } from '@mui/lab';


const inputStyle = {
  borderRadius: '25px',
  paddingLeft: '10px',
  height: '38px',
  border: "1px solid rgba(112, 112, 112, 0.5)",
  position: 'relative',
};


const inputContainerStyle = {
  marginBottom: '15px',
  padding: '0px 10px '
};


const headerStyle = {
  fontFamily: "Cako regular",
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "31.21px",
  textAlign: "center",
  color: "rgba(63, 63, 63, 1)"
};


const labelStyle = {
  marginBottom: '5px',
  color: "rgba(112, 112, 112, 1)", 
  fontFamily: 'Aileron',
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "31.21px",
};


function CreateNewFolder({onClose}) {

  

    // INSTANCE
    let dispatch = useDispatch();

  const { createFolderloading } = useSelector((state) => state.folders);  


  const validationSchema =  yup.object({
    title: yup
      .string()
      .required("title is required")
      .min(3, "title must be at least 3 characters"),
    })

  const formik = useFormik({
    initialValues: {
      title: "",
     
    },
    validationSchema: validationSchema,  
    onSubmit: async (values) => {
      let resp = await dispatch(createFolder(values));
      if(resp.status === 200){
        toast.success(resp?.data?.message)
        dispatch(getFolder())
        dispatch(getPicnotesWithFolder())
        onClose();
      }else{
        toast.error(resp.data.message)
      }
    },
  });

  return (
    <Dialog
    open={true}
    maxWidth="xs"
    sx={{ zIndex: 1502, "& .MuiDialog-paper": { borderRadius: "12px", padding: '20px' } }} 
  >
    
        <form onSubmit={formik.handleSubmit}>
          <Grid2 container alignItems="center">
            <Grid2 item size={12} mb={'10px'}>
              <IconButton
                aria-label="close"
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={onClose}
              >
                <CloseIcon style={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Grid2>

            <Grid2 item size={12} textAlign="center">
              <Typography variant="h6" component="div" style={{ ...headerStyle, marginBottom: '10px' }}>
              Create New Folder
              </Typography>
            </Grid2>


          <Grid2 item size={12} style={inputContainerStyle}>
            <label htmlFor="title" style={labelStyle}>Folder name</label>
            <Input
              id="title"
              style={{ ...inputStyle, width: '100%' }}
              placeholder="eg. Science"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
            />
            
          </Grid2>
                   
          

            <Grid2 item size={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0px' }}>
              <LoadingButton
              loading={createFolderloading}
              loadingPosition='start' type="submit" variant="contained" style={{ borderRadius: '20px', color: "white", width: '50%', backgroundColor: "rgba(188, 23, 29, 1)",  textTransform: 'none' }}
            >
              Create Folder
              </LoadingButton>
            </Grid2>
          </Grid2>
        </form>
     
  </Dialog>
  )
}

export default CreateNewFolder