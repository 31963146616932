import { Toaster } from "react-hot-toast";
import "./App.css";
import { Navigate, useRoutes } from "react-router-dom";
import { allRoutes, authRoutes } from "./routes/routes";
import UserContext from "./context/userContext";
import { useState, useEffect } from "react";
import MainLayout from "./layout/mainLayout";
import Pages from "./components/main-page/Pages";
import LifeLongFollowers from "./components/LifeLongFollowers";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function App() {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  // const [firstLoginValue, setFirstLoginValue] = useState(() => {
  //   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   return userInfo?.isFTL;
  // });

  const [firstLoginValue, setFirstLoginValue] = useState(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return userInfo?.isFTL ?? false; // Ensures `false` if `isFTL` is undefined
  });
  

  const [showScroll, setShowScroll] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to update first login status
  const updateFirstLoginStatus = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo?.isFTL) {
      userInfo.isFTL = false;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      setFirstLoginValue(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScroll(window.scrollY > 300); // Show arrow after scrolling 300px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const accessPath = [];

  for (let existingRoute of allRoutes) {
    if (existingRoute.route.path === "pages") {
      accessPath.push({
        ...existingRoute.route,
        element: firstLoginValue ? (
          <LifeLongFollowers onClose={updateFirstLoginStatus} />
        ) : (
          <Pages />
        ),
      });
    } else {
      accessPath.push(existingRoute.route);
    }
  }

  const accessRoutes = [
    {
      path: "/app",
      element: <MainLayout />,
      children: [...accessPath],
    },
    {
      path: "/*",
      element: <Navigate to="/app/pages" />,
    },
  ];

  const access = useRoutes(accessRoutes);
  const authRoutesAccess = useRoutes(authRoutes);

  return (
    <div>
      <UserContext.Provider value={[userData, setUserData]}>
        {!userData ? authRoutesAccess : access}
      </UserContext.Provider>
      <Toaster position="top-right" reverseOrder={false} />

      {/* Scroll to Top Button */}
      {showScroll && !isMobile && (
        <button onClick={handleScrollToTop} className="scroll-to-top">
          <ArrowCircleUpIcon />
        </button>
      )}
    </div>
  );
}

export default App;
