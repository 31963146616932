import { Button, Dialog, Grid2, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import folderImage from './../assets/folder 1.png';
import addfolder from './../assets/folder-add.png';
import shadow from './../assets/folderShadow.png';
import CreateNewFolder from './CreateNewFolder';

const headerStyle = {
  fontFamily: "Cako regular",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "31.21px",
  textAlign: "center",
  color: "rgba(63, 63, 63, 1)"
};

const styleText = {
  fontFamily: "Aileron",
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "center",
  color: "rgba(112, 112, 112, 1)"
};

const centerlizing = {
  display: 'flex', alignItems: 'center', justifyContent: 'center'
};

function AddPicnoteFolder({ onClose }) {
  const [createNewFolder, setCreateNewFolder] = useState(false);

  const handleCreateNewFolder = () => {
    setCreateNewFolder(true); 
  };

  const onCloseCreateNewFolder = () => {
    setCreateNewFolder(false);  
  };

  return (
    <>
      {createNewFolder ? (
        <CreateNewFolder onClose={onCloseCreateNewFolder} />
      ) : (
        <Dialog
          open={true}
          fullWidth
          maxWidth="md"
          sx={{
            zIndex: 1500,
            "& .MuiDialog-paper": {
              borderRadius: "10px",
              padding: '20px',
              minWidth: '400px',
              maxWidth: '29%',
            }
          }}
        >
          <Grid2 container alignItems="center">
            <Grid2 item size={12} mb={'10px'}>
              <IconButton
                aria-label="close"
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={onClose}  
              >
                <CloseIcon style={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Grid2>

            <Grid2 item size={12} textAlign="center">
              <Typography variant="h5" component="div" style={{ ...headerStyle, marginBottom: '20px' }}>
                Add Picnotes to Folder
              </Typography>
            </Grid2>
          </Grid2>

          <Grid2 item size={12} style={{ ...centerlizing, marginBottom: '-10px' }}>
            <img src={folderImage} alt='Logo' />
          </Grid2>
          <Grid2 item size={12} style={{ ...centerlizing, marginBottom: '20px' }}>
            <img src={shadow} alt='Logo' />
          </Grid2>

          <Grid2 item size={12} style={{ ...centerlizing, marginBottom: '10px' }}>
            <Typography style={styleText}>You have not created any folder. </Typography>
          </Grid2>

          <Grid2 item size={12} style={{ ...centerlizing, margin: '20px 0px' }}>
            <Button
              type="submit"
              variant="contained"
              style={{
                fontSize: '14px',
                lineHeight: "21.6px",
                borderRadius: '20px',
                color: "white",
                width: '50%',
                fontWeight: 400,
                backgroundColor: "rgba(188, 23, 29, 1)",
                textTransform: 'none'
              }}
              onClick={handleCreateNewFolder}  // Switch to CreateNewFolder
            >
              <img src={addfolder} alt='logo' />&nbsp; Create Folder
            </Button>
          </Grid2>
        </Dialog>
      )}
    </>
  );
}

export default AddPicnoteFolder;
