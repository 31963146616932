import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const instructionData = {
  picnotes: {
    title: "How to Create Picnotes",
    steps: [
      "Click the Add New Picnote button to get started.",
      "Choose a topic you're passionate about.",
      "Upload an image that relates to your topic.",
      "Write your notes to explain the image.",
      "Click Submit to share your Picnote with others!",
    ],
  },
  folders: {
    title: "How to Create Folders",
    steps: [
      "Click on Create New Folder.",
      "Give your folder a name.",
      "Click Save.",
    ],
  },
  flashcards: {
    title: "How to Create Flashcards",
    steps: [
      "Click on Create Flashcards to start a new set.",
      "Give your new flashcard set a title so you can find it easily.",
      "Click + Add New Flashcard to create your first card.",
      "Type your question or note on the front of the card.",
      "Type the answer on the back of the card.",
      "Click Save to keep your flashcard.",
    ],
  },
  myedTracker: {
    title: "How to Use EdTracker",
    steps: [
      "Choose a category: Want to learn, Currently Learning, or Learned.",
      "Click '+' to add a topic.",
      "Add details like description, link, and hashtags (optional).",
      "Click Submit.",
    ],
  },
  myedTools: {
    title: "How to Use EdTools",
    steps: [
      "Click the '+' to add a new resource.",
      "Give your resource a title.",
      "Add a description, link, and any relevant hashtags (optional).",
      "Click Submit to save.",
    ],
  },
};

const InstructionDialog = ({ open, onClose, TabName }) => {
  const instruction = instructionData[TabName];

  if (!instruction) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
         <span style={{
          fontSize: '25px',
          fontFamily: "cako regular"
         }}>
          {instruction.title}
          </span> 
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {instruction.steps.map((step, index) => (
          <Typography key={index} gutterBottom style={{
            color: "#7c7c7c"
          }}>
            {index + 1}. {step}
          </Typography>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default InstructionDialog;
