// import axios from "axios";

// const config = {};
// const url = `${process.env.REACT_APP_BASE_URL}`;

// config.baseUrl = url;


// axios.interceptors.request.use(
//   async function (config) {
//     // Retrieve tokens from localStorage
//     const temptoken = await localStorage.getItem("Temptoken");
//     const token = await localStorage.getItem("token");

//     // Define the endpoints that require the "token"
//     const useTokenFor = ["/picnote/createcomment", "/tracker/createcomment", "/picnote/create", "/picnote/updateflags", "/user/follow", "/user/unfollow",  "user/folderlist",
//       "picnote/picnotetofolder", "user/addfolder", "edf/fetchfollowings"
//     ];
//     const isSpecificApi = useTokenFor.some((path) => config.url.includes(path));

//     // Use "token" for specified APIs, otherwise use "Temptoken"
//     if (isSpecificApi && token) {
//       config.headers["auth-token"] = `${token}`;
//     } else if (temptoken) {
//       config.headers["auth-token"] = `${temptoken}`;
//     }

//     return config;
//   },
//   function (error) {
//     // console.log("error", error);
//     return Promise.reject(error);
//   }
// );

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // return;
//     if (error?.response?.status === 401) {
//       localStorage.clear();
//       window.location.reload();
//     }
//     return error.response;
//   }
// );

// export default config;


import axios from "axios";

const config = {};
const url = `${process.env.REACT_APP_BASE_URL}`;

config.baseUrl = url;

axios.interceptors.request.use(
  async function (config) {
    // Retrieve tokens and user details from localStorage
    const temptoken = await localStorage.getItem("Temptoken");
    const token = await localStorage.getItem("token");
    const userDetails = JSON.parse(localStorage.getItem("userInfo") || "{}");
    const tempUser = JSON.parse(localStorage.getItem("TempuserInfo") || "{}");

    // Define the endpoints that require the "token"
    let useTokenFor = [
      "/picnote/createcomment",
      "/tracker/createcomment",
      "/picnote/create",
      "/picnote/updateflags",
      "/user/follow",
      "/user/unfollow",
      "user/folderlist",
      "picnote/picnotetofolder",
      "user/addfolder",
      "picnote/singlenote"
    ];

    // Check if userDetails.userId and tempUser.userId are equal
    if (userDetails?.userId && tempUser?.userId && userDetails.userId === tempUser.userId) {
      useTokenFor.push("edf/fetchfollowings");
    }

    const isSpecificApi = useTokenFor.some((path) => config.url.includes(path));

    // Use "token" for specified APIs, otherwise use "Temptoken"
    if (isSpecificApi && token) {
      config.headers["auth-token"] = `${token}`;
    } else if (temptoken) {
      config.headers["auth-token"] = `${temptoken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return error.response;
  }
);

export default config;
