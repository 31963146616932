import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  Grid2,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import "./style.css";
import IWantToLearn from "./IWantToLearn";
import AddIcon from "@mui/icons-material/Add";
import favourite from "./../assets/favourite.png";
import redfavourite from "./../assets/justFav.png";
import comment from "./../assets/message-01.png";
import backGray from "./../assets/backGray.png";
import backRed from "./../assets/backRed.png";
import forwardGary from "./../assets/forwardGray.png";
import forwardRed from "./../assets/forward.png";
import redCircle from "./../assets/redCircle.png";
import percent from "./../assets/25percent.png";
import checked from "./../assets/checkedGreen.png";
import { Divider } from "antd";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  deleteTracker,
  getAllTrackers,
  likeTracker,
  trackerReducer,
  updateTrackerStage,
} from "../redux/tracker";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Comments from "./Comments";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import NoData from "./NoData";

function MyEdTracker() {
  const [clickedCard, setClickedCard] = useState(false);
  const [clickedCard1, setClickedCard1] = useState(false);
  const [comments, setComments] = useState(false);
  const [clickedCardTitle, setClickedCardTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedCardDetail, setClickedCardDetail] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const [orgInfo, setOrgInfo] = useState(() =>
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [tempInfo, setTempInfo] = useState(() =>
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getAllTrackers());
  }, [dispatch]);

  const { allTrackers } = useSelector(trackerReducer);

  // Update cardData to match the status names in the data array
  const cardData = [
    { name: "Want to learn", status: "want" },
    { name: "Currently Learning", status: "learning" },
    { name: "Completed", status: "completed" },
  ];

  const handleClick = (cardTitle) => {
    setClickedCard(true);
    setClickedCardTitle(cardTitle);
  };

  const handleEdit = (cardDetail) => {
    setClickedCard1(true);
    setClickedCardDetail(cardDetail);
  };

  const handleDelete = async (trackerId) => {
    let resp = await dispatch(deleteTracker(trackerId));
    if (resp.status === 200) {
      toast.success(resp.data.message);
      dispatch(getAllTrackers());
    } else {
      toast.error(resp.data.message);
    }
    // console.log(trackerId);
  };
  const HandleClose = () => {
    setClickedCard(false);
    setClickedCard1(false);
  };
  const handleComment = (cardTitle) => {
    setComments(true);
    setClickedCardTitle(cardTitle);
  };
  const HandleCloseComment = () => {
    setComments(false);
  };
  const handleLike = async (trackerId, isLiked) => {
    const newLikedState = !isLiked;

    let resp = await dispatch(likeTracker({ trackerId, liked: newLikedState }));
    // let resp = await dispatch(likeTracker(likedTracker, liked ));
    if (resp?.status === 200) {
      toast.success(resp?.data?.message);
      dispatch(getAllTrackers());
    }
  };

  const handleUpdateStage = async ({ trackerId, trackerStage }) => {
    let resp = await dispatch(updateTrackerStage({ trackerId, trackerStage }));
    if (resp?.status === 200) {
      toast.success(resp.data.message);
      dispatch(getAllTrackers());
    } else {
      toast.error(resp.data.message);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null); // Reset the selected item when menu closes
  };

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget); // Track the menu's anchor
    setSelectedItem(item); // Track the clicked item
  };

  return (
    <>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="flex-start"
        gap={"10px"}
      >
        {clickedCard && (
          <IWantToLearn title={clickedCardTitle} onClose={HandleClose} />
        )}
        {clickedCard1 && (
          <IWantToLearn cardDetail={clickedCardDetail} onClose={HandleClose} />
        )}
        {comments && (
          <Comments title={clickedCardTitle} onClose={HandleCloseComment} />
        )}

        {/* Render the cardData cards at the top */}
        {cardData.map((card, index) => (
          <Grid2
            item
            size={isMobile ? 12 : 3.8}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            key={index}
            backgroundColor="#F3F3F3"
            border="8px solid #F3F3F3"
            style={{
              justifyContent: "center",
              borderRadius: "8px",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: "67px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                }}
                elevation={0}
                onClick={
                  orgInfo?.userId === tempInfo?.userId
                    ? () => handleClick(card)
                    : undefined
                }
              >
                <AddIcon style={{ color: "#BC171D" }} />
                <Typography className="myEdTools">{card.name}</Typography>
              </Card>
            </span>

            <Grid2
              sx={{
                overflow: "auto", // Show scrollbar only when needed
                maxHeight: "620px", // Set a max height for the grid
                "&::-webkit-scrollbar": {
                  display: "none", // Hide the scrollbar initially
                },
                "&:hover::-webkit-scrollbar": {
                  display: "block", // Show the scrollbar when the grid is hovered
                },
              }}
            >
              {allTrackers?.trackerList
                ?.filter((tracker) => tracker.trackerStage === card.status)
                .map((item, idx) => (
                  <Grid2
                    item
                    xs={isMobile ? 12 : 3.8}
                    sm={6}
                    md={5}
                    lg={3}
                    xl={2}
                    key={idx}
                    paddingTop={"8px"}
                    borderRadius={"12px"}
                    backgroundColor="#F3F3F3"
                  >
                    <Card
                      sx={{
                        // width: {
                        //   xs: "100%",
                        //   sm: "100%",
                        //   md: "100%",
                        //   lg: "320px",
                        //   xl: "320px",
                        //   xxl: "400px",
                        // },
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                      }}
                      elevation={0}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 15px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            style={{
                              fontFamily: "Aileron",
                              fontSize: "15px",
                              fontWeight: 400,
                              color: "#3F3F3F",
                            }}
                          >
                            {item?.title}
                            {/* {item.title.length > 18
                              ? `${item.title.slice(0, 18)}...`
                              : item.title} */}
                          </Typography>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* Replace FavoriteIcon with image */}
                          <img
                            src={item?.liked ? redfavourite : favourite}
                            alt="likes"
                            style={{
                              width: "17px",
                              height: "17px",
                              cursor: orgInfo ? "pointer" : "default",
                            }}
                            onClick={ orgInfo ? () =>
                              handleLike(item.trackerId, item?.liked)
                            : null}
                          />
                          &nbsp;
                          <Typography variant="body2" style={{ marginLeft: 1 }}>
                            {item.likes?.length}
                          </Typography>
                          {/* Replace CommentIcon with image */}
                          <img
                            src={comment}
                            alt="comments"
                            style={{
                              width: "17px",
                              height: "17px",
                              marginLeft: "12px",
                              cursor: orgInfo ? "pointer" : "default",

                            }}
                            onClick={ orgInfo ? () => handleComment(item) : null}
                          />
                          &nbsp;
                          <Typography variant="body2">
                            {item.totalRemarks}
                          </Typography>
                          {orgInfo?.userId === tempInfo?.userId ? (
                            <MoreVertIcon
                              style={{
                                fontSize: "18px",
                                color: "#6c6c6c",
                                marginRight: "-10px",
                                cursor: "pointer",
                              }}
                              onClick={(event) => handleMenuOpen(event, item)} // Pass the item when opening the menu
                            />
                          ) : null}
                          {/* Menu for Edit and Delete */}
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() => {
                                handleEdit(selectedItem); // Use the tracked item
                                handleMenuClose();
                              }}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleDelete(selectedItem.trackerId); // Use the tracked item
                                handleMenuClose();
                              }}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>

                      {/* Card image */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          // marginTop: "8px",
                          padding: "0px 18px",
                        }}
                      >
                        {item?.trackerLink ? (
                          <img
                            src={`https://www.google.com/s2/favicons?sz=64&domain=${(() => {
                              try {
                                return new URL(item.trackerLink).hostname;
                              } catch (error) {
                                return "example.com"; // Fallback domain
                              }
                            })()}`}
                            alt="Favicon"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              height: "40px",
                              width: "40px",
                              borderRadius: "5px",
                              border: "1px solid #B8B8B84D",
                              display: "block",
                            }}
                          />
                        ) : (
                          <BrokenImageIcon
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "5px",
                              border: "1px solid #B8B8B84D",
                              display: "block",
                            }}
                          />
                        )}

                        <Typography
                          variant="body2"
                          sx={{
                            marginLeft: 2,
                            width: "50%",
                            textAlign: "left",
                            fontFamily: "Aileron",
                            fontSize: "12px",
                          }}
                        >
                          <a
                            href={item.trackerLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "black",
                            }}
                          >
                            {item.trackerLink.length > 24
                              ? `${item.trackerLink.slice(0, 24)}...`
                              : item.trackerLink}
                          </a>
                        </Typography>
                      </Box>

                      <Divider
                        style={{
                          width: "100%",
                          marginBottom: 3,
                          padding: 0,
                        }}
                      />

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "18px",
                        }}
                      >
                        {/* Left Circle with Background Color */}
                        <div
                          style={{
                            backgroundColor: "#f9f9f9",
                            width: "30px",
                            height: "30px",
                            borderRadius: "4px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.trackerStage === "want" ? (
                            <img
                              src={backGray}
                              alt={item.title}
                              style={{
                                width: "12px",
                                height: "12px",
                                borderRadius: "4px",
                                marginTop: "8px",
                              }}
                            />
                          ) : (
                            // <img
                            //   src={backRed}
                            //   alt={item.title}
                            //   style={{
                            //     width: "12px",
                            //     height: "12px",
                            //     borderRadius: "4px",
                            //     marginTop: "8px",
                            //     cursor: "pointer",
                            //   }}
                            //   onClick={() =>
                            //     handleUpdateStage({
                            //       trackerId: item.trackerId,
                            //       trackerStage:
                            //         item?.trackerStage === "learning"
                            //           ? "want"
                            //           : item?.trackerStage === "completed"
                            //           ? "learning"
                            //           : item?.trackerStage,
                            //     })
                            //   }
                            // />
                            <img
                              src={backRed}
                              alt={item.title}
                              style={{
                                width: "12px",
                                height: "12px",
                                borderRadius: "4px",
                                marginTop: "8px",
                                cursor: orgInfo?.userId === tempInfo?.userId  ? "pointer" : "default", 
                              }}
                              onClick={
                                orgInfo?.userId === tempInfo?.userId
                                  ? () =>
                                      handleUpdateStage({
                                        trackerId: item.trackerId,
                                        trackerStage:
                                          item?.trackerStage === "learning"
                                            ? "want"
                                            : item?.trackerStage === "completed"
                                            ? "learning"
                                            : item?.trackerStage,
                                      })
                                  : null
                              }
                            />
                          )}
                        </div>

                        {/* Text and trackerStage Image with Background */}
                        <Typography
                          style={{
                            margin: "0 10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px 8px",
                            backgroundColor: "#f9f9f9",
                            borderRadius: "4px",
                            width: "200px",
                            fontFamily: "Aileron",
                            fontSize: "16px",
                            fontWeight: 300,
                            color:
                              item.trackerStage === "want"
                                ? "#BC171D"
                                : item.trackerStage === "learning"
                                ? "#FD9800"
                                : "#53C537",
                          }}
                        >
                          {/* trackerStage Image with Background */}
                          {item.trackerStage === "want" ? (
                            <img
                              src={redCircle}
                              alt=""
                              style={{
                                marginRight: "8px",
                                padding: "2px",
                                borderRadius: "50%",
                                width: "22px",
                                height: "22px",
                              }}
                            />
                          ) : item.trackerStage === "learning" ? (
                            <img
                              src={percent}
                              alt=""
                              style={{
                                marginRight: "8px",
                                padding: "2px",
                                borderRadius: "50%",
                                width: "25px",
                                height: "25px",
                              }}
                            />
                          ) : item.trackerStage === "completed" ? (
                            <img
                              src={checked}
                              alt=""
                              style={{
                                marginRight: "8px",
                                padding: "2px",
                                borderRadius: "50%",
                                width: "22px",
                                height: "22px",
                              }}
                            />
                          ) : null}

                          {/* trackerStage Text */}
                          {item.trackerStage === "want"
                            ? "I want to learn"
                            : item.trackerStage === "learning"
                            ? "I am learning"
                            : item.trackerStage === "completed"
                            ? "I learned"
                            : null}
                        </Typography>

                        {/* Right Circle with Background Color */}
                        <div
                          style={{
                            backgroundColor: "#f9f9f9",
                            width: "30px",
                            height: "30px",
                            borderRadius: "4px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.trackerStage === "completed" ? (
                            <img
                              src={forwardGary}
                              alt={item.title}
                              style={{
                                width: "12px",
                                height: "12px",
                                borderRadius: "4px",
                                marginTop: "8px",
                              }}
                            />
                          ) : (
                            <img
                              src={forwardRed}
                              alt={item.title}
                              style={{
                                width: "12px",
                                height: "12px",
                                borderRadius: "4px",
                                marginTop: "8px",
                                cursor:  orgInfo?.userId === tempInfo?.userId ? "pointer" : "default",
                              }}
                              onClick={
                                orgInfo?.userId === tempInfo?.userId
                                ? () =>
                                handleUpdateStage({
                                  trackerId: item.trackerId,
                                  trackerStage:
                                    item?.trackerStage === "want"
                                      ? "learning"
                                      : item?.trackerStage === "learning"
                                      ? "completed"
                                      : item?.trackerStage,
                                })
                                : null 
                              }
                            />
                          )}
                        </div>
                      </div>
                    </Card>
                  </Grid2>
                ))}
            </Grid2>
          </Grid2>
        ))}
        {allTrackers?.trackerList?.length < 1 ? (
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            <NoData TabTitle={"myedTracker"} />
          </Grid>
        ) : null}
      </Grid2>
    </>
  );
}

export default MyEdTracker;
