import {
  Autocomplete,
  Button,
  Chip,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Input } from "antd";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import "../style.css";
import TextArea from "antd/es/input/TextArea";
import CloseIcon from "@mui/icons-material/Close";
import gallery from "../../assets/Gallery.png";
import dashed from "../../assets/Rectangle 2070.png";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { allMyPnotes, createPicnote, myFeed } from "../../redux/picnote";
import AddResource from "./AddResource";
import { uploadTheFile } from "../../redux/media";
import { LoadingButton } from "@mui/lab";
import { profileDetial } from "../../redux/user";
import arrow from "../../assets/arrow-down-01-round.png";
import { allTags, createTag } from "../../redux/tags";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function CreateNewPicnote({ onClose }) {
  const [selectedImage, setSelectedImage] = useState(null); // For storing image URL
  const [selectedFile, setSelectedFile] = useState(null); // For storing the raw image file
  const [imageError, setImageError] = useState("");
  const [addResource, setAddResource] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [picnoteResponseWhileAddingNote, setPicnoteResponseWhileAddingNote] =
    useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  useEffect(() => {
    dispatch(allTags());
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      // You could handle image preview, upload, etc., here
      // console.log("Dropped file:", file);
    }
  }, []);

  const { getRootProps, getInputProps,  } = useDropzone({ onDrop });

  //! INSTANCES
  let dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Fetch data from the Redux store
  const { loading } = useSelector((state) => state.picnote);
  const { allTagsList } = useSelector((state) => state.tags);
  const [filteredTags, setFilteredTags] = useState(allTagsList || []);

  const createdTag = allTagsList?.find((i) => i.title === newTag) || {
    title: newTag,
    id: null,
  };

  const uniqueTags = Array.from(new Set(filteredTags.map(tag => tag.title)))
  .map(title => {
    return filteredTags.find(tag => tag.title === title);
  });


  const handleInputChange = (event, value) => {
    // Filter tags that start with the entered text (case-insensitive)
    const filtered = allTagsList.filter((tag) =>
      tag.title.toLowerCase().startsWith(value.toLowerCase())
    );

    setFilteredTags(filtered);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Allowed MIME types
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (file) {
      // Check for file type (must be image)
      if (!allowedTypes.includes(file.type)) {
        setSelectedFile(null);
        setSelectedImage(null);
        setImageError(
          "Invalid file type. Please upload a JPG, JPEG, or PNG image."
        );
        return;
      }

      // Check for file size (max 2MB)
      if (file.size > 2 * 1024 * 1024) {
        setSelectedFile(null);
        setSelectedImage(null);
        setImageError("File size exceeds 2MB. Please upload a smaller image.");
        return;
      }

      // If all validations pass, store the raw file and preview URL
      setSelectedFile(file); // Store raw file for upload
      setSelectedImage(URL.createObjectURL(file)); // Store image preview URL
      setImageError(""); // Clear any existing errors
    } else {
      setSelectedFile(null);
      setSelectedImage(null);
      setImageError("Please upload an image file.");
    }
  };

  const handleImageRemove = () => {
    setSelectedFile(null);
    setSelectedImage(null);
    setImageError("");
  };

  const closeAddSource = () => {
    setAddResource(false);
    onClose();
  };

  const validationSchema = yup.object({
    title: yup
      .string()
      .required("Title is required")
      .min(3, "Title must be at least 3 characters"),
    // .matches(/^[A-Za-z][A-Za-z0-9\s()-,.]*$/, "Title must start with an alphabet and can contain numbers, hyphens, and parentheses"),
    description: yup
      .string()
      .required("Note is required")
      .min(3, "Note must be at least 3 characters"),
    trackerTags: yup
      .array()
      .min(1, "At least one tag is required")
      .of(yup.object().shape({ title: yup.string().required() })),
  });

  const handleCloseClick = () => {
    setShowConfirmationDialog(true);
  };

  // Function to confirm leaving
  const confirmLeave = () => {
    setShowConfirmationDialog(false);
    onClose(); // Call your existing onClose function to close the main dialog
  };

  // Function to cancel leaving
  const cancelLeave = () => {
    setShowConfirmationDialog(false);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      trackerTags: [] || [createdTag],
      notePicture: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const tags = values?.trackerTags
      ?.map((i) => (typeof i === "object" ? i._id || i.tagId : null)) // Ensure correct ID field is used
      .filter((tagId) => tagId !== null);
  
    // console.log("tag", tags);
      try {
        // Check if an image file is selected for upload
        if (selectedFile) {
          let formData = new FormData();
          formData.append("file", selectedFile);

          // Dispatch the file upload action
          let respFile = await dispatch(uploadTheFile(formData));

          // Check if the file upload was successful
          if (respFile.status === 200) {
            // Assign the uploaded file URL to values.notePicture (assuming server returns URL)
            values.notePicture = respFile.data.url;
          } else {
            return toast.error("File upload failed!");
          }
        }

        // Dispatch the form data (including the image URL) to create a Picnote
        let resp = await dispatch(createPicnote({ ...values, tags }));

        if (resp.status === 200) {
          toast.success(resp.data.message);
          setPicnoteResponseWhileAddingNote(resp.data);
          dispatch(allMyPnotes());
          dispatch(myFeed());
          dispatch(profileDetial());
          setAddResource(true);
          formik.resetForm();
        } else if (resp.status === 400) {
          toast.error("Must be a valid Picnote");
        } else {
          toast.error("Error while creating Picnote");
        }
      } catch (error) {
        console.error("Error during picnote creation:", error);
        toast.error("Something went wrong. Please try again.");
      }
    },
  });

  return (
    <>
      {addResource && addResource ? (
        <AddResource
          closeAddSource={closeAddSource}
          picnoteResponseWhileAddingNote={picnoteResponseWhileAddingNote}
        />
      ) : null}

      <Dialog
        open={true}
        fullWidth
        maxWidth="md"
        sx={{
          zIndex: 1200,
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            padding: isMobile ? "10px 10px 5px 10px" : "20px",
            minWidth: isMobile ? "90%" : "400px",
          },
        }}
      >
        <Grid2 item size={12} mb={"10px"}>
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleCloseClick}
          >
            <CloseIcon style={{ width: "20px", height: "20px" }} />
          </IconButton>
        </Grid2>

        <Grid2 item size={12} textAlign="center">
          <Typography
            component="div"
            className="headerStyle"
            style={{
              marginBottom: "20px",
              fontFamily: "cako regular",
              fontSize: "22px",
              fontWeight: 400,
            }}
          >
            Create New Picnote
          </Typography>
        </Grid2>

        <form onSubmit={formik.handleSubmit}>
          <Grid2
            container
            // padding={"10px 50px"}
            //  spacing={4}
            spacing={isMobile ? 0 : 4}
            padding={isMobile ? "0px" : "10px 50px"}
          >
            {/* Picture Upload Area */}
            <Grid2
              item
              // size={6}
              size={isMobile ? 12 : 6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
                position: "relative",
                backgroundImage: `url(${dashed})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                // height: "330px",
                // width: "330px",
                height: isMobile ? "351px" : "330px",
                width: isMobile ? "100%" : "330px",
                ...getRootProps(),
              }}
            >
              <input {...getInputProps()} style={{ display: "none" }} />
              {/* Show either the uploaded image or the gallery placeholder */}
              {selectedImage ? (
                <>
                  <img
                    src={selectedImage}
                    alt="Uploaded Pic"
                    style={{
                      maxWidth: "100%",
                      // maxHeight: "250px",
                      maxHeight: isMobile ? "200px" : "250px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                  <div
                    style={{ marginTop: "10px", display: "flex", gap: "10px" }}
                  >
                    {/* "Change Image" button */}
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        borderRadius: "20px",
                        textTransform: "none",
                        backgroundColor: "rgba(188, 23, 29, 1)",
                        color: "white",
                      }}
                    >
                      Change Image
                      <input
                        type="file"
                        accept="image/jpeg, image/jpg, image/png"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                    </Button>

                    {/* "Remove Image" button */}
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleImageRemove}
                      style={{
                        borderRadius: "20px",
                        textTransform: "none",
                      }}
                    >
                      Remove Image
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <img src={gallery} alt="pic" />
                  <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
                    <Typography
                      component="div"
                      className="picnotePictureHeader"
                    >
                      {/* Drop your image here, or{" "} */}
                      Click{" "}
                      <span style={{ color: "rgba(188, 23, 29, 1)" }}>
                        browse{" "}
                      </span>
                      to upload image
                    </Typography>
                  </label>
                  <input
                    id="image-upload"
                    type="file"
                    accept="image/jpeg, image/jpg, image/png"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  <Typography component="div" className="picnotePicture">
                    Supports: JPG, JPEG, PNG
                  </Typography>
                  <Typography component="div" className="picnotePicture">
                    Max. size: 2 MB
                  </Typography>
                  <Typography component="div" className="picnotePicture">
                    Minimum width 1000px
                  </Typography>
                </>
              )}

              {imageError && (
                <Typography
                  color="error"
                  style={{
                    textAlign: "center",
                    paddingTop: "10px",
                  }}
                >
                  {imageError}
                </Typography>
              )}
            </Grid2>

            <Grid2 item size={isMobile ? 12 : 6}>
              <Grid2 item size={12} className="inputContainerStyle">
                <label htmlFor="title" className="labelStyle">
                  Title (required)
                </label>
                <Input
                  id="title"
                  className="inputStyle"
                  style={{
                    width: "100%",
                    fontFamily: "Aileron",
                    fontWeight: 600,
                    color: "rgba(63, 63, 63, 1)",
                    fontSize: "13px",
                  }}
                  placeholder="Title"
                  name="title"
                  fullWidth
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "5px" }}
                  >
                    {formik.errors.title}
                  </div>
                ) : null}
              </Grid2>

              <Grid2 item size={12} className="inputContainerStyle">
                <label htmlFor="description" className="labelStyle">
                  Notes (Required) {formik.values.description.length} / 650
                </label>
                <TextArea
                  id="description"
                  placeholder="No more than 650 characters"
                  // autoSize={{ minRows: 5, maxRows: 5 }}
                  autoSize={{ minRows: isMobile ? 3 : 5, maxRows: 5 }}
                  value={formik.values.description}
                  onChange={(e) => {
                    if (e.target.value.length <= 650) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    border: "1px solid rgba(236, 228, 228, 1)",
                    paddingLeft: "10px",
                    paddingRight: "40px",
                    fontFamily: "Aileron",
                    fontWeight: 600,
                    color: "rgba(63, 63, 63, 1)",
                  }}
                />
                {formik.touched.description && formik.errors.description ? (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "5px" }}
                  >
                    {formik.errors.description}
                  </div>
                ) : null}
              </Grid2>

              <Grid2 item size={12} className="inputContainerStyle">
                <label htmlFor="tags" className="labelStyle">
                  Tags (required)
                </label>
                <Autocomplete
                  freeSolo
                  popupIcon={
                    <img
                      src={arrow}
                      alt="drop"
                      style={{
                        color: "#7A53EC",
                        margin: "3px 5px",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  }
                  value={formik.values?.trackerTags}
                  filterOptions={(options, state) => {
                    return options.filter(option => 
                      option.title.toLowerCase().includes(state.inputValue.toLowerCase())
                    );
                  }}
                  id="trackerTags"
                  multiple
                  limitTags={1}
                  options={uniqueTags || []}
                  onInputChange={handleInputChange}
                  getOptionLabel={(option) => option?.title || ""}
                  onChange={async (e, value) => {
                    // Filter out empty or invalid entries
                    const filteredValue = value.filter((tag) => tag?.title?.trim() !== "");
                
                    // Update trackerTags with the filtered value
                    formik.setFieldValue("trackerTags", filteredValue);
                
                    // Get the last entered tag (new tag)
                    const newTagTitle = filteredValue[filteredValue.length - 1]?.title || filteredValue[filteredValue.length - 1];
                
                    // Check if the new tag doesn't already exist in allTagsList
                    if (newTagTitle && !allTagsList?.some((tag) => tag?.title.toLowerCase() === newTagTitle.toLowerCase())) {
                        try {
                            // Dispatch action to create the new tag
                            const createdTag1 = await dispatch(createTag({ title: newTagTitle }));
                
                            if (createdTag1?.data?.newTag) {
                                // Create a new tag object
                                const newTagObject = {
                                    tagId: createdTag1.data.newTag._id,
                                    title: createdTag1.data.newTag.title,
                                };
                
                                // Update trackerTags by replacing the last entry with the new tag object
                                const updatedTags = [...filteredValue.slice(0, -1), newTagObject];
                                formik.setFieldValue("trackerTags", updatedTags);
                            }
                        } catch (error) {
                            console.error("Error creating tag:", error);
                            toast.error("Failed to create tag. Please try again.");
                        }
                    }
                }}
                  
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      variant="standard"
                      id="trackerTags"
                      name="trackerTags"
                      disableUnderline
                      error={
                        formik.touched.trackerTags &&
                        Boolean(formik.errors.trackerTags)
                      }
                      style={{
                        borderRadius: "25px",
                        padding: "5px",
                        border: "1px solid #d1d1d1",
                        paddingLeft: "15px",
                      }}
                    />
                  )}
                />
                {formik.touched.trackerTags && formik.errors.trackerTags ? (
                  <Typography
                    color="red"
                    fontSize="12px"
                    style={{ marginTop: "5px" }}
                  >
                    {formik.errors.trackerTags}
                  </Typography>
                ) : null}
              </Grid2>
            </Grid2>
          </Grid2>

          <Grid2
            item
            size={12}
            fullWidth
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "10px 0px",
            }}
          >
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              type="submit"
              variant="contained"
              style={{
                borderRadius: "20px",
                color: "white",
                width: isMobile ? "100%" : "40%",
                backgroundColor: "rgba(188, 23, 29, 1)",
                textTransform: "none",
              }}
            >
              Create Picnotes
            </LoadingButton>
          </Grid2>
        </form>
      </Dialog>

      <Dialog
        open={showConfirmationDialog}
        onClose={cancelLeave}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to leave without posting?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelLeave} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmLeave}
            color="error"
            variant="contained"
            style={{
              borderRadius: "20px",
              textTransform: "none",
            }}
          >
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateNewPicnote;
