import axios from "axios";
import config from "../services/config";
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  updateFlagsloader: false,
  allMyNotes: null,
  addResourceloader: false,
  follwersFeedsLoader: false,
  singleFeedLoader: false,
  singleNote: null,
  myFeedsData: null,
  deletePicnoteLoader: false,
  addPictoFolderloading: false,
  editPicnoteLoader: false,
  favPicnotes: null,
  favPicnotesL: false,
  TrendingNotesList: null,
  TrendingLoader: false,
  vTrendingLoader: false,
  vTrendingNotesList: null,
  similarNotesList: null,
  similarNotesL: false,
  vTrinList: null,
  vTrinListL: false,
  vSingleNoteData: null,
  vSingleNoteL: false,
  vSimilerNoteL: false,
  vSimilerNoteData: null,
  noteCommentData: null,
  noteCommentL: false,
  createnoteCommentL: false,
  deletenoteCommentL: false,
  addOthersPFloading: false,
};


const slice = createSlice({
  name: 'picnote',
  initialState,
  reducers: {
    rVSingleNoteData: (state) => {
      state.vSingleNoteData = null;
    },

    SingleNoteData: (state) => {
      state.singleNote = null;
    },
    creatingPicnote(state, action) {
      state.loading = true;
      state.error = null;
    },
    creatingPicnoteSuccess(state, action) {
      state.loading = false;
      state.error = null;

    },
    creatingPicnoteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addingPicnoteToFolder(state, action) {
      state.addPictoFolderloading = true;
      state.error = null;
    },
    addingPicnoteToFolderSuccess(state, action) {
      state.addPictoFolderloading = false;
      state.error = null;

    },
    addingPicnoteToFolderFailure(state, action) {
      state.addPictoFolderloading = false;
      state.error = action.payload;
    },
    addingOthersPicnoteToFolder(state, action) {
      state.addOthersPFloading = true;
      state.error = null;
    },
    addingOthersPicnoteToFolderSuccess(state, action) {
      state.addOthersPFloading = false;
      state.error = null;

    },
    addingOthersPicnoteToFolderFailure(state, action) {
      state.addOthersPFloading = false;
      state.error = action.payload;
    },
   
    updatingFlags(state, action) {
      state.updateFlagsloader = true;
      state.error = null;
    },
    updatingFlagsSuccess(state, action) {
      state.updateFlagsloader = false;
      state.error = null;
      state.allFolderList = action.payload;

    },
    updatingFlagsFailure(state, action) {
      state.updateFlagsloader = false;
      state.error = action.payload;
    },
    getttingMyPicnotes(state, action) {
      state.MyPicnotesloader = true;
      state.error = null;
    },
    getttingMyPicnotesSuccess(state, action) {
      state.MyPicnotesloader = false;
      state.error = null;
      state.allMyNotes = action.payload;

    },
    getttingMyPicnotesFailure(state, action) {
      state.MyPicnotesloader = false;
      state.error = action.payload;
    },
    addingResource(state, action) {
      state.addResourceloader = true;
      state.error = null;
    },
    addingResourceSuccess(state, action) {
      state.addResourceloader = false;
      state.error = null;

    },
    addingResourceFailure(state, action) {
      state.addResourceloader = false;
      state.error = action.payload;
    },
    getttingMyFollowersfeeds(state, action) {
      state.follwersFeedsLoader = true;
      state.error = null;
    },
    getttingMyFollowersfeedsSuccess(state, action) {
      state.follwersFeedsLoader = false;
      state.error = null;

    },
    getttingMyFollowersfeedsFailure(state, action) {
      state.follwersFeedsLoader = false;
      state.error = action.payload;
    },
    getttingsingleFeed(state, action) {
      state.singleFeedLoader = true;
      state.error = null;
    },
    getttingsingleFeedSuccess(state, action) {
      state.singleFeedLoader = false;
      state.error = null;
      state.singleNote = action.payload;


    },
    getttingsingleFeedFailure(state, action) {
      state.singleFeedLoader = false;
      state.error = action.payload;
    },
    gettingMyFeed(state, action) {
      state.myFeedLoader = true;
      state.error = null;
    },
    gettingMyFeedSuccess(state, action) {
      state.myFeedLoader = false;
      state.error = null;
      state.myFeedsData = action.payload;
    },
    gettingMyFeedFailure(state, action) {
      state.myFeedLoader = false;
      state.error = action.payload;
    },
    deletingPicnote(state, action) {
      state.deletePicnoteLoader = true;
      state.error = null;
    },
    deletingPicnoteSuccess(state, action) {
      state.deletePicnoteLoader = false;
      state.error = null;


    },
    deletingPicnoteFailure(state, action) {
      state.deletePicnoteLoader = false;
      state.error = action.payload;
    },
    editingPicnote(state, action) {
      state.editPicnoteLoader = true;
      state.error = null;
    },
    editingPicnoteSuccess(state, action) {
      state.editPicnoteLoader = false;
      state.error = null;


    },
    editingPicnoteFailure(state, action) {
      state.editPicnoteLoader = false;
      state.error = action.payload;
    },
    gettingFavPicnote(state, action) {
      state.favPicnotesL = true;
      state.error = null;
    },
    gettingFavPicnoteSuccess(state, action) {
      state.favPicnotesL = false;
      state.error = null;
      state.favPicnotes = action.payload;
    },
    gettingFavPicnoteFailure(state, action) {
      state.favPicnotesL = false;
      state.error = action.payload;
    },
    editingTrendingPicnotes(state, action) {
      state.TrendingLoader = true;
      state.error = null;
    },
    editingTrendingPicnotesSuccess(state, action) {
      state.TrendingLoader = false;
      state.error = null;
      state.TrendingNotesList = action.payload;
    },
    editingTrendingPicnotesFailure(state, action) {
      state.TrendingLoader = false;
      state.error = action.payload;
    },
    gettingVTrendingNotes(state, action) {
      state.vTrendingLoader = true;
      state.error = null;
    },
    gettingVTrendingNotesSuccess(state, action) {
      state.vTrendingLoader = false;
      state.error = null;
      state.vTrendingNotesList = action.payload;
    },
    gettingVTrendingNotesFailure(state, action) {
      state.vTrendingLoader = false;
      state.error = action.payload;
    },
    getttingSimilarNotes(state, action) {
      state.similarNotesL = true;
      state.error = null;
    },
    getttingSimilarNotesSuccess(state, action) {
      state.similarNotesL = false;
      state.error = null;
      state.similarNotesList = action.payload;
    },
    getttingSimilarNotesFailure(state, action) {
      state.similarNotesL = false;
      state.error = action.payload;
    },
    getttingVTNotes(state, action) {
      state.vTrinListL = true;
      state.error = null;
    },
    getttingVTNotesSuccess(state, action) {
      state.vTrinListL = false;
      state.error = null;
      state.vTrinList = action.payload;
    },
    getttingVTNotesFailure(state, action) {
      state.vTrinListL = false;
      state.error = action.payload;
    },
    getttingSingleVTNote(state, action) {
      state.vSingleNoteL = true;
      state.error = null;
    },
    getttingSingleVTNoteSuccess(state, action) {
      state.vSingleNoteL = false;
      state.error = null;
      state.vSingleNoteData = action.payload;
    },
    getttingSingleVTNoteFailure(state, action) {
      state.vSingleNoteL = false;
      state.error = action.payload;
    },
    getttingVSimilarNotes(state, action) {
      state.vSimilerNoteL = true;
      state.error = null;
    },
    getttingVSimilarNotesSuccess(state, action) {
      state.vSimilerNoteL = false;
      state.error = null;
      state.vSimilerNoteData = action.payload;
    },
    getttingVSimilarNotesFailure(state, action) {
      state.vSimilerNoteL = false;
      state.error = action.payload;
    },
    getttingPicnoteComment(state, action) {
      state.noteCommentL = true;
      state.error = null;
    },
    getttingPicnoteCommentSuccess(state, action) {
      state.noteCommentL = false;
      state.error = null;
      state.noteCommentData = action.payload;
    },
    getttingPicnoteCommentFailure(state, action) {
      state.noteCommentL = false;
      state.error = action.payload;
    },
    creattingPicnoteComment(state, action) {
      state.createnoteCommentL = true;
      state.error = null;
    },
    creattingPicnoteCommentSuccess(state, action) {
      state.createnoteCommentL = false;
      state.error = null;
    },
    creattingPicnoteCommentFailure(state, action) {
      state.createnoteCommentL = false;
      state.error = action.payload;
    },
    deletingPicnoteComment(state, action) {
      state.deletenoteCommentL = true;
      state.error = null;
    },
    deletingPicnoteCommentSuccess(state, action) {
      state.deletenoteCommentL = false;
      state.error = null;
    },
    deletingPicnoteCommentFailure(state, action) {
      state.deletenoteCommentL = false;
      state.error = action.payload;
    },
  }
});

const {
  creatingPicnote,
  creatingPicnoteSuccess,
  creatingPicnoteFailure,
  addingPicnoteToFolder,
  addingPicnoteToFolderSuccess,
  addingPicnoteToFolderFailure,
  addingOthersPicnoteToFolder,
  addingOthersPicnoteToFolderSuccess,
  addingOthersPicnoteToFolderFailure,
  deletingPicnoteComment,
  deletingPicnoteCommentSuccess,
  deletingPicnoteCommentFailure,
  updatingFlags,
  updatingFlagsSuccess,
  updatingFlagsFailure,
  getttingMyPicnotes,
  getttingMyPicnotesSuccess,
  getttingMyPicnotesFailure,
  addingResource,
  addingResourceSuccess,
  addingResourceFailure,
  getttingMyFollowersfeeds,
  getttingMyFollowersfeedsSuccess,
  getttingMyFollowersfeedsFailure,
  getttingsingleFeed,
  getttingsingleFeedSuccess,
  getttingsingleFeedFailure,
  gettingMyFeed,
  gettingMyFeedSuccess,
  gettingMyFeedFailure,
  deletingPicnote,
  deletingPicnoteSuccess,
  deletingPicnoteFailure,
  editingPicnote,
  editingPicnoteSuccess,
  editingPicnoteFailure,
  gettingFavPicnote,
  gettingFavPicnoteSuccess,
  gettingFavPicnoteFailure,
  editingTrendingPicnotes,
  editingTrendingPicnotesSuccess,
  editingTrendingPicnotesFailure,
  getttingSimilarNotes,
  getttingSimilarNotesSuccess,
  getttingSimilarNotesFailure,
  getttingVTNotes,
  getttingVTNotesSuccess,
  getttingVTNotesFailure,
  getttingSingleVTNote,
  getttingSingleVTNoteSuccess,
  getttingSingleVTNoteFailure,
  getttingVSimilarNotes,
  getttingVSimilarNotesSuccess,
  getttingVSimilarNotesFailure,
  gettingVTrendingNotes,
  gettingVTrendingNotesSuccess,
  gettingVTrendingNotesFailure,
  getttingPicnoteComment,
  getttingPicnoteCommentSuccess,
  getttingPicnoteCommentFailure,
  creattingPicnoteComment,
  creattingPicnoteCommentSuccess,
  creattingPicnoteCommentFailure,
  rVSingleNoteData,
  SingleNoteData

} = slice.actions;

export const picnoteReducer = (state) => state.picnoteReducer

export const resetVSingleNoteData = () => (dispatch) => {
  dispatch(rVSingleNoteData());
};
export const resetSingleNoteData = () => (dispatch) => {
  dispatch(SingleNoteData());
};

export const createPicnote = (data) => async (dispatch) => {
  dispatch(creatingPicnote());
  try {
    const response = await axios.post(
      `${config.baseUrl}/picnote/create`, data    );

    if (response.status === 200) {
      dispatch(creatingPicnoteSuccess(response.status)); 
    } else {
      dispatch(creatingPicnoteFailure(response.status)); 
    }

    return response;
  } catch (e) {
    dispatch(
      creatingPicnoteFailure("Something went wrong while creating picnote!")
    );
  }
};

export const addPicnoteToFolder = (data) => async (dispatch) => {
  dispatch(addingPicnoteToFolder());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/picnote/picnotetofolder`, data    );

    if (response.status === 200) {
      dispatch(addingPicnoteToFolderSuccess(response.status)); 
    } else {
      dispatch(addingPicnoteToFolderFailure(response.status)); 
    }

    return response;
  } catch (e) {
    dispatch(
      addingPicnoteToFolderFailure("Something went wrong while creating folder!")
    );
  }
};

export const othersPicnoteToFolder = (data) => async (dispatch) => {
  dispatch(addingOthersPicnoteToFolder());
  try {
    const response = await axios.post(
      `${config.baseUrl}/picnote/addnotetofolder`, data    );

    if (response.status === 200) {
      dispatch(addingOthersPicnoteToFolderSuccess(response.status)); 
    } else {
      dispatch(addingOthersPicnoteToFolderFailure(response.status)); 
    }

    return response;
  } catch (e) {
    dispatch(
      addingOthersPicnoteToFolderFailure("Something went wrong while creating folder!")
    );
  }
};

export const updateFlags = (values) => async (dispatch) => {
  dispatch(updatingFlags());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/picnote/updateflags`, values);

    if (response.status === 200) {
      dispatch(updatingFlagsSuccess(response.data)); 
    } else {
      dispatch(updatingFlagsFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      updatingFlagsFailure("Something went wrong while updating flags!")
    );
  }
};


export const addResource = (values) => async (dispatch) => {
  dispatch(addingResource());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/picnote/addresource`, values);

    if (response.status === 200) {
      dispatch(addingResourceSuccess(response.data)); 
    } else {
      dispatch(addingResourceFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      addingResourceFailure("Something went wrong while updating flags!")
    );
  }
};


export const  allMyPnotes = () => async (dispatch) => {
  dispatch(getttingMyPicnotes());
  try {
    const response = await axios.get(
      `${config.baseUrl}/edf/allmyactivenotes`);

    if (response.status === 200) {
      dispatch(getttingMyPicnotesSuccess(response.data)); 
    } else {
      dispatch(getttingMyPicnotesFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      getttingMyPicnotesFailure("Something went wrong while getting picnotes!")
    );
  }
};

export const followersFeeds = () => async (dispatch) => {
  dispatch(getttingMyFollowersfeeds());
  try {
    const response = await axios.get(
      `${config.baseUrl}/edf/allmynotes`);

    if (response.status === 200) {
      dispatch(getttingMyFollowersfeedsSuccess(response.data)); 
    } else {
      dispatch(getttingMyFollowersfeedsFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      getttingMyFollowersfeedsFailure("Something went wrong while getting picnotes!")
    );
  }
};


export const myFeed = () => async (dispatch) => {
  dispatch(gettingMyFeed());
  try {
    const response = await axios.get(
      `${config.baseUrl}/edf/myfeeds/${'%20'}`);

    if (response.status === 200) {
      dispatch(gettingMyFeedSuccess(response.data)); 
    } else {
      dispatch(gettingMyFeedFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      gettingMyFeedFailure("Something went wrong while getting picnote!")
    );
  }
};

export const singleFeed = (picnoteId) => async (dispatch) => {
  dispatch(getttingsingleFeed());
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/singlenote/${picnoteId}`);

    if (response.status === 200) {
      dispatch(getttingsingleFeedSuccess(response.data)); 
    } else {
      dispatch(getttingsingleFeedFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      getttingsingleFeedFailure("Something went wrong while getting picnote!")
    );
  }
};


export const deletePicnote = (picnoteId) => async (dispatch) => {
  dispatch(deletingPicnote());
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/delete/${picnoteId}`);

    if (response.status === 200) {
      dispatch(deletingPicnoteSuccess(response.data)); 
    } else {
      dispatch(deletingPicnoteFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      deletingPicnoteFailure("Something went wrong while deleting picnote!")
    );
  }
};

export const favouritePicnote = () => async (dispatch) => {
  dispatch(gettingFavPicnote());
  try {
    const response = await axios.get(
      `${config.baseUrl}/edf/myfavorites`);
     
    if (response.status === 200) {
      dispatch(gettingFavPicnoteSuccess(response.data)); 
    } else {
      dispatch(gettingFavPicnoteFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      gettingFavPicnoteFailure("Something went wrong while deleting picnote!")
    );
  }
};
export const editPicnote = (data) => async (dispatch) => {
  dispatch(editingPicnote());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/picnote/editpicnote`, data);

    if (response.status === 200) {
      dispatch(editingPicnoteSuccess(response.data)); 
    } else {
      dispatch(editingPicnoteFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      editingPicnoteFailure("Something went wrong while editing picnote!")
    );
  }
};

export const getTrendeing = (data) => async (dispatch) => {
  dispatch(editingTrendingPicnotes());
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/trending`, data);

    if (response.status === 200) {
      dispatch(editingTrendingPicnotesSuccess(response.data)); 
    } else {
      dispatch(editingTrendingPicnotesFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      editingTrendingPicnotesFailure("Something went wrong while getting trending!")
    );
  }
};

export const getVTrendeing = (data) => async (dispatch) => {
  dispatch(gettingVTrendingNotes());
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/trendingvisitor`, data);

    if (response.status === 200) {
      dispatch(gettingVTrendingNotesSuccess(response.data)); 
    } else {
      dispatch(gettingVTrendingNotesFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      gettingVTrendingNotesFailure("Something went wrong while getting trending!")
    );
  }
};



export const similarNotes = (tags) => async (dispatch) => {
  dispatch(getttingSimilarNotes());
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/similar/${tags}`);

    if (response.status === 200) {
      dispatch(getttingSimilarNotesSuccess(response.data)); 
    } else {
      dispatch(getttingSimilarNotesFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      getttingSimilarNotesFailure("Something went wrong while getting picnotes!")
    );
  }
};


export const vSimilarNotes = (tags) => async (dispatch) => {
  dispatch(getttingVSimilarNotes());
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/similarvisitor/${tags}`);

    if (response.status === 200) {
      dispatch(getttingVSimilarNotesSuccess(response.data)); 
    } else {
      dispatch(getttingVSimilarNotesFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      getttingVSimilarNotesFailure("Something went wrong while getting picnotes!")
    );
  }
};

export const visiterTrending = () => async (dispatch) => {
  dispatch(getttingVTNotes());
  // getttingVisiterTrendingTNotes
  // console.log("basUrl", config?.baseUrl)
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/trendingvisitor`);

    if (response.status === 200) {
      dispatch(getttingVTNotesSuccess(response.data)); 
    } else {
      dispatch(getttingVTNotesFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      getttingVTNotesFailure("Something went wrong while getting picnotes!")
    );
  }
};


export const singleVisiterNote = (noteId) => async (dispatch) => {
  dispatch(getttingSingleVTNote());
  // getttingVisiterTrendingTNotes
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/singlenotevisitor/${noteId}`);

    if (response.status === 200) {
      dispatch(getttingSingleVTNoteSuccess(response.data)); 
    } else {
      dispatch(getttingSingleVTNoteFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      getttingSingleVTNoteFailure("Something went wrong while getting picnotes!")
    );
  }
};

export const getPicnoteComments = (noteId) => async (dispatch) => {
  dispatch(getttingPicnoteComment());
  // getttingVisiterTrendingTNotes
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/getnotecomments/${noteId}`);

    if (response.status === 200) {
      dispatch(getttingPicnoteCommentSuccess(response.data)); 
    } else {
      dispatch(getttingPicnoteCommentFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      getttingPicnoteCommentFailure("Something went wrong while getting picnotes!")
    );
  }
};
export const createPicnoteComment = (data) => async (dispatch) => {
  dispatch(creattingPicnoteComment());
  // getttingVisiterTrendingTNotes
  try {
    const response = await axios.post(
      `${config.baseUrl}/picnote/createcomment`, data);

    if (response.status === 200) {
      dispatch(creattingPicnoteCommentSuccess(response.data)); 
    } else {
      dispatch(creattingPicnoteCommentFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      creattingPicnoteCommentFailure("Something went wrong while creating picnotes!")
    );
  }
};


export const deletePicnoteComment = (commentId) => async (dispatch) => {
  dispatch(deletingPicnoteComment());
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/deletecomment/${commentId}`);

    if (response.status === 200) {
      dispatch(deletingPicnoteCommentSuccess(response.data)); 
    } else {
      dispatch(deletingPicnoteCommentFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      deletingPicnoteCommentFailure("Something went wrong while deleting picnote!")
    );
  }
};




export default slice.reducer;

