import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import ScrollToTop from "./views/ScrollTop";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const domainId = process.env.REACT_APP_DOMAIN_URL;
const clientId = process.env.REACT_APP_CLIENT_ID_URL;

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <Auth0Provider
      domain={domainId}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: "https://picnotes.org/auth/callback"
      }}
    >
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Auth0Provider>
    {/* </React.StrictMode> */}
  </Provider>
);

reportWebVitals();
