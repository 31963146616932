import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  Grid2,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextArea from "antd/es/input/TextArea";
import { Input } from "antd";
import { useFormik } from "formik";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { createTracker, editTracker, getAllTrackers } from "../redux/tracker";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { allTags, createTag } from "../redux/tags";
import arrow from "../assets/arrow-down-01-round.png";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const headerStyle = {
  fontFamily: "Cako regular",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "31.21px",
  textAlign: "center",
  color: "rgba(63, 63, 63, 1)",
};

const labelStyle = {
  marginBottom: "5px",
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "31.21px",
};

const inputContainerStyle = {
  marginBottom: "15px",
};

const inputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "38px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
};

const centerlizing = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function IWantToLearn({ onClose, title, cardDetail }) {
  const [imageUrl, setImageUrl] = useState("");
  const [imageError, setImageError] = useState(false);
  const [contentType, setContentType] = useState(""); // Add this line
  const [videoUrl, setVideoUrl] = useState(""); // Add this line

  useEffect(() => {
    dispatch(allTags());
  }, []);

  // console.log("clickedCardTitle", title)

  //! INSTANCES
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Fetch data from the Redux store
  const { createTrackerL } = useSelector((state) => state.tracker);
  const { allTagsList } = useSelector((state) => state.tags);

  const validationSchema = yup.object({
    title: yup
      .string()
      .required("title is required")
      .min(3, "title must be at least 3 characters"),
      description: yup
      .string()
      .required("Note is required")
      .min(3, "Note must be at least 3 characters"),
      trackerLink: yup
      .string()
      .required("tracker Link is required")
      .min(3, "tracker Link must be at least 3 characters"),
    trackerTags: yup
      .array()
      .min(1, "At least one tag is required")
      .of(yup.object().shape({ title: yup.string().required() })),
  });

  // console.log("allTagsList", allTagsList);
  const formik = useFormik({
    initialValues: {
      title: cardDetail?.title || "",
      description: cardDetail?.description || "",
      trackerLink: cardDetail?.trackerLink || "",
      trackerStage: cardDetail?.trackerStage || title?.status,
      trackerTags: cardDetail?.trackerTags?.map(tag => ({
        tagId: tag.tagId,
        title: tag.tagTitle,
      })) || [],
      trackerId: cardDetail?.trackerId || ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Construct payload with only `tagId` for `trackerTags`
      const payload = {
        ...values,
        trackerTags: values.trackerTags.map(tag => tag.tagId),
      };
  
      // Dispatch action with modified payload
      const response = await dispatch(cardDetail ? editTracker(payload) : createTracker(payload));
  
      // Handle response and reset form as necessary
      if (response.status === 200) {
        dispatch(getAllTrackers());
        toast.success(response.data.message);
        onClose();
        formik.resetForm();
      } else {
        toast.error(response.data.message);
      }
    },
  });
  

  const handleInputChange = (e) => {
    const url = e.target.value;
    setImageError(false);

    try {
      const domain = new URL(url).hostname;

      // Image handling
      if (/\.(jpeg|jpg|gif|png)$/.test(url)) {
        setContentType("image");
        setImageUrl(url);
      }
      // YouTube video handling
      else if (domain.includes("youtube.com") || domain.includes("youtu.be")) {
        const videoId = url.split("v=")[1] || url.split("/").pop();
        setContentType("video");
        setVideoUrl(`https://www.youtube.com/embed/${videoId}`);
      }
      // Website logo for articles or general websites
      else {
        setContentType("logo");
        setImageUrl(`https://logo.clearbit.com/${domain}`);
      }
    } catch {
      setImageUrl("");
      setImageError(true);
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      sx={{
        zIndex: 1200,
        "& .MuiDialog-paper": {
          borderRadius: "10px",
          padding: "20px 30px",
          minWidth: isMobile ? "350px" : "400px",
          maxWidth: isMobile ? "500px" : "600px",
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid2 container alignItems="center">
          <Grid2 item size={12} mb={"10px"}>
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={onClose}
            >
              <CloseIcon style={{ width: "20px", height: "20px" }} />
            </IconButton>
          </Grid2>

          <Grid2 item size={12} textAlign="center">
            <Typography
              variant="h5"
              component="div"
              style={{ ...headerStyle, marginBottom: "20px" }}
            >
              {title?.name}
            </Typography>
          </Grid2>
        </Grid2>

        <Grid2 item size={12} style={inputContainerStyle}>
          <label htmlFor="title" style={labelStyle}>
            Topic (Required)
          </label>
          <Input
            className="inputTypeTextStyle"
            id="title"
            style={{ ...inputStyle, width: "100%" }}
            placeholder="Topic (Required)"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
          />
          {formik.touched.title && formik.errors.title ? (
            <div style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.title}
            </div>
          ) : null}
        </Grid2>

        <Grid2 item size={12} mb={"10px"}>
          <Typography
            variant="body6"
            style={{ ...labelStyle, marginBottom: "8px", textAlign: "left" }}
          >
            Notes
          </Typography>
          <TextArea
            className="inputTypeTextStyle"
            placeholder="Add description here"
            rows={6}
            maxRows={6}
            name="description" // Add this line to bind it with formik
            value={formik.values.description} // Bind the value to formik state
            onChange={formik.handleChange} // Bind the onChange to formik
            onBlur={formik.handleBlur} // Bind the onBlur to formik
            error={formik.touched.description && Boolean(formik.errors.description)}

            style={{
              borderRadius: "10px",
              width: "100%",
              border: "1px solid rgba(236, 228, 228, 1)",
              paddingLeft: "10px",
              paddingRight: "40px",
            }}
          />
           {formik.touched.description && formik.errors.description ? (
            <div style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.description}
            </div>
          ) : null}
        </Grid2>

        <Grid2 item size={12} mb={"10px"}>
          <Typography
            variant="body6"
            style={{ ...labelStyle, marginBottom: "8px", textAlign: "left" }}
          >
            Link
          </Typography>
          <div className="inputContainer">
            <Input
              className="inputTypeTextStyle"
              placeholder="add link"
              name="trackerLink" // Bind to Formik
              value={formik.values.trackerLink} // Bind the value to Formik state
              onChange={(e) => {
                formik.handleChange(e); // Update Formik state
                handleInputChange(e); // Call your existing handler
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.trackerLink && Boolean(formik.errors.trackerLink)}
              style={{
                borderRadius: "10px",
                width: "100%",
                border: "1px solid rgba(236, 228, 228, 1)",
                paddingLeft: "10px",
                paddingRight: "100px",
                height: "75px",
              }}
            />

            {/* Conditional Rendering */}
            {contentType === "image" && !imageError && (
              <img src={imageUrl} alt="Image" className="inputImage" />
            )}

            {contentType === "video" && (
              <iframe
                width="120px"
                height="75px"
                src={videoUrl}
                title="Video Preview"
                allow="fullscreen"
              />
            )}

            {contentType === "logo" && !imageError && (
              <img src={imageUrl} alt="Website Logo" className="inputImage" />
            )}

            {/* Error Message */}
            {imageError && (
              <Typography
                color="red"
                fontSize={"11px"}
                style={{ marginTop: "5px" }}
              >
                Image could not be loaded.
              </Typography>
            )}
          </div>
          {formik.touched.trackerLink && formik.errors.trackerLink ? (
            <div style={{ color: "red", fontSize: "12px" }}>
              {formik.errors.trackerLink}
            </div>
          ) : null}
        </Grid2>

        <Grid2 item size={12} style={inputContainerStyle}>
          <Typography
            variant="body6"
            style={{ ...labelStyle, marginBottom: "8px", textAlign: "left" }}
          >
            Hashtags (Required)
          </Typography>
          <Autocomplete
            popupIcon={
              <img
                src={arrow}
                alt="drop"
                style={{
                  color: "#7A53EC",
                  margin: "3px 5px",
                  width: "22px",
                  height: "22px",
                }}
              />
            }
            value={formik.values.trackerTags}
            id="trackerTags"
            multiple
            limitTags={1}
            options={allTagsList || []}
            getOptionLabel={(option) => option?.title || ""}
            onChange={(e, value) => {
              if (!value || value.length === 0) return; // Check if value is not empty

              const newTagTitle =
                value[value.length - 1]?.title || value[value.length - 1];

              // Check if it's a new tag and not already in allTagsList
              if (
                newTagTitle &&
                !allTagsList.some((tag) => tag.title === newTagTitle)
              ) {
                // Dispatch action to add the new tag to Redux
                dispatch(createTag({ title: newTagTitle }));
              }

              formik.setFieldValue("trackerTags", value);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  label={option?.title}
                  size="small"
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                variant="standard"
                id="trackerTags"
                name="trackerTags"
                size="small"
                error={
                  formik.touched.trackerTags &&
                  Boolean(formik.errors.trackerTags)
                }
                style={{
                  borderRadius: "25px",
                  padding: "5px",
                  border: "1px solid #d1d1d1",
                  paddingLeft: "15px",
                }}
              />
            )}
          />

          {/* Display error message below the Autocomplete */}
          {formik.touched.trackerTags && formik.errors.trackerTags ? (
            <Typography
              color="red"
              fontSize="12px"
              style={{ marginTop: "5px" }}
            >
              {formik.errors.trackerTags}
            </Typography>
          ) : null}
        </Grid2>

        <Grid2
          item
          size={12}
          style={{ ...centerlizing, paddingBottom: "10px" }}
        >
          <LoadingButton
            loading={createTrackerL}
            loadingPosition="start"
            type="submit"
            style={{
              borderRadius: "20px",
              color: "white",
              width: "50%",
              backgroundColor: "rgba(188, 23, 29, 1)",
              textTransform: "none",
            }}
          >
            Submit
          </LoadingButton>
        </Grid2>
      </form>
    </Dialog>
  );
}

export default IWantToLearn;
