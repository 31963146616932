import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddResource from "../components/create-picnote/AddResource";
import MasonryCom from "./MasonryCom";
import NoData from "./NoData";
import { favouritePicnote } from "../redux/picnote";

function MyFavorite() {
  const [addResource, setAddResource] = useState(false);
  const [selectedNoteFormMyF, setSelectedNoteFormMyF] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const dispatch = useDispatch();

  const { favPicnotes } = useSelector((state) => state.picnote) || {
    favPicnotes: [],
  };

  useEffect(() => {
    setLoading(true);
    dispatch(favouritePicnote())
      .finally(() => setLoading(false)); // Stop loading after fetching
  }, [dispatch]);

  const closeAddSource = () => {
    setAddResource(false);
    setSelectedNoteFormMyF(null);
  };

  return (
    <>
      {addResource && selectedNoteFormMyF ? (
        <AddResource
          closeAddSource={closeAddSource}
          selectedNoteFormMyF={selectedNoteFormMyF}
        />
      ) : null}

      {loading ? (
        "Loading Data..."
      ) : favPicnotes?.picnotes?.length > 0 ? (
        <MasonryCom
          TabName={"favouritePicnote"}
          picnotes={favPicnotes?.picnotes}
          // column={4}
          column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
          cardsWidth={"1200px"}
        />
      ) : (
        <NoData TabTitle={"myFavourite"} />
      )}
    </>
  );
}

export default MyFavorite;
