import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";

function Success({ title, subTitle, login, logo, loginPage }) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      xs={12}
      marginLeft={isMobile ? "0px" : '118px'}
      justifyContent={"center"}
      height={"86vh"}
      alignItems={"center"}
    >
      <Grid item xs={isMobile ? 10 : 4}>
        <Card
          style={{
            boxShadow: "10px 10px 30px 0px #6271EB33",
            borderRadius: "24px",
            height: "300px",
          }}
        >
          <CardContent>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ mt: 5 }}
            >
              <Grid item>
                {logo}
                <style>
                  {`
                    .MuiSvgIcon-root {
                      font-size: 62px; /* Adjust the size as needed */
                    }
                  `}
                </style>
              </Grid>
              <Grid item xs={12} style={{}}>
                <Typography
                  align="center"
                  style={{
                    fontSize: "30px",
                    fontWeight: 500,
                    fontFamily: "Lexend",
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  align={"center"}
                  style={{
                    color: "#829096",
                    fontFamily: "Lexend",
                    fontWeight: 400,
                  }}
                >
                  {subTitle}
                </Typography>
              </Grid>
              {login}
              {loginPage}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Success;
