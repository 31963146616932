import axios from "axios";
import config from "../services/config";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  userData: null,
  userEditLoader: false,
  eduListLoader: false,
  eduListDta: null,
  profileDetailLoader: false,
  error: null, // Added error handling state
  folloingLoader: false,
  unfollowingLoader: false,
  profileDetails: null,
  vProfileDetailLoader: false,
  vProfileDetails: null,
  tempUserDetail: null

};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Login actions
    loggedInUser(state) {
      state.loading = true;
      state.error = null;
    },
    loggedInUserSuccess(state, action) {
      state.loading = false;
      state.userData = action.payload;
    },
    loggedInUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // Edit user actions
    editingUser(state) {
      state.userEditLoader = true;
      state.error = null;
    },
    editingUserSuccess(state, action) {
      state.userEditLoader = false;
      state.userData = action.payload;
    },
    editingUserFailure(state, action) {
      state.userEditLoader = false;
      state.error = action.payload;
    },

    // Get education list actions
    gettingListOfEdu(state) {
      state.eduListLoader = true;
      state.error = null;
    },
    gettingListOfEduSuccess(state, action) {
      state.eduListLoader = false;
      state.eduListDta = action.payload; // Consistent state key
    },
    gettingListOfEduFailure(state, action) {
      state.eduListLoader = false;
      state.error = action.payload;
    },

    // Profile detail actions
    gettingprofileDetialEdu(state) {
      state.profileDetailLoader = true;
      state.error = null;
    },
    gettingprofileDetialEduSuccess(state, action) {
      state.profileDetailLoader = false;
      state.profileDetails = action.payload; // Ensure consistent state key
    },
    gettingprofileDetialEduFailure(state, action) {
      state.profileDetailLoader = false;
      state.error = action.payload;
    },

    //Visiter Profile detail actions
    gettingVProfileDetialEdu(state) {
      state.vProfileDetailLoader = true;
      state.error = null;
    },
    gettingVProfileDetialEduSuccess(state, action) {
      state.vProfileDetailLoader = false;
      state.vProfileDetails = action.payload; // Ensure consistent state key
    },
    gettingVProfileDetialEduFailure(state, action) {
      state.vProfileDetailLoader = false;
      state.error = action.payload;
    },

    // Logged in users action

    gettingprofile(state) {
      state.profileDetailLoader = true;
      state.error = null;
    },
    gettingprofileSuccess(state, action) {
      state.profileDetailLoader = false;
      state.eduListDta = action.payload; // Ensure consistent state key
    },
    gettingprofileFailure(state, action) {
      state.profileDetailLoader = false;
      state.error = action.payload;
    },

    // Following users action
    followingUser(state) {
      state.folloingLoader = true;
      state.error = null;
    },
    followingUserSuccess(state, action) {
      state.folloingLoader = false;
      state.eduListDta = action.payload; // Ensure consistent state key
    },
    followingUserFailure(state, action) {
      state.folloingLoader = false;
      state.error = action.payload;
    },
    unfollowingUser(state) {
      state.unfollowingLoader = true;
      state.error = null;
    },
    unfollowingUserSuccess(state, action) {
      state.unfollowingLoader = false;
      state.eduListDta = action.payload; // Ensure consistent state key
    },
    unfollowingUserFailure(state, action) {
      state.unfollowingLoader = false;
      state.error = action.payload;
    },

    // temp users action
    tempProfileUser(state) {
      state.folloingLoader = true;
      state.error = null;
    },
    tempProfileUserSuccess(state, action) {
      state.folloingLoader = false;
      state.tempUserDetail = action.payload;
    },
    tempProfileUserFailure(state, action) {
      state.folloingLoader = false;
      state.error = action.payload;
    },
  }
});

const {
  loggedInUser,
  loggedInUserSuccess,
  loggedInUserFailure,
  editingUser,
  editingUserSuccess,
  editingUserFailure,
  gettingListOfEdu,
  gettingListOfEduSuccess,
  gettingListOfEduFailure,
  gettingprofileDetialEdu,
  gettingprofileDetialEduSuccess,
  gettingprofileDetialEduFailure,
  gettingVProfileDetialEdu,
  gettingVProfileDetialEduSuccess,
  gettingVProfileDetialEduFailure,
  followingUser,
  followingUserSuccess,
  followingUserFailure,
  unfollowingUser,
  unfollowingUserSuccess,
  unfollowingUserFailure,
  gettingprofile,
  gettingprofileSuccess,
  gettingprofileFailure,
  tempProfileUser,
  tempProfileUserSuccess,
  tempProfileUserFailure
} = slice.actions;

export const userReducer = (state) => state.user;  // Access state via 'user'

export const loggedUser = (paramValue) => async (dispatch) => {
  dispatch(loggedInUser());
  try {
    const response = await axios.get(`${config.baseUrl}/edf/fetchedf/${paramValue}`);

    if (response.status === 200) {
      dispatch(loggedInUserSuccess(response.data));  // Use response.data
    } else {
      dispatch(loggedInUserFailure("Failed to fetch user data"));
    }

    return response;
  } catch (e) {
    dispatch(loggedInUserFailure("Something went wrong while fetching user!"));
  }
};

export const editUser = (paramValue) => async (dispatch) => {
  dispatch(editingUser());
  try {
    const response = await axios.patch(`${config.baseUrl}/edf/edit`, paramValue);

    if (response.status === 200) {
      dispatch(editingUserSuccess(response.data));  // Use response.data
    } else {
      dispatch(editingUserFailure("Failed to edit user data"));
    }

    return response;
  } catch (e) {
    dispatch(editingUserFailure("Something went wrong while editing user"));
  }
};

export const eduList = () => async (dispatch) => {
  dispatch(gettingListOfEdu());
  try {
    const response = await axios.get(`${config.baseUrl}/edf/listfollowing`);

    if (response.status === 200) {
      dispatch(gettingListOfEduSuccess(response.data));  // Use response.data
    } else {
      dispatch(gettingListOfEduFailure("Failed to fetch education list"));
    }

    return response;
  } catch (e) {
    dispatch(gettingListOfEduFailure("Something went wrong while getting the education list"));
  }
};

export const profileDetial = () => async (dispatch) => {
  dispatch(gettingprofileDetialEdu());
  try {
    const response = await axios.get(`${config.baseUrl}/edf/getprofile`);

    if (response.status === 200) {
      dispatch(gettingprofileDetialEduSuccess(response.data));  // Correct API call
    } else {
      dispatch(gettingprofileDetialEduFailure("Failed to fetch profile details"));
    }

    return response;
  } catch (e) {
    dispatch(gettingprofileDetialEduFailure("Something went wrong while getting profile details"));
  }
};
export const VisiterProfileDetial = (userId) => async (dispatch) => {
  dispatch(gettingVProfileDetialEdu());
  try {
    const response = await axios.get(`${config.baseUrl}/edf/getedfprofile/${userId}`);

    if (response.status === 200) {
      dispatch(gettingVProfileDetialEduSuccess(response.data));  // Correct API call
    } else {
      dispatch(gettingVProfileDetialEduFailure("Failed to fetch profile details"));
    }

    return response;
  } catch (e) {
    dispatch(gettingVProfileDetialEduFailure("Something went wrong while getting profile details"));
  }
};

export const getProfile = () => async (dispatch) => {
  dispatch(gettingprofile());
  try {
    const response = await axios.get(`${config.baseUrl}/edf/profile`);

    if (response.status === 200) {
      dispatch(gettingprofileSuccess(response.data));  // Correct API call
    } else {
      dispatch(gettingprofileFailure("Failed to fetch profile details"));
    }

    return response;
  } catch (e) {
    dispatch(gettingprofileFailure("Something went wrong while getting profile details"));
  }
};


  export const followUser = (paramValue) => async (dispatch) => {
    dispatch(followingUser());
    try {
      const response = await axios.patch(`${config.baseUrl}/user/follow`, paramValue);

      if (response.status === 200) {
        dispatch(followingUserSuccess(response.data));  // Use response.data
      } else {
        dispatch(followingUserFailure("Failed while following user"));
      }

      return response;
    } catch (e) {
      dispatch(followingUserFailure("Something went wrong while following user"));
    }
  };

  export const unfollowUser = (paramValue) => async (dispatch) => {
    dispatch(unfollowingUser());
    try {
      const response = await axios.patch(`${config.baseUrl}/user/unfollow`, paramValue);

      if (response.status === 200) {
        dispatch(unfollowingUserSuccess(response.data));  // Use response.data
      } else {
        dispatch(unfollowingUserFailure("Failed to unfollowing user "));
      }

      return response;
    } catch (e) {
      dispatch(unfollowingUserFailure("Something went wrong while unfollowing user"));
    }
  };

  export const tempProfile = (edfluencerId) => async (dispatch) => {
    // console.log("param: ", edfluencerId)
    dispatch(tempProfileUser());
    try {
      const response = await axios.get(`${config.baseUrl}/user/tempsignin/${edfluencerId}`);

      if (response.status === 200) {
        dispatch(tempProfileUserSuccess(response.data));  // Use response.data
      } else {
        dispatch(tempProfileUserFailure("Failed to edit user data"));
      }

      return response;
    } catch (e) {
      dispatch(tempProfileUserFailure("Something went wrong while editing user"));
    }
  };

export default slice.reducer;
