import React from 'react'
import Header from '../../views/auth/Header'
import { Divider, Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { Outlet } from 'react-router-dom'
import Footer from '../../views/auth/Footer'

function AuthLayout() {
  return (
  <Layout
  style={{
    backgroundColor: "#fff",
  }}
>
  <Header />
  <Content>
    <Outlet />
  </Content>
  <Divider />
  <Footer />
</Layout>
  )
}

export default AuthLayout