import React, { useEffect, useState } from "react";
import { Card, Grid, Grid2, Typography } from "@mui/material";
import deleteImage from "../../assets/deleteFolder.png";
import vector from "../../assets/Vector small .png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import CardCarousel from "./CardCarousel";
import {
  deleteFlashCardCategory,
  flashCardReducer,
  getCategoryWithCards,
} from "../../redux/flashCards";
import FlashCards from "../flash-cards/FlashCards";

const getCategoryColorMap = (categories) => {
  const colors = ["#1da8c7", "#faac50", "#ff71aa", "#795efe"];
  const colorMap = {};

  categories.forEach((category, index) => {
    colorMap[category.categoryId] = colors[index % colors.length]; // Cycle through colors
  });

  return colorMap;
};

function FolderGrid({ folderName, setFolderNotes, color }) {
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );
  const [userDetail1, setUserDetail1] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );


  const dispatch = useDispatch();

  const handleDeleteFolder = async (categoryId) => {
    let resp = await dispatch(deleteFlashCardCategory(categoryId));
    if (resp.status === 200) {
      toast.success(resp.data.message);
      dispatch(getCategoryWithCards());
    } else {
      toast.error(resp.data.message);
    }
  };

  const handleFolderNotes = () => {
    setFolderNotes(folderName?.cards);
  };

  return (
    <Card style={{ ...cardStyle, position: "relative" }}>
      <Grid2 container size={12} style={{ ...headerStyle }}>
        <Typography style={typographyStyle}>
          {folderName?.categoryTitle?.length > 20
            ? folderName?.categoryTitle?.substring(0, 20) + "..." 
            : folderName?.categoryTitle}{" "}
          
        </Typography>

        {userDetail1 && userDetail1?.userId === folderName?.ownerId ? (
          <img
            src={deleteImage}
            alt="delete"
            style={deleteImageStyle}
            onClick={() => handleDeleteFolder(folderName?.categoryId)}
          />
        ) : null}
      </Grid2>

      {/* Display main card with unique color */}
      <div
        style={{
          ...mainCardStyle,
          backgroundColor: color,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
          position: "relative",
          height:
            userDetail1 && userDetail1?.userId === folderName?.ownerId
              ? "60%"
              : "65%",

          marginTop:
            userDetail1 && userDetail1?.userId === folderName?.ownerId
              ? "0px"
              : "20px",
        }}
        onClick={handleFolderNotes}
      >
        <div
          style={{
            position: "absolute",
            top: "0%",
            left: "0%",
          }}
        >
          <img
            src={vector}
            alt="vector"
            style={{
              width: "200px",
              height: "100px",
            }}
          />
        </div>

        {folderName?.cards?.length > 0 ? (
          <div>
            <Typography
              style={{
                fontSize: "12px",
                color: "#fff",
              }}
            >
              {folderName.cards[0]?.question}
            </Typography>
          </div>
        ) : (
          <Typography
            style={{
              fontSize: "12px",
              color: "#fff",
              fontStyle: "italic",
            }}
          >
            No question added in this card.
          </Typography>
        )}
      </div>

      {/* Additional cards indicator */}
      {folderName?.cards?.length > 1 && (
        <div style={smallCardStyle}>
          <Typography
            variant="body2"
            style={{ textAlign: "center", color: "#fff" }}
          >
            +{folderName.cards?.length - 1}
          </Typography>
        </div>
      )}
    </Card>
  );
}

const cardStyle = {
  minWidth: "236px",
  maxWidth: "236px",
  height: "200px",
  borderRadius: "14px",
  backgroundColor: "#F3F3F3",
};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 10px",
};

const typographyStyle = {
  padding: "6px 0px",
  fontFamily: "Aileron",
  fontSize: "14px",
  fontWeight: 400,
  color: "#3F3F3F",
};

const deleteImageStyle = {
  width: "60px",
  height: "60px",
  cursor: "pointer",
};

const mainCardStyle = {
  padding: "10px",
  borderRadius: "10px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  // height: "60%",
  width: "90%",
  margin: "0 auto",
  overflow: "hidden",
};

const smallCardStyle = {
  position: "absolute",
  bottom: "25px",
  right: "15px",
  width: "34px",
  height: "35px",
  borderRadius: "5px",
  backgroundColor: "rgba(255, 255, 255, 0.3)",
  backdropFilter: "blur(5px)",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1) ",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function CategoryList() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [colorMap, setColorMap] = useState({});
  const [selectedPicnotes, setSelectedPicnotes] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);

  useEffect(() => {
    dispatch(getCategoryWithCards());
  }, [dispatch]);

  const { CategoriesWithCards } = useSelector(flashCardReducer);

  useEffect(() => {
    if (CategoriesWithCards?.listCards) {
      const map = getCategoryColorMap(CategoriesWithCards.listCards);
      setColorMap(map);
    }
  }, [CategoriesWithCards]);

  const handleFolderClick = (picnotes, categoryTitle, category) => {
    setSelectedFolder({ picnotes, categoryTitle, category });
  };

  const handleBackScreen = () => {
    setSelectedFolder(null);
  };

  if (selectedFolder) {
    return (
      <Grid width={"100%"}>
        <CardCarousel
          selectedCard={selectedFolder.picnotes}
          categoryTitle={selectedFolder.categoryTitle}
          handleBackScreen={handleBackScreen}
          category={selectedFolder?.category}
          color={colorMap}
        />
      </Grid>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: isMobile ? "center" : "start",
        gap: "16px",
        alignItems: "center",
      }}
    >
      {CategoriesWithCards?.listCards?.length > 0 ? (
        CategoriesWithCards?.listCards?.map((category, index) => (
          <FolderGrid
            key={category.categoryId}
            folderName={category}
            setFolderNotes={(picnotes) =>
              handleFolderClick(picnotes, category.categoryTitle, { category })
            }
            color={colorMap[category.categoryId]}
          />
        ))
      ) : (
        <Grid container item xs={12}>
          <FlashCards />
        </Grid>
      )}
    </div>
  );
}

export default CategoryList;
