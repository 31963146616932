import { Card, CardContent, Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button } from "antd";
import { authReducer, verifyEmail } from "../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import success from "../assets/success.png"
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';


const Verification = () => {
    const [originalUser, setOriginalUser] = useState(
      JSON.parse(localStorage.getItem("userInfo"))
    );

  //! instances
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  //! SELECTORS
  // const { loading, result, error } = useSelector(authReducer);
  const { loading, status, error } = useSelector((state) => state.auth);

    useEffect(() => {
    if (originalUser) {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
    }
    dispatch(verifyEmail(searchParams.get("token")));
    // eslint-disable-next-line
  }, []);

  // console.log("status", status)
  // useEffect(() => {
  //   dispatch(verifyEmail(searchParams.get("token")));
  //   // eslint-disable-next-line
  // }, []);

  return (
    <Grid2
      container
      size={12}
      justifyContent={"center"}
      height={"86vh"}
      alignItems={"center"}
      style={{
        // backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "calc( 100vh - 50px )",
      }}
    >
      <Grid2 item size={3.2} marginBottom={"50px"}>
        <Card
          style={{
            boxShadow: "10px 10px 30px 0px #6271EB33",
            borderRadius: "24px",
            height: "300px",
          }}
        >
          {loading ? (
            <CardContent>
              {" "}
              <Grid2
                container
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ mt: 5 }}
              >
                {" "}
                <Typography
                  style={{ fontSize: "16px" }}
                  variant="h6"
                  sx={{ mt: 14 }}
                >
                  Verifying...
                </Typography>
              </Grid2>
            </CardContent>
          ) : (
            <CardContent>
              { status === 500 ? (
                <Grid2
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ mt: 5 }}
                >
                  <Grid2 item size={12} paddingBottom={"30px"} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <WarningAmberOutlinedIcon style={{ color: "rgba(188, 23, 29, 1)" }} />
                    <style>
                      {`
                    .MuiSvgIcon-root {
                      font-size: 62px; /* Adjust the size as needed */
                    }
                  `}
                    </style>
                  </Grid2>
                  <Grid2 item size={12}>
                    <Typography
                      align="center"
                      style={{
                        fontSize: "22px",
                        fontWeight: 500,
                        fontFamily: "Aileron",
                      }}
                    >
                      Sorry, invalid user to verify. Try again!
                      {/* {result?.data?.message} */}
                    </Typography>
                  </Grid2>
                  <Grid2 item xs={12}>
                    <Typography align="center" paddingTop={"30px"}>
                      <Link to="../login">
                      <Button
                          style={{
                            borderRadius: '20px',
                            backgroundColor: 'rgba(188, 23, 29, 1)',
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: 300,
                            width: '105px',
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid',
                            padding: 0,
                            textTransform: 'none',
                            marginLeft: '10px',
                          }}>
                          {" "}
                          Go to login
                        </Button>
                      </Link>
                    </Typography>
                  </Grid2>
                </Grid2>
              ) : status && status  === 400 ?  (
                <Grid2
                  container
                  display={'flex'}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ mt: 5 }}
                >
                  <Grid2 item paddingBottom={"30px"}>
                    <WarningAmberOutlinedIcon style={{ color: "f4b700" }} />
                    <style>
                      {`
                    .MuiSvgIcon-root {
                      font-size: 62px; /* Adjust the size as needed */
                    }
                  `}
                    </style>
                  </Grid2>
                  <Grid2 item xs={12}>
                    <Typography
                      align="center"
                      style={{
                        fontSize: "30px",
                        fontWeight: 500,
                        fontFamily: "Aileron",
                      }}
                    >
                      Already Verified!
                    </Typography>
                  </Grid2>
                  <Grid2 item xs={12}>
                    <Typography align="center" paddingTop={"30px"}>
                      <Link to="../login">
                      <Button
                    style={{
                      borderRadius: '20px',
                      backgroundColor: 'rgba(188, 23, 29, 1)',
                      color: '#fff',
                      fontSize: '14px',
                      fontWeight: 300,
                      width: '105px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid',
                      padding: 0,
                      textTransform: 'none',
                      marginLeft: '10px',
                    }}>
                          {" "}
                          Go to login
                        </Button>
                      </Link>
                    </Typography>
                  </Grid2>
                </Grid2>
              ) : (
                status === 200 && (
                  <Grid2
                    container
                    display={'flex'}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ mt: 5 }}
                  >
                    <Grid2 item>
                      {/* <img
                        src={success}
                        alt="Success logo"
                        className="element-to-animate"
                        width={'84px'}
                        height={'84px'}
                      /> */}

                      <CheckCircleOutlinedIcon style={{
                        color: '#28bf40',
                      width: '100px',
                      height: "100px",
                      backgroundColor: "#ddfbe1",
                      borderRadius: '50px',
                      }}/>
                    </Grid2>
                    <Grid2 item size={12} style={{}}>
                      <Typography
                        align="center"
                        style={{
                          fontSize: "30px",
                          fontWeight: 500,
                          fontFamily: "Aileron",
                        }}
                      >
                        Email Verified!
                      </Typography>
                    </Grid2>
                    <Grid2 item xs={12}>
                      <Typography align="center" paddingTop={"20px"}>
                        <Link to="../login">
                        <Button
                            style={{
                              borderRadius: '20px',
                              backgroundColor: 'rgba(188, 23, 29, 1)',
                              color: '#fff',
                              fontSize: '14px',
                              fontWeight: 300,
                              width: '105px',
                              height: '40px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: '1px solid',
                              padding: 0,
                              textTransform: 'none',
                              marginLeft: '10px',
                            }}>
                          {" "}
                          Go to login
                        </Button>
                      </Link>
                      </Typography>
                    </Grid2>
                  </Grid2>
                )
              )}
            </CardContent>
          )}
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default Verification;
