import React, { useEffect, useState } from "react";
import { Card, Grid2, Typography } from "@mui/material";
import deleteImage from "../assets/deleteFolder.png";
import { useDispatch, useSelector } from "react-redux";
import { deleteFolder, getPicnotesWithFolder } from "../redux/folders";
import toast from "react-hot-toast";
import MasonryCom from "./MasonryCom";
import NoData from "./NoData";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";



function MyFolders({ folderName, setFolderNotes }) {
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));



  const handleDeleteFolder = async (id) => {
    let resp = await dispatch(deleteFolder(id));
    if (resp.status === 200) {
      toast.success(resp.data.message);
      dispatch(getPicnotesWithFolder());
    } else {
      toast.error(resp.data.message);
    }
  };


  const handleFolderNotes = () => {
    if (folderName?.picnotes?.length > 0) {
      setFolderNotes(folderName?.picnotes, folderName?.title); 
    }
  };

  return (
    <Card
      style={{ ...cardStyle, cursor: "pointer",  marginLeft: isMobile ? "10px" : "0px" }}

      onClick={handleFolderNotes}
    >
      <Grid2 container size={12} style={{ ...headerStyle }}>
        <Typography style={typographyStyle}>{folderName?.title}</Typography>
        {userDetail?.userId === folderName?.ownerId ? (
          <img
            src={deleteImage}
            alt="delete"
            style={deleteImageStyle}
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering the folder click
              handleDeleteFolder({ folderId: folderName?.folderId });
            }}
          />
        ) : null}
      </Grid2>

      <Grid2 container spacing={2} style={{ padding: "0px 10px" }}>
        {folderName?.picnotes?.slice(0, 7).map((item, index) => (
          <Grid2
            item
            size={4}
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {index < 5 ? (
              <img
                src={item?.notePicture}
                alt="picnote pic"
                style={{
                  width: "64px",
                  height: "58px",
                  borderRadius: "5px",
                }}
              />
            ) : (
              <>
                <img
                  src={item?.notePicture}
                  alt="picnote pic"
                  style={{
                    width: "64px",
                    height: "58px",
                    borderRadius: "10px",
                  }}
                />
                {folderName?.picnotes?.length > 6 && (
                  <div
                    style={{
                      position: "absolute",
                      width: "64px",
                      height: "58px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      zIndex: 1,
                    }}
                  >
                    +{folderName?.picnotes?.length - 6}
                  </div>
                )}
              </>
            )}
          </Grid2>
        ))}
      </Grid2>
    </Card>
  );
}

const cardStyle = {
  width: "236px",
  height: "200px",
  minWidth: "236px",
  minHeight: "200px",
  position: "relative",
  borderRadius: "14px",
  boxSizing: "border-box",
  backgroundColor: "#F3F3F3",

};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const typographyStyle = {
  padding: "16px",
  fontFamily: "Aileron",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "21.6px",
  textAlign: "left",
  color: "#3F3F3F",
  paddingLeft: "10px",
};

const deleteImageStyle = {
  width: "60px",
  height: "60px",
  cursor: "pointer",
};

function FolderGrid() {
  const dispatch = useDispatch();
  const [selectedPicnotes, setSelectedPicnotes] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setLoading(true)
    dispatch(getPicnotesWithFolder()).finally(()=> setLoading(false))
  }, [dispatch]);

  const { picnotesWFolder } = useSelector((state) => state.folders);

  // console.log("selectedPicnotes", selectedPicnotes);

 

  const handleFolderClick = (picnotes, title) => {
    setSelectedPicnotes({ picnotes, title });
  };

  const handleBackScreen = () => {
    setSelectedPicnotes(null);
  };

  if (selectedPicnotes) {
    return (
      <MasonryCom
        picnotes={selectedPicnotes.picnotes}
        column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
        cardsWidth={"1200px"}
        onBackClick={handleBackScreen}
        TabName={"myFolders"}
        folderTitle={selectedPicnotes.title} 
      />
    );
  }

  return (
    // <div style={{ display: "flex", justifyContent: isMobile ? "end" : "start", gap: "16px",
    //   flexDirection: isMobile ? "column" : "row",
    //  }}>
    <Grid2
    container
    spacing={2}
    sx={{
      padding: "16px",
      justifyContent: "flex-start",
    }}
  >


    {loading ? (
      "Laoding data..."
    ) : picnotesWFolder?.folderList?.length > 0 ? (
      picnotesWFolder.folderList.map((folder) => (
        <MyFolders
          key={folder.folderId}
          folderName={folder}
          setFolderNotes={(picnotes) =>
            handleFolderClick(picnotes, folder.title)
          }
        />
      ))
    ) : (
      <NoData TabTitle={"myFolder"} />
    )}
  {/* </div> */}
  </Grid2>
  
  );
}

export default FolderGrid;
