import { Card, CardContent, Grid, Grid2, Typography } from "@mui/material";
import React from "react";

import backgroundImage from "../../assets/collage.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styleCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function UserGuide() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      style={{
        padding: "1rem", // or use a responsive unit
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // Ensures the background covers the whole area
        backgroundPosition: "center", // Centers the background image
        height: "calc(100vh - 86px)", // Fits the remaining viewport height
        
      }}
      justifyContent="center"
      alignItems="center" // Center vertically
    >
       <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    xs={isMobile ? 12 : 10} 
                    style={{
                      height: isMobile ? "auto" : "450px",
                    }}
                  >
                    <Card
                      style={{
                        boxShadow: "10px 10px 30px 0px #6271EB33",
                        borderRadius: "24px",
                        width: isMobile ? "100%" : "auto", 
                      }}
                    >
          {" "}
          <CardContent
            style={{
              margin: "20px 30px",
              height: "500px",
              overflow: "auto",
            }}
          >
            <Typography
              variant="h2"
              style={{
                color: "#213641",
                fontSize: "30px",
                fontWeight: 400,
                paddingBottom: "20px",
              }}
            >
              Guidelines
            </Typography>
            <Typography
              style={{
                color: "#666666",
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Lexend",
                paddingBottom: "10px",
              }}
            ></Typography>
            <Grid2 container spacing={2}>
              <Grid2 item size={12} style={{ ...styleCenter }}>
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "Aileron",
                    color: "#bc171d",
                    fontWeight: "bold",
                  }}
                >
                  Picnotes For Everyone
                </Typography>
              </Grid2>

              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  I’m an educator - How can Picnotes help me?
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                    color: "#6c6c6c",
                  }}
                >
                  Picnotes is a game-changer for educators. It empowers your
                  students to become active participants in their learning
                  journey. By providing an intuitive platform, Picnotes makes it
                  effortless for students to create, share, and discover
                  knowledge. <br />
                  <br /> With Picnotes, you can gain valuable insights into your
                  students' understanding. Their Picnotes offer a unique window
                  into their thought processes, allowing you to assess their
                  comprehension in a dynamic and engaging way. Beyond
                  traditional assessments, Picnotes encourage critical thinking,
                  creativity, and communication skills.
                  <br />
                  <br /> Picnotes also fosters a vibrant learning community.
                  Students can connect with peers, share their knowledge, and
                  build confidence as they see their work displayed prominently
                  on their profiles.
                </Typography>
              </Grid2>

              <br />
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  I’m a student - How can Picnotes help me?
                </Typography>
              </Grid2>

              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                    color: "#6c6c6c",
                  }}
                >
                  Picnotes is your all-in-one study companion. We’ve created
                  tools to help you learn smarter, not harder. Whether you’re a
                  visual learner, love flashcards, or need to stay organized,
                  Picnotes has you covered.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                    color: "#6c6c6c",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      fontFamily: "Aileron",
                      // textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    Picnote
                  </span>{" "}
                  are a fun and effective way to summarize your learning.
                  Combine images with concise notes to create study guides that
                  stick. Plus, you can link to articles, videos, or books for
                  deeper understanding.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                    color: "#6c6c6c",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      fontFamily: "Aileron",
                      // textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    Flashcards
                  </span>{" "}
                  are perfect for memorization. Create your own flashcards to
                  test your knowledge and watch your grades improve.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                    color: "#6c6c6c",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      fontFamily: "Aileron",
                      // textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    EdTracker
                  </span>{" "}
                  helps you stay on top of your studies. Plan assignments, track
                  your progress, and celebrate your achievements. It’s like
                  having a personal study planner right at your fingertips.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                    color: "#6c6c6c",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      fontFamily: "Aileron",
                      // textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    EdTools
                  </span>{" "}
                  keeps your learning materials organized in one place. No more
                  hunting down or searching for online resources. With EdTools,
                  everything you need is just a click away.
                </Typography>
                <br />

                <br />
              </Grid2>

              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  I’m a lifelong learner - How can Picnotes help me?
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                    color: "#6c6c6c",
                  }}
                >
                  Picnotes is designed to support lifelong learners in their
                  pursuit of knowledge. Create a personalized collection of
                  Picnotes on subjects that interest you, from hobbies to
                  professional development. Share your insights with a community
                  of like-minded individuals, sparking discussions and fostering
                  collaboration.
                  <br />
                  <br />
                  By curating your knowledge into easily digestible Picnotes,
                  you not only solidify your understanding but also become a
                  valuable resource for others. Build your personal brand as a
                  subject matter expert while connecting with a global network
                  of learners.
                  <br />
                  <br />
                </Typography>
              </Grid2>

              {/* Now how it work */}
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                    color: "#bc171d",
                  }}
                >
                  How Does It Work?
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Aileron",
                  }}
                >
                  <Typography
                    style={{
                      color: "blue",
                    }}
                  >
                    How Do I Create Picnotes?
                  </Typography>
                  <Typography
                    style={{
                      color: "#6c6c6c",
                    }}
                  >
                    Picnotes are a quick and easy way to share your knowledge.
                    Here's how to create one:
                  </Typography>
                  <br />

                  <Typography>
                    <span>
                      <strong>1.</strong>{" "}
                      <strong style={{ color: "#bc171d" }}>Log in</strong> to
                      your Picnotes account.
                    </span>
                    <br />
                    <span>
                      <strong>2.</strong> Go to{" "}
                      <strong style={{ color: "#bc171d" }}>
                        your profile page
                      </strong>{" "}
                      and click on{" "}
                      <strong style={{ color: "#bc171d" }}>My Picnotes</strong>.
                    </span>
                    <br />
                    <span>
                      <strong>3.</strong> Click the{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Create Picnotes
                      </strong>{" "}
                      button to get started.
                    </span>
                    <br />
                    <span>
                      <strong>4.</strong>{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Choose a topic
                      </strong>{" "}
                      you're passionate about.
                    </span>
                    <br />
                    <span>
                      <strong>5.</strong>{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Upload an image
                      </strong>{" "}
                      that relates to your topic.
                    </span>
                    <br />
                    <span>
                      <strong>6.</strong>{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Write your notes
                      </strong>{" "}
                      to explain the image.
                    </span>
                    <br />
                    <span>
                      <strong>7.</strong> Click{" "}
                      <strong style={{ color: "#bc171d" }}>Submit</strong> to
                      share your Picnote with others!
                    </span>
                  </Typography>
                  <br />
                  <br />
                  <Typography
                    style={{
                      color: "blue",
                    }}
                  >
                    How Do I Create Flashcards?
                  </Typography>
                  <br />
                  <Typography>
                    <span>
                      <strong style={{ color: "black" }}>1.</strong>{" "}
                      <strong style={{ color: "#bc171d" }}>Log in</strong> to
                      your Picnotes account.
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>2.</strong> Go to{" "}
                      <strong style={{ color: "#bc171d" }}>
                        your profile page
                      </strong>{" "}
                      and click on{" "}
                      <strong style={{ color: "#bc171d" }}>
                        My Flashcards
                      </strong>
                      .
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>3.</strong> Click on{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Create Flashcards
                      </strong>{" "}
                      to start a new set.
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>4.</strong> Give your
                      new flashcard set a title so you can find it easily.
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>5.</strong> Click{" "}
                      <strong style={{ color: "#bc171d" }}>
                        + Add New Flashcard
                      </strong>{" "}
                      to create your first card.
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>6.</strong> Type your{" "}
                      <strong style={{ color: "#bc171d" }}>
                        question or note
                      </strong>{" "}
                      on the front of the card.
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>7.</strong> Type the{" "}
                      <strong style={{ color: "#bc171d" }}>answer</strong> on
                      the back of the card.
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>8.</strong> Click{" "}
                      <strong style={{ color: "#bc171d" }}>Save</strong> to keep
                      your flashcard.
                    </span>
                    <br />
                    <br />
                    <span>
                      <strong style={{ color: "#bc171d" }}>
                        Repeat steps 5-8
                      </strong>{" "}
                      to add more flashcards to your set.
                    </span>
                  </Typography>

                  <br />
                  <br />
                  <Typography
                    style={{
                      color: "blue",
                    }}
                  >
                    How Do I Create Folders?
                  </Typography>
                  <br />
                  <Typography>
                    <span>
                      <strong style={{ color: "black" }}>1.</strong>{" "}
                      <strong style={{ color: "#bc171d" }}>Log in</strong> to
                      your Picnotes account.
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>2.</strong> Go to{" "}
                      <strong style={{ color: "#bc171d" }}>
                        your profile page
                      </strong>{" "}
                      and click on{" "}
                      <strong style={{ color: "#bc171d" }}>My Folders</strong>.
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>3.</strong> Click on{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Create New Folder
                      </strong>
                      .
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>4.</strong>{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Give your folder a name
                      </strong>{" "}
                      and click{" "}
                      <strong style={{ color: "#bc171d" }}>Save</strong>.
                    </span>
                  </Typography>

                  <br />
                  <br />
                  <Typography
                    style={{
                      color: "blue",
                    }}
                  >
                    How Do I Use EdTracker?
                  </Typography>
                  <Typography
                    style={{
                      color: "#6c6c6c",
                    }}
                  >
                    The EdTracker helps you keep track of what you're learning.
                    You can add items to different stages of your learning
                    journey.
                  </Typography>
                  <br />
                  <span>To add something to your EdTracker:</span>

                  <Typography>
                    <span>
                      <strong style={{ color: "black" }}>1.</strong> Go to your
                      profile and click{" "}
                      <strong style={{ color: "#bc171d" }}>
                        "My EdTracker"
                      </strong>
                      .
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>2.</strong> Choose a
                      category:{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Want to learn
                      </strong>
                      ,{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Currently Learning
                      </strong>
                      , or <strong style={{ color: "#bc171d" }}>Learned</strong>
                      .
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>3.</strong> Click{" "}
                      <strong style={{ color: "#bc171d" }}>+</strong> to add a
                      topic.
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>4.</strong> Add details
                      like description, link, and hashtags (optional).
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>5.</strong> Click{" "}
                      <strong style={{ color: "#bc171d" }}>Submit</strong>.
                    </span>
                  </Typography>
                  <br />
                  <span>Move items between categories as you learn.</span>

                  <br />
                  <br />
                  <Typography
                    style={{
                      color: "blue",
                    }}
                  >
                    How Do I use the EdTools?
                  </Typography>
                  <Typography
                    style={{
                      color: "#6c6c6c",
                    }}
                  >
                    Keep all your helpful learning materials in one place.
                  </Typography>
                  <br />
                  <Typography>
                    <span>
                      <strong style={{ color: "black" }}>1.</strong> Go to your
                      profile and click{" "}
                      <strong style={{ color: "#bc171d" }}>
                        "My EdTracker"
                      </strong>
                      .
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>2.</strong> Choose a
                      category:{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Want to learn
                      </strong>
                      ,{" "}
                      <strong style={{ color: "#bc171d" }}>
                        Currently Learning
                      </strong>
                      , or <strong style={{ color: "#bc171d" }}>Learned</strong>
                      .
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>3.</strong> Click{" "}
                      <strong style={{ color: "#bc171d" }}>+</strong> to add a
                      topic.
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>4.</strong> Add details
                      like description, link, and hashtags (optional).
                    </span>
                    <br />
                    <span>
                      <strong style={{ color: "black" }}>5.</strong> Click{" "}
                      <strong style={{ color: "#bc171d" }}>Submit</strong>.
                    </span>
                  </Typography>
                  <br />

                  <span>
                    Now you can easily find your resources whenever you need
                    them.
                  </span>
                </Typography>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default UserGuide;
