import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { Divider } from "antd";
import React, { useState } from "react";
import David from "../../assets/Blog/David Crowe.jpeg";
import Hussein from "../../assets/Blog/Ahmed Hussein.jpeg";
import Dexter from "../../assets/Blog/Dexter Chu.jpeg";
import Naomi from "../../assets/Blog/Naomi Harm.jpeg";
import BlogDetail from "./BlogDetail";

function Blogs() {
  const [blogDetail, setBlogDetail] = useState(null);

  const cardData = [
    {
      id: 1,
      image: Dexter,
      title: "Dexter Chu",
      description: "Spontaneity In Learning",
      subTitle: "lifelong Learner",
      x: "",
      website:
        "https://www.meetup.com/login/?returnUri=https://www.meetup.com/Vancouver-Knowledge-Sharing-Club/members/180221552/",
    },
    {
      id: 2,
      image: Naomi,
      title: "Naomi Harm",
      description:
        "Implementing Learning Experiences And Understanding Our Children's Educational Needs ",
      subTitle: "Educational Leader",
      x: "https://x.com/naomiharm",
      website: "https://www.innovativeeducator.org/",
    },
    {
      id: 3,
      image: Hussein,
      title: "Ahmed Hussein",
      description: "A Great Scientist Shares His Passion For Physics",
      subTitle: "Educational Leader",
      x: "",
      website: "https://www.unbc.ca/people/hussein-dr-ahmed",
      linkedIn: "https://www.linkedin.com/in/ahmed-hussein-73a8975/",
    },
    {
      id: 4,
      image: David,
      title: "David Crowe",
      description: "David Crowe On Cultivating A Constant Desire For Knowledge",
      subTitle: "lifelong Learner",
      x: "",
      website: "",
      linkedIn: "https://www.linkedin.com/in/david-crowe-2070551/",
    },
  ];

  const handleBlogDetail = (id) => {
    const selectedCard = cardData.find((card) => card.id === id); // Find the selected card's details
    setBlogDetail(selectedCard); // Store the selected card's details in state
  };

  const handleClose = () => {
    setBlogDetail(null); // Reset to null when closing
  };

  return (
    <Grid container padding={"10px"}>
      {/* Conditionally render BlogDetail with the selected card's details */}
      {blogDetail && (
        <BlogDetail details={blogDetail} handleClose={handleClose} />
      )}
      <Grid item xs={12} mt={"20px"}>
        <Typography
          variant="h4"
          style={{
            textAlign: "center",
            fontFamily: "Aileron",
          }}
        >
          EdFluencers
        </Typography>
      </Grid>
      <Divider maxWidth={"50%"} />
      <Grid item xs={12}>
        <Typography
          style={{
            textAlign: "center",
            fontFamily: "Aileron",
          }}
        >
          Conversations with thoughtful educational leaders and passionate
          lifelong learners.
        </Typography>
      </Grid>

      <Grid
        container
        spacing={2} // Add spacing between cards
        justifyContent="center"
        mt={2}
      >
        {cardData.map((card) => (
          <Grid
            item
            key={card.id}
            xs={12} // Full width on extra-small screens
            sm={6} // Two cards per row on small screens
            md={4} // Three cards per row on medium screens
            lg={3} // Four cards per row on large screens
          >
            <Card
              sx={{
                borderRadius: "20px",
                boxShadow: 3,
                cursor: "pointer",
                maxWidth: "350px",
                mx: "auto", // Center the card horizontally
                minHeight: "400px",
              }}
              onClick={() => handleBlogDetail(card.id)}
            >
              <CardMedia
                component="img"
                sx={{
                  height: 200,
                  objectFit: "contain",
                }}
                image={card.image}
                alt={card.title}
              />
              <CardContent>
                <Typography fontSize="20px" fontWeight={600}>
                  {card.title}
                </Typography>
                <Typography
                  color="text.secondary"
                  fontSize={"14px"}
                  marginTop={"-5px"}
                >
                  {card.subTitle}
                </Typography>
                <Typography
                  fontSize="16px"
                  textAlign="justify"
                  paddingTop={"10px"}
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default Blogs;
