import { Navigate } from "react-router-dom";
import ContactUs from "../views/auth/ContactUs";
import AuthLayout from "../layout/AuthLayout";
import SignUp from "../views/auth/SignUp";
import SignIn from "../views/auth/SignIn";
import Pages from "./../components/main-page/Pages";
import Verification from "../components/Verification";
import SchoolSignup from "../views/auth/SchoolSignup";
import AboutUs from "../views/auth/AboutUs";
import LandingPage from "../views/auth/Dashboard";
import MainPageForSchool from "../views/auth/MainPageForSchool";
import Followers from "../components/Followers";
import TermsCondition from "../views/TermsCondition";
import PrivacyPolicy from "../views/PrivacyPolicy";
import LifeLongFollowers from "../components/LifeLongFollowers";
import Trending from "../components/Trending";
import Searching from "../components/Searching";
import Blogs from "../views/auth/Blogs";
import Explore from "../components/Explore";
import UserGuide from "../views/auth/UserGuide";
import ResetPassword from "../views/auth/ResetPassword";
import AuthCallback from "../components/AuthCallBack";

// let userInfo = localStorage.getItem("userInfo");
// userInfo = JSON.parse(userInfo);

// const firstLoginValue = userInfo?.isFTL;

// Helper function to update localStorage
// const updateFirstLoginStatus = () => {
//   let userInfo = JSON.parse(localStorage.getItem("userInfo"));
//   if (userInfo && userInfo.isFTL) {
//     userInfo.isFTL = false;
//     localStorage.setItem("userInfo", JSON.stringify(userInfo));
//     window.location.reload();
//   }
// };

export const allRoutes = [
  {
    path: "/app",
    route: {
      index: true,
      path: "pages",
      element:
      //  firstLoginValue ? (
      //   <LifeLongFollowers
      //     onClose={() => {
      //       updateFirstLoginStatus();
      //     }}
      //   />
      // ) : (
        <Pages />
      // ),
    },
  },

  {
    path: "app/aboutus",
    route: { index: true, path: "aboutus", element: <AboutUs /> },
  },
  {
    path: "app/followers",
    route: { index: true, path: "followers", element: <Followers /> },
  },
  {
    path: "app/trending",
    route: { index: true, path: "trending", element: <Trending /> },
  },
  {
    path: "app/explore",
    route: { index: true, path: "explore", element: <Explore /> },
  },
  {
    path: "app/search",
    route: { index: true, path: "search", element: <Searching /> },
  },
  {
    path: "app/contactus",
    route: { index: true, path: "contactus", element: <ContactUs /> },
  },
  {
    path: "app/termsandconditions",
    route: {
      index: true,
      path: "termsandconditions",
      element: <TermsCondition />,
    },
  },
  {
    path: "app/privacyandpolicy",
    route: {
      index: true,
      path: "privacyandpolicy",
      element: <PrivacyPolicy />,
    },
  },
  {
    path: "app/userGuide",
    route: { index: true, path: "userGuide", element: <UserGuide /> },
  },
  {
    path: "app/blogs",
    route: { index: true, path: "blogs", element: <Blogs /> },
  },
];

export const authRoutes = [
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
       path:"callback",
       element: <AuthCallback />
      },
      {
        path: "landingPage",
        element: <LandingPage />,
      },
     {
      path:"callback",
      element: <AuthCallback />
     },
     {
      path:"callback",
      element: <AuthCallback />
     },
     {
      path:"callback",
      element: <AuthCallback />
     },
     {
      path:"callback",
      element: <AuthCallback />
     },
      {
        path: "pages",
        element: <Pages />,
      },
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
      {
        path: "schoolsignup",
        element: <SchoolSignup />,
      },
      {
        path: "schoolmainpage",
        element: <MainPageForSchool />,
      },
      {
        path: "contactus",
        element: <ContactUs />,
      },
      {
        path: "aboutus",
        element: <AboutUs />,
      },
      {
        path: "verified",
        element: <Verification />,
      },
      {
        path: "termsandconditions",
        element: <TermsCondition />,
      },
      {
        path: "privacyandpolicy",
        element: <PrivacyPolicy />,
      },
      {
        path: "userGuide",
        element: <UserGuide />,
      },
      {
        path: "trending",
        element: <Trending />,
      },
      {
        path: "explore",
        element: <Explore />,
      },
      {
        path: "search",
        element: <Searching />,
      },
      {
        path: "blogs",
        element: <Blogs />,
      },
      {
        path: "forgot",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/*",
    element: <Navigate to="/auth/landingPage" />,
  },
  {
    path: "*",
    element: <h1>404 :</h1>,
  },
];
