import React, { useEffect, useState } from "react";
import { getTrendeing, getVTrendeing } from "../redux/picnote";
import { useDispatch, useSelector } from "react-redux";
import { Grid2, TextField, Typography } from "@mui/material";
import ViewPicnote from "./ViewPicnote";
import MasonryCom from "./MasonryCom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Trending() {
  const [addResource, setAddResource] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedTag, setSelectedTag] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  let userInfo = localStorage.getItem("userInfo");
  userInfo = JSON.parse(userInfo);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { TrendingNotesList, vTrendingNotesList, TrendingLoader } = useSelector(
    (state) => state.picnote
  );

  const notesTags = userInfo ? TrendingNotesList : vTrendingNotesList;

  const tags = notesTags?.lookingData
    ? [
        "All",
        ...new Set(
          notesTags?.lookingData?.flatMap((note) =>
            note?.tags?.map((tag) => tag?.tagTitle)
          )
        ),
      ]
    : ["All"];

  useEffect(() => {
    if (userInfo) {
      dispatch(getTrendeing());
    } else {
      dispatch(getVTrendeing());
    }
  }, [dispatch]);

  // This is because of auto load if i remove this then just initall 20 notes will loads.
    useEffect(() => {
      dispatch(userInfo ? getTrendeing() : getVTrendeing());
    }, [dispatch]);
  

  const closeAddSource = () => {
    setAddResource(false);
    setSelectedNote(null);
  };

  const notesList = userInfo ? TrendingNotesList : vTrendingNotesList;

  // Filter notes based on the selected tag
  const filteredNotes = notesList?.lookingData?.filter(
    (note) =>
      selectedTag === "All" ||
      note?.tags.some((tag) => tag?.tagTitle === selectedTag)
  );

  // console.log("filteredNotes", filteredNotes)

  // Filter tags based on search query
  const filteredTags = searchQuery
    ? tags.filter((tag) =>
        tag.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : tags.slice(0, 15);

  return (
    <>
      {selectedNote ? (
        <ViewPicnote
          closeAddSource={closeAddSource}
          picnoteCardsdata={selectedNote}
        />
      ) : null}

      <Grid2
        container
        spacing={2}
        mb={"80px"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* Title Grid */}
        <Grid2 item xs={12} style={{ textAlign: "center" }}>
          <Typography
            style={{
              fontFamily: "cako regular",
              fontWeight: 400,
              fontSize: "36px",
            }}
          >
            Trending 🔥
          </Typography>
        </Grid2>

        {/* Tags Grid */}
        <Grid2 item xs={12} style={{ textAlign: "center" }}>
          <div style={{ marginTop: "10px" }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search tags..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                width: "100%",
                maxWidth: "300px",
                marginBottom: "20px",
              }}
              InputProps={{
                style: {
                  borderRadius: "25px",
                },
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              paddingBottom: isMobile ? "10px" : "30px",
              margin: isMobile ? "0px 30px" : null,
            }}
          >
            {filteredTags?.map((item, index) => (
              <Typography
                key={index}
                onClick={() => setSelectedTag(item)}
                style={{
                  borderRadius: "25px",
                  border: "1px solid",
                  borderColor: selectedTag === item ? "#BC171D" : "gray",
                  padding: "5px 10px",
                  fontSize: "14px",
                  color: selectedTag === item ? "#BC171D" : "inherit",
                  cursor: "pointer",
                  fontWeight: 400,
                  transition: "all 0.3s ease",
                }}
              >
                {item}
              </Typography>
            ))}
           
          </div>
        </Grid2>
        {TrendingLoader ? (
          "Loading picnotes..."
        ) : (
          <MasonryCom
            picnotes={filteredNotes}
            column={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 5 }}
            cardsWidth={"90%"}
            TabName={"Trending"}
          />
        )}
      </Grid2>
    </>
  );
}

export default Trending;
