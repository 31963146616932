import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  IconButton,
  Grid2,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import person from "./../assets/personR.png";
import arrow from "./../assets/arrow-down-01-round.png";
import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Formik, useFormik } from "formik";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { uploadTheFile } from "../redux/media";
import { editUser, profileDetial } from "../redux/user";
import { GetCountries } from "react-country-state-city";

const headerStyle = {
  fontFamily: "Cako regular",
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "31.21px",
  textAlign: "center",
  color: "rgba(63, 63, 63, 1)",
};

const labelStyle = {
  marginBottom: "5px",
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "31.21px",
};

const inputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "38px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
  position: "relative",
};

const inputContainerStyle = {
  marginBottom: "5px",
};

function EditProfile({ onClose, userDetails }) {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [stateid, setStateid] = useState("");
  const [cityid, setCityid] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [imageError, setImageError] = useState("");
  const [uploadedImage, setUploadedImage] = useState(
    userDetails.userPicture || null
  );
  const [userData2, setUserData2] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );

  // console.log("userInfow:", userDetails);
  //! INSTANCES
  let dispatch = useDispatch();

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Check for file size (max 2MB)
    if (file && file.size <= 2 * 1024 * 1024) {
      setSelectedFile(file); // Store raw file for upload
      setUploadedImage(URL.createObjectURL(file)); // Update the preview image
      setImageError("");
    } else {
      setSelectedFile(null);
      setUploadedImage(userDetails.userPicture); // Revert to original picture
      setImageError("File size exceeds 2MB. Please upload a smaller image.");
    }
  };
  const handleIconClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  useEffect(() => {
    GetCountries()
      .then((result) => {
        setCountriesList(result); // Correct variable name
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  // Setting up Formik for form handling
  const formik = useFormik({
    initialValues: {
      edfId: userData2?.userId,
      firstName: userDetails?.firstName || "",
      lastName: userDetails?.lastName || "",
      email: userData2?.email || "",
      country: userDetails?.country || "",
      city: userDetails?.city || "",
      userPicture: userDetails?.userPicture || "",
      description: userDetails?.description || "",
    },
    onSubmit: async (values) => {
      try {
        if (selectedFile) {
          let formData = new FormData();
          formData.append("file", selectedFile);

          let respFile = await dispatch(uploadTheFile(formData));

          if (respFile.status === 200) {
            values.userPicture = respFile.data.url;
          } else {
            return toast.error("File upload failed!");
          }
        }

        let resp = await dispatch(editUser(values));

        if (resp.status === 200) {
          toast.success(resp.data.message);
          formik.resetForm();
          dispatch(profileDetial());

          // Update userInfo in localStorage with the new picture
          const updatedUserInfo = { ...userData2, picture: values.userPicture };
          localStorage.setItem("userInfo", JSON.stringify(updatedUserInfo));

          onClose(true); // Close the dialog
        } else {
          toast.error("Error while updating user");
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.");
      }
    },
  });

  return (
    <Dialog
      open={true}
      maxWidth="xs"
      sx={{
        zIndex: 1200,
        "& .MuiDialog-paper": { borderRadius: "10px", padding: "20px" },
      }}
    >
      <Formik
        initialValues={{
          aboutMe: "",
          country: "",
        }}
        onSubmit={(values) => {
          // console.log(values);
        }}
      >
        {({ setFieldValue }) => (
          <form onSubmit={formik.handleSubmit}>
            <Grid2 container alignItems="center" padding={"8px"}>
              <Grid2 item size={12} mb={"10px"}>
                <IconButton
                  aria-label="close"
                  style={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                  onClick={onClose}
                >
                  <CloseIcon style={{ width: "20px", height: "20px" }} />
                </IconButton>
              </Grid2>

              <Grid2 item size={12} textAlign="center">
                <Typography
                  variant="h5"
                  component="div"
                  style={{ ...headerStyle, marginBottom: "20px" }}
                >
                  Edit Profile
                </Typography>
              </Grid2>

              <Grid2 item size={12} textAlign={"center"}>
                <Box position="relative" display="inline-block">
                  <label htmlFor="image-upload">
                    {uploadedImage ? (
                      <img
                        src={uploadedImage}
                        alt="uploaded"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <img
                        src={person}
                        alt="default"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </label>
                  <input
                    id="image-upload"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef} // Attach fileInputRef here
                    onChange={handleImageUpload}
                  />

                  <IconButton
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      backgroundColor: "rgba(248, 227, 228, 1)",
                      padding: "5px",
                      border: "2px solid #fff",
                    }}
                    aria-label="edit"
                    component="span"
                    onClick={handleIconClick}
                  >
                    <CameraAltIcon
                      fontSize="small"
                      style={{ color: "rgba(188, 23, 29, 1)" }}
                    />
                  </IconButton>
                </Box>
              </Grid2>

              <Grid2 container item size={12} spacing={2}>
                <Grid2 item size={6} style={inputContainerStyle}>
                  <label htmlFor="firstName" style={labelStyle}>
                    First Name
                  </label>
                  <Input
                    id="firstName"
                    style={{ ...inputStyle, width: "100%" }}
                    placeholder="First Name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                  />
                </Grid2>
                <Grid2 item size={6} style={inputContainerStyle}>
                  <label htmlFor="lastName" style={labelStyle}>
                    Last Name
                  </label>
                  <Input
                    id="lastName"
                    style={{ ...inputStyle, width: "100%" }}
                    placeholder="Last Name"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid2>
              </Grid2>

              <Grid2 item size={12} style={inputContainerStyle}>
                <label htmlFor="email" style={labelStyle}>
                  Email
                </label>
                <Input
                  id="email"
                  style={{ ...inputStyle, width: "100%" }}
                  placeholder="nawab.ali@growtharbor.com"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled
                />
                {formik.touched.email && formik.errors.email ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.lastName}
                  </div>
                ) : null}
              </Grid2>

              <Grid2 item size={12}>
                <label htmlFor="email" style={labelStyle}>
                  Country
                </label>
                <FormControl fullWidth>
                  <Select
                    name="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    sx={{
                      borderRadius: "25px",
                      height: "38px",
                      "& .MuiSelect-select": {
                        padding: "10px", // Optional: Adjust padding if needed
                      },
                    }}
                  >
                    {countriesList.map((country) => (
                      <MenuItem key={country.isoCode} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2 item size={12} style={inputContainerStyle}>
                  <label htmlFor="City" style={labelStyle}>
                   City
                  </label>
                  <Input
                    id="city"
                    style={{ ...inputStyle, width: "100%" }}
                    placeholder="City"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid2>

              <Grid2 item size={12} style={inputContainerStyle}>
                <label htmlFor="description" style={labelStyle}>
                  Profile message
                </label>
                <TextArea
                  id="description"
                  placeholder="Tell us about yourself"
                  autoSize={{ minRows: 4, maxRows: 5 }}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    border: "1px solid rgba(236, 228, 228, 1)",
                    paddingLeft: "10px",
                    paddingRight: "40px",
                  }}
                />
              </Grid2>

              <Grid2
                item
                size={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    borderRadius: "20px",
                    color: "white",
                    width: "50%",
                    backgroundColor: "rgba(188, 23, 29, 1)",
                    textTransform: "none",
                  }}
                >
                  Update
                </Button>
              </Grid2>
            </Grid2>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default EditProfile;
