import React, { useEffect, useState } from "react";
import "./SignUp.css";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import logo from "./../../assets/Logo.jpg";
import person from "./../../assets/person.png";
import { Link, useNavigate } from "react-router-dom";
import search from "./../../assets/search.png";
import CreateNewPicnote from "../../components/create-picnote/CreateNewPicnote";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFollower } from "../../redux/tags";
import { useFormik } from "formik";
import { searchInput } from "../../redux/media";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = {
  fontFamily: "Aileron",
  fontWeight: 400,
  fontSize: "14px",
  textDecoration: "none",
  color: "rgba(63, 63, 63, 1)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const selectedStyle = {
  ...styles,
  color: "rgba(188, 23, 29, 1)",
};

function Header() {
  const [picnotesOpen, setPicnotesOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selected, setSelected] = useState(""); // State to track the selected heading
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );
  const [originalUser, setOriginalUser] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [profileData, setProfileData] = useState(null);

  //! INSTANCES
  let dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: async (values) => {
      let resp = await dispatch(searchInput(values.search));
      if (resp.status === 200) {
        navigate("./search");
      }
    },
  });

  // Fetch data from the Redux store
  const { profileDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (profileDetails?.profile) {
      setProfileData(profileDetails?.profile);
    }
  }, [profileDetails]);

  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("TempuserInfo");
    localStorage.removeItem("Temptoken");
    localStorage.removeItem("followingList");
    localStorage.removeItem("baseFollowingList");
    setIsLoggedIn(false);
    window.location.reload();
  };

  const handleMyProfile = async () => {
    const token = localStorage.getItem("token");
    const userInfo = localStorage.getItem("userInfo");

    // Set tempToken and tempInfo with the values of token and userInfo
    if (token) {
      localStorage.setItem("Temptoken", token);
    }
    if (userInfo) {
      localStorage.setItem("TempuserInfo", userInfo);
    }

    dispatch(setSelectedFollower());
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePicnotes = () => {
    setPicnotesOpen(true);
  };

  const onClose = () => {
    setPicnotesOpen(false);
  };
  // const toggleMenu = () => {
  //   setMenuVisible(!menuVisible);
  // };

  const handleLinkClick = (link) => {
    if (selected === link) {
      window.location.reload();
    } else {
      setSelected(link);
    }
  };

  const toggleMenu = (e) => {
    e.stopPropagation(); // Prevents click from bubbling up and closing the menu
    setMenuVisible(!menuVisible);
  };
  
  const handleMenuClick = (e) => {
    e.stopPropagation(); // Prevents closing when clicking inside the menu
  };

  
  return (
    <>
      {picnotesOpen && <CreateNewPicnote onClose={onClose} />}
      {menuVisible && (
        <div
          style={{
            position: "absolute",
            top: "50px",
            right: "20px",
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "5px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={handleLogout}
          >
            Logout
          </div>
        </div>
      )}

      <header
        style={{
          position: "sticky",
          top: 15,
          marginTop: "18px",
          backgroundColor: "white",
          zIndex: 1100,
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: {
              xs: "0px 20px",
              md: "0px 20px 0px 40px",
              lg: "0px 40px 0px 60px",
              backgroundColor: "white",
            },
          }}
        >
          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            {isMobile ? (
              <IconButton onClick={toggleMenu}>
                {menuVisible ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs="auto">
                  <Link
                    to="./aboutus"
                    style={selected === "aboutus" ? selectedStyle : styles}
                    onClick={() => handleLinkClick("aboutus")}
                  >
                    About Us
                  </Link>
                </Grid>

                <Grid item xs="auto">
                  <Link
                    to="./explore"
                    style={selected === "Explore" ? selectedStyle : styles}
                    onClick={() => handleLinkClick("Explore")}
                  >
                    Explore
                  </Link>
                </Grid>

                <Grid item xs="auto">
                  <Link
                    to="./trending"
                    style={selected === "trending" ? selectedStyle : styles}
                    onClick={() => handleLinkClick("trending")}
                  >
                    Trending
                  </Link>
                </Grid>
                <Grid item xs="auto">
                  <Link
                    to="./userGuide"
                    style={selected === "userGuide" ? selectedStyle : styles}
                    onClick={() => handleLinkClick("userGuide")}
                  >
                    Guidelines
                  </Link>
                </Grid>
                <Grid item xs="auto">
                  <Link
                    to="./contactus"
                    style={selected === "contactus" ? selectedStyle : styles}
                    onClick={() => handleLinkClick("contactus")}
                  >
                    Help
                  </Link>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            className="header-center"
            sx={{
              display: "flex",
              justifyContent: isMobile ? "center" : "center",
              textAlign: isMobile ? "center" : "center",
              paddingTop: { xs: "10px", sm: "0px" },
              paddingRight: isMobile ? "50px" : isMedium ? "100px" : "30px",
            }}
          >
            <Link to="./dashboard">
              <img
                src={logo}
                alt="Logo"
                style={{ cursor: "pointer", width: "145px", height: "35px" }}
              />
            </Link>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            className="header-right"
            style={{
              justifyContent: isSmall ? "space-between" : "end",
              display: "flex",
              alignItems: "center",
            }}
          >
            {!menuVisible && !isMobile ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "280px",
                  minWidth: "30%",
                  backgroundColor: "rgba(245, 245, 245, 1)",
                  border: "1px solid rgba(236, 228, 228, 1)",
                  borderRadius: "20px",
                  paddingLeft: "10px",
                  overflow: "hidden",
                }}
              >
                <form
                  onSubmit={formik.handleSubmit}
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <input
                    name="search"
                    onChange={formik.handleChange}
                    value={formik.values.search}
                    placeholder="Search here"
                    style={{
                      border: "none",
                      outline: "none",
                      width: "100%",
                      height: "38px",
                      backgroundColor: "transparent",
                    }}
                  />
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "rgba(188, 23, 29, 1)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      border: "none",
                      marginRight: "5px",
                    }}
                  >
                    <img
                      src={search}
                      alt="Search"
                      style={{ height: "16px", width: "16px" }}
                    />
                  </button>
                </form>
              </div>
            ) : null}

            {originalUser && userDetail ? (
              <>
                <Button
                  onClick={handlePicnotes}
                  style={{
                    color: "rgba(188, 23, 29, 1)",
                    minWidth: "130px",
                    height: "38px",
                    border: "1px solid rgba(188, 23, 29, 1)",
                    borderRadius: "25px",
                    fontFamily: "Aileron",
                    fontSize: "13px",
                    fontWeight: 400,
                    margin: "0px 10px",
                    cursor: "pointer",
                    textTransform: "none",
                  }}
                >
                  + Create Picnotes
                </Button>
                <div>
                  {/* {originalUser?.picture?.startsWith("http" || "https") ? ( */}
                  {originalUser?.picture?.endsWith("png") ||
                  originalUser?.picture?.endsWith("jpg") ||
                  originalUser?.picture?.endsWith("jpeg") ? (
                    <img
                      src={originalUser?.picture}
                      alt="Profile"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    />
                  ) : (
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        backgroundColor: "#b10303",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      {`${originalUser?.firstName?.[0]?.toUpperCase() || ""}${
                        originalUser?.lastName?.[0]?.toUpperCase() || ""
                      }`}
                    </div>
                  )}

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <Link to="./dashboard" style={{ textDecoration: "none" }}>
                      <MenuItem
                        onClick={handleMyProfile}
                        sx={{
                          color: "black",
                          "&:hover": {
                            backgroundColor: "lightgray",
                          },
                        }}
                      >
                        Profile
                      </MenuItem>
                    </Link>

                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
              </>
            ) : !isMobile ? (
              <>
                <Link to="./login">
                  <Button
                    style={{
                      color: "rgba(188, 23, 29, 1)",
                      fontSize: "14px",
                      fontWeight: 300,
                      textTransform: "none",
                      marginLeft: "0px",
                    }}
                  >
                    Log in
                  </Button>
                </Link>

                <Link to="./signup">
                  <Button
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "rgba(188, 23, 29, 1)",
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: 300,
                      width: "105px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid",
                      padding: 0,
                      textTransform: "none",
                      marginLeft: "10px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={person}
                        alt="person"
                        style={{
                          color: "white",
                          width: "16px",
                          height: "16px",
                        }}
                      />
                      &nbsp; Sign up
                    </div>
                  </Button>
                </Link>
              </>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  width: "100%",
                }}
              >
                <input
                  name="search"
                  onChange={formik.handleChange}
                  value={formik.values.search}
                  placeholder="Search here"
                  style={{
                    width: "100%",
                    flex: 1,
                    borderRadius: "20px",
                    height: "38px",
                    backgroundColor: "rgba(245, 245, 245, 1)",
                    border: "1px solid rgba(236, 228, 228, 1)",
                    paddingLeft: "10px",
                    paddingRight: "40px",
                  }}
                />
                <button
                  type="submit"
                  style={{
                    position: "absolute",
                    right: "5px",
                    marginLeft: "10px",
                    backgroundColor: "rgba(188, 23, 29, 1)",
                    border: "none",
                    borderRadius: "20px",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={search}
                    alt="Search"
                    style={{ height: "16px", width: "16px" }}
                  />
                </button>
              </form>
            )}
          </Grid>
        </Grid>
        {menuVisible && isMobile && (
          <Grid
          onClick={handleMenuClick}
            style={{
              position: "absolute",
              top: "50px",
              left: "0",
              right: "0",
              backgroundColor: "white",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
              padding: "10px 20px",
              gap: "10px",
            }}
          >
            <Grid gap={10} onClick={toggleMenu}>
              {/* Link items */}
              <Link
                to="./aboutus"
                style={{ ...styles, marginBottom: "5px" }}
                onClick={() => handleLinkClick("aboutus")}
              >
                About Us
              </Link>

              <Link
                to="./explore"
                style={{ ...styles, marginBottom: "5px" }}
                onClick={() => handleLinkClick("Explore")}
              >
                Explore
              </Link>
              <Link
                to="./trending"
                style={{ ...styles, marginBottom: "5px" }}
                onClick={() => handleLinkClick("trending")}
              >
                Trending
              </Link>
              <Link
                to="./userGuide"
                style={{ ...styles, marginBottom: "5px" }}
                onClick={() => handleLinkClick("userGuide")}
              >
                Guidelines
              </Link>
              <Link
                to="./contactus"
                style={{ ...styles, marginBottom: "5px" }}
                onClick={() => handleLinkClick("userGuide")}
              >
                Help
              </Link>

              {originalUser ? null : (
                <>
                  <Link
                    to="./login"
                    onClick={() => handleLinkClick("login")}
                    style={{
                      ...styles,
                      color: "rgba(188, 23, 29, 1)",
                    }}
                  >
                    Log in
                  </Link>

                  <Link
                    to="./signup"
                    onClick={() => handleLinkClick("signup")}
                    style={{
                      ...styles,
                      color: "rgba(188, 23, 29, 1)",
                      paddingTop: "5px",
                    }}
                  >
                    Sign up
                  </Link>
                </>
              )}

              {originalUser ? (
                <form
                onClick={handleMenuClick} // Prevent menu from closing when clicking the form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    width: isSmall ? "100%" : "50%",
                  }}
                >
                  <input
                    name="search"
                    onClick={handleMenuClick}
                    onChange={formik.handleChange}
                    value={formik.values.search}
                    placeholder="Search here"
                    style={{
                      width: isSmall ? "100%" : "50%",
                      flex: 1,
                      borderRadius: "20px",
                      height: "38px",
                      backgroundColor: "rgba(245, 245, 245, 1)",
                      border: "1px solid rgba(236, 228, 228, 1)",
                      paddingLeft: "10px",
                      paddingRight: "40px",
                    }}
                  />
                  <button
                    type="submit"
                    style={{
                      position: "absolute",
                      right: "5px",
                      marginLeft: "10px",
                      backgroundColor: "rgba(188, 23, 29, 1)",
                      border: "none",
                      borderRadius: "20px",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={search}
                      alt="Search"
                      style={{ height: "16px", width: "16px" }}
                    />
                  </button>
                </form>
              ) : null}
            </Grid>
          </Grid>
        )}
        <Divider style={{ paddingTop: "10px", backgroundColor: "white" }} />
      </header>
    </>
  );
}

export default Header;
