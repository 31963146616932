import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mediaReducer } from "../redux/media";
import { Card, Typography, Grid, Box } from "@mui/material";
import ViewPicnote from "./ViewPicnote";
import { tempProfile } from "../redux/user";
import { setSelectedFollower } from "../redux/tags";
import { useNavigate } from "react-router-dom";
import heart from "../assets/favourite.png";
import Likedheart from "../assets/redfavourite.png";
import commentImage from "../assets/message-01.png";
import AddResource from "./create-picnote/AddResource";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Searching() {
  const { serachData } = useSelector(mediaReducer);
  const [addResource, setAddResource] = useState(false);
  const [selectedNoteFromMyNote, setSelectedNoteFromMyNote] = useState(null);
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));

  const closeAddSource = () => {
    setAddResource(false);
    setSelectedNoteFromMyNote(null);
  };

  const handlePicnote = (note) => {
    setSelectedNoteFromMyNote(note);
    setAddResource(true);
  };

  const handleProfileClick = async (follower) => {
    dispatch(setSelectedFollower(follower));
    const tempResp = await dispatch(tempProfile(follower?.edfId));
    localStorage.setItem("Temptoken", tempResp.data?.tempToken);
    localStorage.setItem("TempuserInfo", JSON.stringify(tempResp.data.tempInfo));
    navigate("/auth/pages");
  };


  return (
    <div style={{ padding: "20px" }}>
      {addResource ? (
        addResource && !userDetail ? (
          <ViewPicnote
            closeAddSource={closeAddSource}
            selectedNoteFromMyNote={selectedNoteFromMyNote}
          />
        ) : (
          <AddResource
            closeAddSource={closeAddSource}
            selectedNoteFromMyNote={selectedNoteFromMyNote}
          />
        )
      ) : null}
      <Typography
        variant="h5"
        gutterBottom
        style={{ margin: "20px 0px 10px 30px" }}
      >
        Picnotes
      </Typography>
      {serachData?.noteResults?.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            padding: "0px 30px",
            mx: "auto",
            display: "grid",
            gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(5, 1fr)",
            gap: "20px",
            overflow: "hidden",
          }}
        >
          {serachData?.noteResults?.map((note) => (
            <Box
              key={note.noteId}
              sx={{
                width: 240,
                height: 390,
                overflow: "hidden",
                cursor: "pointer",
                position: "relative",
                borderRadius: "10px",
                "&:hover": {
                  // filter: "blur(0.5px)",
                  "& .cardContent": {
                    opacity: 1,
                  },
                },
              }}
              onClick={() => handlePicnote(note)}
            >
              <img
                src={note.notePicture}
                alt={note.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <Box>
                {/* Card Content */}
                <Box
                  className="cardContent"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    background:
                      "linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5))",
                    padding: "15px",
                    borderRadius: "0 0 10px 10px",
                    opacity: 0,
                    transition: "opacity 0.3s ease",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "1rem",
                      fontFamily: "Aileron",
                    }}
                  >
                    {note.title?.length > 25
                      ? `${note.title?.slice(0, 25)}...`
                      : note.title}
                  </Typography>

                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "0.7rem",
                      fontFamily: "Aileron",
                    }}
                  >
                    {`${note.edFluencer?.firstName} ${note.edFluencer?.lastName}`}
                  </Typography>
                </Box>

                <Box
                  className="cardContent"
                  sx={{
                    gap: "10px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    padding: "15px",
                    borderRadius: "0 0 10px 10px",
                    opacity: 0,
                    transition: "opacity 0.3s ease",
                  }}
                >
                  {note?.likedById?.includes(userDetail?.userId) ? (
                    <img
                      src={Likedheart}
                      alt="heart"
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <img
                      src={heart}
                      alt="heart"
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  )}
                  <img
                    src={commentImage}
                    alt="comment"
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      padding: "7px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <span
          style={{
            paddingLeft: "100px",
            color: "gray",
          }}
        >
          No Picnotes
        </span>
      )}

      <Typography
        variant="h5"
        gutterBottom
        style={{ margin: "20px 0px 10px 30px" }}
      >
        Users
      </Typography>

      <Grid container spacing={2} sx={{ justifyContent: "start" }}>
        {serachData?.edfResults?.length > 0 ? (
          serachData.edfResults.map((edf, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                sx={{
                  padding: "20px",
                  borderRadius: "10px",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: "180px",
                  cursor: "pointer",
                }}
                onClick={() => handleProfileClick(edf)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {edf.userPicture?.endsWith(".jpg") ||
                  edf.userPicture?.endsWith(".jpeg") ||
                  edf.userPicture?.endsWith(".png") ? (
                    <img
                      src={edf.userPicture}
                      alt={`${edf.firstName?.[0]} ${edf.lastName?.[0]}`}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        marginBottom: "15px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        backgroundColor: "#b10303",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "18px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        marginBottom: "15px",
                      }}
                    >
                      {edf.firstName?.[0] + edf.lastName?.[0]}
                    </Box>
                  )}
                  <Typography
                    sx={{
                      marginBottom: "8px",
                      fontFamily: "Aileron",
                      fontSize: "13px",
                      fontWeight: 600,
                      textAlign: "center",
                      color: "rgba(63, 63, 63, 1)",
                    }}
                  >
                    {`${edf.firstName} ${edf.lastName}`}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))
        ) : (
          <span
          style={{
            paddingLeft: "115px",
            color: "gray",
          }}
        >
            No users found
          </span>
        )}
      </Grid>
    </div>
  );
}

export default Searching;
