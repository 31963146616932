import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  Grid2,
  Grow,
  IconButton,
  Link,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { Input } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "../style.css";
import CloseIcon from "@mui/icons-material/Close";
import folderAdd from "../../assets/folder-add.png";
import favourite from "../../assets/favouriteCircled.png";
import redfavourite from "../../assets/redfavouriteCircled.png";
import flag from "../../assets/Flag.png";
import deleteImage from "../../assets/delete-02.png";
import editImage from "../../assets/edit-02.png";
import thumbUp from "../../assets/thumbs-up.png";
import thumbDown from "../../assets/thumbs-down.png";
import arrow from "../../assets/arrow-down-01-round.png";
import AddPicnoteFolder from "../AddPicnoteFolder";
import * as yup from "yup";
import FolderList from "../FolderList";
import { useDispatch, useSelector } from "react-redux";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import PhotoRoundedIcon from "@mui/icons-material/PhotoRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import VideocamIcon from "@mui/icons-material/Videocam";
import noImage from "../../assets/no image.jpg";


import {
  addResource,
  allMyPnotes,
  deletePicnote,
  editPicnote,
  favouritePicnote,
  myFeed,
  resetSingleNoteData,
  similarNotes,
  singleFeed,
  updateFlags,
} from "../../redux/picnote";
import { LoadingButton } from "@mui/lab";
import FlagIcon from "@mui/icons-material/Flag";
import toast from "react-hot-toast";
import { getFolder } from "../../redux/folders";
import SaveIcon from "@mui/icons-material/Save";
import { uploadTheFile } from "../../redux/media";
import TextArea from "antd/es/input/TextArea";
import {
  allTags,
  createTag,
  getFollowingList,
  setSelectedFollower,
} from "../../redux/tags";
import MasonryCom from "../MasonryCom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Comments from "../Comments";
import {
  followUser,
  profileDetial,
  tempProfile,
  unfollowUser,
} from "../../redux/user";
import { useNavigate } from "react-router-dom";

const addPicnoteButtonStyle = {
  borderRadius: "20px",
  backgroundColor: "rgba(188, 23, 29, 1)",
  color: "#fff",
  fontSize: "12px",
  fontWeight: 300,
  height: "35px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid",
  padding: "15px",
  textTransform: "none",
};

const styleCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "16.8px",
  textAlign: "center",
  cursor: "pointer",
};

const styleForIcons = {
  fontWeight: 400,
  fontFamily: "Aileron",
  paddingRight: "5px",
  fontSize: "16px",
  color: "#3F3F3F",
};

const thumbStyle = {
  backgroundColor: "#17BC6D33",
  width: "150px",
  height: "42px",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px",
  marginTop: "10px",
  cursor: "pointer",
};

const inputStyle = {
  borderRadius: "25px",
  height: "34px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
};
const edtInputStyle = {
  borderRadius: "25px",
  paddingLeft: "10px",
  height: "35px",
  border: "1px solid rgba(112, 112, 112, 0.5)",
  fontSize: "14px",
  width: "100%",
  fontFamily: "Aileron",
  color: "#707070",
};

const CustomSelectIcon = () => (
  <img
    src={arrow}
    alt="Select icon"
    style={{ width: "20px", height: "20px", marginRight: "10px" }}
  />
);

function AddResource({
  picnoteResponseWhileAddingNote,
  closeAddSource,
  picnoteResponse,
  picnoteCardsdata,
  selectedNoteFromMyNote,
  selectedNoteFormMyF,
  isDialogOpen,
  AllNotesForRelated,
}) {
  const [createNew, setCreateNew] = useState(false);
  const [addOption, setAddOption] = useState("Book");
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );
  const [originalUser, setOriginalUser] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [baseProFollowing, setBaseProFollowing] = useState(
    JSON.parse(localStorage.getItem("baseFollowingList"))
  );
  const { allTagsList, followingList } = useSelector((state) => state.tags);
    const [filteredTags, setFilteredTags] = useState(allTagsList || []);
  
  const { addResourceloader, singleNote, singleFeedLoader, myFeedsData } =
    useSelector((state) => state.picnote);
  const [selectedTag, setSelectedTag] = useState(
    originalUser?.userId === singleNote?.note?.edfOwnerId ? "Add" : "Book"
  );

  const uniqueTags = Array.from(new Set(filteredTags.map(tag => tag.title)))
  .map(title => {
    return filteredTags.find(tag => tag.title === title);
  });

  useEffect(() => {
   setBaseProFollowing(JSON.parse(localStorage.getItem("baseFollowingList")))
  }, [singleNote]); 


  const selectedNoteIndex = AllNotesForRelated?.findIndex(
    (note) => note.noteId === singleNote?.note?.noteId
  );

  const relatedNotes =
    selectedNoteIndex >= 0
      ? AllNotesForRelated?.slice(selectedNoteIndex + 1)
      : AllNotesForRelated;

  // Fetch data from the Redux store
  const { allFolderList } = useSelector((state) => state.folders);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(singleNote?.note?.title);
  const [description, setDescription] = useState(singleNote?.note?.description);
  const [trackerTags, setTrackerTags] = useState([]);

  useEffect(() => {
    if (!singleNote?.note?.notePicture) {
      setPictureUrl(null);
    } else {
      setPictureUrl(singleNote?.note?.notePicture);
    }
  }, [singleNote]); // Update when singleNote changes

  useEffect(() => {
    const filteredTags = allTagsList?.filter(
      (tag) =>
        Array.isArray(singleNote?.note?.tags) &&
        singleNote?.note?.tags?.some(
          (selectedTag) => selectedTag.tagId === tag.tagId
        )
    );

    setTrackerTags(filteredTags);
  }, [allTagsList, singleNote]);

  const [pictureUrl, setPictureUrl] = useState(singleNote?.note?.notePicture);

  useEffect(() => {
    setPictureUrl(singleNote?.note?.notePicture || "");
  }, [singleNote]);

  const [uploadedImage, setUploadedImage] = useState(
    singleNote?.note?.notePicture || null
  );
  const [imageError, setImageError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [handleComments, setHandleComments] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file && file.size <= 2 * 1024 * 1024) {
      setSelectedFile(file);
      setUploadedImage(URL.createObjectURL(file));
      setImageError("");
    } else {
      setSelectedFile(null);
      setUploadedImage(pictureUrl);
      setImageError("File size exceeds 2MB. Please upload a smaller image.");
    }
  };

  // Edit button click handler
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleCancelEditing = () => {
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    try {
      let newPictureUrl = pictureUrl;

      // Check if an image file is selected for upload
      if (selectedFile) {
        let formData = new FormData();
        formData.append("file", selectedFile);

        // Dispatch the file upload action
        let respFile = await dispatch(uploadTheFile(formData));

        // Check if the file upload was successful
        if (respFile.status === 200) {
          newPictureUrl = respFile.data.url;
          setPictureUrl(respFile.data.url);
        } else {
          return toast.error("File upload failed!");
        }
      }

      let data = {
        noteId: singleNote?.note?.noteId || "",
        title: title || "",
        description: description || "",
        tags: trackerTags?.map((item) => item?.tagId) || [],
        notePicture: newPictureUrl,
      };

      let resp = await dispatch(editPicnote(data));
      if (resp.status === 200) {
        toast.success(resp.data.message);
        dispatch(allMyPnotes());
        dispatch(
          singleFeed(
            selectedNoteFormMyF?.noteId ||
              picnoteCardsdata?.noteId ||
              selectedNoteFromMyNote?.noteId ||
              picnoteResponseWhileAddingNote?.note?.noteId
          )
        );
      }

      setIsEditing(false);
    } catch (error) {
      console.error("Error during updating user:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const handleAddOptionChange = (event) => {
    setAddOption(event.target.value);
  };

  // Dispatch instance
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const tags =
    originalUser?.userId === singleNote?.note?.edfOwnerId
      ? ["Add", "Book", "Article", "Video", "Image"]
      : ["Book", "Article", "Video", "Image"];

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
    if (tag === "Add") {
      setAddOption("Book");
    }
  };

  useEffect(() => {
    dispatch(getFolder());
    dispatch(getFollowingList());
    dispatch(allTags());
    dispatch(
      singleFeed(
        picnoteResponseWhileAddingNote?.note?.noteId ||
          selectedNoteFormMyF?.noteId ||
          picnoteCardsdata?.noteId ||
          selectedNoteFromMyNote?.noteId ||
          picnoteResponseWhileAddingNote?.note?.noteId
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setPictureUrl(singleNote?.note?.notePicture || "");
    setUploadedImage(singleNote?.note?.notePicture || null);
    setTitle(singleNote?.note?.title || "");
    setDescription(singleNote?.note?.description || "");
    setTrackerTags(singleNote?.note?.tags.map((i) => i.tagId) || "");
  }, [singleNote]);

  if (isDialogOpen === true) {
    closeAddSource();
  }

  const handleCloseRes = () => {
    dispatch(resetSingleNoteData());
    closeAddSource();
    selectedNoteFormMyF = [];
    setPictureUrl(null);
  };

  // console.log("Before closing:", pictureUrl);

  const handleClose = () => {
    selectedNoteFormMyF = [];
    closeAddSource();
    setPictureUrl(null);
  };
  // console.log("After closing:", pictureUrl);

  const validationSchema = yup.object({
    resTitle: yup.string().required("Title is required"),
    resLink: yup
      .string()
      .url("Must be a valid URL")
      .required("Link is required"),
  });

  const formik = useFormik({
    initialValues: {
      resType: addOption,
      resTitle: "",
      resAuthor: "",
      resLink: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        noteId: singleNote?.note?.noteId,
        resources: {
          resType: addOption,
          resTitle: values.resTitle,
          resAuthor: values.resAuthor,
          resLink: values.resLink,
        },
      };
      let resp = await dispatch(addResource(payload));

      if (resp.status === 200) {
        toast.success(resp.data.message);
        dispatch(
          singleFeed(
            picnoteCardsdata?.noteId ||
              picnoteResponse?.picnoteId ||
              picnoteResponse?.note?.noteId ||
              selectedNoteFromMyNote?.noteId ||
              picnoteResponseWhileAddingNote?.note?.noteId
          )
        );
        setSelectedTag(addOption);
        formik.resetForm();
      } else {
        const errorMessages = resp.data.message
          .map((err) => err.msg)
          .join(", ");
        toast.error(errorMessages);
      }
    },
  });

  const handlUpdateFlag = async (flagType) => {
    const values = {
      noteId: singleNote?.note?.noteId,
      liked: flagType === "unlike" ? "f" : "t",
      important: singleNote?.note?.impTNF,
      rightWrong:
        flagType === "right" ? "right" : flagType === "wrong" ? "wrong" : "",
    };
    let resp = await dispatch(updateFlags(values));

    // Handle the response
    if (resp.status === 200) {
      dispatch(
        singleFeed(
          picnoteCardsdata?.noteId ||
            picnoteResponse?.picnoteId ||
            picnoteResponse?.note?.noteId ||
            selectedNoteFormMyF?.noteId ||
            selectedNoteFromMyNote?.noteId ||
            picnoteResponseWhileAddingNote?.note?.noteId
        )
      );
      dispatch(favouritePicnote());
      dispatch(allMyPnotes());
      dispatch(myFeed());

      toast.success(resp.data.message || JSON.stringify(resp.data.message));
    } else {
      toast.error(resp.data.message || JSON.stringify(resp.data.message));
    }
  };

  const handlUpdateFlag2 = async (flagType) => {
    const values = {
      noteId: singleNote?.note?.noteId,
      liked: singleNote?.note?.heartNTF,
      important: flagType === "unCheked" ? "f" : "t",
      rightWrong:
        flagType === "right" ? "right" : flagType === "wrong" ? "wrong" : "",
    };

    // Dispatch the action to update the flags
    let resp = await dispatch(updateFlags(values));

    // Handle the response
    if (resp.status === 200) {
      dispatch(
        singleFeed(
          picnoteCardsdata?.noteId ||
            picnoteResponse?.picnoteId ||
            picnoteResponse?.note?.noteId ||
            selectedNoteFormMyF?.noteId ||
            selectedNoteFromMyNote?.noteId ||
            picnoteResponseWhileAddingNote?.note?.noteId
        )
      );
      dispatch(favouritePicnote());
      dispatch(allMyPnotes());
      dispatch(myFeed());
      toast.success(resp.data.message || JSON.stringify(resp.data.message));
    } else {
      toast.error(resp.data.message || JSON.stringify(resp.data.message));
    }
  };

  const handleDelete = async (noteId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );
    if (isConfirmed) {
      let resp = await dispatch(deletePicnote(noteId));
      if (resp.status === 200) {
        closeAddSource();
        dispatch(allMyPnotes());
        dispatch(profileDetial());
        toast.success(resp.data.message);
      } else {
        toast.error(resp.data.message);
      }
    }
  };

  // console.log("picture", pictureUrl);

  const handleNewPicnote = () => {
    setCreateNew(true);
  };
  const onClose = () => {
    setCreateNew(false);
  };

  const handleComment = () => {
    setHandleComments(true);
  };

  const onCloseComment = () => {
    setHandleComments(false);
  };

  const handleFollow = async (followId) => {
    let resp = await dispatch(followUser(followId));
    if (resp.status === 200) {
      toast.success(resp?.data?.message);
      dispatch(getFollowingList());
      dispatch(profileDetial());
      dispatch(myFeed());
      dispatch(
        singleFeed(
          picnoteCardsdata?.noteId ||
            picnoteResponse?.picnoteId ||
            picnoteResponse?.note?.noteId ||
            selectedNoteFormMyF?.noteId ||
            selectedNoteFromMyNote?.noteId ||
            picnoteResponseWhileAddingNote?.note?.noteId
        )
      );
      let fdata = JSON.parse(localStorage.getItem("baseFollowingList")) || [];
  
      // Ensure followId is a string and not an object
      let cleanFollowId = typeof followId === "object" ? followId.followId : followId;
  
      // Assuming you have user details from response or elsewhere
      let newUser = { edfId: cleanFollowId, firstName: "New", lastName: "User" }; // Replace with actual user details
  
      // Avoid duplicates
      if (!fdata.some(user => user.edfId === cleanFollowId)) {
        fdata.push(newUser);
        localStorage.setItem("baseFollowingList", JSON.stringify(fdata));
      }
    } else {
      toast.error(resp?.data?.message);
    
    }
  };

  const handleUnfollow = async (unFollowIdObj) => {
    let resp = await dispatch(unfollowUser(unFollowIdObj));
    if (resp.status === 200) {
      let successMessage = typeof resp?.data?.message === "string" ? resp.data.message : JSON.stringify(resp.data.message);
      toast.success(successMessage);

      dispatch(getFollowingList());
      dispatch(profileDetial());
      dispatch(myFeed());
       dispatch(
        singleFeed(
          picnoteCardsdata?.noteId ||
            picnoteResponse?.picnoteId ||
            picnoteResponse?.note?.noteId ||
            selectedNoteFormMyF?.noteId ||
            selectedNoteFromMyNote?.noteId ||
            picnoteResponseWhileAddingNote?.note?.noteId
        )
      );
      let fdata = JSON.parse(localStorage.getItem("baseFollowingList")) || [];
      if (!Array.isArray(fdata)) fdata = [];

      let unFollowId = String(unFollowIdObj.unFollowId);
      fdata = fdata.filter((item) => String(item.edfId) !== unFollowId);

      localStorage.setItem("baseFollowingList", JSON.stringify(fdata));
    } else {
      // Ensure error message is a string
      let errorMessage = typeof resp?.data?.message === "string" ? resp.data.message : JSON.stringify(resp.data.message);
      toast.error(errorMessage);
    
    }
  };

  // const goToPreviousPicnote = () => {
  //   if (selectedNoteIndex > 0) {
  //     const previousNoteId = AllNotesForRelated[selectedNoteIndex - 1].noteId;
  //     dispatch(singleFeed(previousNoteId));
  //     dispatch(similarNotes(singleNote?.note?.tags?.map((i) => i.tagId)));
  //   }
  // };

  // const goToNextPicnote = () => {
  //   if (selectedNoteIndex < AllNotesForRelated.length - 1) {
  //     const nextNoteId = AllNotesForRelated[selectedNoteIndex + 1].noteId;
  //     dispatch(singleFeed(nextNoteId));
  //     dispatch(similarNotes(singleNote?.note?.tags?.map((i) => i.tagId)));
  //   }
  // };

  const goToPreviousPicnote = () => {
    if (selectedNoteIndex > 0) {
      const previousNoteId = AllNotesForRelated[selectedNoteIndex - 1].noteId;
      dispatch(singleFeed(previousNoteId));
      dispatch(similarNotes(singleNote?.note?.tags?.map((i) => i.tagId)));
    }
  };

  const goToNextPicnote = () => {
    if (selectedNoteIndex < AllNotesForRelated.length - 1) {
      const nextNoteId = AllNotesForRelated[selectedNoteIndex + 1].noteId;
      dispatch(singleFeed(nextNoteId));
      dispatch(similarNotes(singleNote?.note?.tags?.map((i) => i.tagId)));
    }
  };

  const handleProfileClick = async (follower) => {
    dispatch(setSelectedFollower(follower));
    const tempResp = await dispatch(tempProfile(follower?.edfId));
    localStorage.setItem("Temptoken", tempResp.data?.tempToken);
    localStorage.setItem(
      "TempuserInfo",
      JSON.stringify(tempResp.data.tempInfo)
    );
    navigate("/auth/pages");
  };

  const handleInputChange = (event, value) => {
    if (!value) {
      setFilteredTags(allTagsList); // If input is empty, show all tags
      return;
    }

    // Filter tags that start with the entered text (case-insensitive)
    const filtered = allTagsList.filter((tag) =>
      tag.title.toLowerCase().startsWith(value.toLowerCase())
    );

    setFilteredTags(filtered);
  };

  return (
    <>
      {isMobile ? null : (
        <IconButton
          onClick={goToPreviousPicnote}
          sx={{
            position: "fixed",
            left: 20,
            top: "50%",
            transform: "translateY(-50%)",
            color: "#fff",
            backgroundColor: isMobile ? "rgba(255, 255, 255, 0.82)" : "#989898",
            "&:hover": { backgroundColor: "#dcdcdc" },
            zIndex: 1300,
          }}
        >
          <ChevronLeftIcon sx={{ color: isMobile ? "red" : "white" }} />
        </IconButton>
      )}

      <Dialog
        open={true}
        fullWidth
        TransitionComponent={Grow}
        disableBackdropTransition
        disableScrollLock
        maxWidth={useMediaQuery(useTheme().breakpoints.up("lg")) ? "lg" : "lg"}
        sx={{
          zIndex: 1200,
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            padding: isMobile ? "20px 10px 20px 10px" : "20px",
            minWidth: isMobile ? "90%" : "400px",
          },
        }}
      >
        {createNew ? (
          Array.isArray(allFolderList?.folderList) &&
          allFolderList.folderList?.length > 0 ? (
            <FolderList
              onClose={onClose}
              picnoteDetail={selectedNoteFromMyNote?.noteId}
              picnoteedfOwnerId={singleNote?.note?.edfOwnerId}
              picnoteResponseWhileAddingNote={
                picnoteResponseWhileAddingNote?.note?.noteId
              }
            />
          ) : (
            <AddPicnoteFolder onClose={onClose} />
          )
        ) : null}

        {handleComments ? (
          <Comments
            onClose={onCloseComment}
            title={singleNote?.note}
            comp={"addResource"}
          />
        ) : null}

        <Grid2 item size={12} mb={"10px"}>
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleCloseRes}
          >
            <CloseIcon style={{ width: "20px", height: "20px" }} />
          </IconButton>
        </Grid2>

        <Grid2
          item
          size={12}
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
            paddingTop: "10px",
            gap: isMobile ? "15px" : "0px",
          }}
        >
          <Grid2
            item
            size={isMobile ? 6 : 6}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* {singleNote?.note?.edFluencer?.edfPicture?.startsWith(
              "http" || "https"
            ) ? ( */}
            {/* he aj 11 tareekh ty change kayo aa ko issue ayo ta change kry wathndus wapsi mathyon */}
            {singleNote?.note?.edFluencer?.edfPicture?.endsWith(".jpg") ||
            singleNote?.note?.edFluencer?.edfPicture?.endsWith(".jpeg") ||
            singleNote?.note?.edFluencer?.edfPicture?.endsWith(".png") ? (
              <img
                src={singleNote?.note?.edFluencer?.edfPicture}
                alt="profile"
                style={{
                  width: "44px",
                  height: "44px",
                  borderRadius: "50%",
                  marginRight: "10px",
                  border: "1px solid slategray",
                  cursor:
                    userDetail?.userId &&
                    originalUser?.userId === singleNote?.note?.edFluencer?.edfId
                      ? null
                      : "pointer",
                }}
                onClick={() => handleProfileClick(singleNote?.note?.edFluencer)}
              />
            ) : (
              <div
                style={{
                  width: "44px",
                  height: "44px",
                  borderRadius: "50%",
                  backgroundColor: "#b10303",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                id="basic-button"
              >
                {`${
                  singleNote?.note?.edFluencer?.firstName?.[0]?.toUpperCase() ||
                  ""
                }${
                  singleNote?.note?.edFluencer?.lastName?.[0]?.toUpperCase() ||
                  ""
                }`}
              </div>
            )}
            <span>
              <span
                style={{ ...styleForIcons, cursor: "pointer" }}
                onClick={() => handleProfileClick(singleNote?.note?.edFluencer)}
              >
                {`${singleNote?.note?.edFluencer?.firstName || ""} ${
                  singleNote?.note?.edFluencer?.lastName || ""
                }`}
                {/* {selectedNoteFromMyNote?.ownerName} */}
              </span>
              <br />
              <span style={styleForIcons}>
                {/* {userDetail?.userId &&
                originalUser?.userId ===
                  singleNote?.note?.edFluencer?.edfId ? null : (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#BC171D",
                      fontWeight: 600,
                      cursor: "pointer",
                      fontFamily: "Aileron",
                    }}
                    onClick={() => {
                      const edfId = singleNote?.note?.edFluencer?.edfId;
                      const isFollowing = followingList?.listFollowing?.some(
                        (i) => i.edfId === edfId
                      );

                      isFollowing
                        ? handleUnfollow({ unFollowId: edfId })
                        : handleFollow({ followId: edfId });
                    }}
                  >
                    {followingList?.listFollowing?.some(
                      (i) => i.edfId === singleNote?.note?.edFluencer?.edfId
                    )
                      ? "Following"
                      : "Follow"}
                  </span>
                )} */}
                {userDetail?.userId &&
                originalUser?.userId ===
                  singleNote?.note?.edFluencer?.edfId ? null : (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#BC171D",
                      fontWeight: 600,
                      cursor: singleFeedLoader ? "not-allowed" : "pointer",
                      fontFamily: "Aileron",
                      opacity: singleFeedLoader ? 0.6 : 1,
                    }}
                    onClick={() => {
                      if (singleFeedLoader) return; // Prevent multiple clicks

                      const edfId = singleNote?.note?.edFluencer?.edfId;
                      const isFollowing = followingList?.listFollowing?.some(
                        (i) => i.edfId === edfId
                      );

                      isFollowing
                        ? handleUnfollow({ unFollowId: edfId })
                        : handleFollow({ followId: edfId });
                    }}
                  >
                    {singleFeedLoader
                      ? "Loading..."
                      : baseProFollowing?.some(
                          (i) => i.edfId === singleNote?.note?.edFluencer?.edfId
                        )
                      ? "Following"
                      : "Follow"}
                  </span>
                )}
              </span>
            </span>
          </Grid2>

          <Grid2
            item
            size={isMobile ? 12 : 6}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "8px", sm: "10px" },
              justifyContent: isMobile ? "space-between" : "flex-end",
            }}
          >
            {isMobile && (
              <Button
                style={{
                  ...addPicnoteButtonStyle,
                  fontSize: { xs: "12px", sm: "14px" },
                }}
                onClick={handleNewPicnote}
              >
                <img
                  src={folderAdd}
                  alt="folder"
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                />
                &nbsp; Add to folder
              </Button>
            )}

            {/* {userDetail?.userId === singleNote?.note?.edfOwnerId && ( */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {!isMobile && (
                <Button
                  style={{
                    ...addPicnoteButtonStyle,
                    fontSize: { xs: "12px", sm: "14px" },
                  }}
                  onClick={handleNewPicnote}
                >
                  <img
                    src={folderAdd}
                    alt="folder"
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                  &nbsp; Add to folders
                </Button>
              )}

              {singleNote?.note?.noteComments?.length > 0 ? (
                <ChatBubbleRoundedIcon
                  sx={{
                    width: "25px",
                    height: "25px",
                    color: "rgba(188, 23, 29, 1)",
                    border: "1px solid lightgray",
                    borderRadius: "50%",
                    // padding: "2px",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                  onClick={() => handleComment("unlike")}
                />
              ) : (
                <ChatBubbleOutlineRoundedIcon
                  sx={{
                    width: "25px",
                    height: "25px",
                    color: "rgba(188, 23, 29, 1)",
                    border: "1px solid lightgray",
                    borderRadius: "50%",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                  onClick={() => handleComment("like")}
                />
              )}

              {singleNote?.note?.heart === true ? (
                <img
                  src={redfavourite}
                  alt="liked"
                  style={{
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlUpdateFlag("unlike")}
                />
              ) : (
                <img
                  src={favourite}
                  alt="not liked"
                  style={{
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                    // border: "1px solid lightgray",
                    // padding: "4px",
                    // borderRadius: "50%",
                  }}
                  onClick={() => handlUpdateFlag("liked")}
                />
              )}

              {/* Flags */}
              {singleNote?.note?.important === true ? (
                <FlagIcon
                  sx={{
                    width: "25px",
                    height: "25px",
                    color: "rgba(188, 23, 29, 1)",
                    border: "1px solid lightgray",
                    borderRadius: "50%",
                    padding: "2px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlUpdateFlag2("unCheked")}
                />
              ) : (
                <img
                  src={flag}
                  alt="important"
                  style={{
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlUpdateFlag2("cheked")}
                />
              )}
            </div>
          </Grid2>
        </Grid2>

        {singleFeedLoader ? (
          <Grid2 container spacing={2}>
            <Grid2 item size={6}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={530}
                height={450}
                sx={{ borderRadius: "10px" }}
              />
            </Grid2>
            <Grid2 item size={6}>
              <Skeleton width={200} height={50} sx={{ borderRadius: "10px" }} />
              <Skeleton
                animation="wave"
                width={390}
                height={250}
                sx={{ borderRadius: "10px" }}
              />
              <Skeleton
                animation={false}
                width={470}
                height={150}
                sx={{ borderRadius: "10px" }}
              />
            </Grid2>
          </Grid2>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              {!isEditing ? (
                pictureUrl ? (

                
                <img
                  src={pictureUrl || uploadedImage}
                  alt="pic"
                  loading="lazy"
                  style={{
                    width: "100%",
                    minHeight: "100%",
                    maxHeight: "450px",
                    borderRadius: "10px",
                  }}
                /> ) : (
                  <div style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    border: '1px solid lightgray',
                    borderRadius: '10px',
                    minHeight: '480px',
                    maxHeight: '480px'
                    // paddingTop: '20%' 
                  }}>
                    <img
                      src={noImage}
                      // alt={note.title}
                      style={{
                        width: "50%",
                        height: "50%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                )
              ) : (
                <div>
                  <label htmlFor="fileInput">
                    <img
                      src={pictureUrl || "placeholder.jpg"}
                      loading="lazy"
                      alt="No images"
                      style={{
                        width: "100%",
                        minHeight: "510px",
                        maxHeight: "510px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  {imageError && <p style={{ color: "red" }}>{imageError}</p>}
                </div>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              container
              style={{ alignItems: "start" }}
            >
              <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  {originalUser?.userId === singleNote?.note?.edfOwnerId &&
                  isMobile ? (
                    <Grid2
                      item
                      size={4}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingBottom: isMobile ? "10px" : null,
                      }}
                    >
                      {isEditing ? (
                        <span
                          style={{ ...styleCenter, paddingRight: "20px" }}
                          onClick={handleSaveClick}
                        >
                          <SaveIcon
                            style={{
                              color: "rgba(188, 23, 29, 1)",
                            }}
                          />
                          &nbsp; Save
                        </span>
                      ) : (
                        <span
                          style={{ ...styleCenter, paddingRight: "20px" }}
                          onClick={handleEditClick}
                        >
                          <img
                            src={editImage}
                            alt="edit icon"
                            style={{
                              width: "14px",
                              height: "14px",
                              color: "#3F3F3F",
                            }}
                          />
                          &nbsp; Edit
                        </span>
                      )}
                      {isEditing ? (
                        <span
                          style={{ ...styleCenter, paddingRight: "20px" }}
                          onClick={handleCancelEditing}
                        >
                          <CloseIcon
                            style={{
                              color: "rgba(188, 23, 29, 1)",
                            }}
                          />
                          &nbsp; Cancel
                        </span>
                      ) : (
                        <span
                          style={{ ...styleCenter }}
                          onClick={() => handleDelete(singleNote?.note?.noteId)}
                        >
                          <img
                            src={deleteImage}
                            alt="delete icon"
                            style={{
                              width: "14px",
                              height: "14px",
                              color: "#3F3F3F",
                            }}
                          />
                          &nbsp; Delete
                        </span>
                      )}
                    </Grid2>
                  ) : null}
                  <Grid
                    item
                    xs={isMobile ? 12 : 8}
                    style={{ margin: 0, paddingBottom: "5px" }}
                  >
                    {isEditing ? (
                      <Input
                        style={edtInputStyle}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        variant="outlined"
                        paddingBottom="10px"
                        // size="small"
                      />
                    ) : (
                      <Typography
                        style={{
                          fontFamily: "Cako regular",
                          fontSize: "18px",
                          fontWeight: 400,
                          textAlign: "left",
                        }}
                      >
                        {/* {title || ""} */}
                        {`${(title || "").substring(0, 100)}${
                          (title || "")?.length > 100 ? "..." : ""
                        }`}
                      </Typography>
                    )}
                  </Grid>

                  {originalUser?.userId === singleNote?.note?.edfOwnerId &&
                  !isMobile ? (
                    <Grid2
                      item
                      size={4}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {isEditing ? (
                        <span
                          style={{ ...styleCenter, paddingRight: "20px" }}
                          onClick={handleSaveClick}
                        >
                          <SaveIcon
                            style={{
                              color: "rgba(188, 23, 29, 1)",
                            }}
                          />
                          &nbsp; Save
                        </span>
                      ) : (
                        <span
                          style={{ ...styleCenter, paddingRight: "20px" }}
                          onClick={handleEditClick}
                        >
                          <img
                            src={editImage}
                            alt="edit icon"
                            style={{
                              width: "14px",
                              height: "14px",
                              color: "#3F3F3F",
                            }}
                          />
                          &nbsp; Edit
                        </span>
                      )}
                      {isEditing ? (
                        <span
                          style={{ ...styleCenter, paddingRight: "20px" }}
                          onClick={handleCancelEditing}
                        >
                          <CloseIcon
                            style={{
                              color: "rgba(188, 23, 29, 1)",
                            }}
                          />
                          &nbsp; Cancel
                        </span>
                      ) : (
                        <span
                          style={{ ...styleCenter }}
                          onClick={() => handleDelete(singleNote?.note?.noteId)}
                        >
                          <img
                            src={deleteImage}
                            alt="delete icon"
                            style={{
                              width: "14px",
                              height: "14px",
                              color: "#3F3F3F",
                            }}
                          />
                          &nbsp; Delete
                        </span>
                      )}
                    </Grid2>
                  ) : null}
                </Grid>
                <Grid2 style={{ alignItems: "start", gap: 0 }}>
                  {isEditing ? (
                    <>
                      <TextArea
                        value={description}
                        onChange={(e) => {
                          if (e.target.value?.length <= 650) {
                            setDescription(e.target.value);
                          }
                        }}
                        fullWidth
                        multiline
                        rows={6}
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 650 }}
                        style={{
                          borderRadius: "10px",
                          fontSize: "14px",
                          paddingLeft: "10px",
                          border: "1px solid rgba(112, 112, 112, 0.5)",
                          width: "100%",
                          fontFamily: "Aileron",
                          color: "#707070",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Typography
                        style={{
                          margin: 0,
                          color: "#707070",
                          fontSize: "12px",
                          fontWeight: 400,
                          height: isMobile ? "100%" : "135px",
                          borderRadius: "10px",
                          padding: "5px",
                          textAlign: "justify",
                          overflow: "hidden",
                        }}
                      >
                        {description &&
                        description.length > (isMobile ? 650 : 650)
                          ? `${description?.slice(0, isMobile ? 650 : 650)}...`
                          : description}
                      </Typography>
                    </>
                  )}
                  {/* <Grid2 item size={12} paddingTop={"5px"} overflowX={'scroll'} border={'1px solid red'}>
                    {isEditing ? (
                      <Autocomplete
                        freeSolo
                        popupIcon={
                          <img
                            src={arrow}
                            alt="drop"
                            style={{
                              color: "#7A53EC",
                              margin: "3px 5px",
                              width: "22px",
                              height: "22px",
                            }}
                          />
                        }
                        value={trackerTags}
                        id="trackerTags"
                        multiple
                        limitTags={1}
                        options={allTagsList || []}
                        getOptionLabel={(option) => option?.title || ""}
                        onChange={(e, value) => {
                          const newTagTitle =
                            typeof value[value?.length - 1] === "string"
                              ? value[value?.length - 1]
                              : value[value?.length - 1]?.title;
                          setTrackerTags(value);
                          if (
                            newTagTitle &&
                            !allTagsList.some(
                              (tag) => tag.title === newTagTitle
                            )
                          ) {
                            dispatch(createTag({ title: newTagTitle }));
                          }
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              label={option?.title}
                              size="small"
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            variant="standard"
                            id="trackerTags"
                            name="trackerTags"
                            disableUnderline
                            error={
                              formik.touched.trackerTags &&
                              Boolean(formik.errors.trackerTags)
                            }
                            style={{
                              borderRadius: "25px",
                              padding: "5px",
                              border: "1px solid #d1d1d1",
                              paddingLeft: "15px",
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Typography
                        style={{ color: "#BC171D", marginTop: "5px" }}
                      >
                        {singleNote?.note?.tags?.map((item, index) => (
                          <Typography
                            key={index}
                            style={{
                              display: "inline-block",
                              margin: "5px",
                              padding: "5px 10px",
                              borderRadius: "25px",
                              border: "1px solid #BC171D",
                            }}
                          >
                            {item?.tagTitle}
                          </Typography>
                        ))}
                      </Typography>
                    )}
                  </Grid2> */}

                  <Grid2
                    item
                    size={12}
                    paddingTop={"5px"}
                    overflowX="scroll"
                    style={{
                      display: "flex",
                      flexWrap: isEditing ? "wrap" : "nowrap",
                      overflowX: isEditing ? "visible" : "auto",
                    }}
                  >
                    {isEditing ? (
                      <Autocomplete
                        freeSolo
                        popupIcon={
                          <img
                            src={arrow}
                            alt="drop"
                            style={{
                              color: "#7A53EC",
                              margin: "3px 5px",
                              width: "22px",
                              height: "22px",
                            }}
                          />
                        }
                        value={trackerTags}
                        filterOptions={(options, state) => {
                          return options.filter(option => 
                            option.title.toLowerCase().includes(state.inputValue.toLowerCase())
                          );
                        }}
                        id="trackerTags"
                        multiple
                        limitTags={1}
                        options={uniqueTags || []}
                        // options={allTagsList || []}
                  onInputChange={handleInputChange}

                        getOptionLabel={(option) => option?.title || ""}
                        onChange={(e, value) => {
                          const newTagTitle =
                            typeof value[value?.length - 1] === "string"
                              ? value[value?.length - 1]
                              : value[value?.length - 1]?.title;
                          setTrackerTags(value);
                          if (
                            newTagTitle &&
                            !allTagsList?.some(
                              (tag) => tag?.title === newTagTitle
                            )
                          ) {
                            dispatch(createTag({ title: newTagTitle }));
                          }
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              label={option?.title}
                              size="small"
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            variant="standard"
                            id="trackerTags"
                            name="trackerTags"
                            disableUnderline
                            error={
                              formik.touched.trackerTags &&
                              Boolean(formik.errors.trackerTags)
                            }
                            style={{
                              borderRadius: "25px",
                              padding: "5px",
                              border: "1px solid #d1d1d1",
                              paddingLeft: "15px",
                              minWidth: '300px'
                            }}
                          />
                        )}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          // gap: "10px",
                        }}
                      >
                        {singleNote?.note?.tags?.map((item, index) => (
                          <Typography
                            key={index}
                            style={{
                              display: "inline-block",
                              margin: "5px",
                              padding: "5px 10px",
                              borderRadius: "25px",
                              border: "1px solid #BC171D",
                              whiteSpace: "nowrap", // Prevent wrapping of text
                              color: "#BC171D",
                            }}
                          >
                            {item?.tagTitle}
                          </Typography>
                        ))}
                      </div>
                    )}
                  </Grid2>

                  <Grid2 style={{ paddingBottom: "10px" }}>
                    <span
                      style={{
                        ...thumbStyle,
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                        color: "#17BC6D",
                      }}
                      onClick={() => handlUpdateFlag("right")}
                    >
                      <img src={thumbUp} alt="thumb Up" /> &nbsp;{" "}
                      {singleNote?.note?.rights}
                    </span>

                    <span
                      style={{
                        ...thumbStyle,
                        backgroundColor: "#BC171D33",
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                        color: "#BC171D",
                      }}
                      onClick={() => handlUpdateFlag("wrong")}
                    >
                      <img src={thumbDown} alt="thumb Down" /> &nbsp;{" "}
                      {singleNote?.note?.wrongs}
                    </span>
                  </Grid2>
                </Grid2>

                {originalUser?.userId === singleNote?.note?.edfOwnerId ? (
                  <Grid2
                    container
                    item
                    xs={12}
                    style={{
                      border: "1px solid #70707080",
                      borderRadius: "5px",
                      padding: "10px",
                      minWidth: isMobile ? "320px" : "100%",
                      maxWidth: isMobile ? "320px" : "100%",
                      overflowX: isMobile ? "scroll" : "hidden",
                      minHeight: "200px",
                      maxHeight: "200px",
                      display: "flex",
                      alignItems: "start",
                    }}
                  >
                    <Grid2
                      container
                      item
                      size={12}
                      style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                        overflow: "hidden",
                        // minWidth: "520px",
                        // maxWidth: "520px",
                        minheight: "200px",
                        maxheight: "200px",
                        overflowY: "hidden",
                      }}
                    >
                      <Grid2
                        item
                        size={isMobile ? 12 : 4}
                        justifyItems={"start"}
                        paddingBottom={"10px"}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            fontFamily: "Cako",
                            fontSize: "24px",
                            fontWeight: "400",
                            lineHeight: "28.8px",
                            textAlign: "left",
                          }}
                        >
                          Resources
                        </Typography>
                      </Grid2>

                      {/* tags on top */}
                      <Grid2
                        item
                        size={isMobile ? 12 : 8}
                        alignItems={"end"}
                        justifyContent={isMobile ? "start" : "end"}
                        display="flex"
                        gap={1}
                        paddingBottom={isMobile ? "30px" : "0px"}
                      >
                        {tags.map((tag, index) => {
                          // Count the resources that match the tag
                          const matchingResources =
                            singleNote?.note?.resources.filter((resource) => {
                              if (
                                tag === "Book" &&
                                resource?.resType === "Book"
                              )
                                return true;
                              if (
                                tag === "Article" &&
                                resource?.resType === "Article"
                              )
                                return true;
                              if (
                                tag === "Image" &&
                                resource?.resType === "Image"
                              )
                                return true;
                              if (
                                tag === "Video" &&
                                resource?.resType === "Video"
                              )
                                return true;
                              return false;
                            });

                          return (
                            <Box
                              key={index}
                              onClick={() => handleTagClick(tag)}
                              sx={{
                                border:
                                  selectedTag === tag
                                    ? "1px solid #BC171D"
                                    : "1px solid #70707080",
                                borderRadius: "25px",
                                padding: "5px 8px",
                                cursor: "pointer",
                                textAlign: "center",
                                fontSize: "12px",
                                fontFamily: "Aileron",
                                fontWeight: 400,
                                color:
                                  selectedTag === tag ? "#BC171D" : "inherit",
                                maxWidth: "80px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {tag}{" "}
                              {tag === "Add"
                                ? null
                                : ": " + matchingResources?.length}
                            </Box>
                          );
                        })}
                      </Grid2>

                      {singleNote?.note?.resources?.length > 0 ? (
                        selectedTag ? (
                          selectedTag !== "Add" &&
                          singleNote.note.resources.filter((resource) => {
                            return (
                              (selectedTag === "Book" &&
                                resource?.resType === "Book") ||
                              (selectedTag === "Article" &&
                                resource?.resType === "Article") ||
                              (selectedTag === "Image" &&
                                resource?.resType === "Image") ||
                              (selectedTag === "Video" &&
                                resource?.resType === "Video")
                            );
                          }).length > 0 ? (
                            singleNote.note.resources.map((resource, index) => {
                              const isResourceMatching =
                                (selectedTag === "Book" &&
                                  resource?.resType === "Book") ||
                                (selectedTag === "Article" &&
                                  resource?.resType === "Article") ||
                                (selectedTag === "Image" &&
                                  resource?.resType === "Image") ||
                                (selectedTag === "Video" &&
                                  resource?.resType === "Video");

                              return (
                                isResourceMatching && (
                                  <Grid2
                                    container
                                    item
                                    size={12}
                                    spacing={2}
                                    display={"flex"}
                                    alignItems="start"
                                    justifyContent={"start"}
                                    key={index}
                                    sx={{ marginBottom: "10px" }}
                                  >
                                    <Grid2 item size={2}>
                                      <Box
                                        sx={{
                                          backgroundColor: "#F3F3F3",
                                          padding: "20px 10px",
                                          borderRadius: "8px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {resource?.resType === "Book" ? (
                                          <MenuBookRoundedIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : resource?.resType === "Article" ? (
                                          <ArticleRoundedIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : resource?.resType === "Image" ? (
                                          <PhotoRoundedIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : resource?.resType === "Video" ? (
                                          <VideocamIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : null}
                                      </Box>
                                    </Grid2>

                                    <Grid2 item size={10}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontFamily: "Aileron",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          color: "#454545",
                                        }}
                                      >
                                        {resource?.resTitle}
                                      </Typography>
                                      <Box
                                        sx={{
                                          fontFamily: "Aileron",
                                          fontSize: "12px",
                                          fontWeight: 400,
                                          color: "#707070",
                                          textTransform: "lowercase",
                                        }}
                                      >
                                        {resource?.resAuthor?.length > 0 && (
                                          <>
                                            <Typography
                                              component="span"
                                              sx={{
                                                fontSize: "12px",
                                                fontWeight: 400,
                                                color: "#707070",
                                              }}
                                            >
                                              {resource?.resType === "Book" ||
                                              resource?.resType === "Article"
                                                ? "author"
                                                : null}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              component="span"
                                              sx={{
                                                fontFamily: "Aileron",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                ml: 0.5,
                                                color: "#454545",
                                              }}
                                            >
                                              {resource?.resAuthor}
                                            </Typography>
                                          </>
                                        )}
                                      </Box>
                                      <Link
                                        href={resource?.resLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                          fontFamily: "Aileron",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          textDecoration: "none",
                                          color: "#454545",
                                          mt:
                                            resource?.resAuthor?.length > 0
                                              ? 0
                                              : 1,
                                        }}
                                      >
                                        {resource?.resLink?.length > 30
                                          ? `${resource.resLink?.slice(
                                              0,
                                              30
                                            )}...`
                                          : resource?.resLink}
                                      </Link>
                                    </Grid2>
                                  </Grid2>
                                )
                              );
                            })
                          ) : (
                            <Grid
                              container
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: "40px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "20px",
                                  color: "#6c6c6c",
                                }}
                              >
                                No resources added...
                              </Typography>
                            </Grid>
                          )
                        ) : null
                      ) : (
                        selectedTag !== "Add" && (
                          <Grid
                            container
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingTop: isMobile ? "10px" : "40px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "20px",
                                color: "#6c6c6c",
                              }}
                            >
                              No resources added.
                            </Typography>
                          </Grid>
                        )
                      )}

                      {selectedTag === "Add" && (
                        <Grid2
                          container
                          spacing={2}
                          justifyContent={"space-between"}
                          padding={"10px"}
                        >
                          <Grid2 item size={6}>
                            {/* <Typography style={labelStyle}>File type</Typography> */}
                            <Select
                              style={{
                                width: "100%",
                                height: "34px",
                                borderRadius: "25px",
                              }}
                              value={addOption}
                              onChange={handleAddOptionChange}
                              displayEmpty
                              IconComponent={CustomSelectIcon}
                            >
                              <MenuItem value="" disabled>
                                Select an option
                              </MenuItem>
                              <MenuItem value="Book">Book</MenuItem>
                              <MenuItem value="Article">Article</MenuItem>
                              <MenuItem value="Video">Video</MenuItem>
                              <MenuItem value="Image">Image</MenuItem>
                            </Select>
                          </Grid2>

                          {/* Conditionally render Title or Link input */}
                          <Grid2 item size={6}>
                            {addOption === "Video" || addOption === "Image" ? (
                              <>
                                {/* <label htmlFor="resLink" style={labelStyle}>
                              Link
                            </label> */}
                                <Input
                                  id="resLink"
                                  style={{ ...inputStyle, width: "100%" }}
                                  // className="inputTextStyling"
                                  placeholder="Link"
                                  name="resLink"
                                  value={formik.values.resLink}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik.touched.resLink &&
                                    Boolean(formik.errors.resLink)
                                  }
                                />
                                {formik.touched.resLink &&
                                formik.errors.resLink ? (
                                  <div
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    {formik.errors.resLink}
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <>
                                <Input
                                  id="resTitle"
                                  style={{ ...inputStyle }}
                                  className="inputTextStyling"
                                  placeholder="Title"
                                  name="resTitle"
                                  value={formik.values.resTitle}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik.touched.resTitle &&
                                    Boolean(formik.errors.resTitle)
                                  }
                                />
                                {formik.touched.resTitle &&
                                formik.errors.resTitle ? (
                                  <div
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    {formik.errors.resTitle}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </Grid2>

                          {/* Conditionally render additional input fields based on dropdown selection */}
                          <Grid2 container item size={12}>
                            {(addOption === "Book" ||
                              addOption === "Article") && (
                              <Grid2
                                container
                                item
                                size={12}
                                spacing={2}
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Grid2 item size={6}>
                                  {/* <label htmlFor="resAuthor" style={labelStyle}>
                                Author
                              </label> */}
                                  <Input
                                    id="resAuthor"
                                    style={{ ...inputStyle }}
                                    className="inputTextStyling"
                                    placeholder="Author"
                                    name="resAuthor"
                                    value={formik.values.resAuthor}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                      formik.touched.resAuthor &&
                                      Boolean(formik.errors.resAuthor)
                                    }
                                  />
                                  {formik.touched.resAuthor &&
                                  formik.errors.resAuthor ? (
                                    <div
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {formik.errors.resAuthor}
                                    </div>
                                  ) : null}
                                </Grid2>
                                <Grid2 item size={6}>
                                  {/* <label htmlFor="resLink" style={labelStyle}>
                                Link
                              </label> */}
                                  <Input
                                    id="resLink"
                                    style={{ ...inputStyle }}
                                    className="inputTextStyling"
                                    placeholder="Link"
                                    name="resLink"
                                    value={formik.values.resLink}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                      formik.touched.resLink &&
                                      Boolean(formik.errors.resLink)
                                    }
                                  />
                                  {formik.touched.resLink &&
                                  formik.errors.resLink ? (
                                    <div
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {formik.errors.resLink}
                                    </div>
                                  ) : null}
                                </Grid2>
                              </Grid2>
                            )}

                            {(addOption === "Video" ||
                              addOption === "Image") && (
                              <>
                                <Grid2 container item size={12}>
                                  <Input
                                    id="resTitle"
                                    style={{ ...inputStyle }}
                                    className="inputTextStyling"
                                    placeholder="Title"
                                    name="resTitle"
                                    value={formik.values.resTitle}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                      formik.touched.resTitle &&
                                      Boolean(formik.errors.resTitle)
                                    }
                                  />
                                  {formik.touched.resTitle &&
                                  formik.errors.resTitle ? (
                                    <div
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {formik.errors.resTitle}
                                    </div>
                                  ) : null}
                                </Grid2>
                              </>
                            )}
                          </Grid2>
                        </Grid2>
                      )}
                    </Grid2>

                    {/* Create reference button */}
                    {selectedTag === "Add" ? (
                      <Grid2
                        item
                        size={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LoadingButton
                          loading={addResourceloader}
                          position={"start"}
                          type="submit"
                          variant="contained"
                          style={{
                            borderRadius: "20px",
                            color: "white",
                            width: isMobile ? "100%" : "40%",
                            backgroundColor: "rgba(188, 23, 29, 1)",
                            textTransform: "none",
                          }}
                        >
                          Create Reference
                        </LoadingButton>
                      </Grid2>
                    ) : null}
                  </Grid2>
                ) : (
                  <Grid2
                    container
                    item
                    xs={12}
                    style={{
                      border: "1px solid #70707080",
                      borderRadius: "5px",
                      padding: "10px",
                      minWidth: isMobile ? "320px" : "100%",
                      maxWidth: isMobile ? "320px" : "100%",
                      overflowX: isMobile ? "scroll" : "hidden",
                      minHeight: "200px",
                      maxHeight: "200px",
                      display: "flex",
                      alignItems: "start",
                    }}
                  >
                    <Grid2
                      container
                      item
                      size={12}
                      style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                        overflow: "hidden",
                        // minWidth: "520px",
                        // maxWidth: "520px",
                        minheight: "200px",
                        maxheight: "200px",
                        overflowY: "hidden",
                      }}
                    >
                      {/* add resource for not owner */}

                      <Grid2
                        item
                        size={5}
                        paddingBottom={"10px"}
                        justifyItems={"start"}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            fontFamily: "Cako",
                            fontSize: "24px",
                            fontWeight: "400",
                            lineHeight: "28.8px",
                            textAlign: "left",
                          }}
                        >
                          Resources
                        </Typography>
                      </Grid2>
                      {/* Top tags for not owner */}
                      <Grid2
                        item
                        size={7}
                        display="flex"
                        justifyContent={"end"}
                        gap={1}
                      >
                        {tags.map((tag, index) => {
                          const matchingResources =
                            singleNote?.note?.resources.filter((resource) => {
                              if (
                                tag === "Book" &&
                                resource?.resType === "Book"
                              )
                                return true;
                              if (
                                tag === "Article" &&
                                resource?.resType === "Article"
                              )
                                return true;
                              if (
                                tag === "Image" &&
                                resource?.resType === "Image"
                              )
                                return true;
                              if (
                                tag === "Video" &&
                                resource?.resType === "Video"
                              )
                                return true;
                              return false;
                            });

                          return (
                            <Box
                              key={index}
                              onClick={() => handleTagClick(tag)}
                              sx={{
                                border:
                                  selectedTag === tag
                                    ? "1px solid #BC171D"
                                    : "1px solid #70707080",
                                borderRadius: "25px",
                                padding: "5px 8px",
                                cursor: "pointer",
                                textAlign: "center",
                                fontSize: "12px",
                                fontFamily: "Aileron",
                                fontWeight: 400,

                                color:
                                  selectedTag === tag ? "#BC171D" : "inherit",

                                maxWidth: "80px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {tag}{" "}
                              {tag === "Add"
                                ? null
                                : ": " + matchingResources?.length}
                            </Box>
                          );
                        })}
                      </Grid2>

                      {singleNote?.note?.resources?.length > 0 ? (
                        selectedTag ? (
                          singleNote.note.resources.filter((resource) => {
                            return (
                              (selectedTag === "Book" &&
                                resource?.resType === "Book") ||
                              (selectedTag === "Article" &&
                                resource?.resType === "Article") ||
                              (selectedTag === "Image" &&
                                resource?.resType === "Image") ||
                              (selectedTag === "Video" &&
                                resource?.resType === "Video")
                            );
                          }).length > 0 ? (
                            singleNote.note.resources.map((resource, index) => {
                              const isResourceMatching =
                                (selectedTag === "Book" &&
                                  resource?.resType === "Book") ||
                                (selectedTag === "Article" &&
                                  resource?.resType === "Article") ||
                                (selectedTag === "Image" &&
                                  resource?.resType === "Image") ||
                                (selectedTag === "Video" &&
                                  resource?.resType === "Video");

                              return (
                                isResourceMatching && (
                                  <Grid2
                                    container
                                    item
                                    size={12}
                                    spacing={2}
                                    display={"flex"}
                                    alignItems="start"
                                    justifyContent={"start"}
                                    key={index}
                                    sx={{ marginBottom: "10px" }}
                                  >
                                    <Grid2 item size={2}>
                                      <Box
                                        sx={{
                                          backgroundColor: "#F3F3F3",
                                          padding: "20px 10px",
                                          borderRadius: "8px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {resource?.resType === "Book" ? (
                                          <MenuBookRoundedIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : resource?.resType === "Article" ? (
                                          <ArticleRoundedIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : resource?.resType === "Image" ? (
                                          <PhotoRoundedIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : resource?.resType === "Video" ? (
                                          <VideocamIcon
                                            style={{
                                              color: "#bc171d",
                                            }}
                                          />
                                        ) : null}
                                      </Box>
                                    </Grid2>

                                    <Grid2 item size={10}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontFamily: "Aileron",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          color: "#454545",
                                        }}
                                      >
                                        {resource?.resTitle}
                                      </Typography>
                                      <Box
                                        sx={{
                                          fontFamily: "Aileron",
                                          fontSize: "12px",
                                          fontWeight: 400,
                                          color: "#707070",
                                          textTransform: "lowercase",
                                        }}
                                      >
                                        {resource?.resAuthor?.length > 0 && (
                                          <>
                                            <Typography
                                              component="span"
                                              sx={{
                                                fontSize: "12px",
                                                fontWeight: 400,
                                                color: "#707070",
                                              }}
                                            >
                                              {resource?.resType === "Book" ||
                                              resource?.resType === "Article"
                                                ? "author"
                                                : null}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              component="span"
                                              sx={{
                                                fontFamily: "Aileron",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                ml: 0.5,
                                                color: "#454545",
                                              }}
                                            >
                                              {resource?.resAuthor}
                                            </Typography>
                                          </>
                                        )}
                                      </Box>
                                      <Link
                                        href={resource?.resLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                          fontFamily: "Aileron",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          textDecoration: "none",
                                          color: "#454545",
                                          mt:
                                            resource?.resAuthor?.length > 0
                                              ? 0
                                              : 1,
                                        }}
                                      >
                                        {resource?.resLink?.length > 30
                                          ? `${resource.resLink.slice(
                                              0,
                                              30
                                            )}...`
                                          : resource?.resLink}
                                      </Link>
                                    </Grid2>
                                  </Grid2>
                                )
                              );
                            })
                          ) : (
                            <Grid
                              container
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: "40px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "20px",
                                  color: "#6c6c6c",
                                }}
                              >
                                No resources added.
                              </Typography>
                            </Grid>
                          )
                        ) : null
                      ) : (
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "40px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "20px",
                              color: "#6c6c6c",
                            }}
                          >
                            No resources added.
                          </Typography>
                        </Grid>
                      )}
                    </Grid2>
                  </Grid2>
                )}
              </form>
            </Grid>
          </Grid>
        )}
        <Grid2
          container
          style={{
            fontFamily: "Cako regular",
            fontSize: "26px",
            fontWeight: 400,
            lineHeight: "36.01px",
            textAlign: "center",
            padding: isMobile ? "10px 15px" : "10px 0px",
            marginBottom: "20px",
          }}
        >
          Related Picnotes
        </Grid2>

        <MasonryCom
          picnotes={
            picnoteResponseWhileAddingNote ? myFeedsData?.myFeeds : relatedNotes
          }
          column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
          inAddR={"inAddR"}
          isDialogOpen={true}
          TabName={"AddResource"}
        />
      </Dialog>
      {isMobile ? null : (
        <IconButton
          onClick={goToNextPicnote}
          sx={{
            position: "Fixed",
            right: 20,
            top: "50%",
            transform: "translateY(-50%)",
            color: "#fff",
            backgroundColor: isMobile ? "rgba(255, 255, 255, 0.82)" : "#989898",

            "&:hover": { backgroundColor: "#dcdcdc" },
            zIndex: 1300,
          }}
        >
          <ChevronRightIcon sx={{ color: isMobile ? "red" : "white" }} />
        </IconButton>
      )}
    </>
  );
}

export default AddResource;
