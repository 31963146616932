import React, { useEffect, useState } from "react";
import back from "../../assets/Vector.png";
import forward from "../../assets/Vector (1).png";
import Frame from "../../assets/Frame (1).png";
// import vector from "../../assets/Vector 7179.png";
import vector from "../../assets/Vector 7179 - Copy.png";
import vectorM from "../../assets/Vector 7179 - Copym.png";
// import A from "../../assets/A.png";
import A from "../../assets/A.copy.png";
import AM from "../../assets/A.copyM.png";
// import Logo from "../../assets/logo-clean-.png";
import ReactFlipCard from "reactjs-flip-card";
import { Box, Input, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import "./CardCarousel.css";
import {
  deleteFlashCard,
  editFlashCard,
  flashCardReducer,
  getCardsOfCategory,
  getCategoryWithCards,
} from "../../redux/flashCards";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function CardCarousel({ handleBackScreen, categoryTitle, category }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [editCard, setEditCard] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const categoryid = category?.category?.categoryId;

  useEffect(() => {
    dispatch(getCardsOfCategory(categoryid));
    // eslint-disable-next-line
  }, [dispatch]);

  const { CategoryCards, getCWCloading } = useSelector(flashCardReducer);

  const selectedCarda = CategoryCards?.listCards || [];

  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuVisible((prev) => !prev);
  };

  const colors = ["#1da8c7", "#faac50", "#ff71aa", "#795efe"];

  const handleNext = () => {
    setIsFlipped(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % selectedCarda?.length);
    }, 200); // Small delay to ensure the flip resets first
    setEditCard(false);
    setMenuVisible(false);
  };
  
  const handleBack = () => {
    setIsFlipped(false);
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + selectedCarda?.length) % selectedCarda?.length
      );
    }, 200);
    setEditCard(false);
    setMenuVisible(false);
  };
  
  const handleCancel = () => {
    setEditCard(false);
    setMenuVisible(false);
  };

  const visibleCards = [
    selectedCarda[
      (currentIndex - 1 + selectedCarda?.length) % selectedCarda?.length
    ],
    selectedCarda[currentIndex],
    selectedCarda[(currentIndex + 1) % selectedCarda?.length],
  ];

  const handleEditCard = () => {
    const currentCard = selectedCarda[currentIndex];
    formik.setValues({
      cardId: currentCard.cardId,
      categoryId: category?.category?.categoryId,
      question: currentCard.question || "",
      answer: currentCard.answer || "",
    });
    setEditCard(true);
  };

  const handleDeleteCard = async () => {
    const currentCard = selectedCarda[currentIndex];
    const cardId = currentCard?.cardId;
    const resp = await dispatch(deleteFlashCard(cardId));
    if (resp?.status === 200) {
      toast.success(resp?.data?.message);
      dispatch(getCategoryWithCards());
      dispatch(getCardsOfCategory(categoryid));
    } else {
      toast.error(resp?.data?.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      cardId: "",
      categoryId: category?.categoryId,
      question: "",
      answer: "",
    },

    onSubmit: async (values) => {
      try {
        const resp = await dispatch(editFlashCard(values));
        if (resp?.status === 200) {
          toast.success(resp.data.message);
          setEditCard(false);
          dispatch(getCategoryWithCards());
          dispatch(getCardsOfCategory(categoryid));
        } else {
          toast.error(resp.data.message || "Failed to update the card.");
        }
      } catch (error) {
        toast.error("An error occurred while updating the card.");
      }
    },
  });

  return (
    <>
      <Typography
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          cursor: "pointer",
          fontSize: "13px",
        }}
        onClick={handleBackScreen}
      >
        <KeyboardBackspaceIcon style={{ fontSize: "18px", color: "#3F3F3F" }} />
        Back
      </Typography>

      {getCWCloading ? (
        "Loading"
      ) : (
        <div
          style={{
            width: isMobile ? "300px" : "600px",
            margin: "0 auto",
            textAlign: "center",
            backgroundColor: "#F3F3F3",
            padding: "20px 0",
            borderRadius: "10px",
            zIndex: 12,
            overflow: "hidden",
          }}
        >
          <span
            style={{
              color: "#3F3F3F",
              fontWeight: 600,
              fontFamily: "aileron",
              fontSize: "20px",
            }}
          >
            {categoryTitle}
          </span>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              gap: isMobile ? "5px" : "10px",
              // overflow: "hidden",
            }}
          >
            {visibleCards.map((card, index) => {
              const cardIndex =
                (currentIndex + index - 1 + selectedCarda?.length) %
                selectedCarda?.length;
              const cardColor = colors[cardIndex % colors.length];

              return index === 1 ? (
                editCard ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      p: 2,
                      bgcolor: cardColor,
                      borderRadius: 2,
                      color: "#fff",
                      position: "relative",
                      minHeight: "100%",
                      flex: 1,
                      zIndex: 5,
                      minWidth: isMobile ? "65%" : "55%",
                      paddingTop: "30px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <span
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        {" "}
                        Q :
                      </span>
                      <Input
                        name="question"
                        value={formik.values.question}
                        onChange={formik.handleChange}
                        placeholder="Edit Question"
                        sx={{
                          flex: 1,
                          color: "#fff",
                          bgcolor: "transparent",
                          border: "none",
                          borderBottom: "none",
                          ":before": { borderBottomColor: "none" },
                          ":after": { borderBottomColor: "none" },
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <span
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        {" "}
                        A :
                      </span>
                      <Input
                        name="answer"
                        value={formik.values.answer}
                        onChange={formik.handleChange}
                        placeholder="Edit Answer"
                        sx={{
                          flex: 1,
                          color: "#fff",
                          bgcolor: "transparent",
                          border: "none",
                          borderBottom: "none",
                          ":before": { borderBottomColor: "none" },
                          ":after": { borderBottomColor: "none" },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                        paddingTop: "10px",
                      }}
                    >
                      <button
                        type="button"
                        onClick={handleCancel}
                        style={{
                          padding: "5px",
                          backgroundColor: cardColor,
                          color: "#fff",
                          border: "1px solid white",
                          cursor: "pointer",
                          fontWeight: "bold",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <CloseIcon />
                      </button>

                      <button
                        type="submit"
                        onClick={formik.handleSubmit}
                        style={{
                          padding: "5px",
                          backgroundColor: cardColor,
                          color: "#fff",
                          cursor: "pointer",
                          fontWeight: "bold",
                          border: "1px solid white",
                          borderRadius: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <CheckIcon />
                      </button>
                    </Box>
                  </Box>
                ) : (
                  <div className="card-container" onClick={toggleFlip}>
                    <div className={`card ${isFlipped ? "flipped" : ""}`}>
                      {/* Front Side Content */}
                      {isFlipped ? "" : (
                      <div
                        className="card-side card-front"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => {
                          setIsHovered(false);
                          setMenuVisible(false);
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            backgroundColor: cardColor,
                            backgroundImage: isMobile ? `url(${vectorM})` : `url(${vector})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100%",
                            backgroundPosition: "center",
                            width: isMobile ? "50vw" : "350px", 
                            height: isMobile ? "120px" : "200px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            fontSize: "14px",
                            fontFamily: "Aileron",
                            borderRadius: "10px",
                            backfaceVisibility: "hidden",
                            WebkitBackfaceVisibility: "hidden",
                          }}
                        >
                          {isHovered &&  !isFlipped && (
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                zIndex: 3,
                                cursor: "pointer",
                              }}
                              onClick={toggleMenu}
                            >
                              <div
                                style={{
                                  width: "28px",
                                  height: "28px",
                                  border: "1px solid rgba(0, 0, 0, 0.2)",
                                  borderRadius: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "5px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                }}
                              >
                                <MoreVertIcon />
                              </div>
                            </div>
                          )}

                          {menuVisible && (
                            <div
                              style={{
                                position: "absolute",
                                top: "40px",
                                right: "10px",
                                backgroundColor: "rgba(0, 0, 0, 0.2)",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                borderRadius: "5px",
                                zIndex: 4,
                              }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div
                                style={{
                                  padding: "10px",
                                  cursor: "pointer",
                                  borderBottom: "1px solid #ddd",
                                }}
                                onClick={handleEditCard}
                              >
                                <EditIcon style={{ fontSize: "20px" }} />
                              </div>
                              <div
                                style={{
                                  padding: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={handleDeleteCard}
                              >
                                <DeleteIcon style={{ fontSize: "20px" }} />
                              </div>
                            </div>
                          )}

                          <div>{card?.question}</div>

                          {/* Logo on Front */}
                          {/* {!isFlipped ? (
                          <img
                            src={Logo}
                            alt="Logo"
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                              width: "60px",
                              height: "22px",
                              zIndex: 5,
                            }}
                          />
                          ) : null} */}
                        </div>
                      </div>)}

                      {/* Back Side Content */}
                      <div className="card-side card-back">
                        <div
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "#19b76b",
                            backgroundImage: isMobile ? `url(${AM})` : `url(${A})`, // Ensure this URL is correct
                            backgroundSize: isMobile ? "100%" : "cover", // Makes the background image cover the entire card
                            backgroundRepeat: "no-repeat",
                            // backgroundPosition: "center", // Ce
                            height: isMobile ? "120px" : "200px",
                            width: isMobile ? "200px" : "350px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: 400,
                            fontFamily: "Aileron",
                            lineHeight: "18px",
                            padding: "20px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            zIndex: 1,
                            cursor: "pointer",
                            position: "relative",
                            overflow: "hidden",
                            transform: "rotateX(0deg)",
                            transition:
                              "transform 0.5s ease, opacity 0.5s ease",
                          }}
                        >
                          <div>{card?.answer}</div>

                          {/* Logo on Back */}
                          {/* <img
                            src={Logo}
                            alt="Logo"
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                              width: "60px",
                              height: "22px",
                              zIndex: 5,
                            }}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div
                  key={card?.id}
                  style={{
                    backgroundColor: cardColor,
                    borderRadius: "10px",
                    height: isMobile ? "120px" : "200px",
                    width: isMobile ? "200px" : "350px",
                    position: "relative",
                    transform:
                      index === 1
                        ? "scale(1)"
                        : index === 0
                        ? "translateX(-10px) translateY(10px) rotate(-5deg)"
                        : "translateX(10px) translateY(10px) rotate(5deg)",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    fontSize: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    cursor: "default",
                    overflow: "hidden",
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                      paddingLeft: "10px",
                    }}
                  >
                    {card?.question}
                  </span>
                </div>
              );
            })}
          </div>

          <span
            style={{
              color: "#707070",
              fontFamily: "Aileron",
              fontSize: "14px",
            }}
          >
            {currentIndex + 1} / {selectedCarda?.length}
          </span>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            {/* Back Button */}
            <button
              onClick={handleBack}
              aria-label="Go Back"
              style={{
                backgroundColor: "#fff",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40px",
                height: "40px",
                border: "none",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                transition: "transform 0.2s",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <img
                src={back}
                alt="Back"
                style={{ width: "60%", height: "70%" }}
              />
            </button>

            {/* Central Button */}
            <button
              style={{
                background: "#BC171D",
                borderRadius: "50%",
                padding: "10px",
                margin: "0 15px",
                border: "1px solid #BC171D",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                transition: "transform 0.2s",
              }}
              aria-label="Central Button"
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <img
                src={Frame}
                alt="Frame"
                style={{ width: "100%", height: "100%" }}
              />
            </button>

            {/* Next Button */}
            <button
              onClick={handleNext}
              aria-label="Go Next"
              style={{
                backgroundColor: "#fff",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40px",
                height: "40px",
                border: "none",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                transition: "transform 0.2s",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <img
                src={forward}
                alt="Next"
                style={{ width: "60%", height: "70%" }}
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CardCarousel;
