import { Button, Chip, Divider, Grid2, MenuItem, Typography, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import './SignUp.css';
import picture from './../../assets/login.png';
import person from './../../assets/person.png';
import personR from './../../assets/personR.png';
import facebook from './../../assets/facebook.png';
import google from './../../assets/google.png';
import arrow from './../../assets/arrow-down-01-round.png';
import { Input,  } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import * as yup from "yup";
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { schoolSignup, signup } from '../../redux/auth';
import "react-country-state-city/dist/react-country-state-city.css";
import {
  GetCountries,
  GetState,
  GetCity,
} from "react-country-state-city";
import { uploadTheFile } from '../../redux/media';
import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';

const centerStyle = {
  display: 'flex',
  justifyContent: 'center', 
  alignItems: 'center',
  // paddingLeft: '40px'
};



const CustomSelectIcon = () => (
  <img
    src={arrow} // Replace with the URL of your icon
    alt="Select icon"
    style={{ width: '20px', height: '20px', marginRight: '10px' }} // Adjust size as needed
  />
);


const inputStyle = {
  borderRadius: '25px',
  paddingLeft: '10px',
  height: '38px',
  border: "1px solid rgba(112, 112, 112, 0.5)",
  position: 'relative',
};

const labelStyle = {
  color: "rgba(112, 112, 112, 1)", 
  fontFamily: 'Aileron',
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "20.21px",
};

const signupStyle = {
  backgroundColor: "rgba(188, 23, 29, 0.1)",
  color: "rgba(188, 23, 29, 1)",
  borderRadius: '25px',
  height: '38px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: { sx: '115px', md: '227px' },
  cursor: 'not-allowed'
};

// Add a separate style for the icon container
const profileImageIconStyle = {
  position: 'absolute',
  left: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '60px', 
  height: '60px', 
  backgroundColor: 'rgba(239, 239, 239, 1)', 
  borderRadius: '50%', 
  backgroundImage: `url(${person})`, 
  backgroundSize: '26px 26px', 
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
};

const validationSchema =  yup.object({
  firstName: yup
    .string()
    .required("First Name is required")
    .min(3, "First Name must be at least 3 characters"),
  lastName: yup
    .string()
    .required("Last Name is required")
    .min(3, "Last Name must be at least 3 characters"),
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"),
  description: yup
    .string()
    .required("description is required")
    .min(3, "description must be at least 3 characters"),
  address: yup
    .string()
    .required("address is required")
    .min(3, "address must be at least 3 characters"),
  email: yup.string().email("Invalid email format").required("Email is required"),
  password: yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .required('Password is required'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})

function SchoolSignup() {
  const [countryid, setCountryid] = useState('');
  const [stateid, setStateid] = useState('');
  const [cityid, setCityid] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null); // Store the file
  const [imagePreview, setImagePreview] = useState(null); // Store the image URL




    // INSTANCE

    let dispatch = useDispatch();


  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      address: "",
      firstName: "",
      lastName: "",
      email: "",
      country: countryid,
      city: cityid,
      state: stateid,
      password: "",
      confirmPassword: "",
      userPicture: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Check if a file is selected for upload
        if (selectedFile) {
          let formData = new FormData();
          formData.append("file", selectedFile);
  
          // Dispatch the file upload action
          let respFile = await dispatch(uploadTheFile(formData));
  
          // Check if the file upload was successful
          if (respFile.status === 200) {
            // Assign the uploaded file URL to values.userPicture
            values.userPicture = respFile.data.url;
          } else {
            return toast.error("File upload failed!");
          }
        }
  
        // Dispatch signup action with form values, including userPicture
        let resp = await dispatch(schoolSignup(values));
  
        // Handle the response from the signup action
        if (resp.status === 200) {
          toast.success(resp.data.message);
          formik.resetForm();
        } else {
          toast.error(resp.data.message);
        }
      } catch (error) {
        // console.error("Error during signup:", error);
        toast.error("Something went wrong. Please try again.");
      }
    },
  });


  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result); // Correct variable name
    }).catch(error => {
      console.error('Error fetching countries:', error);
    });
  }, []);


  const handleImageUpload = (event) => {
    const file = event.target.files[0];

  
    if (file && file.size <= 2 * 1024 * 1024) {
      setSelectedImage(URL.createObjectURL(file)); 
      setImageError("");
    } else {
      setSelectedImage(null);
      setImageError("File size should be less then 2MB.");
    }
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      // console.log("Selected file:", file);

      // Set the file to state
      setSelectedFile(file);

      // Create an object URL to display the image preview
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
    } else {
      setSelectedFile(null);
      setImagePreview(null);
    }
  };
  return (
    <Grid2 container style={{ margin: '0px 0px 80px 0px' }}>
    <Grid2 item size={7.1} style={{ justifyContent: 'center', alignItems: 'start', display: 'flex', paddingLeft: '65px', marginTop: '60px'}}>
      <img src={picture} alt="Logo" style={{ height: '756px', width: '562px' }} />
    </Grid2>

    <Grid2 container item size={3} marginTop={"50px"}>
    <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid2 item size={12} style={centerStyle} >
            <Typography style={{
              color: 'rgba(63, 63, 63, 1)',
              fontFamily: "Cako regular",
              fontWeight: 400,
              fontSize: '24px',
              marginBottom: '30px ',
            }}>Sign Up for school</Typography>
          </Grid2>
          <Grid2 item size={12} paddingBottom={'10px'}>
              <label htmlFor="Name of Institution" style={labelStyle}>Name of Institution</label>
              <Input
                id="title"
                style={{ ...inputStyle, width: '100%' }}
                className='inputTextStyling'
                placeholder="Name of Institution"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
              />
               {formik.touched.title && formik.errors.title ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.title}</div>
                ) : null}
              
            </Grid2>
            <Grid2 item size={12} paddingBottom={'10px'}>
              <label htmlFor="Description" style={labelStyle}>Description</label>
              <TextArea
                id="description"
                style={{ ...inputStyle, borderRadius: '10px', width: '100%' }}
                className='inputTextStyling'
                placeholder="Description"
                name="description"
                autoSize={{
                  minRows: 2,
                  maxRows: 3,
                }}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
              />
               {formik.touched.description && formik.errors.description ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.description}</div>
                ) : null}
              
            </Grid2>
            
            <Grid2 item size={12} paddingBottom={'10px'}>
              <Typography style={labelStyle}>Country</Typography>
              <Select
                  sx={{
                    width: '100%',
                    height: '38px',
                    borderRadius: '25px',
                    '&:hover': {
                      borderColor: 'rgba(188, 23, 29, 1)',
                    },
                    '& .MuiSelect-select': {
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '8px',
                    },
                    backgroundColor: 'white',
                  }}
                  IconComponent={CustomSelectIcon}
                  onChange={(e) => {
                    const selectedCountryId = e.target.value;
                    setCountryid(selectedCountryId);
                    GetState(selectedCountryId)
                      .then((result) => {
                        setStateList(result);
                        setStateid('');
                        setCityid('');
                      });
                  }}
                  value={countryid || ''}
                >
                  <MenuItem value="">
                    <em>Select Country</em>
                  </MenuItem>
                  {countriesList.map((item) => (
                    <MenuItem key={item.id} value={item.id} sx={{ display: 'flex', alignItems: 'center' }}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid2>
            <Grid2 container item size={12} spacing={2} paddingBottom={'10px'}>
            <Grid2 item size={6}>
              <Typography variant="body6" style={labelStyle}>
                State
              </Typography>
              <Select
              sx={{
                width: '100%',
                height: '38px',
                borderRadius: '25px',
                '&:hover': {
                  borderColor: 'rgba(188, 23, 29, 1)',
                },
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '8px',
                },
                backgroundColor: 'white',
              }}
                IconComponent={CustomSelectIcon}
                  onChange={(e) => {
                    const selectedStateId = e.target.value;
                    const selectedState = stateList.find((state) => state.id === selectedStateId);
                    
                    setStateid(selectedState?.id || '');
                    formik.setFieldValue('state', selectedState?.name || ''); 

                    GetCity(countryid, selectedState?.id)
                      .then((result) => {
                        setCityList(result);
                        setCityid('');
                      });
                  }}
                  value={stateid || ''} 
                >
                  <MenuItem value="">
                    <em>Select State</em>
                  </MenuItem>
                  {stateList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                   {formik.touched.country && formik.errors.country ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.country}</div>
                ) : null}
                </Select>
            </Grid2>

            <Grid2 item size={6}>
              <Typography variant="body6" style={labelStyle}>
                City
              </Typography>
              <Select
              sx={{
                width: '100%',
                height: '38px',
                borderRadius: '25px',
                // border: '1px solid rgba(112, 112, 112, 0.5)',
                '&:hover': {
                  borderColor: 'rgba(188, 23, 29, 1)',
                },
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '8px',
                },
                backgroundColor: 'white',
              }}
                  IconComponent={CustomSelectIcon}
                  // style={{ width: '100%', height: '38px' }}
                  onChange={(e) => {
                    const selectedCityId = e.target.value; // Get the selected city ID
                    const selectedCity = cityList.find(city => city.id === selectedCityId); // Find the city object using its ID
                    setCityid(selectedCity?.id || ''); // Set the city ID in state
                    formik.setFieldValue('city', selectedCity?.name || ''); // Set the city name in Formik
                  }}
                  value={cityid} 
                >
                  <MenuItem value="">
                    <em>Select City</em>
                  </MenuItem>
                  {cityList.map((item) => (
                    <MenuItem key={item.id} value={item.id}> {/* Use item.id as value */}
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>



            </Grid2>
          </Grid2>

          <Grid2 item size={12} paddingBottom={'10px'}>
              <label htmlFor="address" style={labelStyle}>Address</label>
              <Input
                id="address"
                style={{ ...inputStyle, width: '100%' }}
                className='inputTextStyling'
                placeholder="eg. street no 21"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
              />
               {formik.touched.address && formik.errors.address ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.address}</div>
                ) : null}
              
            </Grid2>

            
          <Grid2 container item size={12} spacing={2}  paddingBottom={'10px'}>
            <Grid2 item size={6} >
              <label htmlFor="firstName" style={labelStyle}>First Name</label>
              <Input
                id="firstName"
                style={{ ...inputStyle, width: '100%' }}
                className='inputTextStyling'
                placeholder="First Name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              />
               {formik.touched.firstName && formik.errors.firstName ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.firstName}</div>
                ) : null}
              
            </Grid2>
            <Grid2 item size={6} >
              <label htmlFor="lastName" style={labelStyle}>Last Name</label>
              <Input
                id="lastName"
                className='inputTextStyling'
                style={{ ...inputStyle, width: '100%' }}
                placeholder="Last Name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
               {formik.touched.lastName && formik.errors.lastName ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.lastName}</div>
                ) : null}
            </Grid2>
            
          </Grid2>
          <Grid2 item size={12}  paddingBottom={'10px'} >
                <label htmlFor="email" style={labelStyle}>Email</label>
                <Input
                  id="email"
                  style={{ ...inputStyle, width: '100%' }}
                  placeholder="abc@gmail.com"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  
                />
                {formik.touched.email && formik.errors.email ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.email}</div>
                ) : null}
        
          </Grid2>

          <Grid2 container item size={12} spacing={2}  paddingBottom={'20px'}>
            <Grid2 item size={6} >
              <label htmlFor="password" style={labelStyle}>Password</label>
              <Input.Password
                id="password"
                style={{ ...inputStyle, width: '100%' }}
                placeholder="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
              {formik.touched.password && formik.errors.password ? (
                <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.password}</div>
              ) : null}
            </Grid2>

            <Grid2 item size={6} >
              <label htmlFor="confirmPassword" style={labelStyle}>Confirm Password</label>
              <Input.Password
                id="confirmPassword"
                style={{ ...inputStyle, width: '100%' }}
                placeholder="Confirm password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.confirmPassword}</div>
              ) : null}
            </Grid2>
          </Grid2>
            <label htmlFor="password" style={{
                color: "rgba(112, 112, 112, 1)", 
                fontFamily: 'Aileron',
                fontSize: "12px",
                fontWeight: 400,
                paddingBottom:'10px'  
            }}>Upload picture</label>
          <Grid2
            marginTop={'10px'}
              item
              size={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                marginBottom: '15px',
                position: 'relative',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '80px',
                border: '1px solid rgba(112, 112, 112, 0.5)',
                borderRadius: '10px',
              }}
            >
          {imagePreview ? (
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src={imagePreview}
              alt="Uploaded Pic"
              style={{
                maxWidth: '100%',
                maxHeight: '70px',
                objectFit: 'cover',
                borderRadius: '10px',
                marginLeft: '10px',
              }}
            />
            <Typography className="picnotePictureHeader" paddingLeft={'20px'}>
              Choose your image{' '}
              <span
                style={{ color: 'rgba(188, 23, 29, 1)', cursor: 'pointer' }}
                onClick={() => document.getElementById('pictureLink').click()}
              >
                browse
              </span>
            </Typography>
            <input
        hidden
        type="file"
        id="pictureLink"
        onChange={handleFileUpload} 
      />
          </span>
          ) : (
            <>
              <label htmlFor="image-upload" >
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start',
                    paddingLeft: '20px',
                  }}
                >
                  <img src={personR} alt="Placeholder" />
                  <Typography className="picnotePictureHeader" paddingLeft={'20px'}>
                    Choose your image{' '}
                    <span
                      style={{ color: 'rgba(188, 23, 29, 1)', cursor: 'pointer' }}
                      onClick={() => document.getElementById('pictureLink').click()}
                    >
                      browse
                    </span>
                  </Typography>
                </span>
              </label>

      <input
        hidden
        type="file"
        id="pictureLink"
        onChange={handleFileUpload} 
      />
    </>
  )}
          </Grid2>



      <Grid2 item size={12} paddingBottom={'20px'}>
        <Button
          fullWidth
          type="submit"
          sx={{
            backgroundColor: 'rgba(188, 23, 29, 1)',
            color: '#fff',
            borderRadius: '25px',
            mt: 2,
            '&:hover': {
              backgroundColor: 'rgba(188, 23, 29, 0.8)',
            }
          }}
        >
          Sign Up
        </Button>
      </Grid2>

      <Grid2 item size={12} paddingBottom={'20px'}>
        <Divider>
          <Chip label="Or" size="small" />
        </Divider>
      </Grid2>

      <Grid2 container item size={12} spacing={2}>
        <Grid2 item size={6}>
          <Typography style={signupStyle}>  <img src={google} alt="Google" />&nbsp; Google</Typography>
        </Grid2>
        <Grid2 item size={6}>
          <Typography style={signupStyle}><img src={facebook} alt="Facebook" />&nbsp; Facebook</Typography>
        </Grid2>
      </Grid2>

      <Grid2
                      item                      
                      size={12}
                      justifyContent="center"
                      alignItems={'center'}
                      display={'flex'}
                      style={{ marginTop: "2rem", paddingBottom: '20px' }}          
                    >
                              <span style={{ color: "#829096" }}>
                                already have an account?{" "}
                                <Link
                                  to="../login"
                                  style={{
                                    color: "rgba(188, 23, 29, 1)",
                                    fontWeight: 600,
                                    textDecoration: "none",
                                  }}
                                >
                                  Log in
                                </Link>
                              </span>
                      </Grid2>
    </form>
    </Grid2>


  </Grid2>
  )
}

export default SchoolSignup