import axios from "axios";
import config from "../services/config";
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  createFolderloading: false,
  getFolderloading: false,
  allFolderList: null,
  getPWFloading: false,
  deleteFolderloading: false,
  picnotesWFolder: null,
}

const slice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    creatingFolder(state, action) {
      state.createFolderloading = true;
      state.error = null;
    },
    creatingFolderSuccess(state, action) {
      state.createFolderloading = false;
      state.error = null;

    },
    creatingFolderFailure(state, action) {
      state.createFolderloading = false;
      state.error = action.payload;
    },
    gettingFolder(state, action) {
      state.getFolderloading = true;
      state.error = null;
    },
    gettingFolderSuccess(state, action) {
      state.getFolderloading = false;
      state.error = null;
      state.allFolderList = action.payload;

    },
    gettingFolderFailure(state, action) {
      state.getFolderloading = false;
      state.error = action.payload;
    },
    gettingPicnotesWFolder(state, action) {
      state.getPWFloading = true;
      state.error = null;
    },
    gettingPicnotesWFolderSuccess(state, action) {
      state.getPWFloading = false;
      state.error = null;
      state.picnotesWFolder = action.payload;

    },
    gettingPicnotesWFolderFailure(state, action) {
      state.getPWFloading = false;
      state.error = action.payload;
    },
    deletingFolder(state, action) {
      state.deleteFolderloading = true;
      state.error = null;
    },
    deletingFolderSuccess(state, action) {
      state.deleteFolderloading = false;
      state.error = null;
      state.picnotesWFolder = action.payload;

    },
    deletingFolderFailure(state, action) {
      state.deleteFolderloading = false;
      state.error = action.payload;
    },
  },
  });

  const {
    creatingFolder,
    creatingFolderSuccess,
    creatingFolderFailure,
    gettingFolder,
    gettingFolderSuccess,
    gettingFolderFailure,
    gettingPicnotesWFolder,
    gettingPicnotesWFolderSuccess,
    gettingPicnotesWFolderFailure,
    deletingFolder,
    deletingFolderSuccess,
    deletingFolderFailure,
  } = slice.actions;

export const folderReducer = (state) => state.folders


export const createFolder = (data) => async (dispatch) => {
  dispatch(creatingFolder());
  try {
    const response = await axios.post(
      `${config.baseUrl}/user/addfolder`, data    );

    if (response.status === 200) {
      dispatch(creatingFolderSuccess(response.status)); 
    } else {
      dispatch(creatingFolderFailure(response.status)); 
    }

    return response;
  } catch (e) {
    dispatch(
      creatingFolderFailure("Something went wrong while creating folder!")
    );
  }
};
export const getFolder = () => async (dispatch) => {
  dispatch(gettingFolder());
  try {
    const response = await axios.get(
      `${config.baseUrl}/user/folderlist`);

    if (response.status === 200) {
      dispatch(gettingFolderSuccess(response.data)); 
    } else {
      dispatch(gettingFolderFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      gettingFolderFailure("Something went wrong while getting folder!")
    );
  }
};


export const getPicnotesWithFolder = () => async (dispatch) => {
  dispatch(gettingPicnotesWFolder());
  try {
    const response = await axios.get(
      `${config.baseUrl}/picnote/folderwithpicnotes`);

    if (response.status === 200) {
      dispatch(gettingPicnotesWFolderSuccess(response.data)); 
    } else {
      dispatch(gettingPicnotesWFolderFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      gettingPicnotesWFolderFailure("Something went wrong while getting folder!")
    );
  }
};


export const deleteFolder = (id) => async (dispatch) => {
  dispatch(deletingFolder());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/picnote/deletefolder`, id);

    if (response.status === 200) {
      dispatch(deletingFolderSuccess(response.data)); 
    } else {
      dispatch(deletingFolderFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      deletingFolderFailure("Something went wrong while deleting folder!")
    );
  }
};

export default slice.reducer;
