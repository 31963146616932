import { Card, CardContent, Grid, Grid2, Typography } from "@mui/material";
import React from "react";
// import backgroundImage from "../../assests/BG.png";
import { Link } from "react-router-dom";
import backgroundImage from "../assets/bg.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styleCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const styleText = {
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Aileron",
};

function TermsCondition({ onClose }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      style={{
        padding: "1rem", // or use a responsive unit
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // Ensures the background covers the whole area
        backgroundPosition: "center", // Centers the background image
        height: "calc(100vh - 86px)", // Fits the remaining viewport height
      }}
      justifyContent="center"
      alignItems="center" // Center vertically
    >
      <Grid
        container
        alignItem="center"
        justifyContent="center"
        xs={isMobile ? 12 : 10} 
      >
        <Card
          style={{
            boxShadow: "10px 10px 30px 0px #6271EB33",
            borderRadius: "24px",
          }}
        >
          {" "}
          <CardContent
            style={{
              margin: "20px 30px",
              height: "500px",
              overflow: "auto",
            }}
          >
            <Typography
              variant="h2"
              style={{
                color: "#213641",
                fontSize: "30px",
                fontWeight: 400,
                paddingBottom: "20px",
              }}
            >
              Terms and Conditions
            </Typography>
            <Typography
              style={{
                color: "#666666",
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Lexend",
                paddingBottom: "10px",
              }}
            ></Typography>
            <Grid2 container spacing={2}>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "justify",
                }}
              >
                <Typography>
                  These terms and conditions (the "Terms and Conditions") govern
                  the use of www.picnotes.org (the "Site"). This Site is owned
                  and operated by Picnotes Learnings Ltd.. This Site is a social
                  media and user-generated content.
                  <br />
                  <br /> By using this Site, you indicate that you have read and
                  understand these Terms and Conditions and agree to abide by
                  them at all times.
                </Typography>
              </Grid2>

              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Intellectual Property
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  All content published and made available on our Site is the
                  property of Picnotes Learnings Ltd. and the Site's creators.
                  This includes, but is not limited to images, text, logos,
                  documents, downloadable files and anything that contributes to
                  the composition of our Site.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Age Restrictions
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <span style={{ textDecoration: "underline" }}>Employees</span>
                  <br />
                  The minimum age to use our Site is 13 years old. By using this
                  Site, users agree that they are over 13 years old. We do not
                  assume any legal responsibility for false statements about
                  age.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Acceptable Use
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  As a user of our Site, you agree to use our Site legally, not
                  to use our Site for illegal purposes, and not to: <br />
                  <br />
                  - Harass or mistreat other users of our Site; - Violate the
                  rights of other users of our Site; or <br />
                  <br />
                  - Post any material that may be deemed inappropriate or
                  offensive. <br />
                  <br />
                  If we believe you are using our Site illegally or in a manner
                  that violates these Terms and Conditions, we reserve the right
                  to limit, suspend or terminate your access to our Site. We
                  also reserve the right to take any legal steps necessary to
                  prevent you from accessing our Site.
                  <br />
                  <br />
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Accounts
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  When you create an account on our Site, you agree to the
                  following:
                  <br />
                  <br />
                  1. You are solely responsible for your account and the
                  security and privacy of your account, including passwords or
                  sensitive information attached to that account; and
                  <br />
                  <br />
                  2. All personal information you provide to us through your
                  account is up to date, accurate, and truthful and that you
                  will update your personal information if it changes.
                  <br />
                  <br />
                  We reserve the right to suspend or terminate your account if
                  you are using our Site illegally or if you violate these Terms
                  and Conditions.
                  <br />
                  <br />
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Links to Other Websites
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  Our Site contains links to third party websites or services
                  that we do not own or control. We are not responsible for the
                  content, policies, or practices of any third party website or
                  service linked to on our Site. It is your responsibility to
                  read the terms and conditions and privacy policies of these
                  third party websites before using these sites.
                  <br />
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Limitation of Liability
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  Picnotes Learnings Ltd. and our directors, officers, agents,
                  employees, subsidiaries, and affiliates will not be liable for
                  any actions, claims, losses, damages, liabilities and expenses
                  including legal fees from your use of the Site.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Indemnity
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  Except where prohibited by law, by using this Site you
                  indemnify and hold harmless Picnotes Learnings Ltd. and our
                  directors, officers, agents, employees, subsidiaries, and
                  affiliates from any actions, claims, losses, damages,
                  liabilities and expenses including legal fees arising out of
                  your use of our Site or your violation of these Terms and
                  Conditions.
                  <br />
                </Typography>
              </Grid2>

              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Applicable Law
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  These Terms and Conditions are governed by the laws of the
                  Province of British Columbia.
                  <br />
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Severability
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  If at any time any of the provisions set forth in these Terms
                  and Conditions are found to be inconsistent or invalid under
                  applicable laws, those provisions will be deemed void and will
                  be removed from these Terms and Conditions. All other
                  provisions will not be affected by the removal and the rest of
                  these Terms and Conditions will still be considered valid.
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Changes
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  These Terms and Conditions may be amended from time to time in
                  order to maintain compliance with the law and to reflect any
                  changes to the way we operate our Site and the way we expect
                  users to behave on our Site. We will notify users by email of
                  changes to these Terms and Conditions or post a notice on our
                  Site.
                  <br />
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Aileron",
                    textDecoration: "underline",
                  }}
                >
                  Contact Details
                </Typography>
              </Grid2>
              <Grid2
                item
                size={12}
                style={{
                  textAlign: "start",
                }}
              >
                <Typography style={styleText}>
                  <br />
                  Please contact us if you have any questions or concerns. Our contact details are as follows:

                  <br />
                  <br />
                  (604) 347-9629
                  <br />
                  akane@picnotes.org
                  <br />
                  55 Bremner Blvd Toronto ON M5J 0A6<br /><br />
                  You can also contact us through the feedback form available on our Site.
                  <br />
                  <br />
                
                    Effective Date: 1st day of August, 2024
                    
                </Typography>
              </Grid2>
              
            </Grid2>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default TermsCondition;
