import { Button, Grid2 } from "@mui/material";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import favourite from "./../../assets/favourite.png";
import flashCardsIcon from "./../../assets/flashCardsIcon.png";
import folder from "./../../assets/folder.png";
import tools from "./../../assets/EdTools.png";
import book from "./../../assets/book.png";
import green from "./../../assets/GreenDot.png";
import red from "./../../assets/redDot.png";
import yellow from "./../../assets/YellowDot.png";
import menuSquare from "./../../assets/dashboard-square.png";
import note from "./../../assets/note.png";
import MyProfile from "../MyProfile";
import MyEdTools from "../MyEdTools";
import PicnotesCards from "../PicnotesCards";
import CreateNewFolder from "../CreateNewFolder";
import CreateNewPicnote from "../create-picnote/CreateNewPicnote";
import MyFolders from "../MyFolders";
import Followers from "../Followers";
import MyPicnotes from "../create-picnote/MyPicnotes";
import MyEdTracker from "../MyEdTracker";
import MyFavorite from "../MyFavorite";
import Following from "../Following";
import { useSelector } from "react-redux";
import FlashCards from "../flash-cards/FlashCards";
import CreateFlashCard from "../flash-cards/CreateFlashCard";
import CategoryList from "../create-picnote/CategoryList";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getFollowingList } from "../../redux/tags";

const addPicnoteButtonStyle = {
  borderRadius: "20px",
  backgroundColor: "rgba(188, 23, 29, 1)",
  color: "#fff",
  fontSize: "12px",
  fontWeight: 300,
  height: "35px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid",
  padding: "15px",
  textTransform: "none",
};
const addPicnoteButtonStyleMobile = {
  borderRadius: "20px",
  backgroundColor: "rgba(188, 23, 29, 1)",
  color: "#fff",
  fontSize: "10px",
  fontWeight: 300,
  height: "25px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid",
  padding: "15px",
  textTransform: "none",
};

const logoStyle = {
  fontFamily: "Aileron",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "19.2px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#4c4c4c",
};
const Heading = {
  fontFamily: "Cako regular",
  fontSize: "26px",
  fontWeight: 400,
  color: "rgba(63, 63, 63, 1)",
};

const logoStyle1 = {
  width: "16px",
  height: "16px",
  marginRight: "5px",
};

const activeTabStyle = {
  color: "rgba(188, 23, 29, 1)",
};

const textStyle = {
  fontFamily: "Aileron",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "14.8px",
  textAlign: "right",
  color: "#3F3F3F",
};

const activeImageStyle = {
  filter:
    "invert(1%) sepia(22%) saturate(5855%) hue-rotate(339deg) brightness(90%) contrast(127%)",
};

function Pages() {
  const [activeTabKey2, setActiveTabKey2] = useState("myFeed");
  const [createFolder, setCreateFolder] = useState(false);
  const [createFlashcrads, setCreateFlashcrads] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [orgInfo, setOrgInfo] = useState(() =>
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [tempInfo, setTempInfo] = useState(() =>
    JSON.parse(localStorage.getItem("TempuserInfo"))
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { selectedFollower, followingList } = useSelector((state) => state.tags);

  const handleNewFlashcardsClose = () => {
    setCreateFlashcrads(false);
  };


  const contentListNoTitle = {
    myFeed: <PicnotesCards />,
    myFolders: <MyFolders />,
    myEdTools: <MyEdTools />,
    myPicnotes: <MyPicnotes />,
    myEdTracker: <MyEdTracker />,
    myFavorites: <MyFavorite />,
    myFlashcards: createFlashcrads ? (
      <CreateFlashCard handleNewFlashcardsClose={handleNewFlashcardsClose} />
    ) : (
      <CategoryList />
    ),
  };

  const handleShowFollowers = () => {
    setActiveSection("followers");
  };
  const handleShowPicnotes = () => {
    setActiveSection("picnotes");
  };

  const handleShowFollowing = () => {
    setActiveSection("following");
  };

  const onTab2Change = (key) => {
    setActiveTabKey2(key);
    setActiveSection(null);

    if (key !== "myFlashcards") {
      setCreateFlashcrads(false);
    }
  };

  const handleNewFolder = () => {
    setCreateFolder(true);
  };
  const handleNewFlashcards = () => {
    setCreateFlashcrads(true);
  };

  const handleNewPicnote = () => {
    setCreateNew(true);
  };

  const closePicnote = () => {
    setCreateNew(false);
  };

  const onClose = () => {
    setCreateFolder(false);
  };

  useEffect(() => {
    const tempUserInfo = JSON.parse(localStorage.getItem("TempuserInfo"));
    setTempInfo(tempUserInfo);
    setActiveTabKey2("myFeed");
    getFollowingList()
  }, [selectedFollower]);

  useEffect(() => {
    const hasReloaded = sessionStorage.getItem("hasReloaded");

    if (!hasReloaded) {
      sessionStorage.setItem("hasReloaded", "true");
      window.location.reload();
    }
  }, []);

  const tabListNoTitle = [
    {
      key: "myFeed",
      label: (isActive) => (
        <span style={{ ...logoStyle, ...(isActive ? activeTabStyle : {}) }}>
          <img
            src={menuSquare}
            alt="logo"
            style={{
              ...logoStyle1,
              ...(isActive ? activeImageStyle : {}),
            }}
          />
          {orgInfo?.userId === tempInfo?.userId ? "My Feed" : "Feed"}
        </span>
      ),
    },
    {
      key: "myPicnotes",
      label: (isActive) => (
        <span style={{ ...logoStyle, ...(isActive ? activeTabStyle : {}) }}>
          <img
            src={note}
            alt="logo"
            style={{
              ...logoStyle1,
              ...(isActive ? activeImageStyle : {}),
            }}
          />
          {orgInfo?.userId === tempInfo?.userId ? "My Picnotes" : "Picnotes"}
        </span>
      ),
    },
    {
      key: "myFolders",
      label: (isActive) => (
        <span style={{ ...logoStyle, ...(isActive ? activeTabStyle : {}) }}>
          <img
            src={folder}
            alt="logo"
            style={{
              ...logoStyle1,
              ...(isActive ? activeImageStyle : {}),
            }}
          />
          {orgInfo?.userId === tempInfo?.userId ? "My Folders" : "Folder"}
        </span>
      ),
    },
    {
      key: "myFavorites",
      label: (isActive) => (
        <span style={{ ...logoStyle, ...(isActive ? activeTabStyle : {}) }}>
          <img
            src={favourite}
            alt="logo"
            style={{
              ...logoStyle1,
              ...(isActive ? activeImageStyle : {}),
            }}
          />
          {orgInfo?.userId === tempInfo?.userId ? "My Favorites" : "Favorites"}
        </span>
      ),
    },
    {
      key: "myFlashcards",
      label: (isActive) => (
        <span style={{ ...logoStyle, ...(isActive ? activeTabStyle : {}) }}>
          <img
            src={flashCardsIcon}
            alt="logo"
            style={{
              ...logoStyle1,
              ...(isActive ? activeImageStyle : {}),
            }}
          />
          {orgInfo?.userId === tempInfo?.userId
            ? "My Flashcards"
            : "Flashcards"}
        </span>
      ),
    },
    {
      key: "myEdTracker",
      label: (isActive) => (
        <span style={{ ...logoStyle, ...(isActive ? activeTabStyle : {}) }}>
          <img
            src={book}
            alt="logo"
            style={{
              ...logoStyle1,
              ...(isActive ? activeImageStyle : {}),
            }}
          />
          {orgInfo?.userId === tempInfo?.userId ? "My EdTracker" : "EdTracker"}
        </span>
      ),
    },
    {
      key: "myEdTools",
      label: (isActive) => (
        <span style={{ ...logoStyle, ...(isActive ? activeTabStyle : {}) }}>
          <img
            src={tools}
            alt="logo"
            style={{
              ...logoStyle1,
              ...(isActive ? activeImageStyle : {}),
            }}
          />
          {orgInfo?.userId === tempInfo?.userId ? "My EdTools" : "EdTools"}
        </span>
      ),
    },
  ];

  // localStorage.setItem("baseFollowingList", JSON.stringify(followingList));

  return (
    <Grid2
      container
      sx={{
        p: {
          xs: "10px",
          sm: "20px",
          md: "30px 40px 30px 80px",
        },
      }}
    >
      <Grid2
        item
        size={12}
        sx={{
          ...Heading,
          textAlign: "start",
          mb: { xs: "10px", md: "20px", paddingLeft: isMobile ? "50px" : null },
        }}
      >
        {orgInfo?.userId === tempInfo?.userId ? "My Profile" : "Profile"}
      </Grid2>

      <Grid2 container spacing={4} item size={12}>
        <Grid2
          item
          size={isMobile ? 12 : isTab ? 12 : 2.5}
          style={{
            mb: { xs: "10px", md: "0" },
            display: "flex",
            justifyContent: isMobile ? "center" : "start",
            alignItems: "start",
          }}
        >
          <MyProfile
            onShowFollowers={handleShowFollowers}
            onShowFollowing={handleShowFollowing}
            onShowPicnotes={handleShowPicnotes}
            
            activeTabStyle={{
              color: activeSection ? "#da343a" : null,
            }}
          />
        </Grid2>

        {/* <Grid2 item size={9.4} md={9}> */}
        <Grid2 item size={isMobile || isTab ? 12 : 9.4} sx={{ mb: "10px" }}>
          {createFolder && <CreateNewFolder onClose={onClose} />}
          {createNew && <CreateNewPicnote onClose={closePicnote} />}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              minHeight: "100vh", 
              width: "100%", 
              boxSizing: "border-box",
              
            }}
          >
            <Card
              style={{
                width: "100%",
                minHeight: "650px",
              }}
              tabList={tabListNoTitle.map((tab) => ({
                ...tab,
                label: tab.label(
                  activeSection ? false : tab.key === activeTabKey2
                ), 
              }))}
              activeTabKey={activeSection ? null : activeTabKey2} // Use null when activeSection is active
              tabBarExtraContent={
                orgInfo?.userId === tempInfo?.userId && (
                  <span>
                    {activeTabKey2 === "myPicnotes" ? (
                      !isMobile ? (
                        <Button
                          style={addPicnoteButtonStyle}
                          onClick={handleNewPicnote}
                        >
                          Add New Picnote
                        </Button>
                      ) : (
                        <AddCircleOutlineIcon onClick={handleNewPicnote} style={{marginLeft: '10px', color: "rgba(188, 23, 29, 1)" }} />
                      )
                    ) : activeTabKey2 === "myFolders" ? (
                      !isMobile ? (
                      <Button
                        style={addPicnoteButtonStyle}
                        onClick={handleNewFolder}
                      >
                        Create Folder
                      </Button> ) : (
                        <AddCircleOutlineIcon onClick={handleNewFolder} style={{marginLeft: '10px', color: "rgba(188, 23, 29, 1)" }} />

                      )
                    ) :
                     activeTabKey2 === "myFlashcards" ? (
                      !isMobile ? (

                      <Button
                        style={addPicnoteButtonStyle}
                        onClick={handleNewFlashcards}
                      >
                        Create Flashcard
                      </Button> ) : (
                        <AddCircleOutlineIcon onClick={handleNewFlashcards} style={{marginLeft: '10px', color: "rgba(188, 23, 29, 1)" }} />

                      )
                    ) : activeTabKey2 === "myEdTracker" && 
                      !isMobile ?
                      (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          paddingLeft: '10px'
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={red}
                            alt="dot"
                            style={{
                              width: "8px",
                              height: "8px",
                              marginRight: "5px",
                            }}
                          />
                          <span
                            style={{
                              ...textStyle,
                              fontSize: isMobile ? "9px" : "12px",
                            }}
                          >
                            New Notes
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={yellow}
                            alt="dot"
                            style={{
                              width: "8px",
                              height: "8px",
                              marginRight: "5px",
                            }}
                          />
                          <span
                            style={{
                              ...textStyle,
                              fontSize: isMobile ? "9px" : "12px",
                            }}
                          >
                            Progress
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={green}
                            alt="dot"
                            style={{
                              width: "8px",
                              height: "8px",
                              marginRight: "5px",
                            }}
                          />
                          <span
                            style={{
                              ...textStyle,
                              fontSize: isMobile ? "9px" : "12px",
                            }}
                          >
                            Complete
                          </span>
                        </div>
                      </div> ) : null}
                    
                  </span>
                )
              }
              onTabChange={onTab2Change}
            >
              {activeSection === "followers" ? (
                <Followers />
              ) : activeSection === "following" ? (
                <Following />
              ) : activeSection === "picnotes" ? (
                <MyPicnotes />
              ) : (
                contentListNoTitle[activeTabKey2]
              )}
            </Card>
          </div>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

export default Pages;
