import {
  Grid2,
  Typography,
  Card,
  CardContent,
  Divider,
  Box,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import location from "./../assets/location.png";
import elements from "./../assets/elements.png";
import checkedUser from "./../assets/user-check.png";
import group from "./../assets/user-group.png";
import editIcon from "./../assets/edit-02 (1).png";
import EditProfile from "./EditProfile";
import LifeLongFollowers from "./LifeLongFollowers";
import {
  followUser,
  profileDetial,
  unfollowUser,
  VisiterProfileDetial,
} from "../redux/user";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getFollowingList } from "../redux/tags";
import { myFeed } from "../redux/picnote";
import toast from "react-hot-toast";

const noteStyle = {
  fontFamily: "Aileron",
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "center",
  color: "rgba(112, 112, 112, 1)",
};

const styleText = {
  fontFamily: "Aileron",
  fontSize: "10px",
  fontWeight: 400,
  textAlign: "center",
  color: "rgba(112, 112, 112, 1)",
};

const numberStyle = {
  fontFamily: "Aileron",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
  color: "rgba(63, 63, 63, 1)",
  padding: "6px 0px",
};

const labelStyle = {
  marginBottom: "5px",
  color: "rgba(63, 63, 63, 1)",
  fontFamily: "Aileron",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  textAlign: "center",
};

const iconStyle = {
  width: "20px",
  height: "20px",
};

const followerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
};

function MyProfile({
  onShowFollowers,
  onShowFollowing,
  onShowPicnotes,
  activeTabStyle,
}) {
  const [editOpen, setEditOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [profileData, setProfileData] = useState(null);
  // const [followersList, setFollowersList] = useState(false);
  const [picnotesList, setPicnotesList] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Fetch data from the Redux store
  const { profileDetails, vProfileDetails } = useSelector(
    (state) => state.user
  );
  const { selectedFollower, followingList } = useSelector(
    (state) => state.tags
  );

  // console.log("selectedFollower", selectedFollower);

  useEffect(() => {
    if (profileDetails?.profile) {
      setProfileData(profileDetails?.profile);
    }
  }, [profileDetails]);

  useEffect(() => {
    if (selectedFollower) {
      dispatch(VisiterProfileDetial(selectedFollower?.edfId));
    } else {
      dispatch(profileDetial());
      // Fetch the list of following users below to check whether to display the 'Follow' or 'Unfollow' button.
      dispatch(getFollowingList());
    }

    try {
      const userDetails = localStorage.getItem("userInfo");
      if (userDetails) {
        const parsedUserInfo = JSON.parse(userDetails);
        setUserInfo(parsedUserInfo);
      } else {
        console.warn("No user info found in localStorage");
      }
    } catch (error) {
      console.error("Error reading from localStorage:", error);
    }
  }, [dispatch, selectedFollower]);

  const handleEdit = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const renderProfileData = selectedFollower
    ? vProfileDetails?.profile
    : profileDetails?.profile;

  const orgInfo = JSON.parse(localStorage.getItem("userInfo"));
  const tempInfo = JSON.parse(localStorage.getItem("TempuserInfo"));
  const baseFollowingList = JSON.parse(
    localStorage.getItem("baseFollowingList")
  );

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    if (tab === "followers") onShowFollowers();
    else if (tab === "following") onShowFollowing();
    else if (tab === "picnotes") onShowPicnotes();
  };

  const handleFollowUser = async (followId) => {
    let resp = await dispatch(followUser(followId));
    if (resp.status === 200) {
      toast.success(resp?.data?.message);
      dispatch(getFollowingList());
      dispatch(profileDetial());
      dispatch(myFeed());
  
      // Retrieve local storage data (now directly an array)
      let fdata = JSON.parse(localStorage.getItem("baseFollowingList")) || [];
  
      // Ensure followId is a string and not an object
      let cleanFollowId = typeof followId === "object" ? followId.followId : followId;
  
      // Assuming you have user details from response or elsewhere
      let newUser = { edfId: cleanFollowId, firstName: "New", lastName: "User" }; // Replace with actual user details
  
      // Avoid duplicates
      if (!fdata.some(user => user.edfId === cleanFollowId)) {
        fdata.push(newUser);
        localStorage.setItem("baseFollowingList", JSON.stringify(fdata));
      }
    } else {
      toast.error(resp?.data?.message);
    }
  };
  

  const handleUnfollowUser = async (unFollowIdObj) => {
    let resp = await dispatch(unfollowUser(unFollowIdObj));

    if (resp.status === 200) {
      // Ensure message is a string
      let successMessage = typeof resp?.data?.message === "string" ? resp.data.message : JSON.stringify(resp.data.message);
      toast.success(successMessage);

      dispatch(getFollowingList());
      dispatch(profileDetial());
      dispatch(myFeed());

      let fdata = JSON.parse(localStorage.getItem("baseFollowingList")) || [];
      if (!Array.isArray(fdata)) fdata = [];

      let unFollowId = String(unFollowIdObj.unFollowId);
      fdata = fdata.filter((item) => String(item.edfId) !== unFollowId);

      localStorage.setItem("baseFollowingList", JSON.stringify(fdata));
    } else {
      // Ensure error message is a string
      let errorMessage = typeof resp?.data?.message === "string" ? resp.data.message : JSON.stringify(resp.data.message);
      toast.error(errorMessage);
    }
};


  return (
    <>
      {picnotesList && <LifeLongFollowers />}
      {editOpen && (
        <EditProfile
          userDetails={renderProfileData}
          onClose={handleEditClose}
        />
      )}{" "}
      <Card
        sx={{
          width: {
            xs: "275px",
            sm: "275px",
            md: "275px",
            lg: "275px",
            xl: "275px",
            xxl: "275px",
          },
          maxWidth: {
            xs: "275px",
            sm: "275px",
            md: "275px",
            lg: "275px",
            xl: "275px",
            xxl: "275px",
          },

          padding: "10px",
          border: "8px solid rgba(243, 243, 243, 1)",
          borderRadius: "10px",
          minHeight: "400px",
          maxHeight: orgInfo?.userId !== tempInfo?.userId ? "430px" : "400px",
        }}
      >
        <CardContent>
          <Grid2 container alignItems="center" spacing={2}>
            
            <Grid2 item size={12} textAlign={"center"}>
              <Box
                position="relative"
                display="block"
                width="fit-content"
                margin="auto"
              >
                {renderProfileData?.userPicture?.endsWith(".jpg") ||
                renderProfileData?.userPicture?.endsWith(".jpeg") ||
                renderProfileData?.userPicture?.endsWith(".png") ? (
                  <img
                    src={renderProfileData?.userPicture}
                    alt="profile"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      display: "block",
                    }}
                  />
                ) : (
                  <Box
                    width="100px"
                    height="100px"
                    borderRadius="50%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#b10303"
                    color="white"
                    fontSize="40px"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    {renderProfileData?.firstName?.[0]}
                    {renderProfileData?.lastName?.[0]}
                  </Box>
                )}

                {orgInfo?.userId === tempInfo?.userId ? (
                  <Box
                    position="absolute"
                    bottom="0px"
                    right="0px"
                    width="32px"
                    height="32px"
                    borderRadius="50%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="rgba(248, 227, 228, 1)"
                    border="2px solid #fff"
                  >
                    <img
                      src={editIcon}
                      alt="edit icon"
                      style={{
                        width: "14px",
                        height: "14px",
                        cursor: "pointer",
                      }}
                      onClick={handleEdit}
                    />
                  </Box>
                ) : null}
              </Box>
            </Grid2>

            <Grid2 item size={12}>
              <Typography
                variant="body2"
                style={{
                  ...labelStyle,
                  fontSize:
                    (renderProfileData?.firstName + renderProfileData?.lastName)
                      .length > 20
                      ? "10px"
                      : "inherit",
                  [theme.breakpoints.down(125)]: {
                    fontSize: "10px",
                  },
                }}
              >
                {/* {renderProfileData?.firstName +
                  " " +
                  renderProfileData?.lastName} */}
                {(renderProfileData?.firstName || " ") +
                  " " +
                  (renderProfileData?.lastName || " ")}
              </Typography>

              <Typography
                variant="body2"
                style={{
                  ...noteStyle,
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  [theme.breakpoints.down(125)]: {
                    fontSize: "10px",
                  },
                }}
              >
                {renderProfileData?.city || renderProfileData?.country ? (
                  <img
                    src={location}
                    alt="location"
                    style={{ marginRight: "5px" }}
                  />
                ) : null}
                {(renderProfileData?.city || "") +
                  " " +
                  (renderProfileData?.country || "")}
              </Typography>

              <Typography
                variant="body2"
                style={{
                  ...noteStyle,
                  minHeight: "40px",
                  maxHeight: "40px",
                  [theme.breakpoints.down(125)]: {
                    fontSize: "10px",
                  },
                }}
              >
                {renderProfileData?.description
                  ? renderProfileData.description.length > 80
                    ? renderProfileData.description.slice(0, 80) + "..."
                    : renderProfileData.description
                  : "Kindly add profile message"}
              </Typography>
            </Grid2>
            <Grid2 item size={12} pb={"10px"}>
              <Grid2 item size={12}>
                {orgInfo?.userId && orgInfo?.userId !== tempInfo?.userId ? (
                  // followingList?.listFollowing?.some(
                    baseFollowingList?.some(
                    (item) => item.edfId === tempInfo?.userId
                  ) ? (
                    <Button
                      position={"start"}
                      type="submit"
                      variant="contained"
                      style={{
                        borderRadius: "20px",
                        width: isMobile ? "100%" : "100%",
                        color: "rgba(188, 23, 29, 1)",
                        backgroundColor: "#BC171D1A",
                        textTransform: "none",
                        boxShadow: "none",
                      }}
                      onClick={() =>
                        handleUnfollowUser({ unFollowId: tempInfo?.userId })
                      }
                    >
                      Unfollow
                    </Button>
                  ) : (
                    <Button
                      position={"start"}
                      type="submit"
                      variant="contained"
                      style={{
                        borderRadius: "20px",
                        width: isMobile ? "100%" : "100%",
                        color: "rgba(188, 23, 29, 1)",
                        backgroundColor: "#BC171D1A",
                        textTransform: "none",
                        boxShadow: "none",
                      }}
                      onClick={() =>
                        handleFollowUser({ followId: tempInfo?.userId })
                      }
                    >
                      Follow
                    </Button>
                  )
                ) : null}
              </Grid2>
            </Grid2>

            <Grid2
              item
              size={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  ...followerStyle,
                }}
                onClick={() => handleTabChange("followers")}
              >
                <img
                  src={group}
                  alt="group"
                  style={{
                    ...iconStyle,
                    filter:
                      selectedTab === "followers" && activeTabStyle?.color
                        ? "sepia(15) saturate(14) hue-rotate(-30deg)"
                        : "none",
                  }}
                />

                <span
                  style={{
                    ...numberStyle,
                    ...(selectedTab === "followers" ? activeTabStyle : {}),
                  }}
                >
                  {renderProfileData?.followers || "-"}
                </span>
                <span
                  style={{
                    ...styleText,
                    ...(selectedTab === "followers" ? activeTabStyle : {}),
                  }}
                >
                  Followers
                </span>
              </div>

              <Divider orientation="vertical" flexItem />

              <div
                style={{
                  ...followerStyle,
                  ...(selectedTab === "following" ? activeTabStyle : {}),
                }}
                onClick={() => handleTabChange("following")}
              >
                <img
                  src={checkedUser}
                  alt="check user"
                  style={{
                    ...iconStyle,
                    filter:
                      selectedTab === "following" && activeTabStyle?.color
                        ? "sepia(1) saturate(12) hue-rotate(-38deg)"
                        : "none",
                  }}
                />

                <span
                  style={{
                    ...numberStyle,
                    ...(selectedTab === "following" ? activeTabStyle : {}),
                  }}
                >
                  {renderProfileData?.following || "-"}
                </span>
                <span
                  style={{
                    ...styleText,
                    ...(selectedTab === "following" ? activeTabStyle : {}),
                  }}
                >
                  Following
                </span>
              </div>

              <Divider orientation="vertical" flexItem />

              <div
                style={{
                  ...followerStyle,
                  ...(selectedTab === "picnotes" ? activeTabStyle : {}),
                }}
                onClick={() => handleTabChange("picnotes")}
              >
                <img
                  src={elements}
                  alt="Notes"
                  style={{
                    ...iconStyle,
                    filter:
                      selectedTab === "picnotes" && activeTabStyle?.color
                        ? "sepia(15) saturate(14) hue-rotate(-30deg)"
                        : "none",
                  }}
                />
                <span
                  style={{
                    ...numberStyle,
                    ...(selectedTab === "picnotes" ? activeTabStyle : {}),
                  }}
                >
                  {renderProfileData?.picnotes || "-"}
                </span>
                <span
                  style={{
                    ...styleText,
                    ...(selectedTab === "picnotes" ? activeTabStyle : {}),
                  }}
                >
                  Picnotes
                </span>
              </div>
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
    </>
  );
}

export default MyProfile;
