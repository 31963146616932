import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { loginWithSocial } from "../redux/auth";
import { Grid, Typography } from "@mui/material";
import { getFollowingList } from "../redux/tags";

const AuthCallback = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [authChecked, setAuthChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { followingList } = useSelector((state) => state.tags);

  // useEffect(() => {
  //   dispatch(getFollowingList());
  // }, [dispatch]);

  useEffect(() => {
    const login = async () => {
      if (!isLoading && isAuthenticated && user && !authChecked) {
        setAuthChecked(true); // Prevent multiple calls

        // Prepare user data for backend
        const data = {
          pnSection: "Edfluencer",
          email: user?.email || "",
          firstName: user?.given_name || user?.name || "Unknown",
          lastName: user?.family_name || "",
          picture: user?.picture || "",
          sub: user?.sub || "",
          email_verified: true,
          smuSource: user?.sub?.includes("google-oauth2")
            ? "google"
            : user?.sub?.includes("facebook")
            ? "facebook"
            : "unknown",
        };

        try {
          const resp = await dispatch(loginWithSocial(data));
          if (resp?.status === 200) {
            
            // Save token & user info
            localStorage.setItem("token", resp.data?.token);
            localStorage.setItem(
              "userInfo",
              JSON.stringify(resp.data?.userData)
            );
            localStorage.setItem("Temptoken", resp.data?.token);
            localStorage.setItem(
              "TempuserInfo",
              JSON.stringify(resp.data?.userData)
            );
            localStorage.setItem(
              "baseFollowingList",
              JSON.stringify(followingList)
            );
            dispatch(getFollowingList());

            toast.success("Login successful, Welcome!");

            // Redirect to dashboard or any page
            navigate("/app/pages");
            window.location.reload();
          } else {
            // Show error messages
            const messages = Array.isArray(resp?.data?.message)
              ? resp.data?.message
              : [resp.data?.message];
            messages.forEach((msg) => toast.error(msg?.msg || msg));
          }
        } catch (error) {
          console.error("Login failed:", error);
          toast.error("Something went wrong. Please try again.");
        }
      }
    };

    login();
  }, [isAuthenticated, user, isLoading, dispatch, authChecked, navigate]);

  return (
    <Grid style={{ marginBottom: "95%" }}>
      {/* <Typography variant="h6">Loading...</Typography> */}
    </Grid>
  );
};

export default AuthCallback;
