import { Divider, Grid2, Typography } from "@mui/material";
import React, { useState } from "react";
import logo from "./../../assets/Logo.jpg";
import { Link } from "react-router-dom";
import facebookicon from "./../../assets/fb.png";
import instaIcon from "./../../assets/insta.png";
import LinkedInicon from "./../../assets/linkedIn.png";
import xicon from "./../../assets/x.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CreateNewPicnote from "../../components/create-picnote/CreateNewPicnote";

const styles = {
  fontFamily: "Aileron",
  fontWeight: 300,
  fontSize: "16px",
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
};

const footerHeading = {
  fontFamily: "Cako regular",
  fontWeight: 400,
  fontSize: "18px",
  color: "rgba(63, 63, 63, 1)",
  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
};

const avatarStyle = {
  color: "rgba(188, 23, 29, 1)",
  width: "35px",
  height: "35px",
  // cursor: "not-allowed",
};

const footerLinks = {
  textDecoration: "none",
  color: "rgba(112, 112, 112, 1)",
  fontFamily: "Aileron",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "20px",
  textAlign: "left",
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
};

function Footer() {
  const [orgInfo, setOrgInfo] = useState(() =>
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [createPicnoteClicked, setCreatePicnoteClicked] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickedCreateNote = () => {
    setCreatePicnoteClicked(true);
  };
  const handleClose = () => {
    setCreatePicnoteClicked(false);
  };

  return (
    <Grid2 container backgroundColor="#fff">
      {createPicnoteClicked && orgInfo && (
        <CreateNewPicnote onClose={handleClose} />
      )}

      <Divider />

      {/* Main Footer Content */}
      <Grid2
        item
        size={12}
        container
        margin={isMobile ? "20px 0px" : "40px 0px"}
      >
        <Grid2 item size={0.5}></Grid2>

        {/* Logo and Description */}
        <Grid2
          item
          // size={4.5}
          size={isMobile ? 12 : 4.5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: isMobile ? "center" : "start",
            alignItems: isMobile ? "center" : "start",
          }}
        >
          <Grid2
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              width: isMobile ? "100%" : "auto", // Ensure full width for mobile
              // padding: isMobile ? "0 16px" : "0", // Optional: Add padding for mobile spacing
            }}
          >
            <Link to="./dashboard">
              <img
                src={logo}
                alt="Logo"
                style={{ cursor: "pointer", width: "128px", height: "35px" }}
              />
            </Link>
          </Grid2>

          <Grid2
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: isMobile ? "20px" : null,
            }}
          >
            <Typography
              style={{
                ...styles,
                color: "rgba(112, 112, 112, 1)",
                marginTop: "8px",
                textAlign: "justify",
              }}
            >
              Picnotes combines the appeal of a picture <br />
              sharing platform to an educational <br />
              website.
            </Typography>
          </Grid2>
        </Grid2>

        {/* Footer Links */}
        <Grid2 item size={isMobile ? 12 : 7} container spacing={4}>
          {/* Company Section */}
          <Grid2
            item
            size={isMobile ? 6 : 4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              gap: isMobile ? "5px" : "18px",
              paddingLeft: isMobile ? "10px" : "0px",
            }}
          >
            <Typography style={footerHeading}>Company</Typography>

            <Grid2
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Grid2
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: isMobile ? "5px" : "20px",
                }}
              >
                <Link to="./dashboard" style={footerLinks}>
                  Home
                </Link>
                <Link to="./aboutus" style={footerLinks}>
                  About us
                </Link>
                {/* <Link to="./schoolmainpage" style={footerLinks}>
                  For Schools
                </Link> */}
              </Grid2>

              <Grid2
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: isMobile ? "5px" : "20px",
                }}
              >
                <Link to="./blogs" style={footerLinks}>
                  Blogs
                </Link>
                <Link
                  to="#"
                  style={footerLinks}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent navigation
                    if (orgInfo) {
                      setCreatePicnoteClicked(true); // Show CreateNewPicnote
                    } else {
                      window.location.href = "./login"; // Redirect to login if orgInfo is false
                    }
                  }}
                >
                  Create Picnotes
                </Link>
              </Grid2>
            </Grid2>
          </Grid2>

          {/* Support Section */}
          <Grid2
            item
            size={isMobile ? 6 : 4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              gap: isMobile ? "5px" : "18px",
            }}
          >
            <Typography style={footerHeading}>Support</Typography>

            <Grid2
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Grid2
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: isMobile ? "5px" : "20px",
                }}
              >
                <Link to="./userGuide" style={footerLinks}>
                  Guidelines
                </Link>
                <Link to="./termsandconditions" style={footerLinks}>
                  Terms & Conditions
                </Link>
              </Grid2>

              <Grid2
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: isMobile ? "5px" : "20px",
                }}
              >
                {/* <Typography onClick={handlePrivacyNPolicy} style={footerLinks}>Privacy Policy</Typography> */}
                <Link to="./privacyandpolicy" style={footerLinks}>
                  Privacy Policy
                </Link>
                <Link to="./contactus" style={footerLinks}>
                  Contact us
                </Link>
              </Grid2>
            </Grid2>
          </Grid2>

          {/* Social Media Section */}
          <Grid2
            item
            size={isMobile ? 12 : 4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: isMobile ? "center" : "start",
              justifyContent: isMobile ? "center" : "start",
              gap: "18px",
            }}
          >
            <Typography style={footerHeading}>Follow us on</Typography>

            <Grid2 sx={{ display: "flex", gap: "8px" }}>
              <Link to="https://x.com/PicnotesEd">
                <img src={xicon} alt="X logo" style={avatarStyle} />
              </Link>
              <Link to="https://www.facebook.com/PicnotesEd">
                <img
                  src={facebookicon}
                  alt="Facebook logo"
                  style={avatarStyle}
                />
              </Link>
              <Link to="https://www.linkedin.com/company/picnotesed">
                <img
                  src={LinkedInicon}
                  alt="LinkedIn logo"
                  style={avatarStyle}
                />
              </Link>
              <Link to="https://www.instagram.com/picnotes_knowledgehub/">
                <img src={instaIcon} alt="Instagram logo" style={avatarStyle} />
              </Link>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>

      {/* Copyright Section */}
      <Grid2
        item
        size={12}
        style={{ backgroundColor: "rgba(188, 23, 29, 1)", padding: "20px" }}
      >
        
        <Typography style={{ ...styles, color: "#fff", textAlign: "center" }}>
          Copyright © {new Date().getFullYear()}. All rights reserved
        </Typography>
      </Grid2>
    </Grid2>
  );
}

export default Footer;
