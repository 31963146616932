import axios from "axios";
import config from "../services/config";
import { createSlice } from "@reduxjs/toolkit";


// editFCCloading === edit flashCards Category


const initialState = {
  loading: false,
  editFCCloading: false,
  deleteFCCloading: false,
  getFCCloading: false,
  FCCList: null,
  CreatingFCloading: false,
  editFCloading: false,
  deleteFCloading: false,
  getFCLloading: false,
  FCList: null,
  getCWCloading: false,
  CategoriesWithCards: [],
  CategoryCards: null,
  getCOfCloading: false
};

const slice = createSlice({
  name: "flashcards",
  initialState,
  reducers: {
    creatingFlashCardsC(state, action) {
      state.loading = true;
      state.error = null;
    },
    creatingFlashCardsCSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    creatingFlashCardsCFailure(state, action){
      state.loading = false;
      
    },
    editingFlashCardsC(state, action) {
      state.editFCCloading = true;
      state.error = null;
    },
    editingFlashCardsCSuccess(state, action) {
      state.editFCCloading = false;
      state.error = null;
    },
    editingFlashCardsCFailure(state, action){
      state.editFCCloading = false;     
    },
    deletingFlashCardCategory(state, action) {
      state.deleteFCCloading = true;
      state.error = null;
    },
    deletingFlashCardCategorySuccess(state, action) {
      state.deleteFCCloading = false;
      state.error = null;
    },
    deletingFlashCardCategoryFailure(state, action){
      state.deleteFCCloading = false;     
    },


    gettingFlashCardCategoryList(state, action) {
      state.getFCCloading = true;
      state.error = null;
    },
    gettingFlashCardCategoryListSuccess(state, action) {
      state.getFCCloading = false;
      state.error = null;
      state.FCCList = action.payload
    },
    gettingFlashCardCategoryListFailure(state, action){
      state.getFCCloading = false;     
    },


    creatingFlashCards(state, action) {
      state.CreatingFCloading = true;
      state.error = null;
    },
    creatingFlashCardsSuccess(state, action) {
      state.CreatingFCloading = false;
      state.error = null;
    },
    creatingFlashCardsFailure(state, action){
      state.CreatingFCloading = false;
      
    },
    editingFlashCard(state, action) {
      state.editFCloading = true;
      state.error = null;
    },
    editingFlashCardSuccess(state, action) {
      state.editFCloading = false;
      state.error = null;
    },
    editingFlashCardFailure(state, action){
      state.editFCloading = false;     
    },
    deletingFlashCard(state, action) {
      state.deleteFCloading = true;
      state.error = null;
    },
    deletingFlashCardSuccess(state, action) {
      state.deleteFCloading = false;
      state.error = null;
    },
    deletingFlashCardFailure(state, action){
      state.deleteFCloading = false;     
    },
    gettingFlashCardList(state, action) {
      state.getFCLloading = true;
      state.error = null;
    },
    gettingFlashCardListSuccess(state, action) {
      state.getFCLloading = false;
      state.error = null;
      state.FCList = action.payload
    },
    gettingFlashCardListFailure(state, action){
      state.getFCLloading = false;     
    },
    gettingCategoryWithCards(state, action) {
      state.getCWCloading = true;
      state.error = null;
    },
    gettingCategoryWithCardsSuccess(state, action) {
      state.getCWCloading = false;
      state.error = null;
      state.CategoriesWithCards = action.payload;

    },
    gettingCategoryWithCardsFailure(state, action) {
      state.getCWCloading = false;
      state.error = action.payload;
      state.CategoriesWithCards = null;
    },
    gettingCardOfCategory(state, action) {
      state.getCOfCloading = true;
      state.error = null;
    },
    gettingCardOfCategorySuccess(state, action) {
      state.getCOfCloading = false;
      state.error = null;
      state.CategoryCards = action.payload;

    },
    gettingCardOfCategoryFailure(state, action) {
      state.getCOfCloading = false;
      state.error = action.payload;
      state.CategoryCards = null; 
    },
  },
});

export const {
  creatingFlashCardsC,
  creatingFlashCardsCSuccess,
  creatingFlashCardsCFailure,
  editingFlashCardsC,
  editingFlashCardsCSuccess,
  editingFlashCardsCFailure,
  deletingFlashCardCategory,
  deletingFlashCardCategorySuccess,
  deletingFlashCardCategoryFailure,
  gettingFlashCardCategoryList,
  gettingFlashCardCategoryListSuccess,
  gettingFlashCardCategoryListFailure,
  creatingFlashCards,
  creatingFlashCardsSuccess,
  creatingFlashCardsFailure,
  editingFlashCard,
  editingFlashCardSuccess,
  editingFlashCardFailure,
  deletingFlashCard,
  deletingFlashCardSuccess,
  deletingFlashCardFailure,
  gettingFlashCardList,
  gettingFlashCardListSuccess,
  gettingFlashCardListFailure,
  gettingCategoryWithCards,
  gettingCategoryWithCardsSuccess,
  gettingCategoryWithCardsFailure,
  gettingCardOfCategory,
  gettingCardOfCategorySuccess,
  gettingCardOfCategoryFailure
} = slice.actions;

export const flashCardReducer = (state) => state.flashcards;

export const creatingFlashCardCategory = (data) => async (dispatch) => {
  dispatch(creatingFlashCardsC());
  try {
    const response = await axios.post(
      `${config.baseUrl}/cards/addcategorywithcard`,
      data
    );
    if (response.status === 200) {
      dispatch(creatingFlashCardsCSuccess(response.data));
    } else {
      dispatch(creatingFlashCardsCFailure(response.status));
    }
    return response;
  } catch (e) {
    dispatch(
      creatingFlashCardsCFailure(
        "Something went wrong while craeting card category"
      )
    );
    return { status: 500, message: "Request failed" };
  }
};


export const editFlashCardCategory = (data) => async (dispatch) => {
  dispatch(editingFlashCardsC());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/cards/editcategory`,
      data
    );
    if (response.status === 200) {
      dispatch(editingFlashCardsCSuccess(response.data));
    } else {
      dispatch(editingFlashCardsCFailure(response.status));
    }
    return response;
  } catch (e) {
    dispatch(
      editingFlashCardsCFailure(
        "Something went wrong while editing card category"
      )
    );
    return { status: 500, message: "Request failed" };
  }
};

export const deleteFlashCardCategory = (categoryId) => async (dispatch) => {
  dispatch(deletingFlashCardCategory());
  try {
    const response = await axios.get(
      `${config.baseUrl}/cards/deletecategory/${categoryId}`);

    if (response.status === 200) {
      dispatch(deletingFlashCardCategorySuccess(response.data)); 
    } else {
      dispatch(deletingFlashCardCategoryFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      deletingFlashCardCategoryFailure("Something went wrong while deleting picnote!")
    );
  }
};

export const getCardsOfCategory = (categoryId) => async (dispatch) => {
  dispatch(gettingCardOfCategory());
  try {
    const response = await axios.get(
      `${config.baseUrl}/cards/categorycardlist/${categoryId}`);

    if (response.status === 200) {
      dispatch(gettingCardOfCategorySuccess(response.data)); 
    } else {
      dispatch(gettingCardOfCategoryFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      gettingCardOfCategoryFailure("Something went wrong while deleting picnote!")
    );
  }
};

export const getFlashCardCategoryList = (categoryId) => async (dispatch) => {
  dispatch(gettingFlashCardCategoryList());
  try {
    const response = await axios.get(
      `${config.baseUrl}/cards/deletecategory/${categoryId}`);

    if (response.status === 200) {
      dispatch(gettingFlashCardCategoryListSuccess(response.data)); 
    } else {
      dispatch(gettingFlashCardCategoryListFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      gettingFlashCardCategoryListFailure("Something went wrong while deleting picnote!")
    );
  }
};

export const creatingFlashCard = (data) => async (dispatch) => {
  dispatch(creatingFlashCards());
  try {
    const response = await axios.post(
      `${config.baseUrl}/cards/addcard`,
      data
    );
    if (response.status === 200) {
      dispatch(creatingFlashCardsSuccess(response.data));
    } else {
      dispatch(creatingFlashCardsFailure(response.status));
    }
    return response;
  } catch (e) {
    dispatch(
      creatingFlashCardsFailure(
        "Something went wrong while craeting card category"
      )
    );
    return { status: 500, message: "Request failed" };
  }
};

export const editFlashCard = (data) => async (dispatch) => {
  dispatch(editingFlashCard());
  try {
    const response = await axios.patch(
      `${config.baseUrl}/cards/editcard`,
      data
    );
    if (response.status === 200) {
      dispatch(editingFlashCardSuccess(response.data));
    } else {
      dispatch(editingFlashCardFailure(response.status));
    }
    return response;
  } catch (e) {
    dispatch(
      editingFlashCardFailure(
        "Something went wrong while editing card "
      )
    );
    return { status: 500, message: "Request failed" };
  }
};

export const deleteFlashCard = (cardId) => async (dispatch) => {
  dispatch(deletingFlashCard());
  try {
    const response = await axios.get(
      `${config.baseUrl}/cards/deletecard/${cardId}`);

    if (response.status === 200) {
      dispatch(deletingFlashCardSuccess(response.data)); 
    } else {
      dispatch(deletingFlashCardFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      deletingFlashCardFailure("Something went wrong while deleting card!")
    );
  }
};

export const getFlashCardList = () => async (dispatch) => {
  dispatch(gettingFlashCardList());
  try {
    const response = await axios.get(
      `${config.baseUrl}/cards/cardlist`);

    if (response.status === 200) {
      dispatch(gettingFlashCardListSuccess(response.data)); 
    } else {
      dispatch(gettingFlashCardListFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      gettingFlashCardListFailure("Something went wrong while getting cards!")
    );
  }
};

export const getCategoryWithCards = () => async (dispatch) => {
  dispatch(gettingCategoryWithCards());
  try {
    const response = await axios.get(
      `${config.baseUrl}/cards/allcardswithcategory`);

    if (response.status === 200) {
      dispatch(gettingCategoryWithCardsSuccess(response.data)); 
    } else {
      dispatch(gettingCategoryWithCardsFailure(response.data)); 
    }

    return response;
  } catch (e) {
    dispatch(
      gettingCategoryWithCardsFailure("Something went wrong while getting folder!")
    );
  }
};

export default slice.reducer;
