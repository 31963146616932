import React, { useEffect } from "react";
import { Card, Grid, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFollowerList, setSelectedFollower } from "../redux/tags";
import { tempProfile } from "../redux/user";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

const nameStyle = {
  fontFamily: "Aileron",
  fontSize: "14px",
  fontWeight: 600,
  textAlign: "center",
  color: "rgba(63, 63, 63, 1)",
};

const notesStyle = {
  fontFamily: "Aileron",
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "center",
  color: "rgba(112, 112, 112, 1)",
};

function Followers() {
  const dispatch = useDispatch();
    const navigate = useNavigate();
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const handleProfileClick = async (follower) => {
    dispatch(setSelectedFollower(follower));
    const tempResp = await dispatch(tempProfile(follower?.edfId));
  
    // Set temporary user data in localStorage
    localStorage.setItem("Temptoken", tempResp.data?.tempToken);
    localStorage.setItem("TempuserInfo", JSON.stringify(tempResp.data.tempInfo));
  
    // Check if userInfo exists in localStorage to decide the navigation path
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  
    // Navigate based on whether userInfo is present
    if (!userInfo) {
      navigate("/auth/pages"); // If no userInfo, navigate to the auth page
    } else {
      navigate("/app/pages"); // If userInfo exists, navigate to the app page
    }
    window.location.reload();

  };
  

  const { FollowersList } = useSelector((state) => state.tags);

  useEffect(() => {
    dispatch(getFollowerList());
  }, []);



  return (
    <Grid container spacing={2} sx={{ justifyContent: "start" }}>
      {FollowersList?.listFollowers.map((follower, index) => (
        <Grid
          item
          key={index}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Card
            elevation={isMobile ? 3 : 1}
            sx={{
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              width: "100%",
              maxWidth: "200px",
              cursor: "pointer",
            }}
            onClick={() => handleProfileClick(follower)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* Image at the top */}
              {follower?.edfPicture?.endsWith("jpg") ||
              follower?.edfPicture?.endsWith("jpeg") ||
                follower?.edfPicture?.endsWith("png")  ? (
                <img
                  src={follower.edfPicture}
                  alt={follower.firstName}
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    marginBottom: "15px",
                  }}
                />
              ) : (
                <Box
                  width="70px"
                  height="70px"
                  borderRadius="50%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="#b10303"
                  color="white"
                  fontSize="25px"
                  fontWeight="bold"
                  textTransform="uppercase"
                  marginBottom= "15px"
                >
                  {follower?.firstName?.[0]}
                  {follower?.lastName?.[0]}
                </Box>
              )}
              {/* Name in the center */}
              <Typography style={nameStyle} sx={{ marginBottom: "8px" }}>
                {follower?.firstName + " " + follower?.lastName}
              </Typography>
              {/* Picnotes at the bottom */}
              <Typography style={notesStyle}>
                Picnotes: {follower?.noteCounts}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default Followers;
